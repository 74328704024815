import { useEffect, useState } from "react";
import Icons from "../../../orders/icons";
import { Link } from "react-router-dom";
import ArbitNewAttributeModal from "../../attribute-modal";
import { useSelector, useDispatch } from "react-redux";
import ArbitNewAttributeGroupModal from "../../attribute-group-modal";
import {
  addProductImages,
  addProductImagesLink,
  deleteProductImage,
  fetchAttributeGroups,
  fetchAttributes,
  fetchProduct,
  getAttributeTypes,
} from "../../../../store/products";
import ArbitAttributeItem from "../../attribute-item";

const Attributes = ({ id, productLanguage, productData }) => {
  const [tags, setTags] = useState([]);
  const [tagValue, setTagValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tagValue) {
      setTags((prevVal) => [...prevVal, tagValue]);
      setTagValue("");
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAttributes());
    dispatch(fetchAttributeGroups());
    dispatch(getAttributeTypes());
  }, []);


  const [visibleNewAttributeModal, setVisibleNewAttributeModal] =
    useState(false);
  const attributeGroups = useSelector(
    (state) => state.productReducer.attributeGroups
  );
  const attributeTypes = useSelector(
    (state) => state.productReducer.attributeTypes
  );
  const [visibleNewAttributeGroupModal, setVisibleNewAttributeGroupModal] =
    useState(false);
  const attributeGroupsData = attributeGroups?.map((item) => item?.name);
  const attributesData = useSelector(
    (state) => state.productReducer.attributes
  );

  const [dispatchListener, setDispatchListener] = useState(false);
  

  const checkAttribute = (att, id) => {
    const idsToCheck = [22, 23, 20, 21, 39, 15, 75, 29];
    if (att?.Other && idsToCheck.includes(id)) {
      return false;
    }
    return true;
  };

  const attributes = productData?.attributes || {}; // Default to an empty object if attributes is undefined or null
  const [isClicked2, setClicked2] = useState(false);
  return (
    <div
      className={`border ${
        !isClicked2 && "h-[75px]"
      } border-gray-100 shadow-md rounded-[16px] p-4 flex flex-col gap-2.5 w-[100%] `}
    >
      <div className="flex  justify-between items-center border-b-[1px] border-gray-100  text-gray-100 pb-2.5 ">
        <div className="flex items-center gap-2.5">
          {!isClicked2 ? (
            <Icons.chevronUp
              onClick={() => setClicked2(!isClicked2)}
              className="w-5 h-5 cursor-pointer text-gray-800"
            />
          ) : (
            <Icons.chevronDown
              onClick={() => setClicked2(!isClicked2)}
              className="w-5 h-5 cursor-pointer text-gray-800"
            />
          )}
          <span className="text-[18px]  font-bold leading-6 text-gray-900">
            ATTRIBUTES
          </span>
        </div>
        <div className="flex ">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={"/settings/attributes"}
            className="text-blue-700 hover:text-blue-500 px-4 text-[14px] leading-normal font-semibold"
          >
            MANAGE
          </Link>
          <button
            onClick={() => {
              setVisibleNewAttributeModal(true);
            }}
            className="flex gap-1 items-center px-4 "
          >
            <Icons.circlePlus className="w-5 h-5 text-blue-500" />
            <span className="text-blue-700  text-[14px] leading-normal font-medium">
              ADD
            </span>
          </button>
        </div>
      </div>
      <div className="flex gap-4 flex-wrap  ">
        {isClicked2 &&
          Object.keys(attributes)
            .filter((key) => key !== "Identifiers") // Exclude "Identifiers"
            .map((item2, index) => (
              <div className="flex gap-4 flex-wrap" key={index}>
                {attributes[item2]?.map((item) => {
                  const isThere = checkAttribute(
                    attributes,
                    item["attribute_id"]
                  );
                  if (isThere) {
                    return (
                      <ArbitAttributeItem
                        key={item["id"]}
                        attribute={item}
                        name={item["attribute_name"]}
                        value={item["value"]}
                        attributeId={item["id"]}
                        validationRule={item?.attribute?.validation_rules}
                        description={item?.attribute?.description}
                        id={id}
                        productLanguage={productLanguage}
                      />
                    );
                  }
                  return null; // Ensure to return null if condition fails
                })}
              </div>
            ))}
      </div>
      {visibleNewAttributeModal && (
        <ArbitNewAttributeModal
          show={visibleNewAttributeModal}
          onClose={() => setVisibleNewAttributeModal(false)}
          attributesData={attributesData}
          attributeGroups={attributeGroups}
          attributeTypes={attributeTypes}
          createNewAttribute={() => setVisibleNewAttributeGroupModal(true)}
          id={id}
          attributeLocale={productLanguage}
          productLanguage={productLanguage}
        />
      )}
      {visibleNewAttributeGroupModal && (
        <ArbitNewAttributeGroupModal
          onClose={() => setVisibleNewAttributeGroupModal(false)}
          attributeGroupsData={attributeGroupsData}
          attributeTypes={attributeTypes}
          attributeGroupsList={attributeGroups}
          onCloseParent={() => setVisibleNewAttributeModal(false)}
          dispatchListener={dispatchListener}
          setDispatchListener={setDispatchListener}
          show={visibleNewAttributeGroupModal}
        />
      )}
    </div>
  );
};

export default Attributes;
