import Icons from "../../icons";
import UI from "../../../dynamic-form/elements";
import uti from "../../../dynamic-page/util";
import util from "../../../dynamic-page/util";
import Util from "../../../dynamic-page/util";
import React, { useEffect, useState } from "react";
import { apiActions } from "../../../../store/orders";
import ShipmentLabelList from "./shipmentLabelList";
import priceHandler from "../../../../utils/priceHandler";
import { useDispatch, useSelector } from "react-redux";
import { IoStorefront } from "react-icons/io5";
import descriptiveContent from "../../../../utils/descriptiveContent";
import callEndpoint from "../../../dynamic-page/util/callEndpoint";
import shortenString from "../../../dynamic-page/util/shortenString";

const ShipmentLabel = ({ shipment, orderItem, callBack, updateRow }) => {
  const dispatch = useDispatch();

  const [shipmentData, setShipmentData] = useState(null);
  const data = useSelector((state) => state.orders.orders);

  useEffect(() => {
    const findItem = data?.find((item) => item?.id === orderItem?.id);
    const innerShipment = shipment;
    const billableWeight =
      findItem?.billable_weight || orderItem.billable_weight;
    const returnData = { ...innerShipment, billableWeight: billableWeight };

    setShipmentData(returnData);
  }, [data, shipment]);

  const handleCreateShipment = () => {
    if (!orderItem?.ship_to_state) {
      util.notifier({
        title: "State is required",
        message:
          "You cannot create a label without selecting the city in the order details. Please select the destination city.",
        status: "info",
      });
      return;
    }
    callBack({
      action: "createShipment",
      data: {
        id: orderItem?.id,
        defaultWarehouse: 3,
        items_qty: orderItem?.items_total_qty,
        defaultWarehouse: orderItem?.allocation_warehouse_id,
        ship_to_state: { id: orderItem?.ship_to_state?.id },
        billable_weight: orderItem?.billable_weight,
      },
    });
  };
  const handleCreateQuickShipment = () => {
    callEndpoint({
      title: "Create Label",
      url: `orders/create-shipment-label/${orderItem?.id}/${orderItem?.allocation_warehouse_id}/${shipment?.suggested_shipping_cost?.id}`,
      method: "post",
    }).then((rs) => {
      if (rs?.type === "success") {
        updateRow(orderItem?.id);
      }
    });
  };

  const handlePrintLabel = (print) => {
    callBack({
      action: "printLabel",
      data: {
        id: orderItem?.id,
      },
    });
  };
  const handleViewLabel = (print) => {
    callBack({
      action: "viewLabel",
      data: {
        id: orderItem?.id,
      },
    });
  };

  const handleFilterState = (stateId, state) => {
    callBack({
      action: "filterState",
      data: {
        stateId: stateId,
        state: state,
      },
    });
  };

  const [disabled, setDisabled] = useState(false);
  const handleIsPrimaryToggle = (id) => {
    setDisabled(true);
    dispatch(
      apiActions({
        action: "shipment-labels/mark-as-primary",
        itemId: id,
      })
    ).then((rs) => {
      setDisabled(false);
      util.notifier({
        type: rs.payload?.data?.type,
        message: rs.payload?.data?.message,
      });
      if (rs.payload?.data?.type === "success") {
        updateRow(orderItem?.id);
      }
    });
  };
  const handleIShippingLabelVisibilityToggle = (id) => {
    dispatch(
      apiActions({
        action: "toggle-shipping-label-visibility",
        orderId: orderItem?.id,
        itemId: id,
      })
    ).then((rs) => {
      util.notifier(rs.payload);
      dispatch(
        updateRow({
          path: `orders.[id:${orderItem?.id}].shipment.shipment_labels`,
          value: rs?.payload?.data?.data,
        })
      );
    });
  };

  const primaryShipmentLabel = shipmentData?.shipment_labels?.find((item) => {
    if (item?.is_primary === true && item.is_hidden === false) {
      return item;
    }
  });

  const hiddenPrimaryShipmentLabel = shipmentData?.shipment_labels?.find(
    (item) => {
      return item?.is_primary === true && item.is_hidden === true;
    }
  );

  return (
    <div className={"max-w-[300px] w-full"}>
      {shipmentData && (
        <div
          className={"flex flex-col gap-[4px]  justify-start w-full"}
          style={{ width: "100%" }}
        >
          <div
            className={
              "flex flex-row justify-between items-center gap-[16px] w-full"
            }
            style={{ width: "100%" }}
          >
            <div className="w-full">
              {primaryShipmentLabel && !hiddenPrimaryShipmentLabel && (
                <div
                  className={
                    "flex flex-row gap-[4px] box-content w-full items-center"
                  }
                >
                  <util.popUp
                    toggle={(selected) =>
                      primaryShipmentLabel?.state?.country?.flag ? (
                        descriptiveContent(
                          <img
                            onClick={() =>
                              handleFilterState(
                                primaryShipmentLabel?.state?.id,
                                {
                                  value: primaryShipmentLabel?.state?.id,
                                  label: primaryShipmentLabel?.state?.name,
                                }
                              )
                            }
                            className={"w-[22.4px] h-[16px] cursor-pointer object-cover "}
                            src={primaryShipmentLabel?.state?.country?.flag}
                          />,
                          primaryShipmentLabel?.state?.name
                        )
                      ) : (
                        <IoStorefront
                          className={"w-4 h-4 text-green-300"}
                          onClick={() =>
                            handleFilterState(primaryShipmentLabel?.state?.id)
                          }
                        />
                      )
                    }
                    action={"hover"}
                  >
                    <div className={"text-[12px] text-gray-900 font-medium"}>
                      {primaryShipmentLabel?.state?.name}
                    </div>
                  </util.popUp>
                  {descriptiveContent(
                    primaryShipmentLabel?.international_carrier?.logo ? (
                      <img
                        className={"min-w-[16px] h-[18px]"}
                        src={primaryShipmentLabel?.international_carrier?.logo}
                      />
                    ) : (
                      <Icons.track className={"w-[16px] h-4 text-gray-300"} />
                    ),
                    primaryShipmentLabel?.international_carrier?.name
                  )}
                  <span
                    onClick={() => {
                      if (primaryShipmentLabel?.tracking_url) {
                        window.open(
                          primaryShipmentLabel?.tracking_url,
                          "_blank"
                        );
                      }
                    }}
                    className={
                      "cursor-pointer flex flex-row gap-[4px] items-center max-w-[70px]"
                    }
                  >
                    <span className="truncate text-[#1A56DB]">
                      {primaryShipmentLabel?.tracking_number}
                      </span>
                  </span>
                  <util.copyText
                    className={"text-[12px]"}
                    text={primaryShipmentLabel?.tracking_number}
                    hint={""}
                  />
                  <span className="text-[14px] leading-[21px] font-semibold text-[#BF125D]">
                    ${primaryShipmentLabel?.billed_cost}
                  </span>

                </div>
              )}
              {!primaryShipmentLabel &&
                !hiddenPrimaryShipmentLabel &&
                shipmentData?.suggested_shipping_cost && (
                  <div
                    className={
                      "flex flex-row gap-[4px] box-content cursor-pointer w-full items-center"
                    }
                  >
                    {descriptiveContent(
                      shipmentData?.suggested_shipping_cost?.carrier
                        ?.international_carrier?.logo ? (
                        <img
                          className={"min-w-[20px] h-[20px]"}
                          src={
                            shipmentData?.suggested_shipping_cost?.carrier
                              ?.international_carrier?.logo
                          }
                        />
                      ) : (
                        <Icons.track className={"w-[16px] h-4 text-gray-300"} />
                      ),
                      shipmentData?.suggested_shipping_cost
                        ?.international_carrier?.name
                    )}

                    <span
                      className={
                        "text-[12px] leading-[18px] font-medium text-gray-900"
                      }
                    >
                      {
                        shipmentData?.suggested_shipping_cost?.carrier
                          ?.international_carrier?.name
                      }
                    </span>
                    <span className="text-[12px] leading-[12px] text-gray-600">
                      {shipmentData?.suggested_shipping_cost?.service_name
                        ? util.shortenString(
                            shipmentData?.suggested_shipping_cost?.service_name,
                            30
                          )
                        : "-"}
                    </span>
                  </div>
                )}
            </div>

            {((primaryShipmentLabel && hiddenPrimaryShipmentLabel) ||
              shipmentData?.shipment_labels?.length > 1) && (
              <util.popUp
                toggle={(selected) => (
                  <div
                    className={`h-[20px] min-w-[20px] flex flex-row justify-center items-center bg-purple-200 rounded-[10px] text-purple-900 font-medium hover:bg-purple-600 hover:text-white transition cursor-pointer ${
                      selected && " text-white !bg-purple-600"
                    }`}
                  >
                    {shipmentData?.shipment_labels?.length}
                  </div>
                )}
              >
                <ShipmentLabelList
                  disabled={disabled}
                  shipmentData={shipmentData}
                  actions={{
                    handleIsPrimaryToggle: handleIsPrimaryToggle,
                    handleIShippingLabelVisibilityToggle:
                      handleIShippingLabelVisibilityToggle,
                  }}
                />
              </util.popUp>
            )}
          </div>

          {primaryShipmentLabel && !hiddenPrimaryShipmentLabel && (
            <div
              className={
                "flex flex-row w-full max-w- justify-between gap-[16px]"
              }
              style={{ width: "100%" }}
            >
              <span className={"text-gray-600  flex-grow"}>
                {/* <util.expandableText
                  maxLines={1}
                  className={"text-[12px]"}
                  innerClassName="w-max"
                  tooltip={true}
                  forcePosition={"top"}
                  tooltipClassName={"text-[14px] text-gray-900 !max-w-[350px]"}
                  action={"hover"}
                  delay={500}
                  hideCopy={true}
                > */}
                  {primaryShipmentLabel?.service_name
                    ? shortenString(uti.upperCaseString(
                        // todo : where should we get carrier service from ?
                        primaryShipmentLabel?.service_name,
                        "word"
                      ), 17)
                    : "-"}
                {/* </util.expandableText> */}
              </span>
              <div
                className={
                  "text-blue-600 h-[16px] flex flex-row items-center gap-[4px]"
                }
              >
                <UI.Button
                  item={{ buttonType: "flat", title: "Create Label" }}
                  onClick={handleCreateShipment}
                >
                    <Icons.createLabel className={"w-[20px] p-[2px]"} />
                </UI.Button>

                <UI.Button
                  item={{ buttonType: "flat", title: "Show Label" }}
                  onClick={handleViewLabel}
                >
                    <Icons.showLabel className={"w-[20px] p-[0px]"} />
                </UI.Button>

                <UI.Button
                  item={{ buttonType: "flat", title: "Print Label" }}
                  onClick={handlePrintLabel}
                >
                    <Icons.printLabel className={"w-[20px] p-[2px]"} />
                </UI.Button>
              </div>
            </div>
          )}

          {!primaryShipmentLabel && (
            <div
              className={
                "flex flex-row justify-between gap-[40px] items-center"
              }
            >
              {shipmentData?.suggested_shipping_cost?.cost ? (
                <div
                  className={
                    "text-pink-700 text-[14px] leading-[21px] font-semibold"
                  }
                >
                  {"$" +
                    priceHandler(
                      // todo : if there is no primary then get from suggested , but service name is required
                      shipmentData?.suggested_shipping_cost?.cost
                    )}
                </div>
              ) : (
                "-"
              )}

              <div className={"flex flex-row gap-[4px]"}>
                {shipmentData?.suggested_shipping_cost &&
                  (orderItem?.billable_weight <= 0 ||
                  !orderItem?.shipping_content ||
                  !orderItem?.ship_to_state ? (
                    <util.popUp
                      toggle={(selected) => (
                        <UI.Button
                          item={{
                            buttonType: "primary",
                            className:
                              "h-[24px] w-[40px] px-[4px] !bg-[#1A56DB] !rounded-[6px]",
                            disabled:
                              orderItem?.billable_weight <= 0 ||
                              !orderItem?.shipping_content ||
                              !orderItem?.ship_to_state,
                          }}
                          onClick={handleCreateQuickShipment}
                        >
                          <Icons.quickShip className={"w-[20px] p-[2px]"} />
                        </UI.Button>
                      )}
                      action={"hover"}
                    >
                      <div className="flex flex-col gap-1">
                        <span>
                          {shipmentData?.billableWeight <= 0 &&
                            "Billable weight is required"}
                        </span>
                        <span>
                          {!orderItem?.shipping_content &&
                            "Shipping content is required"}
                        </span>
                        <span>
                          {!orderItem?.ship_to_state &&
                            "Destination State is required"}
                        </span>
                      </div>
                    </util.popUp>
                  ) : (
                    <UI.Button
                      item={{
                        buttonType: "primary",
                        className:
                          "h-[24px] !bg-[#1A56DB] w-[40px] px-[4px] !rounded-[6px]",
                        disabled:
                          orderItem?.billable_weight <= 0 ||
                          !orderItem?.shipping_content ||
                          !orderItem?.ship_to_state,
                      }}
                      onClick={handleCreateQuickShipment}
                    >
                      <Icons.quickShip className={"w-[20px] p-[2px]"} />
                    </UI.Button>
                  ))}

                {orderItem?.billable_weight <= 0 ||
                !orderItem?.shipping_content ||
                !orderItem?.ship_to_state ? (
                  <Util.popUp
                    toggle={(selected) => (
                      <UI.Button
                        item={{
                          buttonType: "alt",
                          className:
                            "!rounded-[6px] h-[24px] item-center flex gap-[8px]  !text-[#1A56DB]  !px-[12px] bg-transparent hover:!text-white hover:bg-[#1A56DB]",
                          disabled:
                            orderItem?.billable_weight <= 0 ||
                            !orderItem?.shipping_content ||
                            !orderItem?.ship_to_state,
                        }}
                        onClick={handleCreateShipment}
                      >
                        <span>Carriers</span>
                        <Icons.chevronRight className={"w-[13px] p-[2px]"} />
                      </UI.Button>
                    )}
                    action={"hover"}
                  >
                    <div className="flex flex-col gap-1">
                      {shipmentData?.billableWeight <= 0 && <span>
                        {shipmentData?.billableWeight <= 0 &&
                          "Billable weight is required"}
                      </span>}
                      {!orderItem?.shipping_content && <span>
                        {!orderItem?.shipping_content &&
                          "Shipping content is required"}
                      </span>}
                      {!orderItem?.ship_to_state && <span>
                        {!orderItem?.ship_to_state &&
                          "Destination State is required"}
                      </span>}
                    </div>
                  </Util.popUp>
                ) : (
                  <UI.Button
                    item={{
                      buttonType: "alt",
                      className:
                        "h-[24px] item-center flex gap-[8px]  !text-[#1A56DB] !px-[12px] bg-transparent hover:!text-white hover:bg-[#1A56DB] !rounded-[6px]",
                      disabled:
                        orderItem?.billable_weight <= 0 ||
                        !orderItem?.shipping_content ||
                        !orderItem?.ship_to_state,
                    }}
                    onClick={handleCreateShipment}
                  >
                    <span>Carriers</span>
                    <Icons.chevronRight className={"w-[13px] p-[2px]"} />
                  </UI.Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ShipmentLabel;
