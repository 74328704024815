const checkUrl = (api, formData, innerFormData = {}, value = null, loadedOptions, loading) => {

    // protect function for first render
    if (formData && Object.keys(formData).length === 0) return false;
    if (!api?.url) return false;
    if (innerFormData && Object.keys(innerFormData).length === 0) return false;

    const childNames = Object.keys(formData);

    let returnValue = {
        disable: false,
        reset: false,
        load: false,
        related: false
    }
    for (let i = 0; i < childNames.length; i++) {
        const childName = childNames[i];
        const searchQuery = `{${childName}}`;


        if (api?.url?.includes(searchQuery)) {
            returnValue.related = true;
            if (formData[childName]) {
                returnValue.load = true;
            } else {
                returnValue.load = false;
            }
            if (!formData[childName] || (loadedOptions?.length === 0 && loading)) {
                returnValue.disable = true
            }

            if (innerFormData[childName] !== formData[childName]) {
                returnValue.reset = true
            }
            /* if (innerFormData[childName]) {
                 if (formData[childName]) {

                 }
             } && formData[childName] !== innerFormData[childName] && value) return true;*/
        }
        //} (formData[childNames[i]] === null || formData[childNames[i]] === "" || formData[childNames[i]] === undefined)) return true;

    }

    return returnValue;
};

export default checkUrl;
