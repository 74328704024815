import React, { useEffect, useRef, useState } from "react";
import Icons from "../../orders/icons";
import { HiPlus } from "react-icons/hi";
import UI from "../../dynamic-form/elements";
import util from "../../dynamic-page/util";
import { addNote, deleteNote, fetchNotes } from "../../../store/sales-listings";
import { useDispatch, useSelector } from "react-redux";
import { updateRow } from "../../../store/fc-restocking";

const InternalNote = ({ notes, id, callBack }) => {
  const scrollableDivRef = useRef(null);

  const dispatch = useDispatch();
  const [allNotes, setAllNotes] = useState(notes);
  const [deleteList, setDeleteList] = useState([]);
  const [lastAction, setLastAction] = useState(null);
  const [note, setNote] = useState("");
  const [updateNotesState, setUpdateNotesState] = useState(false);
  const [addVisibility, setAddVisibility] = useState(false);
  const currentUserId = useSelector((state) => state?.authUser?.authUser?.id);

  let itemId = null;
  let orderId = id;
  if (typeof orderId === "string" && orderId.includes("/")) {
    itemId = orderId.split("/")[1];
    orderId = orderId.split("/")[0];
  }

  const handleChangeNoteInput = (data) => {
    setNote(data);
  };
  const handleBlurNoteInput = (e) => {
    if (note.length < 1) {
      setAddVisibility(false);
    }
  };
  const handleAddNote = async () => {
    if (!note) return false;
    setUpdateNotesState(true);
    await dispatch(addNote({ orderId: id, note: note }));

    let getNotes = await dispatch(fetchNotes({ orderId: id }));
    setLastAction("add");
    getNotes = getNotes?.payload?.data;

    if (itemId) {
      callBack({ action: "updateNote", data: { id: itemId, list: getNotes } });
      //if (callBack) callBack(getNotes)
    } else {
      dispatch(
        updateRow({
          path: `fcRestockings.[id:${orderId}].notes`,
          value: getNotes,
        })
      );
    }

    //dispatch(updateNotes({orderId: id, notes: getNotes}))
    setUpdateNotesState(false);
    setAllNotes(getNotes);
    setNote("");
  };
  const handleDeleteNote = async (noteId) => {
    setDeleteList((prevData) => [...prevData, noteId]);
    setLastAction("delete");
    setUpdateNotesState(true);

    await dispatch(deleteNote({ orderId: id, noteId: noteId }));

    let getNotes = await dispatch(fetchNotes({ orderId: id }));
    getNotes = getNotes?.payload?.data;

    if (itemId) {
      if (callBack) callBack(getNotes);
    } else {
      dispatch(
        updateRow({
          path: `fcRestockings.[id:${orderId}].notes`,
          value: getNotes,
        })
      );
    }

    //dispatch(updateNotes({orderId: id, notes: getNotes}))
    setUpdateNotesState(false);
    setAllNotes(getNotes);
  };
  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  }, [allNotes]);

  const lineAnimationStyles = {
    width: "50%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    position: "absolute",
    left: "0",
    animation: "line-animation 0.5s linear infinite",
  };
  return (
    <div className={" flex flex-col gap-[0px]"}>
      {updateNotesState && (
        <div
          style={{
            top: "15px",
            left: "10px",
            width: "calc(100% - 20px)",
            height: "5px",
            backgroundColor: "white",
            position: "absolute",
            overflow: "hidden",
            borderRadius: "5px",
            background: "#jjj",
          }}
        >
          <div style={lineAnimationStyles}></div>
        </div>
      )}
      <style>
        {" "}
        {`  @keyframes line-animation { 0% { left: -50%; } 100% { left: 100%; } } @keyframes new-animation { 0% {  background: rgba(164,202, 254, 0.3); } 90% { background: rgba(164,202, 254, 0.3); } 100% { background: white; } } .new-item { animation: new-animation 1.5s linear; }`}{" "}
      </style>
      <div
        className={
          "text-sm flex gap-[8px] flex-col max-h-[238px] overflow-y-auto pb-[10px] transition"
        }
        ref={scrollableDivRef}
      >
        {allNotes && allNotes.length > 0 ? (
          allNotes.map((note, index) => (
            <div
              key={index}
              className={`
                                
                                ${
                                  lastAction === "add" &&
                                  index === allNotes.length - 1
                                    ? "new-item"
                                    : ""
                                } rounded-[8px]
                                ${
                                  deleteList.find((item) => item === note.id)
                                    ? "bg-red-100"
                                    : ""
                                }
                             `}
            >
              <div
                className={`flex flex-row gap-[12px] justify-between px-[4px] py-4 shadow-sm rounded-[8px]`}
              >
                {/*Photo Section */}
                <div className={"w-[32px] h-[32px]"}>
                  <img
                    className="rounded-full w-full h-full object-cover"
                    src={
                      note?.created_by?.profile_image ||
                      "/assets/images/defaultAvatar.png"
                    }
                    alt={note?.created_by?.name}
                  />
                </div>

                <div
                  className={"flex flex-col max-w-[385px]  gap-[4px] flex-1"}
                >
                  <div className={"flex flex-row gap-[4px] flex-1"}>
                  {note?.created_by ? <span
                        title={new Date(note?.created_at).toLocaleString()}
                        className={"font-semibold cursor-default w-full"}
                      >
                        {util.upperCaseString(note?.created_by?.name, "word")}
                      </span> : <div className="text-gray-400">Deleted User</div>}
                    <div className={"text-gray-400 w-max whitespace-nowrap"}>
                      {new Date(note?.created_at).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  </div>
                  <div
                    className={`break-words   whitespace-pre-wrap    w-[400px]`}
                  >
                    <span className={"text-gray-500 w-[400px]  "}>
                      {util.upperCaseString(note?.note)}
                    </span>
                  </div>
                </div>
                <div
                  className={`w-[32px] h-[32px] justify-center items-center flex cursor-pointer  hover:text-red-600 rounded-full  transition relative top-[-7px]
                                        ${
                                          deleteList.find(
                                            (item) => item === note.id
                                          )
                                            ? "hidden"
                                            : ""
                                        }
                                        ${
                                          note?.created_by?.id !== currentUserId
                                            ? "cursor-not-allowed text-gray-200 hover:bg-none hover:text-gray-200 "
                                            : "hover:bg-red-100 "
                                        }
                                    `}
                  onClick={() => {
                    if (note?.created_by?.id !== currentUserId) return;
                    handleDeleteNote(note?.id);
                  }}
                >
                  <Icons.del className={"h-[15px]"} />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            className={
              "flex flex-row gap-[4px] flex-row gap-[12px] p-[12px] shadow-sm rounded-[8px] text-gray-400"
            }
          >
            No internal notes added yet.
          </div>
        )}
      </div>
      <div className={"flex flex-col text-sm gap-[0px] "}>
        <div className="flex w-full justify-end">
          <div
            className={`h-[28px] w-[28px] flex rounded-full justify-center  items-center text-blue-600  border-blue-600 border cursor-pointer hover:bg-blue-600 hover:text-white transition ${
              addVisibility && "hidden"
            }`}
            onClick={() => {
              setAddVisibility(!addVisibility);
            }}
          >
            <HiPlus className={"h-[15px] w-[15px]"} />
          </div>
        </div>

        <div
          className={`flex flex-row gap-[4px] overflow-hidden  ${
            addVisibility ? " h-fit" : "h-[0px]"
          }`}
          style={{ transition: "all 0.3s" }}
        >
          {addVisibility && (
            <>
              <UI.Textarea
                item={{
                  placeholder: "Enter your message...",
                  innerClassName: "h-[40px] mt-[8px]",
                  autoFocus: true,
                  inputClassName: "w-full flex-1",
                  value: note,
                  dynamicTextarea: true,
                  rows: "1",
                }}
                value={note}
                handleChange={(data) => {
                  handleChangeNoteInput(data);
                }}
                handleBlur={handleBlurNoteInput}
                handleFocus={() => {}}
                handleKeyPress={(e) => {
                  if (!updateNotesState) {
                    if (e.key === "Enter") {
                      handleAddNote();
                    }
                  }
                }}
              />
              <UI.Button
                item={{
                  label: "Send",
                  buttonType: "primary",
                  className: "h-[40px] !mt-[8px]",
                  disabled: note.length < 1 || updateNotesState,
                }}
                onClick={handleAddNote}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InternalNote;
