import React from "react";
import priceHandler from "../../../../../../utils/priceHandler";
import moment from "moment";
import calculation from "./../model-view/calculation";
import upperCase from "../../../../../dynamic-page/util/upperCaseString";
import { AiFillInfoCircle } from "react-icons/ai";
import util from "../../../../../dynamic-page/util";
import Util from "../../../../../dynamic-page/util";
import SummaryWeight from "./components/weight";

const Summary = ({
  orderItems,
  summaryForm,
  selectedOrder,
  handleSave,
}) => {
  const shipByDate = new Date(summaryForm?.ship_by_date);
  const currentDate = new Date();
  const timeDifference =
    currentDate > shipByDate
      ? currentDate - shipByDate
      : shipByDate - currentDate;

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hoursDifference = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);

  const time =
    daysDifference !== 0
      ? `${daysDifference} Days ${hoursDifference} Hours`
      : `${hoursDifference} Hours`;

  const orderSection = [
    {
      label: summaryForm?.order_numbers?.length > 1 ? "Order IDs" : "Order ID",
      value: summaryForm?.order_id,
      theme: (value) => (
        <div className="flex flex-row gap-[4px] items-center text-gray-900  min-w-fit">
          {summaryForm?.order_numbers?.length > 1 && (
            <util.popUp
              toggle={() => (
                <div className="bg-blue-100 py-[1px] flex items-center  min-w-[20px] h-[20px] px-[6px] text-blue-800 rounded-full hover:bg-blue-200 transition-all">
                  {summaryForm?.order_numbers?.length}
                </div>
              )}
              action="click"
            >
              <div className="flex flex-col gap-1 p-2 overflow-y-auto max-h-[300px]">
                {summaryForm?.order_numbers?.map((order, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center gap-2"
                  >
                    <span className="text-gray-700 font-medium">{order}</span>
                    <div>
                      <util.copyText
                        className={"text-[12px]"}
                        text={order}
                        hint={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </util.popUp>
          )}
          <span className="items-center flex">{value}</span>
        </div>
      ),
    },
    {
      label:
        summaryForm?.shipentegra_order_ids?.length > 1
          ? "Shipentegra IDs"
          : "Shipentegra ID",
      value:
        summaryForm?.shipentegra_order_ids?.length > 0
          ? summaryForm?.shipentegra_order_ids
          : ["-"],
      theme: (value) => (
        <div className="flex flex-row gap-[4px] items-center text-gray-900  min-w-fit">
          {value?.length > 1 && (
            <util.popUp
              toggle={() => (
                <div className="bg-green-100 py-[1px] flex items-center  min-w-[20px] h-[20px] px-[6px] text-green-800 rounded-full hover:bg-blue-200 transition-all">
                  {value?.length}
                </div>
              )}
              action="click"
            >
              <div className="flex flex-col gap-1 p-2 overflow-y-auto max-h-[300px]">
                {value?.map((order, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center gap-2"
                  >
                    <span className="text-gray-700 font-medium">{order}</span>
                    <div>
                      <util.copyText
                        className={"text-[12px]"}
                        text={order}
                        hint={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </util.popUp>
          )}
          <span className="items-center flex">{value[0]}</span>
        </div>
      ),
      condition: summaryForm?.shipentegra_order_ids?.length > 0 ? true : false,
    },
    {
      label: "Order Status",
      value: summaryForm?.arbitbox_status,
      theme: (value) => {
        const statusChanged = value !== selectedOrder?.order_status;
        return (
          <p
            className={` ${
              statusChanged ? "_updatedRow p-0.5 rounded-md" : ""
            }`}
          >
            {value}
          </p>
        );
      },
    },
    {
      label: "Sales Channel",
      value: {
        logo: summaryForm?._data?.sales_channel_id?.data?.marketplace?.logo,
        name: summaryForm?._data?.sales_channel_id?.data?.name,
        country: summaryForm?._data?.sales_channel_id?.data?.country,
      },
      theme: (value) => (
        <div className="flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.logo && <img src={value.logo} className="h-[16px] w-4" />}
          {value?.name && (
            <span className="items-center flex">{upperCase(value?.name)}</span>
          )}
        </div>
      ),
    },
    {
      label: "Account",
      value: summaryForm?._data?.sales_channel_account_id?.data?.name,
    },
    {
      label: "Total Quantity",
      value: calculation.totalQty(orderItems) || "0",
      theme: (value) => <span className={`font-semibold`}>{value}</span>,
    },
    {
      label: "Date",
      value: summaryForm?.order_date
        ? moment(summaryForm?.order_date).format("MMMM D, YYYY")
        : "-",
    },
    { label: "Age", value: summaryForm?.order_age },
    {
      label: "Ship By Date",
      value: summaryForm?.ship_by_date
        ? moment(summaryForm?.ship_by_date).format("MMMM D, YYYY")
        : moment(summaryForm?.order_date).add(2, "days").format("MMMM D, YYYY"),
      theme: (value) => (
        <span className="flex flex-row gap-[4px] items-center">
          {!summaryForm?.ship_by_date && (
            <util.popUp
              action="hover"
              forcePosition="top"
              toggle={() => (
                <AiFillInfoCircle className="w-[17px] h-[17px] text-gray-300" />
              )}
            >
              Empty Ship By Date
            </util.popUp>
          )}
          {value}
        </span>
      ),
    },
    {
      label:
        new Date(summaryForm?.ship_by_date) < new Date()
          ? "Ship By Passed"
          : "Ship By Left",
      value: `
                ${summaryForm?.ship_by_date ? `${time}` : ""}
            `,
      theme: (value) => (
        <span
          className={`${
            new Date(summaryForm?.ship_by_date) < new Date() &&
            "text-red-700 font-semibold"
          }`}
        >
          {value}
        </span>
      ),
    },
  ];

  const financeSection = [
    {
      label: "Order Item",
      value: {
        items_paid:
          summaryForm?.total_items_price_in_usd != null
            ? "$" + priceHandler(summaryForm?.total_items_price_in_usd)
            : "-",
        original_items_paid: priceHandler(summaryForm?.total_items_price, {
          ...summaryForm?._data?.sales_channel_account_id?.data?.currency,
          symbol: `${summaryForm?._data?.sales_channel_account_id?.data?.currency?.code}${summaryForm?._data?.sales_channel_account_id?.data?.currency?.symbol}`,
        }),
      },
      theme: (value) => (
        <div className="flex flex-row gap-[8px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.original_items_paid &&
            summaryForm?._data?.sales_channel_account_id?.data?.currency
              ?.code !== "USD" && (
              <span className="text-base font-medium text-gray-500 h-18px items-center flex">
                {value?.original_items_paid}
              </span>
            )}
          {value?.items_paid && (
            <span className="text-base h-18px items-center flex">
              {value?.items_paid}
            </span>
          )}
        </div>
      ),
    },
    {
      label: "Order Shipping",
      value: {
        shipping_paid:
          summaryForm?.shipping_paid != null
            ? "$" + priceHandler(summaryForm?.shipping_paid)
            : "-",
        original_shipping_paid: priceHandler(
          summaryForm?.original_shipping_paid,
          {
            ...summaryForm?._data?.sales_channel_account_id?.data?.currency,
            symbol: `${summaryForm?._data?.sales_channel_account_id?.data?.currency?.code}${summaryForm?._data?.sales_channel_account_id?.data?.currency?.symbol}`,
          }
        ),
      },
      theme: (value) => (
        <div className="flex flex-row gap-[8px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.original_shipping_paid &&
            summaryForm?._data?.sales_channel_account_id?.data?.currency
              ?.code !== "USD" && (
              <span className="text-base font-medium text-gray-500 h-18px items-center flex">
                {value?.original_shipping_paid}
              </span>
            )}
          {value?.shipping_paid && (
            <span className="text-base  h-18px items-center flex">
              {value?.shipping_paid}
            </span>
          )}
        </div>
      ),
      condition: !summaryForm?.shipping_paid ? false : true,
    },
    {
      label: "Paid Total",
      value: {
        total_paid:
          summaryForm?.total_paid != null
            ? "$" + priceHandler(summaryForm?.total_paid)
            : "-",
        original_total_paid: priceHandler(summaryForm?.original_total_paid, {
          ...summaryForm?._data?.sales_channel_account_id?.data?.currency,
          symbol: `${summaryForm?._data?.sales_channel_account_id?.data?.currency?.code}${summaryForm?._data?.sales_channel_account_id?.data?.currency?.symbol}`,
        }),
      },
      theme: (value) => (
        <div className="flex flex-row gap-[8px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.original_total_paid &&
            summaryForm?._data?.sales_channel_account_id?.data?.currency
              ?.code !== "USD" && (
              <span className="text-base font-medium text-gray-500 h-18px items-center flex">
                {value?.original_total_paid}
              </span>
            )}
          {value?.total_paid && (
            <span className="text-base font-semibold h-18px items-center flex">
              {value?.total_paid}
            </span>
          )}
        </div>
      ),
    },
    {
      label: "Cost of Product",
      value:
        priceHandler(
          summaryForm?.products_cost,
          summaryForm?._data?.sales_channel_account_id?.data?.currency
        ) || "-",
      condition: !summaryForm?.products_cost ? false : true,
    },
    {
      label: "Cost of Shipment",
      value:
        priceHandler(
          summaryForm?.shipping_cost,
          summaryForm?._data?.sales_channel_account_id?.data?.currency
        ) || "-",
      condition: summaryForm?.label?.id ? true : false,
    },
    {
      label: "Profit",
      value: {
        profit: summaryForm?.profit,
        profit_margin: summaryForm?.profit_margin,
      },
      theme: (value) => (
        <div className="flex flex-row gap-[8px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.profit_margin ? (
            <span className="text-base font-medium text-gray-500 h-18px items-center flex">
              {value?.profit_margin}%
            </span>
          ) : (
            <span className="-mr-[7px]">-</span>
          )}
          <span className="text-base font-semibold h-18px items-center flex">
            {priceHandler(
              value?.profit,
              summaryForm?._data?.sales_channel_account_id?.data?.currency
            )}
          </span>
        </div>
      ),
      condition: !summaryForm?.profit ? false : true,
    },
  ];

  const shipmentSection = [
    {
      label: "Status",
      value: summaryForm?.label?.current_status
        ? summaryForm?.label?.current_status
        : summaryForm?.label?.id
        ? "Suggestion"
        : "Waiting for Shipment",
      theme: (value) => <p className="font-semibold">{value}</p>,
    },
    {
      label: "Content",
      value: summaryForm?.shipping_content,
      theme: (value) => (
        <SummaryWeight
          value={value}
          callBack={(data) => handleSave(data, "shipping_content")}
        />
      ),
    },
    {
      label: "From Warehouse",
      value:
        summaryForm?.label?.warehouse?.name ||
        summaryForm?.label?.from_warehouse?.name,
      condition: summaryForm?.label?.id ? true : false,
    },
    {
      label: "To Country",
      value: {
        name: summaryForm?.ship_to_country?.code,
        flag: summaryForm?.ship_to_country?.flag,
      },
      theme: (value) => (
        <div className="flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.flag && (
            <util.popUp
              action="hover"
              toggle={() => (
                <img
                  src={value.flag}
                  className={"h-[12px] w-[17px] rounded-[2px]"}
                  style={{ objectFit: "cover" }}
                />
              )}
            >
              <div className="px-[8px] py-[4px]">{value?.name}</div>
            </util.popUp>
          )}
          {value?.name && (
            <span className="text-[14px] h-18px items-center flex">
              {upperCase(value?.name)}
            </span>
          )}
        </div>
      ),
      condition: summaryForm?.label?.id ? true : false,
    },
    {
      label: "B. Weight",
      value: summaryForm?.billable_weight,
      theme: (value) => (
        <div className="flex items-center gap-1">
          <SummaryWeight
            onlyNumber={true}
            value={value}
            callBack={(data) => handleSave(data, "weight")}
          />
          {summaryForm?.billable_weight ? "kg" : ""}
        </div>
      ),
    },
    {
      label: "Provider",
      value: {
        name: upperCase(summaryForm?.label?.carrier?.provider?.name, "first"),
        logo: summaryForm?.label?.carrier?.provider?.logo,
      },
      theme: (value) => (
        <div className="flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.logo && <img src={value.logo} className="h-[16px] w-4" />}
          {value?.name && (
            <span className="text-[14px] h-18px items-center flex">
              {upperCase(value?.name)}
            </span>
          )}
        </div>
      ),
      condition: !summaryForm?.label?.carrier?.provider?.name ? false : true,
    },
    {
      label: "Carrier",
      value: {
        name:
          summaryForm?.label?.international_carrier?.name ||
          summaryForm?.label?.carrier?.international_carrier?.name,
        logo:
          summaryForm?.label?.international_carrier?.logo ||
          summaryForm?.label?.carrier?.international_carrier?.logo,
      },
      theme: (value) => (
        <div className="flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit">
          {value?.logo && <img src={value.logo} className="h-[12px]" />}
          {value?.name && (
            <span className="text-[14px] h-18px items-center flex">
              {upperCase(value?.name)}
            </span>
          )}
        </div>
      ),
      condition: summaryForm?.label?.id ? true : false,
    },
    {
      label: "Service",
      value: summaryForm?.label?.service_name,
      condition: summaryForm?.label?.id ? true : false,
    },
    {
      label: "Tracking ID",
      value: (
        <span
          className="text-blue-700 cursor-pointer"
          onClick={() =>
            window.open(summaryForm?.label?.tracking_url, "_blank")
          }
        >
          {summaryForm?.label?.tracking_number}
        </span>
      ),
      condition: summaryForm?.label?.current_status ? true : false,
    },
  ];

  const title = (title) => (
    <Util.popUp
      toggle={() => (
        <p>
          Discount - {title?.length > 15 ? title?.slice(0, 15) + "..." : title}
        </p>
      )}
      action={"hover"}
    >
      <p>{title}</p>
    </Util.popUp>
  );

  orderItems?.forEach((item) => {
    if (item.unit_price < 0) {
      financeSection.splice(2, 0, {
        label: title(item.title),
        value: priceHandler(item.unit_price, {
          ...summaryForm?._data?.sales_channel_account_id?.data?.currency,
        })?.replace("$-", "-$"),
      });
    }
  });

  const renderSection = (title, items, hint) => (
    <div>
      <style>
        {`
        ._updatedRow {
          background-color: #DEF7EC;
          animation: fadeOut 3s forwards;
      }

      @keyframes fadeOut {
          0% {background-color: transparent;}
          50% {background-color: #DEF7EC;}
          100% {background-color: transparent;}
      }
        `}
      </style>
      <div className="font-bold text-gray-900 text-[20px] leading-[30px] ">
        {title}
      </div>
      <>{hint}</>
      {items
        .filter((item) => {
          if (item.condition === false) {
            return false;
          }
          return true;
        })
        .map((item, index) => (
          <div
            key={index}
            className="flex flex-row w-full items-center pt-[8px] justify-between"
          >
            <span className="select-none flex text-gray-900 text-[16px] font-semibold justify-start items-start whitespace-nowrap">
              {item.label}
            </span>
            <span className="font-normal text-[16px] text-gray-900 text-right ">
              {item.theme ? item.theme(item.value) : item.value}
            </span>
          </div>
        ))}
    </div>
  );

  const hint =
  (summaryForm?._data?.sales_channel_account_id?.currency?.code && summaryForm?._data?.sales_channel_account_id?.data?.currency?.code !==
    "USD") ? (
      <div className="flex flex-row items-center px-4 py-2 mt-1 text-[12px] rounded-md leading-[19.5px] text-blue-800 bg-blue-50 w-full">
        <p>
          This order was placed in
          <span className="font-semibold">
            {" "}
            {
              summaryForm?._data?.sales_channel_account_id?.data?.currency?.code
            }{" "}
          </span>
          and has been converted to your organization's default currency (
          <span className="font-semibold">USD</span>) using the current exchange
          rate of
          <span className="font-semibold">
            {" "}
            {(
              summaryForm?.original_items_paid / summaryForm?.items_paid
            ).toFixed(2)}
          </span>
          .
        </p>
      </div>
    ) : null;

  return (
    <div className="flex flex-col px-[16px] mb-[16px] h-[50%]">
      <div className="flex flex-col gap-[8px] ">
        {renderSection("Order", orderSection)}
        {renderSection("Finance", financeSection, hint)}
        {renderSection("Shipment", shipmentSection)}
      </div>
    </div>
  );
};

export default Summary;
