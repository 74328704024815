import { useDispatch, useSelector } from "react-redux";
import HandmadeSkeleton from "../../../utils/skeleton";
import util from "../../dynamic-page/util";
import { FaTruckFast } from "react-icons/fa6";
import Icons from "../../orders/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Ui from "../../dynamic-form/elements";
// Import Elements
import { HiRefresh } from "react-icons/hi";
import { fetchCalculatorData } from "../../../store/calculator/thunk";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Util from "../../dynamic-page/util";
import priceHandler from "../../../utils/priceHandler";
import axios from "axios";

const CalculatorDatas = ({ item, data, binded }) => {
  const data2 = useSelector((state) => state.calculator.data);
  const status = useSelector((state) => state.calculator.status);

  const [currentWarehouse,setCurrentWarehouse]=useState(binded?.onWarehouse?.data)

  
  const dispatch = useDispatch();


  function titleCase(string) {
    if (string) {
      return string[0]?.toUpperCase() + string?.slice(1)?.toLowerCase();
    } else {
      return "";
    }
  }

  const fetchWarehouseInfo = async () => {
    if (binded?.onWarehouse?.data) {
      try {
        const response = await axios.get(
          `/api/v1/${
            binded?.onWarehouse?.type === "pickup" ? "pickup-points" : "warehouses"
          }/${binded?.onWarehouse?.data?.id}`
        ); // Assuming this endpoint exists
        setCurrentWarehouse(response.data.data);
      } catch (error) {
        console.error("Failed to fetch warehouse info", error);
      }
    }
  };

  useEffect(() => {
    setCurrentWarehouse(binded?.onWarehouse?.data)
  },[binded?.onWarehouse?.data])

  const [update, setUpdate] = React.useState(false);
  const [clicked, setClicked] = useState(false);
  const [carriers, setCarriers] = React.useState([]);
  const tooltip = (cost) => {
    return [
      {
        label: "Carrier Shipment Cost",
        value: cost?.cost,
      },
      {
        label:
          binded?.onWarehouse?.type === "pickup"
            ? "Pick-up Point Transfer Cost"
            : "Warehouse Transfer Cost",
        value: currentWarehouse?.transfer_cost || 0,
      },
      {
        label: "Pick-up Point Processing Cost",
        value: currentWarehouse?.processing_fee || 0,
      },
      {
        label: "Total Shipment Cost",
        value:
          +cost?.cost +
          (Number(currentWarehouse?.transfer_cost) || 0) +
          (Number(currentWarehouse?.processing_fee) || 0),
      },
    ];
  };

  // Create ref to handle form data.
  const myFormRef = useRef();
  const checkboxRef = useRef();
  const createNewRef = useCallback((node) => {
    checkboxRef.current = node;

    // if (node)
  }, []);
  const isoDateToHuman = (date) => {
    return new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const daysHoursAgoFromIso = (date) => {
    return (
      Math.floor((new Date() - new Date(date)) / 1000 / 60 / 60 / 24) +
      "d " +
      (Math.floor((new Date() - new Date(date)) / 1000 / 60 / 60) % 24) +
      "h"
    );
  };

  return (
    <div className="  ">
      {status !== "idle" && (
        <div className="">
          <div className=" ">
            <ul className="flex text-gray-500   text-[12px]  font-semibold leading-[18px] rounded-tr-lg rounded-tl-lg bg-gray-50 ">
              <li className="w-[213px] whitespace-nowrap flex items-center gap-2 p-4  ">
                PROVIDER{" "}
              </li>
              <li className="w-[213px] whitespace-nowrap flex items-center gap-2  p-4 ">
                CARRIER{" "}
              </li>
              <li className="w-[323px] whitespace-nowrap  flex items-center gap-2 p-4  ">
                SERVICE{" "}
              </li>
              <li className="w-[130px]  whitespace-nowrap  flex items-center gap-2 p-4  ">
                T. COST{" "}
              </li>
            </ul>
            <hr />
            <div
              className={`flex max-h-[calc(100vh-53.5vh)] min-h-[calc(100vh-58.5vh)] sticky ${
                data2?.length > 9 &&
                status !== "pending" &&
                "overflow-y-scroll "
              }   flex-col text-black `}
            >
              {status !== "pending" &&
                data2?.map((item, index) => {
                  return (
                    <div key={item?.id}>
                      <div className="flex sticky overflow-x-auto min-w-[700px] font-medium text-sm leading-[21px] items-center">
                        <div className="w-[213px]   flex items-center gap-1 p-4 ">
                          <img
                            className="w-4 h-4 object-contain"
                            src={item?.carrier?.provider?.logo}
                            alt=""
                          />
                          <span className="text-[14px] leading-[21px] font-medium  text-gray-900">
                            {titleCase(item?.carrier?.provider?.name)}
                          </span>
                        </div>
                        {item?.carrier?.name?.length > 11 ? (
                          <util.popUp
                            toggle={(selected) => (
                              <div className="w-[200px]  flex items-center   gap-1 p-4 ">
                                {item?.carrier?.international_carrier?.logo ? (
                                  <img
                                    className="w-4 h-4 object-cover"
                                    src={
                                      item?.carrier?.international_carrier?.logo
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <FaTruckFast className="w-4 h-4 text-gray-300" />
                                )}
                                <span>
                                  {item?.carrier?.name?.length > 11 ? (
                                    <span className="text-[14px] leading-[21px] font-medium  text-[#111928]">
                                      {item?.carrier?.name?.slice(0, 11)}...
                                    </span>
                                  ) : (
                                    <span className="text-[14px] leading-[21px] font-medium  text-[#111928]">
                                      {item?.carrier?.name}
                                    </span>
                                  )}
                                </span>
                              </div>
                            )}
                            action={"hover"}
                            forcePosition={index === 0 ? "bottom" : "top"}
                          >
                            <div
                              className={
                                "w-[160px] flex items-center pl-1 gap-1"
                              }
                            >
                              <span className="text-[14px] leading-[21px] font-medium  text-[#111928]">
                                {item?.carrier?.name}
                              </span>
                            </div>
                          </util.popUp>
                        ) : (
                          <div className="w-[213px] flex items-center   gap-1 p-4 ">
                            {item?.carrier?.international_carrier?.logo ? (
                              <img
                                className="w-4 h-4 object-contain"
                                src={item?.carrier?.international_carrier?.logo}
                                alt=""
                              />
                            ) : (
                              <FaTruckFast className="w-4 h-4 text-gray-300" />
                            )}
                            <span>
                              {item?.carrier?.name?.length > 11 ? (
                                <span className="text-[14px] leading-[21px] font-medium  text-[#111928]">
                                  {item?.carrier?.name?.slice(0, 11)}...
                                </span>
                              ) : (
                                <span className="text-[14px] leading-[21px] font-medium  text-[#111928]">
                                  {item?.carrier?.name}
                                </span>
                              )}
                            </span>
                          </div>
                        )}

                        <div className="w-[323px] p-4  ">
                          <span className="text-[14px] leading-[21px] font-medium  text-[#111928]">
                            {item?.service_name}
                          </span>
                        </div>
                        <div className="w-[130px]  p-4 flex justify-between items-center ">
                          <span className="text-[14px] leading-[21px] font-semibold text-[#111928]">
                            ${item?.cost.toFixed(2)}
                          </span>
                          <span className="">
                            <Icons.calculator
                              className="w-5 h-5  text-gray-300 cursor-pointer hover:text-gray-500"
                              data-tooltip-id={`PUPTransferCostTooltip${item?.id}`}
                            />
                            <Tooltip
                              id={`PUPTransferCostTooltip${item?.id}`}
                              place="top"
                              positionStrategy="fixed"
                              className="z-[99999] bg-white !opacity-100"
                              style={{
                                backgroundColor: "white",
                                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.05)",
                                borderRadius: "4px",
                                padding: "7px 7px 7px 7px",
                              }}
                            >
                              <div className={"p-1 flex gap-[6px] flex-col"}>
                                {tooltip(item).map((key, index) => {
                                  if (!key.value) return null;
                                  return (
                                    <div
                                      className={
                                        "flex justify-between gap-[16px] min-w-[190px !font-normal"
                                      }
                                      key={index}
                                    >
                                      <span
                                        className={`text-gray-900 text-[12px] leading-5  ${
                                          key.label === "Total Shipment Cost"
                                            ? "!font-medium"
                                            : ""
                                        }`}
                                      >
                                        {key.label}
                                      </span>
                                      <span
                                        className={`text-gray-900 text-[12px] leading-5  ${
                                          key.label === "Total Shipment Cost"
                                            ? "!font-medium"
                                            : ""
                                        }`}
                                        style={{ direction: "ltr" }}
                                      >
                                        ${priceHandler(key.value)}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              {status === "pending" && (
                <div className="">
                  {/* <div class="text-center ">
                                    <div role="status" className="flex flex-col items-center mt-32">
                                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg>
                                        <span class="">Loading...</span>
                                    </div>
                                </div> */}
                  <div class="relative min-w-full bg-white items-center block max-w-sm px-4 py-3  rounded-lg dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                    <div className="flex flex-col gap-3.5">
                      {[21, 3, 23, 5, 5, 6, 6].map((item, indx) => {
                        return (
                          <HandmadeSkeleton
                            key={indx}
                            className={"w-full h-9 animate-pulse"}
                          />
                        );
                      })}
                    </div>
                    {/* <div>
                                        <div role="status" class="absolute -translate-x-1/2 flex items-center flex-col -translate-y-1/2 top-2/4 left-1/2">
                                            <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                            <span class="">Please Wait...</span>
                                        </div>
                                        
                                    </div> */}
                  </div>
                </div>
              )}
              {
                <div>
                  {data2?.length === 0 && status === "succeeded" && (
                    <div
                      className={`flex flex-col h-full text-center py-[50px] rounded-2xl items-center `}
                    >
                      <Icons.brokenHeart className="w-10 h-10 mb-4 text-gray-400" />
                      <p className={"w-[700px] text-gray-400 font-medium"}>
                        Sorry, we were unable to calculate shipping rates for
                        this region. Please check the region details. Or
                        refreshing the data can usually solve this problem
                      </p>
                    </div>
                  )}
                  {!data2 && status === "rejected" && (
                    <div className="flex justify-center items-center text-2xl mt-32">
                      <span className="text-gray-500">
                        Oops! Something went wrong!
                      </span>
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
          <div className="flex justify-between items-center py-2 px-2">
            <div className={"flex w-full items-center "}>
              {
                //listOfLastUpdate[0]?.price_last_update && carriers?.status !== "pending" &&
                <>
                  {status !== "rejected" && (
                    <div className="flex items-center  ">
                      <button disabled={status === "pending" ? true : false}>
                        <Ui.Button
                          item={{
                            className:
                              "h-[35px] w-[35px] bg-white text-gray-400 px-0",
                          }}
                          onClick={() => {
                            // dispatch again but sending fresh as 1
                            setUpdate(true);
                            fetchWarehouseInfo()
                            dispatch(
                              fetchCalculatorData({
                                payload: data,
                                refresh: true,
                                from_state_id:
                                  binded?.onWarehouse?.data?.state?.id,
                              })
                            ).then((rs) => {
                              setUpdate(false);
                              if (rs?.payload?.message) {
                                toast.error(rs?.payload?.message);
                              }
                            });
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`w-[16px] h-[16px] rotate-180 text-gray-500 ${
                              update || status === "pending"
                                ? "animate-spin"
                                : ""
                            }`}
                          >
                            <g id="Vector">
                              <path
                                d="M18.1482 9C17.5849 9 17.1297 9.448 17.1297 10C17.1297 13.86 13.9305 17 10 17C8.11782 17 6.33643 16.26 5.0205 15H7.963C8.52624 15 8.98152 14.552 8.98152 14C8.98152 13.448 8.52624 13 7.963 13H3.1281C2.92643 12.959 2.7156 12.983 2.51495 13.07C2.49661 13.077 2.47726 13.08 2.45893 13.088C2.44569 13.095 2.43143 13.094 2.41819 13.101C2.39069 13.117 2.37541 13.143 2.34893 13.16C2.05661 13.337 1.85189 13.639 1.85189 14V19C1.85189 19.552 2.30717 20 2.87041 20C3.43365 20 3.88893 19.552 3.88893 19V16.68C5.54912 18.141 7.71347 19 10 19C15.0549 19 19.1667 14.962 19.1667 10C19.1667 9.448 18.7114 9 18.1482 9Z"
                                fill="#374151"
                              />
                              <path
                                d="M2.87041 10C2.87041 6.14 6.06958 3 10 3C11.8823 3 13.6637 3.74 14.9796 5H12.0371C11.4738 5 11.0186 5.448 11.0186 6C11.0186 6.552 11.4738 7 12.0371 7H16.8699C17.0726 7.042 17.2855 7.017 17.4862 6.929C17.5045 6.922 17.5238 6.919 17.5422 6.911C17.5554 6.904 17.5697 6.905 17.5829 6.898C17.6104 6.882 17.6257 6.856 17.6522 6.839C17.9435 6.663 18.1482 6.361 18.1482 6V1C18.1482 0.448 17.6929 0 17.1297 0C16.5664 0 16.1112 0.448 16.1112 1V3.32C14.451 1.859 12.2866 1 10 1C4.94513 1 0.833374 5.038 0.833374 10C0.833374 10.552 1.28865 11 1.85189 11C2.41513 11 2.87041 10.552 2.87041 10Z"
                                fill="#374151"
                              />
                            </g>
                          </svg>
                        </Ui.Button>
                      </button>
                      <span className="text-[14px] leading-[21px] text-gray-700">
                        The costs were last updated{" "}
                        {data2?.length && status !== "pending" ? (
                          <span className="text-gray-700 text-[16px]  font-medium leading-[21px]">
                            {daysHoursAgoFromIso(data2[0]?.updated_at)} ago
                          </span>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>
                  )}
                </>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalculatorDatas;
