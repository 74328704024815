import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";

import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import FormModal from "../dynamic-page/FormModal";

import filtersView from "./models-view/filters";
import tableView from "./models-view/table";
import formView from "./models-view/form";

// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";

import {
  fetchCarriers as fetchData,
  fetchCarriersFilters as fetchFilters,
  updateBoundFilter,
  updateCarrier as updateRow,
  updateFilter,
  updateRow as updateSingleRow,
} from "../../store/carrier";

import getActionList from "../dynamic-page/util/getActionList";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import upperCase from "../dynamic-page/util/upperCaseString";
import SearchInput from "../dynamic-page/debt/searchInput";
import StatesModal from "./models-view/statesModal";

const Carriers = () => {
  const dispatch = useDispatch();

  // Initial page at first load.
  useEffect(() => {
    const startPageByFilters = {
      filters: {},
      includes: "state.country,dataProvider,shippingCostDataSource",
      sort: null,
      page: 1,
      search: "",
      limit: 20,
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(storeQuery);

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters());
  }, []);

  // Get data from store.
  const pageData = useSelector((state) => state.carriers);
  const tableData = useSelector((state) => state.carriers?.carriers || []);

  const [selectedRow, setSelectedRow] = useState(null);

  // Handle form modal.
  const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [statesModalVisibility, setStatesModalVisibility] = useState(false);
  const [formData, setFormData] = useState({});
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  // Initial action list.
  const actionList = {
    edit: {
      label: "Update",
      onClick: (item) => {
        if (item?.id) {
          setFormData(item);
          setFormModalVisibility(true);
        } else {
          setFormData(selectedRow[0]);
          setFormModalVisibility(true);
        }
      },
      bulkDisable: true,
    },
    delete: {
      label: <span className={"text-red-600"}>Delete</span>,
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to delete the selected carrier/s?",
          confirmText: "Yes, Delete",
          callBack: (setSubmit) => {
            const ids = item?.id
              ? [item?.id]
              : selectedRow.map((item) => item?.id);
            callEndpoint({
              title: "Delete Carrier",
              url: `carriers`,
              method: "DELETE",
              data: { carriers: ids },
              pureData: true,
            }).then((res) => {
              if (res.type === "success") {
                // onPaginationApply();
                setConfirmationModalVisibility(false);

                ids.map((id) => {
                  dispatch(
                    updateSingleRow({
                      path: `carriers.[id:${id}]._updatedRow`,
                      value: true,
                    })
                  );
                  setTimeout(() => {
                    dispatch(
                      updateSingleRow({
                        path: `carriers.[id:${id}]._deletedRow`,
                        value: true,
                      })
                    );
                    setTimeout(() => {
                      dispatch(
                        updateSingleRow({ path: `carriers.[id:${id}]` })
                      );
                    }, 500);
                  }, 3000);
                });
                setSelectedRow([]);
              }
            });
          },
        });
      },
    },
  };

  // Initial sort list
  const sortList = {
    list: [
      {
        label: "Provider",
        value: "provider_id",
        onClick: (item) => {
          onSortApply("provider_id");
        },
      },
      {
        label: "Name",
        value: "name",
        onClick: (item) => {
          onSortApply("name");
        },
      },
      {
        label: "Code",
        value: "code",
        onClick: (item) => {
          onSortApply("code");
        },
      },
    ],
  };

  // Initial status list
  const statusList = {
    null: {
      label: "",
      actions: [actionList.edit, actionList.delete],
    },
  };

  // handle action of statuses
  const actions = (row) => {
    return { ...getActionList(row, statusList, "none"), count: row?.length };
    // return {
    //     count: row?.length,
    //     list: row?.length === 1 ? [
    //         actionList.edit,
    //         actionList.delete,
    //     ] : [
    //         actionList.delete,
    //     ],
    // };
  };

  // Map status to tab
  // --------------------------------------------------
  // technical debt : it's better to have status name and id in metaFilter
  // --------------------------------------------------
  const mapTab = (tab) => {
    const list = {
      all: null,
      active: 1,
      inactive: 0,
      deleted: 2,
    };
    return list[tab];
  };

  // Handle Pagination
  const onHandlePrevious = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };

  const onHandleNext = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  const handleNotifyUser = (rs) => {
    util.notifier(rs);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);
    // --------------------------------------------------
    // when search is not available in url
    // --------------------------------------------------
    // const currentStoreFilters = pageData?.filters?.filters
    // onFilterApply({...currentStoreFilters, "name": search})

    // --------------------------------------------------
    // when search is available in url
    // --------------------------------------------------
    const currentStoreFilters = pageData?.filters?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: currentStoreFilters,
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(updateFilter(storeQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });

    const filterStoreQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    const filterUrlQuery = urlQueryBuilder(filterStoreQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(filterUrlQuery));
    dispatch(updateFilter(storeQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery);

    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      page: page || currentStoreFilters.page || 1,
      limit: limit || currentStoreFilters?.limit || 20,
    });
    const urlQuery = urlQueryBuilder(storeQuery);
    const filters = { ...currentStoreFilters?.filters };
    if (filters.status != null) filters.status = null;

    const filterStoreQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const filterUrlQuery = urlQueryBuilder(filterStoreQuery);

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(filterUrlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const onSubmitApply = async (data, clear) => {
    let { id, ...payload } = data;
    const innerData = {};

    innerData.cache_identifier = payload?.carrier;
    innerData.dim_divisor = payload?.dim_divisor;
    innerData.international_carrier_id = payload?.international_carrier_id;
    if (innerData?.state_ids?.length === 0) {
      util.notifier({
        type: "error",
        message: "Please select at least one state",
      });
      return;
    }
    innerData.state_ids = payload?.state_ids?.map((item) => item.id);
    if (!id) {
      callEndpoint({
        title: "Create Shipping Carrier",
        url: `carriers/activate-api-carrier`,
        method: "POST",
        data: innerData,
        pureData: true,
      }).then((res) => {
        if (res.type === "success") {
          clear();
          setTimeout(() => {
            onPaginationApply();
          }, 500);
          //  setModalVisibility(false);
        }
      });
    } else {
      callEndpoint({
        title: "Update Shipping Carrier",
        url: `carriers/${id}/update`,
        method: "post",
        data: innerData,
        pureData: true,
      }).then((res) => {
        if (res.type === "success") {
          // onPaginationApply();
          setSelectedRow([]);
          setFormData(null);
          clear();

          dispatch(
            updateSingleRow({
              path: `carriers.[id:${id}]`,
              value: {
                ...res.data,
                _updatedRow: true,
              },
            })
          );
          // remove the updated row after 5 seconds
          setTimeout(() => {
            dispatch(
              updateSingleRow({
                path: `carriers.[id:${id}]._updatedRow`,
                value: false,
              })
            );
          }, 5000);
          //  setModalVisibility(false);
        }
      });
    }

    /*if (!id) {
              await dispatch(createRow(payload)).unwrap().then(handleNotifyUser);
            } else {
              await dispatch(updateRow({ id: id, payload }))
                .unwrap()
                .then(handleNotifyUser);
            }
            onPaginationApply();*/
  };
  

  const onUpdateNote = (data) => {
    callEndpoint({
      url: `carriers/${data.id}/update`,
      method: "post",
      data: { note: data.note },
      pureData: true,
    }).then((rs) => {
      if (rs?.type === "success") {
   
        dispatch(
          updateSingleRow({
            path: `carriers.[id:${data?.id}]`,
            value: {
              ...pageData?.carriers?.find((item) => item?.id === data?.id),
              note: rs?.data?.note,
              _updatedRow: true,
            },
          })
        );
        setTimeout(() => {
          dispatch(
            updateSingleRow({
              path: `carriers.[id:${data?.id}]`,
              value: {
                ...pageData?.carriers?.find((item) => item?.id === data?.id),
                note: rs?.data?.note,
                _updatedRow: false,
              },
            })
          );
        }, 3000);
      }
    });

  };
 
  

  const onDeleteState = (data, id, setStates) => {
    callEndpoint({
      title: "Delete Carrier State",
      url: `carriers/${data.id}/update`,
      method: "post",
      data: { state_ids: data?.states?.map((item) => item.id).filter((item) => item !== id) },
      pureData: true,
    })
      .then((rs) => {
        if (rs?.type === "success") {
          setStates(rs?.data?.states)
          const row = tableData.find((item) => item.id === data.id);
          dispatch(
            updateSingleRow({
              path: `carriers.[id:${data?.id}]`,
              value: {
                ...row,
                states: rs?.data?.states,
                _updatedRow: true,
              },
            })
          );
          setTimeout(() => {
            dispatch(
              updateSingleRow({
                path: `carriers.[id:${data?.id}]._updatedRow`,
                value: false,
              })
            );
          }, 5000);
        }
      })
      .catch((err) => {
        handleNotifyUser(err);
      });
  };


  const openStatesModal = (data) => {
    setFormData(data);
    setStatesModalVisibility(true);
  };

  const tableActions = {
    onUpdateNote,
    openStatesModal,
  };

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          onSearch={onSearchApply}
          title={"Carriers"}
          form={{
            callback: (rs) => {
              setFormModalVisibility(true);
            },
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            discard: ["filter to be hidden "],
            items: filtersView(
              pageData?.filters?.filters,
              pageData?.filtersMeta,
              pageData?.boundFilters
            ),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.status == undefined ? 0 : 1),
          }}
          actionList={actions(selectedRow)}
        />

        <ArbitProductPage.Tab>
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={null}
          />
        </ArbitProductPage.Tab>

        {/* Table is here. */}
        <ArbitProductPage.Content>
          <div className=" ">
            <div className="overflow-x-auto">
              <DynamicTable
                view={tableView}
                mini={true}
                miniWidth="241"
                data={tableView(tableData, tableActions)}
                actions={actions}
                onSelect={onSelectRow}
                selectedRow={selectedRow}
                loading={pageData?.loading}
                style={{
                  header: {
                    className:
                      "flex justify-start !leading-[18px] !h-[50px] -mt-1 !px-4 !py-4 whitespace-nowrap",
                      th: " !p-0",
                    "tracking id": {
                      className: "min-w-min",
                    },
                    logo: {
                      th: "w-[100px]",
                    },
                    shippingCost: {
                      className: "hidden",
                    },
                    status: {
                      className: "hidden",
                    },
                    note: {
                      className: "hidden",
                    },
                  },
                  row: {
                    className:
                      "flex items-start justify-start !h-[51px] !w-max !px-4 !py-4 ",
                    td: "!h-[54px]",
                    logo: {
                      // td: "w-[100px]",
                      className: "!py-0",
                    },
                    // name: {
                    //     td: "w-[100%]",
                    // },
                    // code: {
                    //     td: "w-[250px]",
                    // },
                    _actions: {
                      className: "!px-3 !py-3 ",
                      // td: "!w-[108px]",
                    },
                    shippingCost: {
                      td: "!w-[100%]",
                    },
                    "note": {
                      td: "!w-[48px] ",
                    },
                  },
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
          onPrevious={onHandlePrevious}
          onNext={onHandleNext}
          onPage={onHandlePage}
          loading={pageData.loading}
          onLimit={onHandleLimit}
  resultsText={"Carriers"}
          
        />
      </ArbitProductPage.Page>

      {/* Form Modal is here. */}
      {formModalVisibility && (
        <FormModal
          title={
            formData?.id
              ? `${upperCase(formData?.provider?.name, "first")} - ${
                  formData?.name
                }`
              : "Shipping Carrier"
          }
          prefix="New"
          editPrefix="Update"
          formView={formView}
          visibility={formModalVisibility}
          data={formData}
          onClose={() => {
            setFormData(null);
            setFormModalVisibility(false);
          }}
          createNew={formData?.id ? false : true}
          className="w-[600px]"
          onSubmit={onSubmitApply}
          buttonsContainer="!px-0 !pt-4 !pb-3"
          saveText="Create"
          containerClassName="!max-w-[642px]"
          headerClassName="!p-4"
          container={(child) => (
            <>
              <div
                className={`w-[610px] grid  grid-cols-6 items-start gap-y-4 gap-x-2  justify-center  border-t border-t-gray-200 pt-[16px]`}
              >
                {child}
              </div>
            </>
          )}
        />
      )}

      {/* States Modal is here. */}
      {
        statesModalVisibility && (
          <StatesModal
            data={formData}
            onClose={() => {
              setFormData(null);
              setStatesModalVisibility(false);
            }}
            onDelete={onDeleteState}
          />
        )
      }

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this order as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

export default Carriers;
