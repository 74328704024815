import React, { useEffect, useState } from "react";
import UI from "../../dynamic-form/elements";
import axios from "axios";
import Pending from "../../dynamic-page/elements/pending";
import moment from "moment";

const Sales = ({ modalListing, fulfilmentHandler, productList, dispatch }) => {
  let item = modalListing;
  const days = ["Last 30 days", "Last 60 days", "Last 90 days"];

  const [activeDays, setActiveDays] = React.useState("Last 90 days");
  const handleDaysChange = (days) => {
    setActiveDays(days);
  };

  const [activeTab, setActiveTab] = useState(0);

  const [chartApi, setChartApi] = useState(null);

  const salesData = (data) =>
    data?.sales_channel_listing || data?.sales_channel_listings?.[0];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [loading, setLoading] = useState(false);

  const fetchChartData = async (url) => {
    setLoading(true);
    try {
        const { data } = await axios.get(url);
        setChartApi(data);
    } catch (err) {
        console.error("Error fetching data:", err);
    } finally {
        setLoading(false);
    }
};

const getFetchURL = () => {
    const identifier = salesData(item)?.identifier;
    const period = activeDays === "Last 30 days" ? 30 : activeDays === "Last 60 days" ? 60 : 90;
    const endpoints = [
        `/api/v1/account-listing/sold-units?identifier=${identifier}&period=${period}`,
        `/api/v1/account-listing/sales-revenue?identifier=${identifier}&period=${period}`,
        `/api/v1/account-listing/sold-units-by-fulfilment?identifier=${identifier}&period=${period}`
    ];
    return endpoints[activeTab];
};

useEffect(() => {
    const baseURL = getFetchURL();
    fetchChartData(baseURL);
}, [activeTab, item, activeDays]);

  const reducedChartData = [];
  const allAccountsData = {
    name: "All",
    data: [],
  };

  function generateDateRange(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // months are zero-based
      const year = currentDate.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      dates.unshift(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  // Get the existing dates
  const existingDates = Object.keys(chartApi?.data?.details || {});

  const fulType = (channel) => {
    if (channel === "Amazon") return "FBA";
    else return "FBM";
  };

  // Usage example
  const startDate =
    activeDays === "Last 30 days"
      ? moment().subtract(31, "days").toDate()
      : activeDays === "Last 60 days"
      ? moment().subtract(61, "days").toDate()
      : moment().subtract(91, "days").toDate();
  const endDate = new Date();

  const allDates = generateDateRange(startDate, endDate);

  const missingDates = allDates.filter((date) => !existingDates.includes(date));

  // Loop through the data and adjust the date values for the time zone offset
  for (const date in chartApi?.data?.details) {
    for (const value of chartApi?.data?.details[date]) {
      const accountName =
        activeTab === 2
          ? fulType(value.fulfillment_channel)
          : value.account.name;

      // Format the date using moment.js
      const formattedDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");

      // Add one day to account for the time zone offset
      const adjustedDate = moment(formattedDate).format("YYYY-MM-DD");

      const y =
        activeTab === 0
          ? parseInt(value?.value, 10)
          : parseFloat(value?.value, 10);

      // Find the account in the transformed data array or create a new entry
      const accountEntry = reducedChartData.find(
        (entry) => entry.name === accountName
      );
      if (!accountEntry) {
        reducedChartData.push({
          name: accountName,
          data: [],
        });
      }

      // Append the adjusted date and value to the account entry , or if it already exists, update the value
        const entry = reducedChartData.find((entry) => entry.name === accountName);
        const existingEntry = entry.data.find((entry) => entry.x === adjustedDate);
        if (existingEntry) {
            existingEntry.y += y;
        } else {
            entry.data.push({
                x: adjustedDate,
                y: y,
            });
        }

      // Append the adjusted date and value to the 'All Accounts' entry
      const allAccountsEntry = allAccountsData.data.find(
        (entry) => entry.x === adjustedDate
      );
      if (allAccountsEntry) {
        allAccountsEntry.y += y;
      } else {
        allAccountsData.data.push({
          x: adjustedDate,
          y: y,
        });
      }
    }
  }

  const allDatesSet = new Set();
  reducedChartData.forEach((account) => {
    account.data.forEach((entry) => {
      allDatesSet.add(entry.x);
    });
  });

  // Ensure all accounts have the same dates in their data arrays
  reducedChartData.forEach((account) => {
    const accountDates = new Set(account.data.map((entry) => entry.x));
    const missingDates = [...allDatesSet].filter(
      (date) => !accountDates.has(date)
    );

    // Add missing dates with value 0 to the account's data array
    missingDates.forEach((missingDate) => {
      account.data.push({
        x: missingDate,
        y: 0,
      });
    });

    // Sort the data array by date
    account.data.sort((a, b) => new Date(a.x) - new Date(b.x));
  });

  // append the days until today to all accounts data
  const today = moment().format("YYYY-MM-DD");
  const allAccountsEntry = allAccountsData.data.find(
    (entry) => entry.x === today
  );
  if (!allAccountsEntry) {
    allAccountsData.data.push({
      x: today,
      y: 0,
    });
  }

  // Sort the 'All Accounts' data array by date
  allAccountsData.data.sort((a, b) => new Date(a.x) - new Date(b.x));

  // Add the 'All Accounts' series to the reducedChartData
  reducedChartData.push(allAccountsData);

  for (let missingDate of missingDates) {
    missingDate = missingDate.split("-").reverse().join("-");
    reducedChartData.forEach((account) => {
      const accountEntry = account.data.find(
        (entry) => entry.x === missingDate
      );

      // If entry for the account on the missing date doesn't exist, add it with value 0
      if (!accountEntry) {
        account.data.push({
          x: missingDate,
          y: 0,
        });

        // Sort the data array again after adding the new entry
        account.data.sort((a, b) => new Date(a.x) - new Date(b.x));
      }
    });
  }

  const chartData = {
    series: reducedChartData,
    options: {
      chart: {
        type: "area",
        // toolbar: {
        //   show: false,
        // },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
      },
      xaxis: {
        type: "datetime",
      },
      // Todo - Add the y-axis formatter to control the number of decimal places
      yaxis: {
        labels: {
          formatter: function (val, index) {
            if (activeTab === 0) {
              return val.toFixed(0);
            } else return val;
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            if (val % 1 === 0) {
              return val.toFixed(0);
            }
            else {
              return val.toFixed(2);
            }
          }
      },
      },
      theme: {
        mode: "light", // or 'dark' for dark mode
      },
      legend: {
        position: "bottom",
      },
      colors: ["#1C64F2", "#FDBA8C", "#16BDCA", "#9061F9"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
    },
  };

  return (
    <div className="mt-5 w-full">
      <div className="sales_revenue_graph flex flex-col justify-between items-center mt-3 w-full">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-col justify-between items-start gap-y-0.5">
            <h4 className="text-[30px] text-gray-900 font-bold flex flex-row justify-between items-center gap-x-1.5">
              {loading
                ? "loading.."
                : (activeTab === 1 ? "$" : "") + chartApi?.data?.total}
            </h4>
          </div>
          <div className="flex flex-row justify-between items-center gap-x-3">
            {days.map((item, index) => (
              <button
                type="button"
                key={index}
                className={`text-xs ${activeDays === item ? "active" : ""}`}
                onClick={() => handleDaysChange(item)}
              >
                <div
                  className={`flex flex-row px-4 py-2 rounded-md justify-center font-medium items-center gap-x-1.5 ${
                    activeDays === item
                      ? "bg-blue-500 text-white"
                      : "text-gray-800 bg-gray-100"
                  }`}
                >
                  {item}
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="custom-tabs mt-3">
        <div className="flex flex-row justify-start items-start gap-x-6">
          <button
            className={`text-sm font-medium tab-button ${
              activeTab === 0
                ? "active text-[#1C64F2] border-b-[1px] border-[#1C64F2] pb-1"
                : "text-gray-500"
            }`}
            onClick={() => handleTabClick(0)}
          >
            Units
          </button>
          <button
            className={`text-sm font-medium tab-button ${
              activeTab === 1
                ? "active text-[#1C64F2] border-b-[1px] border-[#1C64F2] pb-1"
                : "text-gray-500"
            }`}
            onClick={() => handleTabClick(1)}
          >
            Revenue
          </button>
          <button
            className={`text-sm font-medium tab-button ${
              activeTab === 2
                ? "active text-[#1C64F2] border-b-[1px] border-[#1C64F2] pb-1"
                : "text-gray-500"
            }`}
            onClick={() => handleTabClick(2)}
          >
            F. Type
          </button>
        </div>
        {loading ? (
          <Pending count={5} h={60} />
        ) : (
          <div className="tab-content">
            {activeTab === 0 && (
              <div className="units-pane">
                <UI.AreaChart chartData={chartData} />
              </div>
            )}
            {activeTab === 1 && (
              <div className="revenue-pane">
                <UI.AreaChart chartData={chartData} />
              </div>
            )}
            {activeTab === 2 && (
              <div className="type-pane">
                <UI.AreaChart chartData={chartData} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sales;
