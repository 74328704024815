import Util from "../../dynamic-page/util"
import util from "../../dynamic-page/util"
import UI from "../../dynamic-form/elements";
import Icons from "../../orders/icons";
import Components from "../../orders/components";

const OrderRowAction = ({actions, style, callBack}) => {
    const activeStyle = (status) => {
        if (status) {
            return "!text-blue-500"
        } else {
            return "!text-gray-500"
        }
    }
    return (
        <div className={"grid grid-cols-2 grid-rows-2 "}>

            {/* Buyer note is here */}

            <Util.popUp
                toggle={(selected) => (
                    <div>
                        <UI.Button item={{
                            buttonType: "flat",
                            className: `p-[2px]  ${activeStyle(actions?.customer_note_enabled)}`
                        }}>
                            <Icons.goToRepricer/>
                        </UI.Button>
                    </div>
                )}
                action={"hover"}
                leftSpace={style?.leftSpace}
            >
                {/* <div style={{hyphens: "auto"}}
                     className={"max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"}>
                    {actions?.customer_note ?
                        util.upperCaseString(actions?.customer_note)
                        :
                        <span className={"text-gray-400"}>Buyer note is empty!</span>
                    }
                </div> */}
            </Util.popUp>


            {/* Internal note is here */}
            <util.popUp
                toggle={(selected) => (
                    <div>
                        <UI.Button item={{
                            buttonType: "flat",
                            className: `p-[2px]  ${activeStyle(actions?.shipping_documents_enabled)}`
                        }}>
                            <Icons.goToListingView/>
                        </UI.Button>
                    </div>
                )}
                leftSpace={style?.leftSpace}
            >
                {/* <div className={"min-w-max px-[8px] py-[4px]"}>
                        {actions?.shipping_documents?.length > 0 ?
                            <Components.shippingDocument list={actions?.shipping_documents}/>
                            :
                            <span className={"text-gray-400 text-[14px]"}>There is no shipping documents!</span>
                        }
    
    
                    </div> */}
            </util.popUp>


            {/* Shipping document is here */}

            <div className={"flex justify-center items-center"}>
                <util.popUp
                    toggle={(selected) => (
                        <div className={"relative"}>
                            <UI.Button item={{
                                buttonType: "flat",
                                className: `p-[2px]  ${activeStyle(actions?.internal_notes?.length)}`
                            }}>
                                <Icons.internalNote className="w-4 h-4"/>
                            </UI.Button>
                            {actions?.internal_notes?.length > 0 && <div
                                className={"absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"}>{actions?.internal_notes.length}</div>}
                        </div>
                    )}
                    leftSpace={style?.leftSpace}
                >
                    <div className={"w-[525px]"}>
                        <Components.internalNote callBack={callBack ? callBack : null} notes={actions?.internal_notes}
                                                 id={actions?.item_id ? `${actions?.order_id}/${actions?.item_id}` : actions?.order_id}/>
                    </div>
                </util.popUp>
            </div>
            {/* Shipping Content is here */}

            <util.popUp
                toggle={(selected) => (
                    <div>
                        <UI.Button item={{
                            buttonType: "flat",
                            className: `p-[2px] ${activeStyle(actions?.shipping_content_enabled)}`
                        }}>
                            <Icons.repricerNote/>
                        </UI.Button>
                    </div>
                )}
                leftSpace={style?.leftSpace}
            >
                {/* <div className={"px-[8px] py-[4px]"}>
                    <Components.shippingContent
                        shipping_content={actions?.shipping_content}
                        gtip={actions?.gtip}
                        id={actions?.item_id ?
                            `${actions?.order_id}/${actions?.item_id}`
                            :
                            actions?.order_id
                        }
                        callBack={(data)=>{
                            if (callBack) { callBack(data)}
                        }}
                    />
                </div> */}

            </util.popUp>


        </div>

    )
}
export default OrderRowAction;