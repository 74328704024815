import { IoMdCalculator } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCalculatorValues,
  updateXButton,
} from "../../../store/calculator";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchCalculatorData } from "../../../store/calculator/thunk";

const CalculateWeight = ({ item, submit, binded, data, validation }) => {
  const dispatch = useDispatch();
  const [billableWeight, setBillableWeight] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const status = useSelector((state) => state.calculator.status);

  useEffect(() => {
    setDisabled(isEmpty());
  }, [data]);

  const isEmpty = () => {
    let disabled = false;
    Object.keys(data).map((item) => {
      if (data[item] === null || data[item] === "") {
        disabled = true;
      }
    });
    return disabled;
  };

  const calculateNumber = () => {
    dispatch(updateCalculatorValues(data));

    let tempBillable;

    if (
      (data.length * data.width * data.height) / data.dim_divison >
      data.weight
    ) {
      setBillableWeight(
        (data.length * data.width * data.height) / data.dim_divison
      );
      tempBillable =
        (data.length * data.width * data.height) / data.dim_divison;
    } else {
      setBillableWeight(parseFloat(data.weight));
      tempBillable = parseFloat(data.weight);
    }
    if (tempBillable < 201) {
      dispatch(
        fetchCalculatorData({
          payload: data,
          refresh: false,
          from_state_id: binded?.onWarehouse?.data?.state?.id,
        })
      ).then((res) => {
        if (res?.payload?.message) {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      toast.error("The billable weight must be less than 200 kg.");
      dispatch(updateXButton(true));
    }
  };

  return (
    <div className=" flex  w-full md:w-[220px]  justify-between flex-col md:flex-row  pr-2     ">
      {status !== "pending" ? (
        <button
          disabled={disabled}
          onClick={calculateNumber}
          className={`${
            disabled
              ? "bg-blue-300 md:mt-[17px]   hover:text-gray-300  w-full md:w-[42px] h-[40px]   flex justify-center items-center p-[9px] rounded-md"
              : "md:mt-[17px] bg-blue-600 hover:bg-blue-700 w-full md:w-[42px] h-[40px]  hover:text-gray-300    flex justify-center items-center p-[9px] rounded-md "
          }`}
        >
          <span>
            <IoMdCalculator className="w-[18px] h-[18px] text-white " />
          </span>
        </button>
      ) : (
        <button
          disabled={true}
          className="bg-blue-300 md:mt-[17px]  w-full md:w-[42px] h-[41px]   hover:text-gray-300    flex justify-center items-center p-[9px] rounded-md"
        >
          <span>
            <IoMdCalculator className="w-[18px] h-[18px] text-white " />
          </span>
        </button>
      )}
      {billableWeight !== null && (
        <div className="flex flex-col  justify-end gap-1   ">
          <span className="text-black text-[14px] leading-[21px] font-medium text-right md:text-left mt-5 md:mt-0 ">
            Billable Weight
          </span>
          <span className="text-right text-[30px] font-[500] leading-[30px]  text-gray-900">
            {billableWeight % 1 === 0
              ? billableWeight
              : billableWeight.toFixed(1)}{" "}
            kg
          </span>
        </div>
      )}
    </div>
  );
};

export default CalculateWeight;
