import upperCase from "../../dynamic-page/util/upperCaseString";
import util from "../../dynamic-page/util";
import { HiFilter, HiInformationCircle } from "react-icons/hi";
import moment from "moment";
import { FaTruckFast } from "react-icons/fa6";
import Icons from "../../orders/icons";
import descriptiveContent from "../../../utils/descriptiveContent";
import priceHandler from "../../../utils/priceHandler";

const tableView = (
  list,
  onCarrierFilter,
  onFromWarehouseFilter,
  onStateFilter,
  onBillableWeightFilter,
  actionList
) => {
  return {
    list: list,
    theme: {
      provider: (item) => (
        <div className="flex justify-start   gap-1.5 items-center">
          {item?.carrier?.provider?.logo ? (
            <img
              className="w-4 h-4 object-contain bg-none"
              src={item?.carrier?.provider?.logo}
            />
          ) : null}
          <p className="font-medium text-[14px] leading-[21px]  text-gray-900">
            {item?.carrier?.provider?.name
              ? upperCase(item?.carrier?.provider?.name, "first")
              : "-"}
          </p>
        </div>
      ),
      "CARRIER SERVICE": (item) => (
        <div>
          <div className="flex justify-start  gap-1.5  items-center  ">
            {item?.carrier?.international_carrier?.logo ? (
              <img
                className="w-4 h-4 object-contain bg-none"
                src={item?.carrier?.international_carrier?.logo}
              />
            ) : (
              <FaTruckFast className="text-gray-300 " />
            )}
            <p className="min-w-min flex justify-start font-[500] text-[14px] leading-[21px]">
              {item?.carrier?.name
                ? upperCase(item?.carrier?.name, "first")
                : "-"}
            </p>
          </div>
          <div className="flex items-center gap-1  ">
            <span className="font-medium leading-[18px] text-[12px]  text-[#374151]">
              {item?.service_name}
            </span>
            {descriptiveContent(
              <HiFilter
                onClick={() => {
                  onCarrierFilter(item?.carrier?.id, {
                    carrier_ids: {
                      value: item?.carrier?.id,
                      label: item?.carrier?.name,
                    },
                  });
                }}
                className="text-gray-300 hover:text-gray-500 w-3 h-3 cursor-pointer"
              />,
              "Filter by Carrier"
            )}
          </div>
        </div>
      ),
      "FROM state, country": (item) => (
        <div className="flex justify-start gap-2   items-center ">
          {item?.from_state?.country?.flag ? (
            <img
              className="w-[22.4px] h-[16px] bg-none "
              src={item?.from_state?.country?.flag}
            />
          ) : null}
          <p className="font-medium text-[14px] leading-[21px]  text-gray-900">
            {item?.from_state?.name
              ? upperCase(item?.from_state?.name, "first")
              : "-"}
            , {item?.from_state?.country?.code}
          </p>
          {descriptiveContent(
            <HiFilter
              onClick={() => {
                onFromWarehouseFilter(item?.from_state?.id, {
                  from_state_ids: {
                    value: item?.from_state?.id,
                    label: item?.from_state?.name,
                  },
                });
              }}
              className="text-gray-300  hover:text-gray-500 w-3 h-3 cursor-pointer"
            />,
            "Filter by From state"
          )}
        </div>
      ),
      "TO STATE, COUNTRY": (item) => (
        <div className="flex justify-start gap-2   items-center ">
          {item?.toState?.country?.flag ? (
            <img
              className="w-[22.4px] h-[16px] bg-none object-contain"
              src={item?.toState?.country?.flag}
            />
          ) : null}
          <p className="font-medium text-[14px] leading-[21px]  text-gray-900">
            {item?.toState?.name
              ? upperCase(item?.toState?.name, "first")
              : "-"}
            , {item?.toState?.country?.code}
          </p>
          {descriptiveContent(
            <HiFilter
              onClick={() => {
                onStateFilter(item?.toState?.id, {
                  to_state_ids: {
                    value: item?.toState?.id,
                    label: item?.toState?.name,
                  },
                });
              }}
              className="text-gray-300  hover:text-gray-500 w-3 h-3 cursor-pointer"
            />,
            "Filter by To state"
          )}
        </div>
      ),
      weight: (item) => (
        <div className="flex items-center gap-1  ">
          <span className="font-bold text-[14px] leading-[21px]   text-gray-900">
            {item?.billable_weight} kg
          </span>
          {descriptiveContent(
            <HiFilter
              onClick={() => {
                onBillableWeightFilter(item?.billable_weight);
              }}
              className="text-gray-300 hover:text-gray-500 w-3 h-3 cursor-pointer"
            />,
            "Filter By Weight"
          )}
        </div>
      ),
      cost: (item) => (
        <div>
          <util.popUp
            toggle={(selected) => (
              <div className="flex   justify-end items-center">
                <div className="flex items-center gap-1">
                  <span className="font-bold  text-[14px] leading-[21px] text-gray-900">
                    ${priceHandler(item?.cost)}
                  </span>
                  <HiInformationCircle className="text-gray-300 hover:text-gray-500 w-3 h-3  " />
                </div>
              </div>
            )}
            action="hover"
            forcePosition={"bottom"}
          >
            <div className="flex gap-2 flex-col text-xs font-normal  py-1 px-2 w-[240px]">
              <div className="flex justify-between">
                <span>Raw Shipping Cost</span>
                <span className="text-gray-900">
                  ${priceHandler(item?.cost)}
                </span>
              </div>
              <div className="flex justify-between font-normal ">
                <span className="text-[14px]">Updated at</span>
                <span className="text-green-600 leading-[21px] text-[14px]">
                  {moment(item?.updated_at).fromNow()}
                </span>
              </div>
            </div>
          </util.popUp>
        </div>
      ),
      "PER KG": (item) => (
        <div>
          <span className="font-medium text-[14px] leading-[21px]   text-gray-900">
            $
            {priceHandler(
              Number(item?.cost / item?.billable_weight).toFixed(2)
            )}
          </span>
        </div>
      ),
      icons: (item) => (
        <div className="flex gap-4 items-center">
          <button>
            {descriptiveContent(
              <Icons.trashBin
                onClick={() => actionList.delete.onClick(item)}
                className="w-[18px] h-[18px] text-gray-300 cursor-pointer hover:text-gray-500"
              />,
              "Delete"
            )}
          </button>
        </div>
      ),
    },
  };
};
export default tableView;
