import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const Dropdown = ({
  className,
  value,
  title,
  height,
  maxHeight,
  width,
  items,
  hideArrow,
  outline,
  border_color,
  bg_color,
  text_color,
  disabled,
  onSelect,
  icon,
  reverseIcon,
  align,
  justify,
  border,
  insetValue,
  dropWidth,
  degree,
  gray,
}) => {
  const [visibleContextMenu, setContextVisibility] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    setActiveItem(value);
  }, [value]);

  const onItemSelect = (item) => {
    // setActiveItem(item);
    onSelect(item);
    setContextVisibility(false);
  };
  return (
    <div className="relative cursor-default   ">
      {visibleContextMenu && (
        <div
          id="wrapper"
          className="fixed left-0 top-0 w-screen  bg-transparent"
          style={{ zIndex: 400 }}
          onClick={() => setContextVisibility(false)}
        ></div>
      )}
      <button
        id="dropdownDefault"
        data-dropdown-toggle="dropdown"
        className={classNames(
          `h-[${height}]  cursor-default
          
           w-${!width ? "44" : "[" + width + "]"} z-1   text-${text_color}-${
            degree ? degree : "600"
          }
                    bg-${bg_color} border border-${
            border && border
          } border-${border_color}-300 hover:bg-${bg_color} rounded-[8px]
                    focus:ring-4 focus:outline-none focus:ring-${border_color}-300 
                    font-normal  text-sm px-2 py-5  text-${align} inline-flex items-center 
                    dark:bg-${bg_color}-600 dark:hover:bg-${bg_color}-700 dark:focus:ring-${border_color}-800`,
          disabled && "bg-gray-100",
          className
        )}
        type="button"
        disabled={disabled}
        onClick={() => setContextVisibility(!visibleContextMenu)}
      >
        <div
          className={classNames(
            `w-full flex cursor-default text-[14.7px] leading-[21px] tracking-wide  justify-${
              !justify ? "between" : "around"
            } items-center text-${text_color}${degree ? "-" + degree : ""}`
          )}
          style={{
            color: gray && "gray",
          }}
        >
          {activeItem ? activeItem : title}

          {activeItem !== "Sort By" ? icon : ""}
          {!hideArrow && (
            <MdOutlineKeyboardArrowDown className="text-gray-400 w-6 h-6" />
          )}

          <div
            id="dropdown"
            className={classNames(
              !visibleContextMenu && "hidden",
              "w-full bg-white border-[1px]      border-gray-200 divide-y divide-gray-100 rounded shadow dark:bg-gray-700 "
            )}
            style={{
              position: "absolute",
              inset: insetValue
                ? `0px auto auto ${insetValue}`
                : "0px auto auto 0px",
              margin: "0px",
              transform: `translate(0px, 40px)`,
              zIndex: 401,
              width: dropWidth ? dropWidth : "100%",
            }}
            data-popper-placement="bottom"
            onClick={(e) => e.preventDefault()}
          >
            <ul
              className={`py-1  max-h-[40vh] text-sm cursor-default text-gray-700 dark:text-gray-200 `}
              aria-labelledby="dropdownDefault"
            >
              {items?.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    onItemSelect(item);
                  }}
                >
                  <p
                    className={classNames(
                      activeItem === item && "bg-[#00A3FF] text-white",
                      "px-2 py-2 hover:bg-[#00A3FF] hover:text-white  text-[14.7px] text-gray-900   flex items-center justify-between"
                    )}
                  >
                    {item}
                    {activeItem === item && reverseIcon}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </button>
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  height: PropTypes.string,
  value: PropTypes.string,
  items: PropTypes.array,
  outline: PropTypes.bool,
  border_color: PropTypes.string,
  text_color: PropTypes.string,
  bg_color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  reverseIcon: PropTypes.element,
  onSelect: PropTypes.func,
};

Dropdown.defaultProps = {
  className: "",
  title: "Dropdown",
  height: "37px",
  value: null,
  items: ["Item1", "Item2", "Item3"],
  outline: false,
  border_color: "blue-600",
  text_color: "white",
  bg_color: "blue",
  disabled: false,
  icon: <></>,
  reverseIcon: <></>,
  onSelect: () => {},
};

export default Dropdown;
