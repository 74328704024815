// channels/add-edit-modal.js
// -----------------------------------------

// Import general libraries
import React, {useRef} from "react";
import * as Yup from "yup";
import UI from "../../dynamic-form/elements";

// Import Elements
import DynamicForm from "../../dynamic-form";
import constants from "../../../constant";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import MainModal from "../../dynamic-page/MainModal";
import Toggle from "../../dynamic-form/components/toggle";

// Main Component: CreateFromScratch
const CreateFromScratch = React.memo(
    ({type, userId, data, onClose, onUpdate, onAdd, product_id}) => {
        const createNewRef = useRef(false);
        const updateCreateNew = (value) => {
            createNewRef.current = value;
        };
        const handleCheckboxChange = () => {
            updateCreateNew(!createNewRef.current);
        };

        const [formData, setFormData] = React.useState(data);
        const [boundData, setBoundData] = React.useState();

        // Form Json
        const dynamicForm = (formDefaultValue, bound) => [
            {
                name: "product_id",
                label: "Product",
                type: "Select2",
                api: {
                    url: "/api/v1/product",
                    query: "?term={query}&type=1",
                    optionValue: (item) => ({
                        value: item?.id,
                        label: upperCaseString(item?.title, "word", true),
                        image: item?.image,
                    }),
                },
                disablePreload: data?.id ? false : true,
                reset: false,
                clear: false,
                className: "col-span-12",
                features: {
                    components: {
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    },
                    formatOptionLabel: (child) => (
                        <div className={"flex flex-row items-center gap-[8px]"}>
                            {child?.image ? (
                                <img
                                    className={"w-[32px] h-[32px] rounded-full"}
                                    src={child?.image}
                                    alt="Product"
                                />
                            ) : (
                                <img
                                    className={"w-[32px] h-[32px] rounded-full"}
                                    src={"/assets/images/noImage.png"}
                                    alt="Product"
                                />
                            )}
                            <span className={"text-gray-800"}>{child?.label}</span>
                        </div>
                    ),
                },
                disabled: product_id ? true : false,
                defaultValue:
                    formDefaultValue?.product?.id ||
                    formDefaultValue?.product_id ||
                    product_id ||
                    null,
                placeholder: (
                    <div
                        className={
                            "flex flex-row gap-[8px] items-center text-[14px] text-[#6B7280]"
                        }
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15px"
                            height="15px"
                            fill="none"
                        >
                            <g clipPath="url(#a)">
                                <path
                                    stroke="#6B7280"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m10.5 10.5-3-3m1-2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                                />
                            </g>
                            <defs>
                                <clipPath id="a">
                                    <path fill="#fff" d="M0 0h12v12H0z"/>
                                </clipPath>
                            </defs>
                        </svg>
                        {/* {item?.placeholder} */} Quick search on products
                    </div>
                ),
            },
            {
                name: "channel_id",
                label: "Supply Channel",
                placeholder: "Select Channel",
                type: "Select",
                api: {
                    url: "/api/v1/channel",
                    query:
                        "?term={query}&filter[supply_channel_type]=WEBSITE,WHOLESALER,MANUFACTURER",
                    optionValue: (item) => ({
                        value: item.id,
                        label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                        logo: item.logo,
                        type: item?.supply_channel_type,
                    }),
                },
                features: {
                    formatOptionLabel: ({label, logo, type}) => (
                        <div
                            className={"flex flex-row gap-[12px] items-center min-h-[25px]"}
                        >
              <span
                  className={
                      "w-[25px] object-cover block overflow-hidden flex flex-col justify-center items-center "
                  }
              >
                <img
                    src={logo || "/assets/images/noImage.png"}
                    alt="Channel"
                />
              </span>
                            <span>{upperCaseString(label)}</span>
                            {type && (
                                <span>
                  ({" "}
                                    {
                                        // leave the first letter as it is  and lowercase the rest
                                        type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
                                    }{" "}
                                    )
                </span>
                            )}
                        </div>
                    ),
                },
                defaultValue:
                    formDefaultValue?.channel?.id || formDefaultValue?.channel_id || null,
                className: "col-span-12",
                validation: Yup.string().required("Supply Channel is required"),
            },
            {
                name: "is_trackable",
                // label: "Tackable Quantity",
                placeholder: "blach",
                type: "Module",
                module: Toggle,
                className: "col-span-12",
                defaultValue: formDefaultValue?.is_trackable ? true : false || false,
                labelClassName: "!text-gray-900 !font-medium",
                // optional : true,
            },
            {
                name: "price",
                label: "Price",
                placeholder: "ex. 15.99",
                type: "Input",
                defaultValue: formDefaultValue?.price || null,
                // disabled: formDefaultValue?.ucode ? true : false,
                className: "col-span-4",
                validation: Yup.string().required("Price is required"),
            },
            {
                name: "currency_id",
                label: "Currency",
                placeholder: "TRY",
                type: "Select",
                api: {
                    url: constants.APIURL.GET_CURRENCIES + "?filter[status]=1",
                    view: constants.APIURL.GET_CURRENCIES,
                    query: "&term={query}",
                    optionValue: (item) => ({
                        value: item.id,
                        label: upperCaseString(item.code),
                    }),
                },
                defaultValue: data?.currency?.id || 1,
                validation: Yup.string().required("Currency is required"),
                // disablePreload: true,
                // style: {
                //     control: {
                //         width: "205px",
                //     },
                //     container: {
                //         width: "205px",
                //     },
                //     menu: {
                //         whiteSpace: 'wrap !important',
                //         width: "200px"
                //     },
                // },
                className: "col-span-4",
                clear: false,
            },
            {
                name: "quantity",
                label: "Quantity",
                placeholder: "ex. 200",
                type: "Input",
                defaultValue: formDefaultValue?.quantity || null,
                // disabled: formDefaultValue?.ucode ? true : false,
                className: "col-span-4",
                validation: (formData, boundData) => {
                    // Generate validation dynamically based on formData
                    if (formData?.is_trackable === true) {
                        return Yup.string().required("Quantity is required");
                    } else {
                        return Yup.string(); // No validation
                    }
                },
                condition: (formData) => formData?.is_trackable === true,
            },
            {
                name: "url",
                label: "URL",
                placeholder: "ex. www.hepsiburada.com/prod...",
                type: "Input",
                defaultValue: formDefaultValue?.url || null,
                // optional: true,
                className: "col-span-12",
                condition: (formData, boundData) =>
                    boundData?.channel_id?.data?.supply_channel_type === "WEBSITE",
                validation: (formData, boundData) => {
                    // Generate validation dynamically based on formData
                    if (boundData?.channel_id?.data?.supply_channel_type === "WEBSITE") {
                        return Yup.string().required("Website is required");
                    } else {
                        return Yup.string(); // No validation
                    }
                },
            },
        ];

        const myFormRef = useRef();
        const myFormCallBack = async () => {
            let formData = await myFormRef?.current?.getData();
            // return
            if (formData) {
                if (!data?.id) {
                    // onClose()
                    if (!createNewRef.current) {
                        // myFormRef.current.reset()
                        // reset all of the fields except the channel_id , also keep the create new checkbox checked

                        const keysToReset = Object.keys(formData).filter(
                            (key) => key !== "channel_id"
                        );

                        onAdd(formData, false, (refresh) => refresh()).then((res) => {
                            if (res?.type === "success") {
                                myFormRef.current.reset();
                            }
                        });
                        // onAdd(formData, false);
                    } else onAdd(formData, true);
                } else {
                    formData = {
                        ...formData,
                        id: data?.id,
                    };
                    onAdd(formData, true);
                }
            }
        };

        return (
            <MainModal
                className="max-w-[672px]"
                containerClassName="!px-5 !pb-5"
                item={{
                    title: `Create Supply Channel Listing from the Product`,
                    submit: myFormCallBack,
                    cancel: onClose,

                    view: (item) => (
                        <div>
                            {
                                <div
                                    className="min-w-[500px] grid grid-cols-12 items-start gap-[8px]  justify-center border-t border-t-gray-200 pt-[8px] ">
                                    {/* Dynamic form is here */}
                                    <DynamicForm
                                        items={dynamicForm(formData, boundData)}
                                        ref={myFormRef}
                                        style={{
                                            label: {className: "!text-gray-900 !font-medium"},
                                        }}
                                        onChange={(data, bound) => {
                                            setBoundData(bound);
                                            setFormData(data);
                                        }}
                                    />
                                </div>
                            }

                            {/* Form control Buttons are here */}
                            <div className="flex w-full justify-between  pt-[8px]  gap-[8px] sticky bottom-0 bg-white">
                                <div className="">
                                    <input
                                        type="checkbox"
                                        id="scales"
                                        name="scales"
                                        ref={createNewRef}
                                        onChange={handleCheckboxChange}
                                        disabled={false}
                                        className={
                                            "w-[20px] h-[20px] mr-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 rounded-md "
                                        }
                                    />
                                    <label for="scales" className="text-[14px] font-medium text-gray-900">Create
                                        Another</label>
                                    {/* <Checkbox
                                    item={{
                                        list : [
                                            {label : "Create New", value : true}
                                        ],
                                        clear: false,
                                        labelClassName: '!text-gray-900 !font-medium',
                                        checkAll : false,
                                    }}
                                    handleChange={handleCheckboxChange}
                                    value={createNewRef.current}
                                    disabled={false}
                                    className={"w-[20px] h-[20px]"}
                                /> */}
                                </div>

                                <div className="flex gap-2">
                                    <UI.Button
                                        item={{
                                            label: <div className={"flex flex-row"}>Cancel</div>,
                                            buttonType: "alt",
                                            className: "h-[37px] !px-[16px]",
                                        }}
                                        onClick={() => onClose()}
                                    />
                                    <UI.Button
                                        item={{
                                            label: (
                                                <div className={"flex flex-row"}>
                                                    {data?.id ? "Update" : "Create"}
                                                </div>
                                            ),
                                            buttonType: "primary",
                                            className: "h-[37px] !px-[16px]",
                                        }}
                                        onClick={myFormCallBack}
                                    />
                                </div>
                            </div>
                        </div>
                    ),
                }}
            />
        );
    }
);

export default CreateFromScratch;
