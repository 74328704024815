/**
 * @author Austin Ames
 * @descritpion ArbitEditableText - a text badge with edit icon and changing as an input box
 */
import { useState } from "react";
import classNames from "classnames";
import { HiCheck, HiX, HiPencil } from "react-icons/hi";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Tooltip } from "flowbite-react";
import { useDispatch } from "react-redux";
import { createBrand } from "../../store/brands";
import util from "../dynamic-page/util";
import Util from "../dynamic-page/util";

const units = ["px", "em", "%"];

const ArbitEditableDropdown = ({
  value,
  secondValue,
  fontSize,
  fontColor,
  bgColor,
  fontWeight,
  showIcon,
  onChange,
  copy,
  cols,
  options,
  width,
  placeholder,
  loadOptions,
  creatable,
}) => {
  const [isEditable, setEditable] = useState(false);
  const [lastValue, setLastValue] = useState("");
  const [localValue, setLocalValue] = useState("");
  const [valid, setValid] = useState(true);

  const dispatch = useDispatch();

  const onHandleEdit = () => {
    setEditable(true);
    setLastValue(value);
    setLocalValue(value);
  };

  const onHandleSave = () => {
    if (!localValue?.value) {
      setValid(false);
      return;
    }
    onChange(localValue);
    setEditable(false);
  };

  const onHandleCancel = () => {
    // setLocalValue(lastValue);
    // onChange(lastValue);
    setEditable(false);
  };

  return (
    <>
      <div
        className={classNames(
          `${"!w-full  flex justify-between "}`,
          isEditable &&
            `border-[1px]  rounded-[8px]  p-[4px] border-gray-300 ${
              !valid && "border-red-500"
            } `
        )}
      >
        <form
          action=""
          className="flex !w-full h-[31px]  space-x-[4px] items-center  justify-between editableDropDown"
        >
          {!isEditable ? (
            <div
              className={`flex items-center justify-between  ${
                !copy && "!w-full"
              } ${secondValue && " !w-full justify-between"}  gap-1.5`}
            >
              <p
                className={classNames(
                  fontColor && `text-${fontColor}`,
                  units.some((unit) => fontSize.endsWith(unit))
                    ? `text-[${fontSize}]`
                    : `text-${fontSize}`,
                  fontWeight && `font-[${fontWeight}]`,
                  "outline-none  whitespace-nowrap  h-[31px] p-0 w-full border-0",
                  isEditable ? "bg-gray-50" : `bg-${bgColor}`
                )}
              >
                {value}
              </p>
              {copy && (
                <span className="flex justify-center">
                  <util.copyText
                    className="text-[12px]"
                    text={value}
                    hint={true}
                  />
                </span>
              )}

              {secondValue && (
                <div className="flex justify-end  !w-full">
                  <p className={"text-[20px]  leading-[30px] font-medium "}>
                    {secondValue}
                  </p>
                </div>
              )}
            </div>
          ) : (
            // <SearchableDropdown
            //     options={brandsData}
            //     setData={setLocalValue}
            //     data={isEditable ? localValue : value}
            //     placeholder={'Selecet Brand'}
            // />
            <div className="editabledropdownAsync z-[9999999] w-full">
              {!creatable ? (
                <AsyncSelect
                  loadOptions={loadOptions}
                  placeholder={placeholder}
                  defaultOptions
                  cacheOptions
                  options={options}
                  value={isEditable ? localValue : value}
                  onChange={(e) => {
                    setLocalValue(e);
                  }}
                  optionHeight={50}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      // height: 50,
                      // minHeight: 50,
                      // width: 300,
                      width: "100%",
                      minWidth: "100%",
                      borderRadius: 10,
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",

                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#00A3FF" : "white",
                      color: state.isSelected ? "white" : "black",
                      "&:hover": {
                        backgroundColor: "#00A3FF",
                        color: "white",
                      },
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      borderRadius: 10,
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                      minWidth: "100%",
                      borderRadius: 10,
                    }),
                  }}
                />
              ) : (
                <AsyncCreatableSelect
                  loadOptions={loadOptions}
                  placeholder={"ex. Omron"}
                  defaultOptions
                  cacheOptions
                  options={options}
                  value={isEditable ? localValue : value}
                  formatCreateLabel={(inputValue) => (
                    <div className="cursor-pointer">
                      <span className="text-blue-700 font-medium ">
                        + Add New:{" "}
                      </span>
                      {/* <HiPlus className="inline-block" /> */}
                      <span>{inputValue}</span>
                    </div>
                  )}
                  isValidNewOption={(inputValue) =>
                    inputValue.trim().length > 0
                  }
                  onCreateOption={(inputValue) => {
                    // go to brands page
                    dispatch(createBrand({ name: inputValue }))
                      .unwrap()
                      .then((payload) => {
                        setLocalValue({
                          label: payload?.data?.data?.name,
                          value: payload?.data?.data?.id,
                        });
                        // change the input value to the created brand
                        // if (payload?.status === 200){
                        util.notifier(payload.data);

                        // }
                      });
                  }}
                  onChange={(e) => {
                    setLocalValue(e);
                  }}
                  optionHeight={50}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      // height: 50,
                      // minHeight: 50,
                      // width: 300,
                      width: "100%",
                      minWidth: "100%",
                      borderRadius: 10,
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                      zIndex: 9999,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#00A3FF" : "white",
                      color: state.isSelected ? "white" : "black",
                      "&:hover": {
                        backgroundColor: "#00A3FF",
                        color: "white",
                      },
                      zIndex: 9999,
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      borderRadius: 10,
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                      boxShadow: state.isFocused
                        ? "0px 0px 0px 1px #00A3FF"
                        : "none",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                      },
                      zIndex: 9999,
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                      minWidth: "100%",
                      borderRadius: 10,
                      zIndex: 9999,
                    }),
                  }}
                />
              )}
            </div>
          )}

          {showIcon &&
            (!isEditable ? (
              <div className="cursor-pointer  !w-full flex justify-end  text-gray-400">
                <HiPencil
                  className="cursor-pointer text-gray-300 hover:text-gray-500"
                  onClick={() => onHandleEdit()}
                />
              </div>
            ) : (
              <div className="flex space-x-[4px] items-center">
                <HiCheck
                  className="cursor-pointer text-gray-300 hover:text-gray-500"
                  onClick={() => onHandleSave()}
                />
                <HiX
                  className="cursor-pointer text-gray-300 hover:text-gray-500"
                  onClick={() => onHandleCancel()}
                />
              </div>
            ))}
        </form>
      </div>
    </>
  );
};

ArbitEditableDropdown.defaultProps = {
  value: "",
  showIcon: true,
  fontSize: "14px",
  fontColor: "gray-900",
  bgColor: "white",
  fontWeight: null,
  onChange: () => {},
};

export default ArbitEditableDropdown;
