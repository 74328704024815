import util from "../../dynamic-page/util";
import DynamicForm from "../index";
import React, {useEffect} from "react";
import upperCaseString from "../../dynamic-page/util/upperCaseString";


const ProductList = ({item, submit}) => {

    const [list, setList] = React.useState(item?.defaultValue || []);
    const connectProductFrom = () => ([
        {
            name: "product",
            label: "Quick search on products",
            type: "Select",
            api: {
                url: '/api/v1/product?filter[type]=1',
                query: "&term={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: upperCaseString(item?.title, "word", true),
                    image: item?.image,
                })
            },
            disablePreload: true,
            reset: false,
            clear: false,
            className: "w-full",
            style: {
                control: {
                    height: "42px !important",
                    minHeight: "auto",
                    background: "#F9FAFB",
                    borderColor: "#D1D5DB",
                },
                menu: {
                    whiteSpace: 'wrap !important',
                },
            },
            features: {
                components: {
                    DropdownIndicator: () => (
                        <div className={"px-[16px] justify-center items-center flex h-[100%]"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" fill="none">
                                <g clipPath="url(#a)">
                                    <path stroke="#6B7280" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="m10.5 10.5-3-3m1-2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"/>
                                </g>
                                <defs>
                                    <clipPath id="a">
                                        <path fill="#fff" d="M0 0h12v12H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    ),
                    IndicatorSeparator: () => null
                },
                formatOptionLabel: (child) => (
                    <div className={"flex flex-row items-center gap-[8px]"}>
                        {child?.data?.image ?
                            <img className={"w-[32px] h-[32px] rounded-full"} src={child?.data?.image}/>
                            :
                            <img className={"w-[32px] h-[32px] rounded-full"} src={"/assets/images/noImage.png"}/>
                        }
                        <span className={"text-gray-800"}>{child?.data?.title}</span>
                    </div>
                ),
            },
            labelClassName: "hidden",
            placeholder: (
                <div className={"flex flex-row gap-[8px] items-center text-[14px] text-[#6B7280]"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="none">
                        <path fill="#6B7280" stroke="#6B7280"
                              d="M8.3 7.2v.5h2.9a.3.3 0 1 1 0 .6H8.3v2.9a.3.3 0 1 1-.6 0V8.3H4.8a.3.3 0 0 1 0-.6h2.9V4.8a.3.3 0 0 1 .6 0v2.4Z"/>
                    </svg>
                    {item?.placeholder}
                </div>),
        }
    ])

    useEffect(() => {
        submit(list)
    }, [list]);


    return (

        <div className="w-[100%] rounded-lg border border-sky-100 flex-col justify-start items-start max-w-[800px]">
            <div
                className="h-[36px] px-[16px] flex bg-blue-50 border-b border-gray-200 justify-start items-center text-blue-800 text-sm font-semibold uppercase leading-[21px]">
                {util.upperCaseString(item.label, "all")}
            </div>

            {list.length ?
                list.map((productItem, index) => (

                    <div className={"flex flex-col"} key={index}>
                        <div
                            className="pl-[16px] py-[7px] border-b border-gray-200 justify-start gap-[10px] text-gray-900 text-sm font-medium leading-[17.50px] flex flex-row items-center">
                            {productItem?.product?.image ?
                                <img className="w-[40px] h-[40px] rounded-full" src={productItem?.product?.image}/>
                                :
                                <img className="w-[40px] h-[40px] " src={"/assets/images/noImage.png"}/>

                            }
                            <div className={"flex flex-auto"}>
                                {productItem?.product?.title}
                            </div>
                            <div className={"!w-[96px] flex justify-center"}>
                                <DynamicForm items={[
                                    {
                                        name: "quantity",
                                        type: "Number",
                                        defaultValue: productItem?.quantity,
                                        clear: false,
                                        reset: false,
                                        innerClassName: "round-[8px] bg-[#F9FAFB] text-[14px] h-[32px] w-[64px]",
                                        handle: true
                                    }
                                ]} onChange={(d, bind) => {
                                    let temp = [...list];
                                    temp[index].quantity = d.quantity;
                                    setList(temp);
                                }}/>

                            </div>
                            <div className={"!w-[62px] flex justify-center"}>
                                <div
                                    className={"text-red-600 rounded-full hover:bg-gray-100 transition hover:text-red-700 cursor-pointer"}
                                    onClick={() => {
                                        let temp = [...list];
                                        temp.splice(index, 1);
                                        setList(temp);
                                    }
                                    }>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="none">
                                        <path fill="currentColor" fillRule="evenodd"
                                              d="M14.1 7.8a.9.9 0 0 0-.804.498L12.644 9.6H9.6a.9.9 0 1 0 0 1.8v9a1.8 1.8 0 0 0 1.8 1.8h7.2a1.8 1.8 0 0 0 1.8-1.8v-9a.9.9 0 1 0 0-1.8h-3.044l-.651-1.302A.9.9 0 0 0 15.9 7.8h-1.8Zm-1.8 5.4a.9.9 0 1 1 1.8 0v5.4a.9.9 0 1 1-1.8 0v-5.4Zm4.5-.9a.9.9 0 0 0-.9.9v5.4a.9.9 0 1 0 1.8 0v-5.4a.9.9 0 0 0-.9-.9Z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </div>

                            </div>
                        </div>
                    </div>
                ))
                :
                <div className={"p-[16px] flex flex-col justify-center items-center text-gray-300 text-[22px]"}>
                    Empty!
                    <span className={"text-[14px]"}>No products Selected.</span>
                </div>
            }


            <div className={"p-[8px] flex flex-col gap-[8px]"}>
                <DynamicForm items={connectProductFrom()} onChange={(d, bind) => {
                    setList([...list, {
                        product: bind.product.data,
                        quantity: 1,
                        id: bind.product.data.id
                    }])
                }}/>
                <div
                    className="text-blue-700 text-sm font-normal leading-[17.50px] justify-end flex hover:text-blue-800 cursor-pointer transition"
                    onClick={() => {
                        window.open("/product/list#action=add", "_blank")
                    }}
                >
                    Didn’t find the single product? Create new.
                </div>
            </div>

        </div>
    )
}

export default ProductList