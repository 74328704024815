import util from "./index";
import React from "react";


const shortenString = (string, maxLength, noTooltip) => {
    if (!string) return "";
    if (string?.length <= maxLength) {
        return string;
    } else {
        return (
            !noTooltip ? <util.popUp
                toggle={(selected) => (<>{string?.slice(0, maxLength - 3) + '...'}</>)}

                action={"hover"}
                // forcePosition={"top"}
            >
                <div className={"px-[8px] py-[4px] text-[14px] whitespace-pre-wrap max-w-[300px]"}> {string} </div>
            </util.popUp> : <>{string?.slice(0, maxLength - 3) + '...'}</>
        )
    }
}
export default shortenString;