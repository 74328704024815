import React from "react";
import Util from "../../dynamic-page/util";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import {HiInformationCircle} from "react-icons/hi";
import Components from "../../orders/components";
import OrderId from "../../shipments/components/orderid";
// import priceHandler from "../../../utils/priceHandler"; 
// import Weight from "../components/weight";
// import ShipmentLabel from "../components/shipmentLabel";
// import InternalNote from "../components/internalNote";
import moment from "moment";
import descriptiveContent from "../../../utils/descriptiveContent";

const tableView = (list, callBack) => {
    const activeStyle = (status) => {
        if (status) {
            return "!text-blue-500";
        } else {
            return "!text-gray-500";
        }
    };

    const nullHandle = (data, chained) => {
        if (!data || chained == null) {
            return "-";
        } else {
            return chained;
        }
    };

    const statusLabel = (item) => {
        return Object.values(item?.statuses).map((status) => {
            return {
                action: status?.detail,
                date: status?.timestamp,
                user: status?.created_by || 'System',
                location: status?.location || 'Warehouse',
            }

        });
    };


    return {
        list: list,
        theme: {
            "actions": (item) => (
                <div className={"flex  gap-[0px] justify-start  w-full"}>
                    <Util.popUp
                        toggle={(selected) => (
                            <Icons.returnRequest
                                className={`${item?.return_request_status === "Approved" ? "text-red-700" : "text-green-700"}`}/>
                        )}
                        action={"hover"}
                    >
                        <div className=" w-[200px] flex justify-between items-center ">
                            <div className="text-gray-900 text-md font-normal leading-[17.50px]">
                                Return Request Status
                            </div>
                            <div className="text-gray-900 text-md font-normal leading-[17.50px]">
                                {item?.return_request_status}
                            </div>
                        </div>
                    </Util.popUp>
                    <Util.popUp
                        toggle={(selected) => (
                            <Icons.labelsPaidBy
                                className={`${item?.label_to_be_paid_by === "Seller" ? "text-red-700" : "text-green-700"}`}/>
                        )}

                        action={"hover"}
                    >
                        <div className=" w-[200px] flex justify-between items-center ">
                            <div className="text-gray-900 text-md font-normal leading-[17.50px]">
                                Label Paid By
                            </div>
                            <div className="text-gray-900 text-md font-normal leading-[17.50px]">
                                {item?.label_to_be_paid_by}
                            </div>
                        </div>
                    </Util.popUp>

                    <Util.popUp
                        toggle={(selected) => (
                            <Icons.azClaim className={`${item?.a_to_z_claim
                            === "Y" ? "text-red-700" : "text-green-700"}`}/>
                        )}
                        action={"hover"}
                    >
                        <div className=" w-[200px] flex justify-between items-center ">
                            <div className="text-gray-900 text-md font-normal leading-[17.50px]">
                                A-Z Claim
                            </div>
                            <div className="text-gray-900 text-md font-normal leading-[17.50px]">
                                {item?.a_to_z_claim}
                            </div>
                        </div>
                    </Util.popUp>
                </div>
            ),
            "status": (item) => (
                <div className={"w-full h-[64px] flex flex-col gap-1 justify-center items-center relative"}>
                    <Components.orderStatus statusId={item?.order_status_id} color={item?.order_status_color_code}
                                            type='return'
                                            refund_amount={item?.refund_amount}
                        // orderId={item?.id}
                        // orderHistory={<StatusHistory title='Order History' list={statusLabel(item)} />}
                        // callBack={callBack}
                    />
                </div>
            ),
            "control": (item) => (
                <div className={"w-[50px] h-[50px]"}>
                    <Components.orderRowAction actions={{
                        order_id: item?.order?.id,
                        customer_note: item?.order?.customer_note,
                        internal_notes: item?.order?.internal_notes,
                        shipping_content: item?.order?.shipping_content,
                        shipping_documents: item?.order?.shipping_documents,

                        customer_note_enabled: item?.order?.customer_note_enabled,
                        internal_notes_enabled: item?.order?.internal_notes_enabled,
                        shipping_content_enabled: item?.order?.shipping_content_enabled,
                        shipping_documents_enabled: item?.order?.shipping_documents_enabled,
                    }}/>

                </div>
            ),
            "status": (item) => (
                <div className={"w-full h-[64px] flex flex-col gap-1 justify-center items-center relative"}>
                    <Components.orderStatus statusId={item?.order_status_id} color={item?.order_status_color_code}
                                            type='return'
                                            refund_amount={item?.refund_amount}
                                            text={item?.refund_amount > 0 ? "Refunded" : "Refund Requested"}
                        // orderId={item?.id}
                        // orderHistory={<StatusHistory title='Order History' list={statusLabel(item)} />}
                        // callBack={callBack}
                    />
                </div>
            ),
            "order date": (item) => (
                <util.dateDisplay item={item} date={item?.order_date} />
            ),
            "order id": (item) => (
                <div className={"flex flex-col gap-[0px] relative justify-start  w-full"}>
                    <OrderId callBack={callBack} orderId={item?.order_id} order={{
                        shipment_labels_count: item?.shipment_labels_count,
                        order_number: item?.order_id, ...item?.account,
                        marketplace: item?.order?.sales_channel_account?.marketplace
                    }}/>
                </div>
            ),
            "request date": (item) => (
                <util.dateDisplay item={item} date={item?.return_request_date} />
            ),
            "return reason": (item) => (
                <div className={"flex flex-col gap-[0px] justify-start  w-full"}>
                    <span className={"text-gray-900 text-sm font-normal leading-[17.50px]"}>{item?.return_reason}</span>
                </div>
            ),
            "RESOLUTION": (item) => (
                <div className={"flex flex-col gap-[0px] justify-start  w-full"}>
                <span className={"text-gray-900 text-sm font-normal leading-[17.50px]"}>{item?.resolution
                }</span>
                </div>
            ),
            "refunded ": (item) => (
                <div className="w-[101px] h-6 justify-center items-center gap-1 inline-flex">
                    <div className="text-red-700 text-2xl font-bold leading-normal">
                        {item?.refund_amount ? "$" + item?.refund_amount : "-"}
                    </div>
                    <util.popUp
                        toggle={(selected) => (<HiInformationCircle className="w-4 h-4 hover:text-gray-500 text-gray-300"/>)}
                        action={"hover"}
                    >
                        <div className=" w-[200px] flex justify-between items-center ">
                            <div className="text-gray-900 text-md font-normal leading-[17.50px]">
                                Order Amount
                            </div>
                            <div className="text-gray-900 text-md font-normal leading-[17.50px]">
                                {item?.order_amount ? item?.order_amount : "-"}
                            </div>
                        </div>
                    </util.popUp>

                </div>
            ),
            "label cost": (item) => (
                <div className="w-[101px] h-6 justify-center items-center gap-1 inline-flex">
                    <div className="text-red-700 text-2xl font-bold leading-normal">
                        {+item?.label_cost ? "$" + item?.label_cost : "-"}
                    </div>
                </div>
            ),
            "return tracking ID": (item) => (
                item?.return_tracking_id ? <div
                    className={"flex flex-row gap-[8px] box-content w-full items-center"}

                >
                    {descriptiveContent(<img
                        className={"min-w-[20px] h-[20px]"}
                        src={item?.carrier?.international_carrier?.logo}
                    />, item?.carrier?.name)}
                    <span onClick={() => window.open(item?.tracking_url, "_blank")}
                          className={"cursor-pointer flex flex-row gap-[4px] items-center"}>
                                    <span>
                                        {/* {util.shortenString(item?.tracking?.number, 20)} */}
                                        {item?.return_tracking_id}
                                    </span>
                                        <Icons.goToTrackURL className={"text-blue-700 w-[9px]"}/>
                                    </span>
                    <util.copyText className={"text-[12px]"} text={item?.return_tracking_id} hint={""}/>
                </div> : "-"
            )
        },
    };
};
export default tableView;
