import moment from "moment";
import React from "react";
import upperCaseString from "../../dynamic-page/util/upperCaseString";

const StatusHistory = ({list, item}) => {
    return (
        <div className={"flex flex-col gap-[6px] px-[4px] py-[2px]"}>
            <div className="w-full h-[18px] justify-between items-center gap-1.5 inline-flex">
                <div className={"font-bold"}>Shipment Status History</div>
                <div
                    className="text-center text-gray-900 text-sm font-medium leading-[21px]">{item?.current_status ? upperCaseString(item?.current_status, "word") : '-'}</div>
            </div>
            {list.map((item, index) => (
                <div
                    className="text-gray-900 text-xs font-normal leading-[18px] gap-[4px] flex flex-row items-center transition cursor-pointer hover:blue-700"
                    key={index}
                >
                    <spam>{upperCaseString(item.action, "word")}</spam>
                    <>
                    </>
                    {item?.user !== "Arbitbox" && <span className="text-gray-500 text-xs font-normal leading-[18px]">
            by
          </span>}
                    {item?.user !== "Arbitbox" && <spam>{upperCaseString(item.user, "word")}</spam>}
                    <span className="text-gray-500 text-xs font-normal leading-[18px]">
            at
          </span>
                    <spam>{upperCaseString(item?.location, "word")}</spam>
                    <span className="text-gray-500 text-xs font-normal leading-[18px]">
            at
          </span>
                    <spam>{moment(item?.date).format("MMM D, YYYY HH:mm")}</spam>
                    {/* <Icons.goToTrackURL className={"text-gray-500 w-[10px] h-[10px] mr-[4px] ml-[4px]"} /> */}
                </div>
            ))}
            <div className="w-full justify-start items-center gap-1.5 inline-flex">
                {item?.transit?.scheduled_delivery_date &&
                    <div className="text-right text-emerald-700 text-xs font-medium leading-[18px]">
                        Scheduled Delivery
                        Date: {moment(item?.transit?.scheduled_delivery_date).format("MMM D, YYYY HH:mm")}
                    </div>}
            </div>
            <div className="w-full h-[18px] justify-between items-center gap-1.5 inline-flex">
                <div className="text-right text-gray-500 text-xs font-medium leading-[18px]">Transit
                    Time: {item?.transit?.transit_time} Days
                </div>
                <div className="text-right text-gray-500 text-xs font-medium leading-[18px]">Latest
                    Event: {item?.last_status_update ? moment(item?.last_status_update).format("MMM D, YYYY HH:mm") : "-"}</div>
            </div>
        </div>
    );
};
export default StatusHistory;
