import DownloadDocs from "../../accounts/modals-view/files";
import callEndpoint from "../../dynamic-page/util/callEndpoint";
import PermissionsModal from "./user-permissions-modal";
import UserActivity from "./userActivity";

const actionList = {
    add: {
        label: "Add Product",
        onClick: ({forms, data}) => {
            forms?.["add"].open(data);
        },
        condition: false,
    },
    permissions: {
        label: "Manage Permissions",
        onClick: ({forms, data, setVisibleExternalModal}) => {
            const onSubmit = ({id, payload}) => {
                callEndpoint({
                    title: "Assign Permissions",
                    method: "post",
                    url: `user/${id}/assign-permissions`,
                    data: {
                        permission_ids: payload,
                    },
                    pureData: true,
                }).then((response) => {
                    if (response?.type === "success") {
                        setVisibleExternalModal(null);
                    }
                });
            };
            forms?.["permissions"].open(
                data,
                <PermissionsModal
                    onSubmit={onSubmit}
                    onClose={() => setVisibleExternalModal(null)}
                    data={data}
                />
            );
        },
        // condition: false
    },
    activate: {
        label: "Activate",
        onClick: ({data, refreshPage, setConfirmationModalVisibility, replaceRow}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to activate the selected user/s?",
                confirmText: "Yes, Activate",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Activate Users",
                        method: "patch",
                        url: `user/status/bulk-action`,
                        data: {
                            status: 1,
                            users: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            if (ids?.length === 1) {
                                replaceRow(data?.id, {...data, status: 1});
                            } else {
                                refreshPage();
                            }
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === 0;
        },
    },

    deactivate: {
        label: "Hold",
        onClick: ({
                      data,
                      refreshPage,
                      setConfirmationModalVisibility,
                      setVisibleForm,
                      replaceRow,
                  }) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to deactivate the selected user/s?",
                confirmText: "Yes, Deactivate",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Deactivate Users",
                        method: "patch",
                        url: `user/status/bulk-action`,
                        data: {
                            status: 0,
                            users: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            if (ids?.length === 1) {
                                replaceRow(data?.id, {...data, status: 0});
                            } else {
                                refreshPage();
                            }
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === 1;
        },
    },

    edit: {
        label: "Edit",
        onClick: ({forms, actions, data}) => {
            forms?.["add"].open(data);
        },
        bulkDisable: true,
    },
    activity: {
        label: "User Activity",
        onClick: ({forms, data, setVisibleExternalModal}) => {
            forms?.["userActivity"].open(
                data,
                <UserActivity
                    onClose={() => setVisibleExternalModal(null)}
                    data={data}
                />
            );
        },
    },
    delete: {
        label: "Delete",
        onClick: ({data, refreshPage, setConfirmationModalVisibility, removeRow}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to delete the selected user/s?",
                confirmText: "Yes, Delete",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Delete Users",
                        method: "delete",
                        url: `user`,
                        data: {
                            users: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            removeRow(ids)
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status !== 2;
        },
    },

    save: {
        label: "Save",
        onClick: ({data, refreshPage, close}) => {
            const id = data?.id;
            const method = "post";
            const url = id ? `user/${id}` : `user`;
            callEndpoint({
                title: "Save User",
                method,
                url,
                data: data,
            }).then((response) => {
                if (response?.type === "success") {
                    close();
                    refreshPage();
                }
            });
        },
        condition: false,
    },
};

export default actionList;
