import React, {useEffect, useRef, useState} from "react";
import Icons from "../../orders/icons";
import shortenString from "../../dynamic-page/util/shortenString";

const Text = ({
                  item,
                  value,
                  validationMessage,
                  handleChange,
                  defaultValue,
                  formData,
              }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [innerText, setInnerText] = useState(value || defaultValue || "");
    const [isEmpty, setIsEmpty] = useState(!(value?.length > 0));
    const contentRef = useRef(null);

    useEffect(() => {
        const currentText = value || defaultValue || "";
        setInnerText(currentText);
        setIsEmpty(!(currentText.length > 0));

        // Automatically enter edit mode and focus if initial text is empty
        if (!currentText && item?.autoFocus) {
            setTimeout(() => enterEditMode(), 0);
        }
    }, [value, defaultValue]);

    const disabled =
        typeof item.disabled === "function"
            ? item.disabled(formData)
            : item.disabled || false;

    const handleInput = () => {
        setIsEmpty(!(contentRef.current.textContent.length > 0));
    };

    const handleClose = (event) => {
        const {relatedTarget, key} = event;
        const text = contentRef.current.textContent;

        if (relatedTarget?.id === "SaveButton" || key === "Enter") {
            handleChange(text, item.name);
        } else {
            contentRef.current.textContent = value;
        }

        setIsEditing(false);
        setIsEmpty(!(text.length > 0));
        contentRef.current.blur();
    };

    const enterEditMode = () => {
        if (contentRef.current) {
            contentRef.current.focus();
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(contentRef.current);
            selection.removeAllRanges();
            selection.addRange(range);
            setIsEditing(true);
        }
    };

    const handleAction = () => {
        if (!isEditing) {
            enterEditMode();
        } else {
            handleClose({});
        }
    };

    const handleKeyInteraction = (event) => {
        const { key } = event;
        if (item.onlyNumber && !/[\d.]/.test(key) && key !== "Backspace") {
            event.preventDefault();
        }
        if (key === "Enter" || key === "Escape") {
            handleClose(event);
            if (key === "Enter") {
                event.preventDefault();
            }
        }
    };

    return (
        <div
            className={`flex flex-row items-center justify-start h-auto  rounded-md min-h-[24px] border-transparent border ${
                disabled ? "disabled:text-gray-400" : ""
            } ${isEditing ? "relative z-50" : ""}`}
        >
            <div onClick={handleAction}>
                <button
                    id="SaveButton"
                    className={`${
                        !isEditing ? "hidden" : ""
                    } transition cursor-pointer active:bg-blue-700 active:text-white text-white hover:bg-blue-600 bg-blue-500 rounded-full w-7 h-7 flex items-center justify-center`}
                >
                    <Icons.check className="w-3.5 h-3.5"/>
                </button>
                <button
                    id="EditButton"
                    className={`${
                        isEditing ? "hidden" : ""
                    } transition ml-2 cursor-pointer active:bg-blue-500 active:text-white text-gray-300 hover:text-blue-500 hover:bg-gray-100 bg-transparent rounded-full w-7 h-7 flex items-center justify-center`}
                >
                    <Icons.edit className="w-4 h-4"/>
                </button>
            </div>
            <div
                contentEditable={isEditing}
                tabIndex={0}
                ref={contentRef}
                onInput={handleInput}
                onBlur={handleClose}
                onKeyDown={handleKeyInteraction}
                className={`transition h-full whitespace-nowrap ${
                    isEditing ? "bg-white py-2 rounded-sm" : ""
                } focus:outline-none ${item.innerClassName || ""} ${
                    validationMessage ? "border-red-500 border-2" : ""
                }`}
            >
                {innerText
                    ? isEditing
                        ? innerText
                        : innerText?.length > 45
                            ? shortenString(innerText, 45)
                            : innerText
                    : ""}
            </div>
            {isEmpty && (
                <span className="opacity-50 cursor-default">{item?.placeholder}</span>
            )}
        </div>
    );
};

export default Text;
