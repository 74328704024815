// TableActions.js
// -----------------------------------------------------------------------------
// Create actions for table row.

// Import General libraries.
import React from "react";

// Import DropDownMenu for actions.
import DropDownMenu from "./DropDownMenu";

// Main Component: TableActions
const TableActions = ({
  rowActions,
  item,
  handleSelect,
  allSelected,
  ver,
  hideSelect,
  hiddenActions,
  style,
}) => (
  <div className={`flex flex-row items-center gap-3.5 mt-[-5px] ${style?.row?._actions?.innerClass || 'first-letter:'}`}>
    {/* Render checkbox for row selection. */}
    {!hideSelect && (
      <div className={"w-[35px] h-[32px] flex justify-center items-center"}>
        <input
          type={"checkbox"}
          className={`transition cursor-pointer w-[16px] h-[16px] rounded-[4px] px-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none `}
          onChange={(event) => {
            handleSelect(item.id, event.target.checked);
          }}
          checked={item.id ? item.rowSelectedInTable : allSelected}
        />
      </div>
    )}

    {/* Render actions for row. */}

    {!hiddenActions &&
      rowActions &&
      Object.keys(item).length > 1 &&
      rowActions(item) && (
        <DropDownMenu
          toggleButton={(selected) => (
            <div
              className={`w-[35px] h-[32px] transition rounded-full flex justify-center items-center hover:bg-[rgba(0,0,0,0.1)] ${
                selected && "bg-[rgba(0,0,0,0.1)]"
              } cursor-pointer text-gray-500 hover:text-gray-900`}
            >
              <svg
                stroke="currentColor"
                fill="#111928"
                strokeWidth="0"
                viewBox="0 0 20 20"
                height="17px"
                width="17px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
              </svg>
            </div>
          )}
          // Pass row item to create action menu by data of row item (Status).
          itemMenu={rowActions(item)}
          // Pass row item to handle callback functions with data of row item.
          item={item}
          ver={ver}
        />
      )}
  </div>
);

export default TableActions;
