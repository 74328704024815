import React from "react";
import { BiSolidCopy } from "react-icons/bi";
import Icons from "../../orders/icons";

const CopyText = ({
  children,
  productCopyIcon,
  className,
  text,
  hint = true,
  mainClassName,
  callBack,
  iconClass,
}) => {
  return (
    <>
      {(text || children) && (
        <div
          // onClick={() => {

          // }}
          className={"cursor-pointer flex items-center"}
        >
          <div className={"flex flex-row items-center relative "}>
            <span
              className={"overflow-hidden flex items-center text-gray-600"}
              //     onClick={() => {
              //         callBack ? callBack() :
              //         navigator.clipboard.writeText(text ? text : children)
              // }}
            >
              <span
                className={` ${className ? className : ""}`}
                onClick={() => {
                  callBack
                    ? callBack()
                    : navigator.clipboard.writeText(text ? text : children);
                }}
              >
                {children ? children : ""}
              </span>
              <div
                className={"group"}
                onClick={() => {
                  navigator.clipboard.writeText(text ? text : children);
                }}
              >
                <span
                  className={
                    "text-white bg-blue-500 rounded-[6px] px-[2px] absolute group-active:w-[100%] h-[100%] top-0 right-0 transition opacity-0 group-active:opacity-100 text-[13px] flex items-center"
                  }
                >
                  {!hint ? <Icons.copyOrders className={iconClass} /> : "Copied!"}
                </span>
                <span
                  className={
                    "text-gray-300 group-hover:text-gray-500 transition group-active:text-"
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(text ? text : children);
                  }}
                >
                  {productCopyIcon ? (
                    <Icons.copyOrders
                      className={`${
                        iconClass && iconClass
                      }text-gray-300 hover:text-gray-500 w-4 h-4`}
                    />
                  ) : (
                      <Icons.copyOrders className={
                        `${iconClass && iconClass}text-gray-300 hover:text-gray-500 w-3 h-3`
                    } />
                  )}
                </span>
              </div>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default CopyText;

const CopyIcon = ({ iconClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 20 20"
    className={`${iconClass ? iconClass : "!w-4 !h-4 "}`}
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
    <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
  </svg>
);
