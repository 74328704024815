import React from "react";
import upperCaseString from "../../../dynamic-page/util/upperCaseString";
import shortenString from "../../../dynamic-page/util/shortenString";
import util from "../../../dynamic-page/util";
import descriptiveContent from "../../../../utils/descriptiveContent";
import Icons from "../../icons";


const shipTo = ({state, city, name, country, customerOrderCount, callBack, order_address_id, order}) => {

    const preserveSpaces = (str) => {
        return str.replace(/\s{2}/g, '\u00A0\u00A0');
      }
    const flag = state?.country?.flag || country?.flag || ""
    const countryName = state?.country?.name || country?.name || "";

    const handleFilterByRecipient = () => {
        callBack({
            action: "filterByOrderAdress",
            data: {ship_to_name: order_address_id, sales_account_id: order?.sales_channel_account?.id, customer: name, sales_channel_account: order?.sales_channel_account},

        })
    }
    return (
        <div className={"flex flex-col gap-[4px] justify-start w-full"}>
            <div
                className={"flex flex-row gap-[4px] text-[12px] items-center text-gray-900 leading-[18px] h-[20px] min-w-fit whitespace-nowrap justify-start"}>

                
                {customerOrderCount > 1 &&
                    
                    descriptiveContent(<Icons.merge className="w-[16px] h-[16px] cursor-pointer"
                    onClick={() => {
                        callBack({
                            action: "mergeOrders",
                            data: order,
                        });
                        }}
                /> , 'Merge orders')
                }
                {customerOrderCount > 1 &&
                    
                   descriptiveContent( <div
                    className={"bg-red-100 py-[1px] min-w-[20px] h-[20px] px-[6px] text-red-800 rounded-full hover:bg-red-200 cursor-pointer transition-all"}
                    onClick={handleFilterByRecipient}
                >
                    {customerOrderCount}
                </div>, 'Filter by recipient name')
                }
                {flag &&
                    <util.popUp
                        toggle={(selected) => (
                            <img src={flag.toLowerCase()} className={"!h-[14px] !w-[20px] aspect-none rounded-[3px]"}
                                 style={{objectFit: 'cover'}}/>)}
                        action={"hover"}
                    >
                        <div className={"px-[8px] py-[4px]"}> {countryName} </div>
                    </util.popUp>

                }
                {name &&
                    descriptiveContent(<span
                        className={`font-medium ${customerOrderCount > 1 ? " text-red-800 " : "text-[#111928]"}`}>{shortenString(preserveSpaces(name), 20)}</span>, 'Recipient name', '', 'top')
                }
            </div>
            <div
                className={"flex flex-row gap-[8px]  items-center h-[1.5em] min-w-fit text-gray-500 whitespace-nowrap"}>
                <span className={"text-[#4B5563] text-[12px] leading-[18px] flex flex-row gap-[2px]"}>
                    <div>{
                        state?.name &&
                        upperCaseString(state?.name || "-", "word")
                    }</div>
                    <div>, </div>
                    {
                        shortenString(upperCaseString(city || "-", "word", true), 20)
                    }
                </span>
            </div>
        </div>
    )
}

export default shipTo