const API_CONSTANT = '/api/v1/'
const GET_DASHBOARD = '/api/v1/orders/filters'
const CSRF_TOKEN = '/sanctum/csrf-cookie'
const LOGIN_URL = '/api/v1/login'
const SIGNUP_URL = '/api/v1/register'
const ME_URL = '/api/v1/user'
const LOGOUT_URL = '/api/v1/logout'
const GET_LISTING_MATCH = '/api/v1/listing_matches'
// const GET_FILTER_META = '/api/v1/listing_matches/filters'
const GET_LISTING_MATCH_FILTERS = '/api/v1/listing_matches/filters'
const GET_OPPORTUNITIES = '/api/v1/opportunities'
const GET_OFFERS = '/api/v1/offers'
const GET_OFFER = '/api/v1/offer'
const GET_OFFERS_FILTERS = '/api/v1/offers/filters'
const PUT_OFFERS_ACTIVATION = '/api/v1/offer/'
const GET_OFFERS_COUNTS = '/api/v1/offers/counts'
const GET_OPPORTUNITIES_FILTERS = '/api/v1/opportunities/filters'
const GET_OPPORTUNITIES_UPDATE = '/api/v1/opportunities/refresh'
const GET_BULK_OPPORTUNITIES_UPDATE = '/api/v1/opportunities/bulk-refresh'
const GET_CHANNELS = '/api/v1/channel'
const GET_CHANNELS_FILTERS = '/api/v1/channel/filters'
const PATCH_BULK_CHANNELS_STATUS = '/api/v1/channel/status/bulk-action'
const DELETE_BULK_CHANNELS = '/api/v1/channel/delete/bulk-action'
const DELETE_CHANNELS = '/api/v1/channel/'
const POST_RESTORE_CHANNELS = '/api/v1/channel/'
const GET_ACCOUNTS = '/api/v1/accounts'
const ACCOUNTS_STORE_OAUTH_CALLBACK = '/api/v1/accounts/authorize-store-oauth-callback'
const GET_ACCOUNTS_FILTERS = '/api/v1/accounts/filters'
const PATCH_BULK_ACCOUNTS_STATUS = '/api/v1/account/status/bulk-action'
const DELETE_ACCOUNTS = '/api/v1/account/'
const BULK_DELETE_ACCOUNTS = '/api/v1/account/delete/bulk-action'
const POST_RESTORE_ACCOUNTS = '/api/v1/account/'
const POST_AUTHORIZE_STORE = ''
const POST_AUTHORIZE_STORE_CALLBACK = '/api/v1/accounts/authorize-store-oauth-callback/amazon'
const GET_TARGETSELLERFOLDERS = '/api/v1/target-seller-folders'
const GET_TARGETSELLERFOLDERS_FILTERS = '/api/v1/target-seller-folders/filters'
const GET_SHIPPINGTEMPLATE = '/api/v1/shipping_template'
const GET_SHIPPINGTEMPLATE_FILTERS = '/api/v1/shipping-template/filters'
const POST_SHIPPINGTEMPALTE = '/api/v1/shipping_template'
const PUT_SHIPPINGTEMPLATE = '/api/v1/shipping_template/'
const DELETE_SHIPPINGTEMPLATE = '/api/v1/shipping_template/'
const DUPLICATE_SHIPPINGTEMPLATE = '/api/v1/shipping_template/duplicate/'
const STATUS_SHIPPINGTEMPLATE = '/api/v1/shipping_template/'
const PATCH_RESTORE_SHIPPINGTEMPLATE = 'api/v1/shipping_template/restore/bulk-action'
const PATCH_RESTORE_SHIPPINGCOSTLIST = 'api/v1/shipping_cost_list/restore/bulk-action'
const PATCH_RESTORE_SHIPPINGPRICELIST = 'api/v1/shipping_cost_list/restore/bulk-action'
const GET_SHIPPINGCOSTLIST = '/api/v1/shipping_cost_list'
const GET_SHIPPINGCOSTLIST_FILTERS = '/api/v1/shipping_cost_list/filters'
const POST_SHIPPINGCOSTLIST = '/api/v1/shipping_cost_list'
const PUT_SHIPPINGCOSTLIST = '/api/v1/shipping_cost_list/'
const DELETE_SHIPPINGCOSTLIST = '/api/v1/shipping_cost_list/'
const DUPLICATE_SHIPPINGCOSTLIST = '/api/v1/shipping_cost_list/duplicate/'
const STATUS_SHIPPINGCOSTLIST = '/api/v1/shipping_cost_list/'
const GET_SHIPPINGPRICING = '/api/v1/shipment_pricing'
const POST_SHIPPINGPRICING = '/api/v1/shipment_pricing'
const PUT_SHIPPINGPRICING = '/api/v1/shipment_pricing/'
const DELETE_SHIPPINGPRICING = '/api/v1/shipment_pricing/'
const DUPLICATE_SHIPPINGPRICING = '/api/v1/shipment_pricing/duplicate/'
const STATUS_SHIPPINGPRICING = '/api/v1/shipment_pricing/'
const GET_SHIPPINGPRICING_FILTERS = '/api/v1/shipment-pricing/filters?showAll=true'
const GET_SALESLISTINGS = "/api/v1/account-listing"
const GET_SALES_LISTING = '/api/v1/account-listing/'
const GET_SALESLISTINGS_FILTERS = "/api/v1/account-listing/filter"
const POST_REFRESH_ACCOUNT_LISTING = '/api/v1/account-listing/refresh'
const POST_SELECTION_MATCHING_LISTING = '/api/v1/listing_matches/bulk'
const GET_USERS = '/api/v1/users'
const GET_USER = '/api/v1/user'
const GET_USERS_FILTERS = '/api/v1/users/filters'
const POST_USER = '/api/v1/user'
const PUT_USER = 'api/v1/user'
const GET_POSITIONS = '/api/v1/position'
const GET_PERMISSIONS = '/api/v1/permission'
const BULK_UPDATE_SHIPPINGCOSTLIST = '/api/v1/shipment_pricing/bulk-upload'
const POST_ASSIGN_PERMISSIONS = '/assign-permissions'
const HIDE_OPPORTUNITIE_ID = '/api/v1/opportunities/hide'
const HIDE_OPPORTUNITIE_BRAND = '/api/v1/opportunities/hide/brand'
const BULK_STATUS_UPDATE = "/api/v1/user/status/bulk-action"
const UNHIDE_OPPORTUNITIE_ID = '/api/v1/opportunities/unhide'
const UNHIDE_OPPORTUNITIE_BRAND = '/api/v1/opportunities/unhide/brand'

// const GET_DOMAIN_PERMISSIONS = 'http://b0bdd107-194d-4091-91ad-9e744030ac62.mock.pstmn.io/domain-permissions'
const GET_DOMAIN_PERMISSIONS = '/api/v1/permissions/domain-permissions'
// const GET_USER_PERMISSIONS = 'http://b0bdd107-194d-4091-91ad-9e744030ac62.mock.pstmn.io/users/1/permissions'
const GET_USER_PERMISSIONS = '/permissions'
const PATCH_BULK_SHIPPING_PRICING_LIST_STATUS = '/api/v1/shipment_pricing/status/bulk-action'
const DELETE_BULK_SHIPPING_PRICING_LIST = '/api/v1/shipment_pricing/delete/bulk-action'
const PATCH_BULK_SHIPPING_TEMPLATE = 'api/v1/shipping_template/status/bulk-action'
const DELETE_BULK_SHIPPING_TEMPLATE = 'api/v1/shipping_template/delete/bulk-action'
const PATCH_BULK_SHIPPING_COST_LIST = 'api/v1/shipping_cost_list/status/bulk-action'
const DELETE_BULK_SHIPPING_COST_LIST = 'api/v1/shipping_cost_list/delete/bulk-action'
const GET_SHIPPING_TEMPLATE_RESTRICTIONS = 'api/v1/shipping-template/restriction_types'
const POST_PRODUCT_IMAGES = 'api/v1/product/images'
const POST_PRODUCT_IMAGES_LINK = 'api/v1/product/images/'
const GET_ATTRIBUTE_GROUPS = 'api/v1/attribute-groups'
const GET_PRODUCT = 'api/v1/product'
const GET_PRODUCT_SUMMARY = 'api/v1/product-summary'


const DELETE_IMAGE = 'api/v1/product/'
const UPDATE_ATTRIBUTE = 'api/v1/attribute-value/'
const GET_ATTRIBUTES = 'api/v1/attribute'
const UPDATE_PRODUCT = 'api/v1/product/'
const SELECT_MAIN_IMAGE=`api/v1/product/select-main-image/`
const GET_BRANDS = 'api/v1/brands'
const GET_RULE = 'api/v1/attribute/rules/'
const GET_ATTRIBUTE_TYPES = 'api/v1/attribute/types'
const POST_ATTRIBUTE = 'api/v1/attribute'
const DELETE_PRODUCT = 'api/v1/product/'
const GET_CATEGORIES = 'api/v1/category/';
const POST_PRODUCT_FROM_DATA = 'api/v1/product/from-data-source'
const GET_SUPPLY_LISTINGS = '/api/v1/supply-channel-listing'
const POST_SUPPLY_LISTING = '/api/v1/supply-listing'
const POST_REFRESH_LISTING = '/api/v1/supply-listings/refresh'
const POST_BULK_REFRESH_LISTING = '/api/v1/supply-listings/bulk-refresh'
const GET_SUPPLY_LISTINGS_FILTERS = '/api/v1/supply-listings/filters'
const GET_LISTING_SOURCES = 'api/v1/channel'
const GET_LISTING_SOURCE_CATEGORIES = 'api/v1/source-categories'
const GET_CATEGORIES_FILTERS = 'api/v1/category/filters'
const PUT_CATEGORY_MAP = 'api/v1/source-categories/'
const GET_AUDITS = '/activities?limit=20'
const POST_PRODUCT_DUPLICATE = 'api/v1/product/'
const GET_LANGUAGES = 'api/v1/language'
const CREATE_PRODUCT = 'api/v1/product'
const GET_SUPPLY_REQUESTS = '/api/v1/supply-request'
const POST_SUPPLY_REQUEST = '/api/v1/supply-request'
const POST_SUPPLY = '/api/v1/supply-purchase/bulk'
const GET_SUPPLY_REQUESTS_FILTERS = '/api/v1/supply-request/filters'
const PUT_UPDATE_SUPPLY_REQUEST = '/api/v1/supply-request/'
const DELETE_SUPPLY_REQUEST = '/api/v1/supply-request'
const POST_OPPURTUNITY_NOTES = '/api/v1/opportunities/'
const GET_EMPLOYEE = 'api/v1/employees'
const POST_CREATE_CHANNEL = 'api/v1/channel'
const PUT_UPDATE_CHANNEL = 'api/v1/channel/'
// post creat account, put update account
const POST_CREATE_ACCOUNT = 'api/v1/account'
const PUT_UPDATE_ACCOUNT = 'api/v1/account/'
// post create category, put update category
const POST_CREATE_CATEGORY = 'api/v1/category'
const PUT_UPDATE_CATEGORY = 'api/v1/category/'
const DEL_Category = 'api/v1/category/'
const BULK_DELETE_CATEGORY = 'api/v1/category/delete/bulk-action'
const BULK_UPDATE_CATEGORY = 'api/v1/category/status/bulk-action'
// bank accounts create, update, delete, get
const GET_BANK_ACCOUNTS = 'api/v1/bank-accounts'
const POST_CREATE_BANK_ACCOUNT = 'api/v1/bank-accounts'
const PUT_UPDATE_BANK_ACCOUNT = 'api/v1/bank-accounts/'
const DELETE_BANK_ACCOUNT = 'api/v1/bank-accounts/'
const BULK_DELETE_BANK_ACCOUNTS = 'api/v1/bank-accounts/bulk-delete'
const PUT_BULK_BANK_ACCOUNTS_STATUS = 'api/v1/bank-accounts/status'
const POST_RESTORE_BANK_ACCOUNTS = 'api/v1/bank-accounts/restore'
const GET_BANK_ACCOUNTS_FILTERS = 'api/v1/bank-accounts/filters'

// now for currencies
const GET_CURRENCIES = 'api/v1/currencies'
const POST_CREATE_CURRENCY = 'api/v1/currencies'
const PUT_UPDATE_CURRENCY = 'api/v1/currencies/'
const DELETE_CURRENCY = 'api/v1/currencies/'
const BULK_DELETE_CURRENCIES = 'api/v1/currencies/bulk-delete'
const PUT_BULK_CURRENCIES_STATUS = 'api/v1/currencies/status'
const POST_RESTORE_CURRENCIES = 'api/v1/currencies/restore'
const GET_CURRENCIES_FILTERS = 'api/v1/currencies/filters'

const GET_UNITS = 'api/v1/units'
const POST_CREATE_UNIT = 'api/v1/units'
const PUT_UPDATE_UNIT = 'api/v1/units/'
const DELETE_UNIT = 'api/v1/units/'
const BULK_DELETE_UNITS = 'api/v1/units/bulk-delete'
const PUT_BULK_UNITS_STATUS = 'api/v1/units/status'
const POST_RESTORE_UNITS = 'api/v1/units/restore'
const GET_UNITS_FILTERS = 'api/v1/units/filters'

// now brands
const POST_CREATE_BRAND = 'api/v1/brands'
const PUT_UPDATE_BRAND = 'api/v1/brands/'
const DELETE_BRAND = 'api/v1/brands/'
const BULK_DELETE_BRANDS = 'api/v1/brands/delete/bulk-action'
const PUT_BULK_BRANDS_STATUS = 'api/v1/brands/status/bulk-action'
const POST_RESTORE_BRANDS = 'api/v1/brands/restore'
//now for vat-rates
const GET_VAT_RATES = 'api/v1/vat-rates'
const GET_VAT_RATES_FILTERS = 'api/v1/vat-rates/filters'
const POST_CREATE_VAT_RATE = 'api/v1/vat-rates'
const PUT_UPDATE_VAT_RATE = 'api/v1/vat-rates/'
const DELETE_VAT_RATE = 'api/v1/vat-rates/'
const BULK_DELETE_VAT_RATES = 'api/v1/vat-rates/bulk-delete'
const PUT_BULK_VAT_RATES_STATUS = 'api/v1/vat-rates/status'
const POST_RESTORE_VAT_RATES = 'api/v1/vat-rates/restore'
//now for Balance
const GET_BALANCE = 'api/v1/balance'
const POST_CREATE_BALANCE = 'api/v1/balance'
const PUT_UPDATE_BALANCE = 'api/v1/balance/'
const DELETE_BALANCE = 'api/v1/balance/'
const BULK_DELETE_BALANCE = 'api/v1/balance/bulk-delete'
const PUT_BULK_BALANCE_STATUS = 'api/v1/balance/status'
const POST_RESTORE_BALANCE = 'api/v1/balance/restore'
const GET_BALANCE_FILTERS = 'api/v1/balance/filters'
//now for Payment-Methods
const GET_PAYMENT_METHODS = 'api/v1/payment-methods'
const POST_CREATE_PAYMENT_METHOD = 'api/v1/payment-methods'
const PUT_UPDATE_PAYMENT_METHOD = 'api/v1/payment-methods/'
const DELETE_PAYMENT_METHOD = 'api/v1/payment-methods/'
const BULK_DELETE_PAYMENT_METHODS = 'api/v1/payment-methods/bulk-delete'
const PUT_BULK_PAYMENT_METHODS_STATUS = 'api/v1/payment-methods/status'
const POST_RESTORE_PAYMENT_METHODS = 'api/v1/payment-methods/restore'
const GET_PAYMENT_METHODS_FILTERS = 'api/v1/payment-methods/filters'

// supply purchases
const GET_SUPPLY_PURCHASES = 'api/v1/supply-purchase'
const PUT_BULK_SUPPLY_PURCHASE_STATUS = 'api/v1/supply-purchase/status'
const BULK_DELETE_SUPPLY_PURCHASES = 'api/v1/supply-purchase/bulk-delete'
const POST_RESTORE_SUPPLY_PURCHASES = 'api/v1/supply-purchase/restore'
const GET_SUPPLY_PURCHASES_FILTERS = 'api/v1/supply-purchase/filters'
const PUT_UPDATE_SUPPLY_PURCHASE = 'api/v1/supply-purchase/'

//now for payments purchase
const GET_PAYMENT_PURCHASES = 'api/v1/payments-purchase'
const PUT_BULK_PAYMENT_PURCHASE_STATUS = 'api/v1/payments-purchase/status'
const DELETE_PAYMENT_PURCHASE = 'api/v1/payments-purchase/'
const BULK_DELETE_PAYMENT_PURCHASES = 'api/v1/payments-purchase/bulk-delete'
const POST_RESTORE_PAYMENT_PURCHASES = 'api/v1/payments-purchase/restore'
const GET_PAYMENT_PURCHASES_FILTERS = 'api/v1/payments-purchase/filters'
const PUT_UPDATE_PAYMENT_PURCHASE = 'api/v1/payments-purchase/'
const POST_CREATE_PAYMENT_PURCHASE = 'api/v1/payments-purchase'

//inventory 
const GET_INVENTORY = 'api/v1/inventory'
const POST_CREATE_INVENTORY = 'api/v1/inventory'
const PUT_UPDATE_INVENTORY = 'api/v1/inventory/'
const DELETE_INVENTORY = 'api/v1/inventory/'
const GET_INVENTORY_FILTERS = 'api/v1/inventory/filters'

//goods acceptance
const GET_GOODS_ACCEPTANCE = 'api/v1/good-acceptance'
const POST_CREATE_GOODS_ACCEPTANCE = 'api/v1/good-acceptance/bulk-acceptance'
const DELETE_GOODS_ACCEPTANCE = 'api/v1/good-acceptance/'
const POST_RESTORE_GOODS_ACCEPTANCE = 'api/v1/good-acceptance/restore'
const GET_MAIN_INVENTORY = 'api/v1/inventory/listing'
const GET_GOODS_ACCEPTANCE_FILTERS = 'api/v1/good-acceptance/filters'

//warehouse
const GET_WAREHOUSES = 'api/v1/arbitbox_warehouse'
const POST_CREATE_WAREHOUSE = 'api/v1/arbitbox_warehouse'
const PUT_UPDATE_WAREHOUSE = 'api/v1/arbitbox_warehouse/'
const BULK_DELETE_WAREHOUSES = 'api/v1/arbitbox_warehouse'
const POST_RESTORE_WAREHOUSES = 'api/v1/arbitbox_warehouse/restore'
const GET_WAREHOUSES_FILTERS = 'api/v1/arbitbox_warehouse/filters'
const POST_CREATE_GOODS_ACCEPTANCE_FROM_PRODUCT = 'api/v1/good-acceptance/product/'
const PUT_UPDATE_GOODS_ACCEPTANCE_FROM_PRODUCT = 'api/v1/good-acceptance/'

// carriers
const GET_CARRIERS = 'api/v1/carriers'
const GET_CARRIERS_FILTERS = 'api/v1/carriers/filters'
const POST_CREATE_CARRIER = 'api/v1/carriers'
const PUT_UPDATE_CARRIER = 'api/v1/carriers/'
const DELETE_CARRIER = 'api/v1/carriers/'
const BULK_DELETE_CARRIERS = 'api/v1/carriers/delete/bulk-action'
const PATCH_BULK_CARRIERS_STATUS = 'api/v1/carriers/status/bulk-action'
const POST_RESTORE_CARRIERS = 'api/v1/carriers/restore/bulk-action'
const GET_API_PROVIDERS = 'api/v1/api-providers'
const GET_STORAGE_FACILITY_PROVIDERS = 'api/v1/storage_facility_providers/listing'

const GET_COUNTRIES = 'api/v1/country'
const GET_STATES = 'api/v1/state'

//locale
const GET_LOCALE = 'api/v1/locale/'
// Attributes
const POST_CREATE_ATTRIBUTE = 'api/v1/attribute'
const PUT_UPDATE_ATTRIBUTE = 'api/v1/attribute/'
const DELETE_ATTRIBUTE = 'api/v1/attribute/'
// const BULK_DELETE_ATTRIBUTES = 'api/v1/attribute/bulk-delete'
// const PUT_BULK_ATTRIBUTES_STATUS = 'api/v1/attribute/status'
const GET_ATTRIBUTE_FILTERS = 'api/v1/attribute/filters'

// Orders
const GET_ORDERS = 'api/v1/orders'
const GET_ORDERS_FILTERS = 'api/v1/orders/filters'
const GET_ORDERS_VIEW = 'api/v1/orders/'
const BULK_DELETE_ORDERS = 'api/v1/orders'
const POST_RESTORE_ORDERS = 'api/v1/restore'

// Notifications
const GET_NOTIFICATIONS = 'api/v1/order-notifications'
const GET_NOTIFICATIONS_FILTER = 'api/v1/order-notifications/filters'

// Carrier_Services
const GET_CARRIER_SERVICES = 'api/v1/carrier_service'
const GET_CARRIER_SERVICES_FILTERS = 'api/v1/carrier_service/filters'
const POST_CREATE_CARRIER_SERVICE = 'api/v1/carrier_service'
const PUT_UPDATE_CARRIER_SERVICE = 'api/v1/carrier_service/'
const DELETE_CARRIER_SERVICE = 'api/v1/carrier_service/'
const BULK_DELETE_CARRIER_SERVICES = 'api/v1/carrier_service/delete/bulk-action'
const PATCH_BULK_CARRIER_SERVICES_STATUS = 'api/v1/carrier_service/status/bulk-action'
const POST_RESTORE_CARRIER_SERVICES = 'api/v1/carrier_service/restore/bulk-action'

// shipping data source
const GET_SHIPPINGCOST_DATA_SOURCE = 'https://arbitbox.test/api/v1/shipping_cost_data_source'

const POST_DEPARTMENT = 'api/v1/department'
const POST_POSITION = 'api/v1/position'

//
const POST_SYNC_WITH_MARKETPLACE = 'api/v1/accounts/sync-with-marketplace'

// WAREHOUSE_CENTER
const WAREHOUSE_LIST_ALL_URL = '/api/v1/warehouses'
const WAREHOUSE_FILTER_URL = '/api/v1/arbitbox_warehouse/filters'
const WAREHOUSE_GET_BY_ID_URL = 'api/v1/warehouses'
const WAREHOUSE_CREATE_URL = 'api/v1/warehouses'
const WAREHOUSE_UPDATE_URL = 'api/v1/warehouses'
const WAREHOUSE_DELETE_URL = 'api/v1/warehouses'


//  FETCH CALCULATOR ITEMS
const CALCULATOR_DATAS = 'api/v1/logistics/calculator'


// SHIPPING COST PAGE
const GET_SHIPPING_COST = 'api/v1/shipping-costs'
const DELETE_SHIPPING_COST = 'api/v1/shipping-costs/'
const BULK_DELETE_SHIPPING_COST = 'api/v1/shipping-costs/delete/bulk-action'
const GET_SHIPPING_COST_FILTERS = 'api/v1/shipping-costs/filters'

export default {
    CALCULATOR_DATAS,
    LOGIN_URL,
    SIGNUP_URL,
    GET_SHIPPING_COST_FILTERS,
    GET_SHIPPING_COST,
    DELETE_SHIPPING_COST,
    ME_URL,
    LOGOUT_URL,
    CSRF_TOKEN,
    GET_LISTING_MATCH, //  GET_FILTER_META,
    GET_LISTING_MATCH_FILTERS,
    GET_OPPORTUNITIES,
    GET_OPPORTUNITIES_FILTERS,
    GET_OPPORTUNITIES_UPDATE,
    GET_TARGETSELLERFOLDERS,
    GET_TARGETSELLERFOLDERS_FILTERS,
    GET_SHIPPINGTEMPLATE,
    POST_SHIPPINGTEMPALTE,
    PUT_SHIPPINGTEMPLATE,
    DELETE_SHIPPINGTEMPLATE,
    DUPLICATE_SHIPPINGTEMPLATE,
    STATUS_SHIPPINGTEMPLATE,
    GET_SHIPPINGCOSTLIST,
    POST_SHIPPINGCOSTLIST,
    PUT_SHIPPINGCOSTLIST,
    DELETE_SHIPPINGCOSTLIST,
    DUPLICATE_SHIPPINGCOSTLIST,
    STATUS_SHIPPINGCOSTLIST,
    PATCH_RESTORE_SHIPPINGCOSTLIST,
    GET_SHIPPINGPRICING,
    GET_SHIPPINGPRICING_FILTERS,
    POST_SHIPPINGPRICING,
    PUT_SHIPPINGPRICING,
    DELETE_SHIPPINGPRICING,
    DUPLICATE_SHIPPINGPRICING,
    STATUS_SHIPPINGPRICING,
    PATCH_RESTORE_SHIPPINGTEMPLATE,
    PATCH_RESTORE_SHIPPINGPRICELIST,
    GET_OFFERS,
    GET_OFFER,
    BULK_DELETE_SHIPPING_COST,
    GET_OFFERS_FILTERS,
    GET_SALESLISTINGS,
    GET_SALESLISTINGS_FILTERS,
    POST_REFRESH_ACCOUNT_LISTING,
    GET_SALES_LISTING,
    POST_SELECTION_MATCHING_LISTING,
    GET_CHANNELS,
    GET_CHANNELS_FILTERS,
    DELETE_CHANNELS,
    POST_RESTORE_CHANNELS,
    POST_AUTHORIZE_STORE,
    POST_AUTHORIZE_STORE_CALLBACK,
    GET_ACCOUNTS,
    ACCOUNTS_STORE_OAUTH_CALLBACK,
    GET_ACCOUNTS_FILTERS,
    SELECT_MAIN_IMAGE,
    GET_USERS,
    GET_USER,
    GET_USERS_FILTERS,
    POST_USER,
    PUT_USER,
    GET_POSITIONS,
    GET_SHIPPINGCOSTLIST_FILTERS,
    GET_DOMAIN_PERMISSIONS,
    GET_USER_PERMISSIONS,
    GET_PERMISSIONS,
    POST_ASSIGN_PERMISSIONS,
    GET_SHIPPINGTEMPLATE_FILTERS,
    PATCH_BULK_CHANNELS_STATUS,
    DELETE_BULK_CHANNELS,
    GET_BULK_OPPORTUNITIES_UPDATE,
    BULK_UPDATE_SHIPPINGCOSTLIST,
    HIDE_OPPORTUNITIE_ID,
    HIDE_OPPORTUNITIE_BRAND,
    BULK_STATUS_UPDATE,
    PATCH_BULK_SHIPPING_PRICING_LIST_STATUS,
    GET_PRODUCT_SUMMARY,
    DELETE_BULK_SHIPPING_PRICING_LIST,
    PATCH_BULK_SHIPPING_TEMPLATE,
    DELETE_BULK_SHIPPING_TEMPLATE,
    PATCH_BULK_SHIPPING_COST_LIST,
    DELETE_BULK_SHIPPING_COST_LIST,
    GET_SHIPPING_TEMPLATE_RESTRICTIONS,
    UNHIDE_OPPORTUNITIE_ID,
    UNHIDE_OPPORTUNITIE_BRAND,
    POST_PRODUCT_IMAGES,
    POST_PRODUCT_IMAGES_LINK,
    GET_ATTRIBUTE_GROUPS,
    GET_PRODUCT,
    DELETE_IMAGE,
    UPDATE_ATTRIBUTE,
    GET_ATTRIBUTES,
    UPDATE_PRODUCT,
    GET_BRANDS,
    GET_RULE,
    GET_ATTRIBUTE_TYPES,
    POST_ATTRIBUTE,
    DELETE_PRODUCT,
    GET_CATEGORIES,
    POST_PRODUCT_FROM_DATA,
    GET_SUPPLY_LISTINGS,
    POST_SUPPLY_LISTING,
    POST_REFRESH_LISTING,
    GET_SUPPLY_LISTINGS_FILTERS,
    GET_LISTING_SOURCES,
    GET_LISTING_SOURCE_CATEGORIES,
    PUT_CATEGORY_MAP,
    GET_AUDITS,
    POST_PRODUCT_DUPLICATE,
    GET_LANGUAGES,
    CREATE_PRODUCT,
    GET_SUPPLY_REQUESTS,
    POST_SUPPLY_REQUEST,
    POST_SUPPLY,
    GET_SUPPLY_REQUESTS_FILTERS,
    POST_OPPURTUNITY_NOTES,
    PATCH_BULK_ACCOUNTS_STATUS,
    DELETE_ACCOUNTS,
    POST_RESTORE_ACCOUNTS,
    BULK_DELETE_ACCOUNTS,
    PUT_OFFERS_ACTIVATION,
    GET_OFFERS_COUNTS,
    GET_CATEGORIES_FILTERS,
    GET_EMPLOYEE,
    POST_CREATE_CHANNEL,
    PUT_UPDATE_CHANNEL,
    POST_CREATE_ACCOUNT,
    PUT_UPDATE_ACCOUNT,
    POST_CREATE_CATEGORY,
    PUT_UPDATE_CATEGORY,
    DEL_Category,
    BULK_DELETE_CATEGORY,
    BULK_UPDATE_CATEGORY,
    GET_BANK_ACCOUNTS,
    POST_CREATE_BANK_ACCOUNT,
    PUT_UPDATE_BANK_ACCOUNT,
    DELETE_BANK_ACCOUNT,
    GET_CURRENCIES,
    POST_CREATE_CURRENCY,
    PUT_UPDATE_CURRENCY,
    DELETE_CURRENCY,
    POST_CREATE_BRAND,
    PUT_UPDATE_BRAND,
    DELETE_BRAND,
    GET_VAT_RATES,
    POST_CREATE_VAT_RATE,
    PUT_UPDATE_VAT_RATE,
    DELETE_VAT_RATE,
    GET_BALANCE,
    POST_CREATE_BALANCE,
    PUT_UPDATE_BALANCE,
    DELETE_BALANCE,
    GET_PAYMENT_METHODS,
    POST_CREATE_PAYMENT_METHOD,
    PUT_UPDATE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD,
    BULK_DELETE_BANK_ACCOUNTS,
    PUT_BULK_BANK_ACCOUNTS_STATUS,
    POST_RESTORE_BANK_ACCOUNTS,
    BULK_DELETE_CURRENCIES,
    PUT_BULK_CURRENCIES_STATUS,
    POST_RESTORE_CURRENCIES,
    BULK_DELETE_BRANDS,
    PUT_BULK_BRANDS_STATUS,
    POST_RESTORE_BRANDS,
    BULK_DELETE_VAT_RATES,
    PUT_BULK_VAT_RATES_STATUS,
    POST_RESTORE_VAT_RATES,
    BULK_DELETE_BALANCE,
    PUT_BULK_BALANCE_STATUS,
    POST_RESTORE_BALANCE,
    BULK_DELETE_PAYMENT_METHODS,
    PUT_BULK_PAYMENT_METHODS_STATUS,
    POST_RESTORE_PAYMENT_METHODS,
    GET_BANK_ACCOUNTS_FILTERS,
    GET_CURRENCIES_FILTERS,
    GET_PAYMENT_METHODS_FILTERS,
    GET_BALANCE_FILTERS,
    DELETE_SUPPLY_REQUEST,
    GET_SUPPLY_PURCHASES,
    GET_SUPPLY_PURCHASES_FILTERS,
    PUT_BULK_SUPPLY_PURCHASE_STATUS,
    BULK_DELETE_SUPPLY_PURCHASES,
    POST_RESTORE_SUPPLY_PURCHASES,
    PUT_UPDATE_SUPPLY_REQUEST,
    GET_VAT_RATES_FILTERS,
    PUT_UPDATE_SUPPLY_PURCHASE,
    GET_PAYMENT_PURCHASES,
    PUT_BULK_PAYMENT_PURCHASE_STATUS,
    DELETE_PAYMENT_PURCHASE,
    BULK_DELETE_PAYMENT_PURCHASES,
    POST_RESTORE_PAYMENT_PURCHASES,
    GET_PAYMENT_PURCHASES_FILTERS,
    PUT_UPDATE_PAYMENT_PURCHASE,
    POST_CREATE_PAYMENT_PURCHASE,
    GET_INVENTORY,
    POST_CREATE_INVENTORY,
    PUT_UPDATE_INVENTORY,
    DELETE_INVENTORY,
    GET_GOODS_ACCEPTANCE,
    POST_CREATE_GOODS_ACCEPTANCE,
    GET_MAIN_INVENTORY,
    GET_INVENTORY_FILTERS,
    GET_UNITS,
    POST_CREATE_UNIT,
    PUT_UPDATE_UNIT,
    DELETE_UNIT,
    BULK_DELETE_UNITS,
    PUT_BULK_UNITS_STATUS,
    POST_RESTORE_UNITS,
    GET_UNITS_FILTERS,
    GET_WAREHOUSES,
    POST_CREATE_WAREHOUSE,
    PUT_UPDATE_WAREHOUSE,
    BULK_DELETE_WAREHOUSES,
    POST_RESTORE_WAREHOUSES,
    PUT_UPDATE_GOODS_ACCEPTANCE_FROM_PRODUCT,
    POST_CREATE_GOODS_ACCEPTANCE_FROM_PRODUCT,
    POST_BULK_REFRESH_LISTING,
    POST_RESTORE_GOODS_ACCEPTANCE,
    DELETE_GOODS_ACCEPTANCE,
    GET_WAREHOUSES_FILTERS,
    GET_GOODS_ACCEPTANCE_FILTERS,
    GET_LOCALE,

    POST_CREATE_ATTRIBUTE,
    PUT_UPDATE_ATTRIBUTE,
    DELETE_ATTRIBUTE,
    GET_ATTRIBUTE_FILTERS,

    // Dashboard
    GET_DASHBOARD,

    // Orders
    GET_ORDERS,
    GET_ORDERS_FILTERS,
    GET_ORDERS_VIEW,
    BULK_DELETE_ORDERS,
    POST_RESTORE_ORDERS,

    // Notifications
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_FILTER,

    // Carriers
    GET_CARRIERS,
    GET_CARRIERS_FILTERS,
    POST_CREATE_CARRIER,
    PUT_UPDATE_CARRIER,
    DELETE_CARRIER,
    BULK_DELETE_CARRIERS,
    PATCH_BULK_CARRIERS_STATUS,
    POST_RESTORE_CARRIERS,

    // Carriers Services
    GET_CARRIER_SERVICES,
    GET_CARRIER_SERVICES_FILTERS,
    POST_CREATE_CARRIER_SERVICE,
    PUT_UPDATE_CARRIER_SERVICE,
    DELETE_CARRIER_SERVICE,
    BULK_DELETE_CARRIER_SERVICES,
    PATCH_BULK_CARRIER_SERVICES_STATUS,
    POST_RESTORE_CARRIER_SERVICES,

    // Shipping data source
    GET_SHIPPINGCOST_DATA_SOURCE,

    GET_COUNTRIES,
    GET_STATES,
    GET_API_PROVIDERS,
    GET_STORAGE_FACILITY_PROVIDERS,

    // Sync with marketplace
    POST_SYNC_WITH_MARKETPLACE,
    POST_DEPARTMENT,
    POST_POSITION,
    API_CONSTANT,

    // warehouse fulfillment central

    WAREHOUSE_LIST_ALL_URL,
    WAREHOUSE_FILTER_URL,
    WAREHOUSE_GET_BY_ID_URL,
    WAREHOUSE_CREATE_URL,
    WAREHOUSE_UPDATE_URL,
    WAREHOUSE_DELETE_URL,
}
