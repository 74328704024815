import React, { useEffect } from "react";

const Checkbox = ({
  item,
  value = [],
  validationMessage,
  bind,
  handleChange,
  formData,
}) => {
  const disabled =
    typeof item.disabled === "function"
      ? item.disabled(formData)
      : item.disabled || false;

  const arrayValue = () => {
    return !value || value === ""
      ? []
      : !Array.isArray(value)
      ? [value]
      : value.length === 0
      ? Array.isArray(item.defaultValue)
        ? item.defaultValue
        : [item.defaultValue]
      : value;
  };
  const handleCheckAll = (e) => {
    if (e.target.checked) {
      handleChange(
        item?.list?.map((obj) => obj.value),
        item?.name
      );
      bind &&
        bind(
          item?.list?.map((obj) => obj),
          item?.name
        );
    }
    if (!e.target.checked) {
      handleChange([], item?.name);
      bind && bind([], item?.name);
    }
  };
  const handleUpdate = (option) => {
    const updatedArray = arrayValue(value).includes(option)
      ? arrayValue().filter((item) => item !== option)
      : [...arrayValue(), option];
    handleChange(updatedArray, item?.name);
    //  bind the whole object not just the value
    bind &&
      bind(
        item?.list?.filter((obj) => updatedArray.includes(obj.value)),
        item?.name
      );
  };

  const isChecked = (option) => {
    return arrayValue().includes(option);
  };

  useEffect(() => {
    bind &&
      bind(
        item?.list?.filter((obj) => arrayValue().includes(obj.value)),
        item?.name
      );
  }, []);

  // Return Input in view
  return (
    <div className={"flex gap-2 flex-col"}>
      {item.checkAll && item?.list && item?.list?.length > 1 && (
        <div
          className={"flex gap-2 items-center border-b border-b-gray-200 pb-2"}
        >
          <input
            type={"checkbox"}
            className={`transition w-[20px] h-[20px] ${
              disabled && "disabled"
            }  disabled:text-gray-400 rounded-md px-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1  ${
              item.innerClassName || ""
            } ${validationMessage ? "border-red-500 border-2" : ""}`}
            onChange={(e) => {
              handleCheckAll(e);
            }}
            checked={item?.list?.length === arrayValue()?.length}
          />
          <span className="flex flex-row gap-1">
            <span className="text-gray-400">Select All</span>
            <span className="text-gray-400">
              ({item?.list?.reduce((acc, curr) => acc + curr.count, 0)}
              {/* in {item?.list?.length}*/})
            </span>
          </span>
        </div>
      )}

      {item?.list && item?.list?.length > 0 ? (
        item?.list?.map((option, index) => (
          <div className={"flex gap-2 items-center"} key={index}>
            <input
              name={option.value}
              type={"checkbox"}
              checked={isChecked(option.value)}
              disabled={disabled}
              className={`transition w-[20px] h-[20px] ${
                item.disabled && "disabled"
              }  disabled:text-gray-400 rounded-md px-[8px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1  ${
                item.innerClassName || ""
              } ${validationMessage ? "border-red-500 border-2" : ""}`}
              value={value}
              onChange={() => {
                handleUpdate(option.value);
              }}
            />
            <span className="flex flex-row gap-1">
              {option.label} {isChecked(option.value)}
              {option.count || option.count === 0 ? (
                <span className="text-gray-400">({option.count})</span>
              ) : (
                ""
              )}
            </span>
          </div>
        ))
      ) : (
        <div className={"text-gray-400"}> No Item!</div>
      )}
    </div>
  );
};

export default Checkbox;
