import React, { useEffect, useRef, useState } from "react";
import Header from "./elements/Header";
import Row from "./elements/Row";
import TableActions from "./elements/TableActions";
import ClickDropDown from "./elements/ClickDropDown";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

const DynamicTable = React.memo(
  ({
    data,
    actions,
    onSelect,
    selectedRow = [],
    loading,
    view,
    style = null,
    ver = 0,
    mini,
    meta,
    miniWidth,
    hiddenFields,
    loadMoreData,
    limit = 20, // Limit for load more trigger
  }) => {
    const tableRef = useRef(null);
    const containerRef = useRef(null);
    const loaderRef = useRef(null);
    const menuRef = useRef(null);
    const actionList = actions ? actions(selectedRow) : [];

    const [isOpen, setIsOpen] = useState(false);
    const [clickedItem, setClickedItem] = useState(null);
    const [lastClickEvent, setLastClickEvent] = useState(null);
    const [tableWidth, setTableWidth] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const handleContextMenu = (event, item) => {
      if (window.getSelection().toString() !== "") {
        return;
      }
      event.preventDefault();
      setClickedItem(item);
      setIsOpen(true);
      setLastClickEvent({
        clientX: event.clientX,
        clientY: event.clientY,
      });

      if (menuRef.current) {
        menuRef.current.style.left = `${event.clientX}px`;
        menuRef.current.style.top = `${event.clientY}px`;
      }

      document.addEventListener("click", handleHide);
    };

    const handleHide = () => {
      setIsOpen(false);
      document.removeEventListener("click", handleHide);
    };

    const [innerData, setInnerData] = useState(data);

    const changeInnerData = (
      obj = [],
      id = [],
      isSelect = false,
      upDateData
    ) => {
      return {
        ...obj,
        list: obj.list?.map((item) =>
          typeof id[0] === "undefined" && !upDateData
            ? { ...item, rowSelectedInTable: isSelect }
            : id.includes(item.id)
            ? { ...item, rowSelectedInTable: isSelect }
            : item
        ),
      };
    };

    const handleSelect = (id, select) => {
      const innerDataObj = changeInnerData(innerData, [id], select);
      onSelect(
        innerDataObj.list.filter((item) => item.rowSelectedInTable === true)
      );
      setInnerData(innerDataObj);
    };

    useEffect(() => {
      const initializedData = changeInnerData(
        data,
        selectedRow?.map((item) => item.id),
        true,
        true
      );
      setInnerData(initializedData);
      setIsLoaded(false);
    }, [data, selectedRow]);

    const updateLoaderWidth = () => {
      if (containerRef.current && loaderRef.current && tableRef.current) {
        loaderRef.current.style.width = `${tableRef.current.scrollWidth}px`;
      }
    };
    useEffect(() => {
      checkAndLoadMore();
      updateLoaderWidth();
    }, [innerData]);

    // Perform initial load checks
    const checkAndLoadMore = () => {
      if (
        meta &&
        meta.current_page < meta.last_page &&
        tableRef.current &&
        tableRef.current.clientHeight < window.innerHeight - 210
      ) {
        loadMoreData();
      }
    };

    useEffect(() => {
      setIsLoaded(true);
      updateLoaderWidth();
    }, [innerData.list?.length, isLoaded]);

    // Update loader width and no more dynamic updates
    useEffect(() => {
      const handleResize = () => {
        updateLoaderWidth();
        checkAndLoadMore();
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const filteredTheme = Object.keys(innerData?.theme || {}).reduce(
      (acc, key) => {
        if (hiddenFields?.includes(key)) {
          return acc;
        }
        return { ...acc, [key]: innerData.theme[key] };
      },
      {}
    );

    const extendedTheme = {
      ...{
        ...(style.hiddenActions && style.hideSelect
          ? {}
          : {
              _actions: (item, index) => (
                <TableActions
                  rowActions={actions}
                  item={item}
                  index={index}
                  hideSelect={style?.hideSelect}
                  hiddenActions={style?.hiddenActions}
                  handleSelect={handleSelect}
                ver={ver}
                style={style}
                  allSelected={
                    innerData?.list?.length ===
                      innerData?.list?.filter(
                        (item) => item?.rowSelectedInTable === true
                      ).length && innerData?.list?.length > 0
                  }
                />
              ),
            }),
      },
      ...filteredTheme,
    };

    return (
      <>
        <div
          className={`overflow-x-auto ${style?.outerContainer}`}
          id="scrollableDiv"
        >
          <div
            ref={containerRef} // Assign ref to table container
            style={{
              height: !mini
                ? "calc(100vh - 220px)"
                : `calc(100vh - ${miniWidth || "365"}px)`,
              boxSizing: "border-box",
              width: "fit-content",
              minWidth: "100%",
              ...style?.table?.style,
            }}
            className={`h-full contt ${style?.table?.className}`}
          >
            {selectedRow?.length > 1 ? (
              <ClickDropDown
                ref={menuRef}
                lastClickEvent={lastClickEvent}
                itemMenu={actionList}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            ) : actions && actions(clickedItem) ? (
              <ClickDropDown
                ref={menuRef}
                lastClickEvent={lastClickEvent}
                itemMenu={actions(clickedItem)}
                item={clickedItem}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            ) : null}

            <InfiniteScroll
              dataLength={innerData.list?.length}
              next={() => {
                
                loadMoreData();
              }}
              hasMore={meta?.last_page > meta?.current_page}
              loader={
                loading !== "pending" && (
                  <div
                    ref={loaderRef}
                    className="loader-wrapper"
                    style={{
                      width: tableRef.current?.scrollWidth || "100%",
                    }}
                  >
                    <Skeleton
                      count={3}
                      height={56}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                      }}
                    />
                  </div>
                )
              }
              style={{ overflow: "unset" }} // Make sure the scrolling is handled by the container div
              scrollableTarget="scrollableDiv"
            >
              <table
                className={"table-auto w-full dynamicTableMain"}
                ref={tableRef}
              >
                <thead
                  className={
                    "sticky t-[0px] z-[10] l-[0px] bg-gray-50 border-b border-gray-200"
                  }
                  style={{ top: 0 }}
                >
                  <tr className={"py-3"}>
                    <Header theme={extendedTheme} tableStyle={style} />
                  </tr>
                </thead>

                <tbody
                  className={
                    style?.tbody?.className ? style?.tbody?.className : ""
                  }
                >
                  {loading === "idle" ? (
                    innerData?.list &&
                    innerData?.list?.length > 0 &&
                    innerData?.list?.map((item, rowIndex) => (
                      <tr
                        onContextMenu={(event) =>
                          handleContextMenu(event, item)
                        }
                        key={rowIndex}
                        id={`dynamicTableRow_${item.id}`}
                        className={` ${
                          item._updatedRow
                            ? item?._updatedColor
                              ? " _updatedRow " + item?._updatedColor
                              : " _updatedRow "
                            : ""
                        } 
                                                ${
                                                  item._deletedRow
                                                    ? " _deletedRow "
                                                    : ""
                                                } h-full overflow-y-scroll hover:bg-gray-100  
                                                border-b border-b-gray-200 transition  ${
                                                  item.rowSelectedInTable &&
                                                  "bg-blue-50 hover:bg-blue-100"
                                                }`}
                      >
                        <Row
                          data={item}
                          rowIndex={rowIndex}
                          theme={extendedTheme}
                          tableStyle={style}
                        />
                      </tr>
                    ))
                  ) : (
                    <div
                      className={
                        "w-full h-full min-h-[400px] gap-2 flex flex-col absolute"
                      }
                      style={{ width: "100%" }} // Ensure the loader takes up the table width dynamically
                    >
                      {[...Array(10)].map((_, index) => (
                        <div className={"px-[40px] py-[10px]"} key={index}>
                          <div
                            className="skeleton transition"
                            style={{
                              height: "20px",
                              opacity: 0.5 + Math.random(), // Removed unnecessary Math.floor
                              width: `${Math.random() * 70 + 30}%`, // Simplified width calculation
                              borderRadius: "50px",
                              background:
                                "linear-gradient(to right, #eeeeee 0%, #dddddd 50%, #eeeeee 100%)",
                              animation: "skeleton-pulse 1.2s infinite",
                            }}
                          ></div>
                        </div>
                      ))}
                    </div>
                  )}
                </tbody>
              </table>
            </InfiniteScroll>

            {!innerData?.list ||
              (innerData?.list?.length === 0 && loading === "idle" && (
                <div className={"w-full p-[16px] box-border"}>
                  <div className={""}>
                    <div
                      className={`flex flex-col w-[calc(100vw-32px)] text-center py-[50px] rounded-2xl items-center ${style?.noData?.className}`}
                    >
                      <h1 className={"text-3xl text-gray-400 mb-6"}>
                        No Data Found!
                      </h1>
                      <p className={"w-[700px] text-gray-400 font-medium"}>
                        Please adjust your search and/or filter settings to find
                        data that matches your criteria.
                        <br /> If you haven't set any filters yet and still
                        don't see any data, it's possible that no records exist
                        in the system.
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <style>
            {`
                            ._updatedRow {
                                background-color: #DEF7EC;
                                animation: fadeOut 3s forwards;
                            }

                            ._updatedRow.purple {
                                background-color: #edebfe !important;
                                animation: fadeOutPurple 3s forwards;
                            }
                            ._updatedRow.red {
                              background-color: rgb(255 228 230);  !important;
                                animation: fadeOutRed 2s forwards;
                            }

                            ._updatedRow > td > div {
                                max-height: 1000px;
                                transition: max-height 1s;
                            }

                            @keyframes fadeOut {
                                0% {background-color: #DEF7EC;}
                                90% {background-color: #DEF7EC;}
                                100% {background-color: transparent;}
                            }

                            @keyframes fadeOutPurple {
                                0% {background-color: #edebfe;}
                                90% {background-color: #edebfe;}
                                100% {background-color: transparent;}
                            }

                            @keyframes fadeOutRed {
                                0% {background-color: rgb(255 228 230); ;}
                                90% {background-color: rgb(255 228 230); ;}
                                100% {background-color: transparent;}
                            }

                            ._deletedRow {
                                /*background-color: #FDE8E8 !important;*/
                            }
                            ._deletedRow > td > div {
                                overflow: hidden;
                                max-height: 0;
                            }
                            ._deletedRow > td {
                                padding-top: 0px;
                                padding-bottom: 0px;
                            }
                        `}
          </style>
        </div>
      </>
    );
  }
);

export default DynamicTable;

const skeletonStyle = {
  background:
    "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)",
  backgroundSize: "200% 100%",
  animation: "pulse-skeleton 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite",
  width: "100%", // Ensure full width
};
