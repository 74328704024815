import { HiFilter } from "react-icons/hi";
import Util from "../util";
import SingleGroupProductBadge from "./SingleGroupProductBadge";
import descriptiveContent from "../../../utils/descriptiveContent";
import Icons from "../../orders/icons";
import { BiSolidCopy } from "react-icons/bi";

const productName = ({ item, hide, user, handleTableFilters }) => (
  <div className={` gap-[8px] flex flex-col justify-start w-[100%] `}>
    <div className={"flex flex-row gap-[8px] cursor-pointer"}>
      <Util.expandableText
        maxLines={1}
        productCopyIcon={true}
        className={"text-[12px] leading-[14px] text-gray-900  "}
        tooltip={true}
        callBack={() => {
          window.open(`/product/${item?.id}`, "_blank");
        }}
      >
        {Util.upperCaseString(item?.title)}
      </Util.expandableText>
    </div>
    <div className={"flex justify-between "}>
      <div className={"flex flex-row gap-[4px] text-gray-500"}>
        {item?.brand_name || item?.brand?.name ? (
          <p
            className={
              "text-[12px] leading-[14px] flex items-center gap-1 font-medium text-gray-500 "
            }
          >
            {Util.shortenString(item?.brand_name || item?.brand?.name, 30)}{" "}
            {!hide?.brandFilter &&
              descriptiveContent(
                <HiFilter
                  onClick={() => {
                    handleTableFilters(
                      {
                        brand_id: [item?.brand?.id],
                      },
                      {
                        brand_id: {
                          value: item?.brand?.id,
                          label: item?.brand?.name,
                        },
                      }
                    );
                  }}
                  className="text-gray-300 hover:text-gray-500 w-3.5 h-3.5 cursor-pointer"
                />,
                "Filter by Brand"
              )}
          </p>
        ) : (
          "-"
        )}
      </div>

      <div
        className="flex items-center gap-[2px] !text-[12px] cursor-pointer"
        onClick={() => {
          window.open(`/product/${item?.id}`, "_blank");
        }}
      >
        {!hide?.type && <SingleGroupProductBadge product={item} />}

        {user ? (
          <div
            className={
              "text-blue-700 font-medium text-[12px] items-center leading-normal flex flex-row gap-[4px] "
            }
          >
            {user && (
              <img
                src={user?.image_url || "/assets/images/defaultAvatar.png"}
                className={"w-[20px] h-[20px] rounded-full object-cover"}
              />
            )}
            {Util.upperCaseString(user?.name, "word")}
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Util.copyText
                className={"text-[9px]"}
                text={item?.apid}
                hint={false}
                productCopyIcon={true}
              />
            </span>

            {!hide?.assignFilter &&
              descriptiveContent(
                <HiFilter
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTableFilters(
                      {
                        assign_id: [user?.id],
                      },
                      {
                        assign_id: {
                          value: user?.id,
                          label: user?.name,
                        },
                      }
                    );
                  }}
                  className="text-gray-300 hover:text-gray-500 w-3.5 h-3.5 cursor-pointer"
                />,
                "Filter by Assignee"
              )}
          </div>
        ) : (
          <div className="text-gray-400 flex gap-1 items-center">
            <span>Unassigned</span>
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Util.copyText
                className={"text-[9px]"}
                text={item?.apid}
                hint={false}
                productCopyIcon={true}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default productName;
