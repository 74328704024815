import CustomDeclarations from "./components/product-overview-attributes/custom-declarations";
import Essentials from "./components/product-overview-attributes/essentials";
import Identifiers from "./components/product-overview-attributes/identifiers";
import Tags from "./components/product-overview-attributes/tags";
import Attributes from "./components/product-overview-attributes/attributes";
import Description from "./components/product-overview-attributes/description";
import Media from "./components/product-overview-attributes/media";
import Shipping from "./components/product-overview-attributes/shipping";
import DirtyBox from "./components/product-overview-attributes/DirtyBox";
const ProductOverview = ({ productData, id, productLanguage }) => {
  return (
    <div className="px-0 lg:px-2  flex flex-col lg:flex-row  gap-2 pb-4 sm:pb-16 pt-2  lg:pb-0 h-[calc(100vh-41vh)] overflow-y-auto box-border">
      <div className="flex flex-col gap-2 w-full lg:flex-[1_0_0] lg:mb-32">
        <div className=" flex flex-col gap-4 xl:flex xl:flex-row ">
          <DirtyBox title={"ESSENTIALS"}>
            <Essentials id={id} productData={productData} />
          </DirtyBox>
        </div>
        <div className="flex  gap-4 flex-no  xl:flex-row">
          <DirtyBox
            title={"SHIPPING"}
            className={"xl:w-[100%] flex-row box-border"}
            childClassName={
              "flex   flex-col md:flex-wrap md:flex-row w-full gap-3 "
            }
          >
            <Shipping
              id={id}
              weightNum={23}
              dimNum={21}
              productData={JSON.parse(JSON.stringify(productData))}
              title={"Merchant Shipping"}
              titleClassName={
                "text-blue-800 font-semibold text-[18px] leading-normal"
              }
              weightTitle={"Merchant Shipping Weight"}
              textColor={"text-blue-800"}
              weightDesc={
                "The weight you box your Merchant orders in when you ship them."
              }
              dimTitle={"Merchant Shipping Dimensions"}
              dimDesc={
                "The dimensions you box your Merchant orders in when you ship them."
              }
            />
            <Shipping
              id={id}
              productData={JSON.parse(JSON.stringify(productData))}
              weightNum={22}
              dimNum={20}
              titleClassName={
                "text-orange-700 font-semibold text-[18px] leading-normal"
              }
              title={"Fulfillment Shipping"}
              textColor={"text-orange-700"}
              weightTitle={"Fulfillment Shipping Weight"}
              weightDesc={
                "Package weight when sending the product to the fulfillment center."
              }
              dimTitle={"Fulfillment Shipping Dimensions"}
              dimDesc={
                "Package dimensions when sending the product to the fulfillment center."
              }
            />

            <CustomDeclarations id={id} productData={productData} />
          </DirtyBox>
          {/* <CustomDeclarations id={id} productData={productData} /> */}
        </div>
        <div className="flex flex-col xl:flex-row  gap-2 pb-5 w-full">
          <DirtyBox title={"DESCRIPTION"}>
            <Description id={id} productData={productData} />
          </DirtyBox>
          <Attributes
            id={id}
            productLanguage={productLanguage}
            productData={productData}
          />
        </div>
      </div>
      <div className="  lg:flex-[1_0_0] w-full mb-28 sm:mb-10 lg:min-w-[182px] lg:max-w-[348px]  lg:mb-0 flex  flex-col gap-2">
        <Identifiers id={id} productData={productData} />
        <Tags id={id} productData={productData} />
        <Media productData={productData} id={id} />
      </div>
    </div>
  );
};

export default ProductOverview;
