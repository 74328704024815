import Icons from "../../orders/icons";
import Util from "../util";

const Header = ({ theme, tableStyle}) => (
  <>
    {Object.keys(theme).map((item, index) => {
      if (!tableStyle?.header?.[item]?.th?.includes("hidden")) {
        
        return (
          <th
            key={index}
            data-name={item}
            className={`
                            ${item.startsWith("_") ? "w-[90px]" : ""}
                            ${
                              item.startsWith("_actions")
                                ? "actionRow !w-fit !min-w-max !max-w-fit"
                                : ""
                            }
                            ${
                              item === "status"
                                ? "w-[90px] pr-[0px] mr-[0px]"
                                : ""
                            }
                            ${
                              tableStyle?.header?.th
                                ? tableStyle?.header?.th
                                : ""
                            }
                            ${
                              tableStyle?.header?.[item]?.th
                                ? tableStyle?.header?.[item]?.th
                                : ""
                            }
                        `}
          >
            <div
              className={`  gap-1.5 text-[12px] 
                                flex justify-start text-left h-[42px] items-center whitespace-nowrap
                                ${
                                  item.startsWith("_")
                                    ? "pl-[10px] pr-[0px] w-[90px] mr-[0px] !justify-start"
                                    : "px-[30px]"
                                }
                                        ${
                                          item.startsWith("_actions")
                                            ? "!w-fit"
                                            : ""
                                        }
                                ${
                                  item.startsWith("*sm-") &&
                                  "px-[0px]} min-w-max  max-w-fit"
                                }
                                ${
                                  item.startsWith("*fit-") &&
                                  "min-w-fit w-fit  max-w-fit opacity-0"
                                }
                                ${
                                  !item.startsWith("_") &&
                                  tableStyle?.header?.className
                                }
                                ${index === 1 ? "pl-[15px]" : ""}
                                
                                ${
                                  tableStyle?.header?.[item]?.className &&
                                  tableStyle?.header?.[item]?.className
                                }
                            `}
            >
              <span
                className={
                  "whitespace-no-wrap block min-w-max  text-gray-500 uppercase font-semibold select-none"
                }
              >
                {item === "_actions"
                  ? theme["_actions"]({ header: true })
                  : item}
              </span>
              {tableStyle?.row[item]?.icon && (
                <div> {tableStyle.row[item].icon}</div>
              )}
            </div>
          </th>
        );
      }
    })}
  </>
);
export default Header;
