const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Order Date",
                value: "order_date",
                onClick: (item) => {
                    callback("order_date");
                }
            },
            {
                label: "Billable Weight",
                value: "billable_weight",
                onClick: (item) => {
                    callback("billable_weight");
                }
            },
            {
                label: "Shipping Cost",
                value: "billed_cost",
                onClick: (item) => {
                    callback("billed_cost");
                }
            },
            {
                label: "Shipped At",
                value: "created_at",
                onClick: (item) => {
                    callback("created_at");
                }
            },
            {
                label: "Latest Event",
                value: "latest_event",
                onClick: (item) => {
                    callback("latest_event");
                }
            }
        ]
    }
}

export default sortView