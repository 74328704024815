import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import MainModal from "../../dynamic-page/MainModal";
import UI from "../../dynamic-form/elements";
import {Datepicker} from 'flowbite-react';
import Icon from '../icons';
import axios from "axios";

// Define a CSS class to change the border color
const customDatepickerStyles = {
    border: '1px solid #E5E7EB', // Replace #ff0000 with your desired border color
    color: '#1F2A37',
    fontSize: '14px', // Add the desired font-size value
    fontWeight: 500, // Add the desired font-weight value
};

const SalesHistoryModal = ({type, item, data, onClose, onUpdate, onAdd, meta, show}) => {
    const dispatch = useDispatch();


    // Define a state variable to track the active tab
    const [activeTab, setActiveTab] = useState(0);

    const [chartApi, setChartApi] = useState(null);

    const salesData = (data) => data?.sales_channel_listing || data?.sales_channel_listings[0]

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        let baseURL = activeTab === 0 ? `/api/v1/account-listing/sold-units?identifier=${salesData(item)?.identifier}&period=60` : activeTab === 1 ? `/api/v1/account-listing/sales-revenue?identifier=${salesData(item)?.identifier}&period=60` : `/api/v1/account-listing/sold-units-by-fulfilment?identifier=${salesData(item)?.identifier}&period=60`;
        const fetchChartData = async () => {
            const res = await axios.get(baseURL);
            const {data} = res;
            setChartApi(data);
        }
        fetchChartData();
    }, [activeTab, item]);


    const reducedChartData = chartApi?.data?.details?.reduce((acc, item) => {
        // each item has a value property, and account object which has a name property, reduce the data so i can get the values for each account
        const accountName = item.account.name;
        const accountValue = item.value;
        if (acc[accountName]) {
            acc[accountName].unshift(accountValue);
        } else {
            acc[accountName] = [accountValue];
        }
        return acc;
    }, {});

    // exteract array of the dates inside details
    const dates = chartApi?.data?.details?.map((item) => {
        // convert it to this format: jan 1
        return item.date;
    });


    // Define a function to handle tab change


    // now from the reducedChartData object, we make the series array
    const series = reducedChartData ? Object.keys(reducedChartData).map((key) => {
        return {
            name: key,
            data: reducedChartData[key],
        };
    }) : [];


    // create a new series array containing 'all' added to the series array
    series.unshift({
        name: 'All',
        data: // data is an array containng all the values of the accounts in the details array
            chartApi?.data?.details?.map((item) => {
                return item.value;
            })?.reverse(),

    });


    // Sample data for the chart
    const chartData = {
        series: series,
        options: {
            chart: {
                type: 'area',
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: dates?.reverse(),
            },
            theme: {
                mode: 'light', // or 'dark' for dark mode
            },
            legend: {
                position: 'bottom',
            },
            colors: ['#1C64F2', '#FDBA8C', '#16BDCA', '#9061F9'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm',
                },
            },
        },
    };

    const statData = [
        {title: '30d restocking suggestion', value: item?.fba_30_days_restock},
        {title: 'Total FC quantity', value: '671'},
        {title: 'Days of stock left', value: '7'},
        {title: 'Warehouse inventory', value: '1220'},
        {title: 'Awaiting supply', value: '199'},
        // Add more data items as needed
    ];

    React.useEffect(() => {
    }, []);

    return (
        <MainModal
            item={{
                title: "Sales History",
                cancel: onClose,
                view: () => (
                    <div className="w-[64vw] relative  border-t border-t-gray-200 py-3">
                        <div className="flex flex-col justify-between items-start gap-[8px]">
                            <div className="grid grid-cols-5 gap-x-3 w-full">
                                <UI.StatBoxList data={statData}/>
                            </div>
                        </div>
                        <div className="sales_revenue_graph flex flex-col justify-between items-center mt-3 w-full">
                            <div className="flex flex-row justify-between items-center w-full">
                                <div className="flex flex-col justify-between items-start gap-y-0.5">
                                    <h4 className="text-[30px] text-gray-900 font-bold flex flex-row justify-between items-center gap-x-1.5">$16,421.89 <span
                                        className="text-green-500 text-[16px] flex flex-row gap-x-1 justify-between items-center">10% <Icon.arrowup/></span>
                                    </h4>
                                    <span className="text-base text-gray-500">
                    {salesData(item)?.identifier}
                  </span>
                                </div>
                                <div className="flex flex-row justify-between items-center gap-x-3">
                                    <Datepicker
                                        style={customDatepickerStyles}
                                        maxDate={new Date("2023-04-29T21:00:00.000Z")}
                                        minDate={new Date("2022-12-31T21:00:00.000Z")}
                                    />
                                    <span className="lowercase text-gray-900 text-base font-medium">to</span>
                                    <Datepicker
                                        style={customDatepickerStyles}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="custom-tabs mt-3">
                            <div className="flex flex-row justify-start items-start gap-x-6">
                                <button
                                    className={`text-sm font-medium tab-button ${activeTab === 0 ? 'active text-[#1C64F2] border-b-[1px] border-[#1C64F2] pb-1' : 'text-gray-500'}`}
                                    onClick={() => handleTabClick(0)}
                                >
                                    Units
                                </button>
                                <button
                                    className={`text-sm font-medium tab-button ${activeTab === 1 ? 'active text-[#1C64F2] border-b-[1px] border-[#1C64F2] pb-1' : 'text-gray-500'}`}
                                    onClick={() => handleTabClick(1)}
                                >
                                    Revenue
                                </button>
                                <button
                                    className={`text-sm font-medium tab-button ${activeTab === 2 ? 'active text-[#1C64F2] border-b-[1px] border-[#1C64F2] pb-1' : 'text-gray-500'}`}
                                    onClick={() => handleTabClick(2)}
                                >
                                    Units by Fullfilment
                                </button>
                            </div>
                            <div className="tab-content">
                                {activeTab === 0 && (
                                    <div className="units-pane">
                                        <UI.AreaChart chartData={chartData}/>
                                    </div>
                                )}
                                {activeTab === 1 && (
                                    <div className="revenue-pane">
                                        <UI.AreaChart chartData={chartData}/>
                                    </div>
                                )}
                                {activeTab === 2 && (
                                    <div className="revenue-pane">
                                        <UI.AreaChart chartData={chartData}/>
                                    </div>
                                )}
                            </div>
                        </div>
                        <UI.Button
                            item={{
                                className: 'text-sm hover:bg-blue-600 hover:text-white font-medium rounded-lg px-4 py-4 bg-transparent w-max ml-auto flex flex-col justify-center items-center',
                                buttonType: 'alt',
                                label: 'Create Supply Request',
                            }}
                        />
                    </div>
                )
            }}
        />
    );
}

export default SalesHistoryModal;
