import { useEffect, useState } from "react";

import formView from "./modal-view/form";
import { IoMdCalculator } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { removeValues } from "../../store/calculator";
import FormModal from "./components/calculatorFormModal";

import { Tooltip } from "react-tooltip";
import Icons from "../orders/icons";

export const WarehouseTooltip = (type) => (
  <span className="">
    <Icons.info
      className="w-4 h-4  text-gray-300 hover:text-gray-500"
      data-tooltip-id={`DimTooltip${type || ""}`}
    />
    <Tooltip
      id={`DimTooltip${type || ""}`}
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
        padding: "0px 0px 0px 0px",
      }}
    >
      <div className="flex flex-col gap-2 py-2.5 px-3 w-[622px]">
        <span className="text-[14px] leading-[14px] font-medium text-gray-900">
          Dimensional Weight Divisor
        </span>
        <span className="text-gray-500 text-[12px] leading-[15px] font-normal whitespace-pre-wrap">
          {`Select the divisor to calculate the dimensional weight of your shipment.
Choose from 5000, 6000, or 3000, based on the international shipping standards applicable to your case.`}
        </span>
      </div>
    </Tooltip>
  </span>
);

const Calculator = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const formData = useSelector((state) => state.calculator.calculatorValues);
  const dispatch = useDispatch();

  const status = useSelector((state) => state.calculator.status);

  useEffect(() => {
    dispatch(removeValues());
  }, [dispatch]);

  return (
    <div>
      <button
        onClick={() => {
          setModalVisibility(!modalVisibility);
          dispatch(removeValues());
        }}
      >
        <IoMdCalculator className="w-5 h-5 text-gray-400 hover:text-gray-300 mt-[6px]" />
      </button>

      {modalVisibility && (
        <FormModal
          title={""}
          prefix={"Shipping Cost Calculator"}
          formView={formView}
          calc={true}
          visibility={modalVisibility}
          data={formData}
          status={status}
          onClose={() => {
            setModalVisibility(false);
            dispatch(removeValues());
          }}
          container={(child) => (
            <>
              <style>
                {`
                        .parentForm {
                            display: flex;
                            flex-wrap:wrap;
                        }
                        .parentForm > div:nth-child(1) { width:38.6%; padding-left:16px; padding-top:4px  }
                        .parentForm > div:nth-child(2) { width:28.5%;  padding-top:4px }
                        .parentForm > div:nth-child(3) { width:28.5%; padding-right:8px; padding-top:4px }
                        .parentForm > div:nth-child(4) { width:19%;  margin-top:5px; padding-left:16px; }
                        .parentForm > div:nth-child(5) { width:1px; }
                        .parentForm > div:nth-child(6) { width:10.2%; margin-top:5px; }
                        .parentForm > div:nth-child(7) { width:10.2%; margin-top:5px; }
                        .parentForm > div:nth-child(8) { width:10.2%;  margin-top:5px; }
                        .parentForm > div:nth-child(9) { width:1px; }
                        .parentForm > div:nth-child(10) { width:11%;  margin-top:5px; }
                        .parentForm > div:nth-child(11) { width:12%; margin-top:9px;   }
                        .parentForm > div:nth-child(12) { width:100%;    }
                        .parentForm > div:nth-child(13) { width:100%;   }
                        
                        
                        @media (max-width: 1024px) {
                            .parentForm > div:nth-child(1),
                            .parentForm > div:nth-child(2),
                            .parentForm > div:nth-child(3),
                            .parentForm > div:nth-child(11)
                            {
                                width: 100%;
                                padding-left:16px;
                                padding-right:16px;
                            }
                            .parentForm > div:nth-child(4){
                                min-width:100%;
                            }
                            .parentForm > div:nth-child(2){
                              padding-top:33px;
                            }
                            .parentForm > div:nth-child(5),
                            .parentForm > div:nth-child(9){
                                display:none;
                            }
                            .parentForm > div:nth-child(4),
                            .parentForm > div:nth-child(6),
                            .parentForm > div:nth-child(7),
                            .parentForm > div:nth-child(8),
                            .parentForm > div:nth-child(9){
                                width:80px;
                                padding-left:16px;
                                padding-right:16px;
                            }
                        }
                            @media (max-width: 510px) {
                            .parentForm > div:nth-child(1),
                            .parentForm > div:nth-child(2),
                            .parentForm > div:nth-child(3),
                            .parentForm > div:nth-child(11)
                            {
                                width: 100%;
                            }
                            .parentForm > div:nth-child(4){
                                min-width:100%;
                            }
                            .parentForm > div:nth-child(5),
                            .parentForm > div:nth-child(9){
                                display:none;
                            }
                            .parentForm > div:nth-child(5),
                            .parentForm > div:nth-child(6),
                            .parentForm > div:nth-child(7),
                            .parentForm > div:nth-child(9){
                                width:80px;
                            }
                        }
                    `}
              </style>
              <div className="max-w-[813px]    parentForm  items-start gap-3  border-t border-t-gray-200 pt-4">
                {child}
              </div>
            </>
          )}
        />
      )}
    </div>
  );
};

export default Calculator;
