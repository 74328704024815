import { useEffect, useState } from "react";
import Icons from "../../../orders/icons";
import Util from "../../../dynamic-page/util";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { toast } from "react-toastify";
import DirtyBox from "./DirtyBox";

const Identifiers = ({ id, productData }) => {
  const [isClicked3, setClicked3] = useState(false);

  const [isSaveClicked, setSaveClicked] = useState(false);

  const [identifierValue, setIdentifierValue] = useState("");

  const isIdentifiers =
    productData?.attributes?.Identifiers &&
    productData?.attributes["Identifiers"];
  const [identifiers, setIdentifiers] = useState(
    isIdentifiers
      ? isIdentifiers.map((item) => ({ ...item, icon: "trash" }))
      : []
  );

  const [barcodeSelect, setBarcodeSelect] = useState({
    value: 5,
    label: "UPC",
    title: "UPC",
    product: 5,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (identifierValue && barcodeSelect) {
      const barcode = {
        barcodeLabel: barcodeSelect?.label,
        barcodeId: barcodeSelect?.product,
      };
      setIdentifiers((prevVal) => [
        ...prevVal,
        {
          value: identifierValue,
          attribute_id: barcodeSelect?.product,
          attribute_name: barcodeSelect?.label,
          icon: "x",
        },
      ]);
      setSaveClicked(true);
      setIdentifierValue("");
    }
  };
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    async function fetchAttributes() {
      await axios
        .get("api/v1/attribute?filter[status]=system&filter[group]=4", {
          withCredentials: true,
        })
        .then((response) => {
          // setWarehouses(response?.data?.data)

          const options = mapOptionsToValues(response?.data?.data);
          setWarehouses(options);
        });
    }

    fetchAttributes();
  }, []);
  const fetchAttributes = async (params) => {
    let url = "api/v1/attribute";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const mapOptionsToValues = (options) => {
    const newArr = options.filter((item) => item?.name !== "Email");
    const newArr2 = newArr.filter((item) => item?.name !== "GTIP");

    return newArr2?.map((option) => ({
      value: option?.id,
      label: option?.name || option?.display_name,
      title: option?.name,
      product: option?.id,
    }));
  };
  const warehousesOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchAttributes("?filter[name]" + inputValue)
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  };

  const handleClick = async () => {
    setSaveClicked(false);
    for (const item of identifiers) {
      if (item?.icon === "x") {
        try {
          setIdentifiers((prevVal) =>
            prevVal.map((tag) =>
              tag.icon === "x" ? { ...tag, icon: "spinner" } : tag
            )
          );
          const res = await axios.post(
            `/api/v1/attribute-value/${item?.attribute_id}/${id}`,
            { value: item?.value, locale: "40" },
            { withCredentials: true }
          );

          if (res?.status === 200) {
            // setWeightPosted(res?.data?.data?.attributeValue?.id);

            toast.success(res?.data?.message);
            setIdentifiers((prevVal) =>
              prevVal.map((tag) =>
                tag.value === item.value
                  ? {
                      ...tag,
                      icon: "check",
                      id: res?.data?.data?.attributeValue?.id,
                    }
                  : tag
              )
            );
            setTimeout(() => {
              setIdentifiers((prevVal) =>
                prevVal.map((tag) =>
                  tag.value === item.value
                    ? {
                        ...tag,
                        icon: "trash",
                        id: res?.data?.data?.attributeValue?.id,
                      }
                    : tag
                )
              );
            }, 2000);

            // setOrginalIdentifiers(prevVal => [...prevVal,res?.data?.data?.attributeValue])
          } else {
            toast.error(res?.data?.message);
            setIdentifiers((prevVal) =>
              prevVal.map((tag) =>
                tag.value === item.value ? { ...tag, icon: "error" } : tag
              )
            );
            setTimeout(() => {
              setIdentifiers((prevVal) =>
                prevVal.filter((tag) => tag.value !== item.value)
              );
            }, 2000);
          }
        } catch (error) {
          toast.error("An error occurred");
          setIdentifiers((prevVal) =>
            prevVal.map((tag) =>
              tag.value === item.value ? { ...tag, icon: "error" } : tag
            )
          );
          setTimeout(() => {
            setIdentifiers((prevVal) =>
              prevVal.map((tag) =>
                tag.value === item.value ? { ...tag, icon: "trash" } : tag
              )
            );
          }, 2000);

          console.error(error);
        }
      }
    }
  };

  const handleDeleteTag = async (item) => {
    try {
      const res = await axios.delete(
        `/api/v1/attribute-value/${item?.id}`,
        { value: item?.value, locale: "40" },
        { withCredentials: true }
      );

      if (res?.status === 204) {
        // setWeightPosted(res?.data?.data?.attributeValue?.id);
        toast.success("Attribute has been deleted");
        setIdentifiers((prevVal) =>
          prevVal.filter((item2) => item2?.id !== item?.id)
        );
        // setOrginalIdentifiers((prevVal) => prevVal.filter((item2) => item2?.id!==item?.id))
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error("An error occurred");
      console.error(error);
    }
  };
  useEffect(() => {
    const hasXIcon = identifiers.some((item) => item.icon === "x");
    setSaveClicked(hasXIcon);
  }, [identifiers]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isSelectFocused, setIsSelectFocused] = useState(false);

  const isFocused = isInputFocused || isSelectFocused;
  return (
    <DirtyBox
      title="IDENTIFIERS"
      texts={{
        tooltipTitle: "Identifiers", 
        tooltipDesc:(<><div className="self-stretch"><span className="text-gray-500 text-xs font-normal font-['Inter'] leading-[15px]">You can add </span><span className="text-gray-500 text-xs font-bold font-['Inter'] leading-[15px]">multiple identifiers</span><span className="text-gray-500 text-xs font-normal font-['Inter'] leading-[15px]"> for an Arbitbox product.<br/>These identifiers will allow </span><span className="text-gray-500 text-xs font-bold font-['Inter'] leading-[15px]">AI-powered matching </span><span className="text-gray-500 text-xs font-normal font-['Inter'] leading-[15px]">of products with listings in sales and supply channels.</span></div>
        </>),
      }}
    >
      {!isClicked3 && (
        <div className="flex  w-full flex-col gap-4  ">
          <div className="   flex flex-col gap-2 w-full   rounded-[8px]">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-1">
                <label className="text-[14px] leading-[21px] font-medium text-gray-900">
                  Add Identifier
                </label>
              </div>
              {identifiers?.length !== 0 && isSaveClicked && (
                <button
                  onClick={handleClick}
                  type="button"
                  className="text-blue-700 hover:text-blue-500 text-[14px] leading-normal font-medium"
                >
                  <Icons.chechkCircle className="w-5 h-5" />
                </button>
              )}
            </div>
            <div className="flex rounded-[8px] w-full  ">
              <AsyncSelect
                cacheOptions
                value={barcodeSelect}
                onChange={(e) => {
                  setBarcodeSelect(e);
                }}
                defaultOptions={warehouses}
                loadOptions={warehousesOptions}
                className="border-none h-[42px] bg-transparent "
                name="provider"
                placeholder="Barcode"
                onFocus={() => setIsSelectFocused(true)}
                onBlur={() => setIsSelectFocused(false)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    minHeight: 42,
                    backgroundColor: "#f7fafc",
                    border: isFocused
                      ? "0.1px solid #2563EB"
                      : "0.1px solid #D1D5DB", // blue-600 for focus, 1px border
                    borderRadius: "0.5rem",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    boxShadow: isFocused ? "0px 0px 0px 1px #2563EB" : "none", // blue-600
                    "&:hover": {
                      border: isFocused
                        ? "0.1px solid #2563EB"
                        : "0.1px solid #D1D5DB", // Disable hover effect, 1px border
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#00A3FF" : "white",
                    color: state.isSelected ? "white" : "black",
                    "&:hover": {
                      backgroundColor: "#00A3FF",
                      color: "white",
                    },
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    borderRadius: 10,
                    border: state.isFocused
                      ? "1px solid #00A3FF"
                      : "1px solid #E5E5E5",
                    boxShadow: state.isFocused
                      ? "0px 0px 0px 1px #00A3FF"
                      : "none",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                    },
                    minWidth: 200,
                  }),
                  container: (provided) => ({
                    ...provided,
                    height: 41,
                  }),
                }}
              />
              <form
                onSubmit={handleSubmit}
                className={`flex-grow ${isFocused && "-mt-[1.5px]"}`}
              >
                <input
                  value={identifierValue}
                  onChange={(e) => setIdentifierValue(e.target.value)}
                  className={`bg-gray-50 px-4 py-2 border border-l-0 w-full
    ${
      isFocused
        ? "focus:border-blue-600 border-2 border-blue-600"
        : "border-gray-300"
    }
    rounded-lg rounded-l-none`}
                  placeholder="ex. 23923900"
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                />
              </form>
            </div>
          </div>
          <div className="flex flex-wrap gap-2  overflow-y-auto ">
            {identifiers?.map((item, index) => {
              if (item?.attribute_name !== "GTIP") {
                return (
                  <div className="flex min-w-[128px] flex-1 whitespace-pre-wrap h-[45px] max-w-[180px] flex-col gap-1.5 rounded-[8px] px-2 py-1 border border-gray-100">
                    <div className="flex justify-between items-center">
                      <span className="text-gray-500 font-semibold text-[12px] leading-normal">
                        {item?.attribute_name}
                      </span>
                      {item?.icon === "x" && (
                        <Icons.close
                          onClick={() => {
                            setIdentifiers((prevVal) =>
                              prevVal.filter((item, index2) => index2 !== index)
                            );
                          }}
                          className="text-gray-500 hover:text-gray-300 w-4 h-4 cursor-pointer"
                        />
                      )}
                      {item?.icon === "spinner" && (
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      )}

                      {item?.icon === "check" && (
                        <Icons.check className="text-green-500  w-4 h-4" />
                      )}
                      {item?.icon === "trash" && (
                        <Icons.trashBin
                          onClick={() => handleDeleteTag(item)}
                          className="text-red-500 hover:text-red-300 w-4 h-4 cursor-pointer"
                        />
                      )}

                      {item?.icon === "error" && (
                        <Icons.close className="text-red-500 hover:text-red-300 w-4 h-4" />
                      )}
                    </div>
                    <div className="flex gap-1 items-center break-words  overflow-hidden break-all">
                      <span className="text-gray-900 text-[14px] break-words  leading-normal font-medium">
                        {item?.value}
                      </span>
                      <Util.copyText
                        className={"text-[12px]"}
                        text={item?.value}
                        hint={false}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
    </DirtyBox>
  );
};

export default Identifiers;
