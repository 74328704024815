import moment from "moment";
import React from "react";
import axios from "axios";

const StatusHistory = ({ id, title, url, includes }) => {
  const [acceptance, setAcceptance] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    const fetchItems = async () => {
      const response = await axios.get(`/api/v1/${url}/${id}`);
      const acceptance = response?.data?.data;

      let list =
        url === "good-acceptance"
          ? statusLabel(acceptance)
          : url === "supply-purchase"
          ? statusLabel(acceptance).slice(1)
          : statusLabel(acceptance).slice(2);

      if (
        url === "supply-request" &&
        acceptance?.supply_purchases?.length > 0
      ) {
        list.push(
          acceptance?.supply_purchases?.map((item) => {
          
            return {
              action: "Purchased",
              name: item?.created_by?.name,
              date: item?.created_at,
              qty: item?.quantity,
            };
          })
        );
        list = list.flat();
      }
      if (
        url === "supply-purchase" &&
        acceptance?.good_acceptances?.length > 0
      ) {
        list.unshift(
          acceptance?.good_acceptances?.map((item) => {
            return {
              action: "Accepted",
              name: item?.accepted_by?.name,
              date: item?.accepted_at,
              qty: item?.quantity,
            };
          })
        );
        list = list.flat();
      }
      setAcceptance(list);
      setLoading(false);
    };
    fetchItems();
  }, []);

  const unitsQty = (item) => {
    if (item === 1) {
      return item + " unit";
    } else {
      return item + " units";
    }
  };


  const statusLabel = (item) => [
    {
      action: "Accepted",
      name: item?.accepted_by?.name,
      date: item?.accepted_at,
      qty: item?.quantity,
    },
    {
      action: "Purchased",
      name:
        url === "good-acceptance"
          ? item?.purchase?.created_by?.name
          : item?.created_by?.name,
      date:
        url === "good-acceptance"
          ? item?.purchase?.created_at
          : item?.created_at,
      qty:
        url === "good-acceptance" ? item?.purchase?.quantity : item?.quantity,
    },
    {
      action: "Requested",
      name:
        url === "good-acceptance"
          ? item?.purchase?.supply_request?.employee?.name
          : url === "supply-purchase"
          ? item?.supply_request?.employee?.name
          : item?.employee?.name,
      date:
        url === "good-acceptance"
          ? item?.purchase?.supply_request?.request_date_time
          : url === "supply-purchase"
          ? item?.supply_request?.request_date_time
          : item?.request_date_time,
      qty:
        url === "good-acceptance"
          ? item?.purchase?.supply_request?.qty
          : url === "supply-purchase"
          ? item?.supply_request?.qty
          : item?.qty,
    },
  ];

  const lineAnimationStyles = {
    width: "50%",
    height: "100%",
    background:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,97,9,0.45475465576855745) 43%, rgba(0,212,255,1) 100%)",
    position: "absolute",
    left: "0",
    animation: "line-animation 0.5s linear infinite",
    borderRadius: "5px",
  };

  return (
    <div className={"flex  flex-col gap-[6px] px-[4px] py-[2px]"}>
      {loading && (
        <div
          style={{
            top: "15px",
            left: "10px",
            width: "calc(100% - 20px)",
            height: "5px",
            backgroundColor: "white",
            position: "absolute",
            overflow: "hidden",
            borderRadius: "5px",
            background: "#jjj",
          }}
        >
          <div style={lineAnimationStyles}></div>
        </div>
      )}
      <style>
        {" "}
        {`  @keyframes line-animation { 0% { left: -50%; } 100% { left: 100%; } } @keyframes new-animation { 0% {  background: rgba(164,202, 254, 0.3); } 90% { background: rgba(164,202, 254, 0.3); } 100% { background: white; } } .new-item { animation: new-animation 1.5s linear; }`}{" "}
      </style>
      <div className={"font-bold"}>
        {title ? title : "Supply Status History"}
      </div>
      {!loading &&
        acceptance?.map((item, index) => (
          <div
            className="text-gray-900 text-xs font-normal leading-[18px] gap-[4px] flex flex-row items-center transition cursor-pointer hover:blue-700"
            key={index}
          >
            <spam>{item.action}</spam>
            <span className="font-semibold">
              {item?.qty && unitsQty(+item?.qty)}
            </span>
            <span className="text-gray-500 text-xs font-normal leading-[18px]">
              by
            </span>
            <spam>{item.name || '-'}</spam>
            <span className="text-gray-500 text-xs font-normal leading-[18px]">
              at
            </span>
            <spam>{moment(item?.date).format("MMM D, YYYY HH:mm")}</spam>
            {/* <Icons.goToTrackURL
                        className={"text-gray-300 text-gray-300 group-hover:text-blue-500 w-[10px] h-[10px] mr-[4px] ml-[4px]"}/> */}
          </div>
        ))}
    </div>
  );
};
export default StatusHistory;
