import React from "react";
import Icons from "../../orders/icons";
// import Components from "../index";
import {fetchSingleOrder, updateBillableWeight, updateRow} from "../../../store/orders";
import {useDispatch, useSelector} from "react-redux";
import notifier from "../../dynamic-page/util/notifier"
import util from "../../dynamic-page/util";

const Weight = ({weight}) => {

    const dispatch = useDispatch()
    const data = useSelector((state) => state?.orders.orders);
    const weightInRedux = data?.find(item => item?.id === weight?.orderId)?.billable_weight || "-"
    const handleSaveWeight = async (t) => {
        await dispatch(updateBillableWeight(
            {orderId: weight?.orderId, billable_weight: Number(t)}
        )).then((rs) => {

            if (rs?.meta?.requestStatus === "fulfilled") {
                const w = rs?.payload?.data?.data?.billable_weight
                notifier({message: `Billable Weight updated to  ${w}`, success: true})
                dispatch(fetchSingleOrder(weight?.orderId)).then((rs) => {
                    dispatch(updateRow({path: `orders.[id:${weight?.orderId}]`, value: rs.payload.data[0]}))
                })

            }

        })

        return true
    }
    return (
        <div className={"flex flex-col  "}>
            {/*{weight?.billable  &&*/}
            <>
                <div className={"flex flex-row gap-[0px] items-center justify-center"}>
                    {/* <Components.saveWeight text={weightInRedux} onSave={handleSaveWeight} className={"min-w-max whitespace-no-wrap group-active:text-purple-800 hover:text-purple-600 flex text-[24px] text-purple-700 font-[700]"}/> */}
                    {!weight?.is_billable_weight_manually_updated ?
                        weight?.selected === "actual" ? <Icons.weightTarazo className={"h-[1em] text-purple-600 mr-1"}/>
                            : weight?.selected === "dimensional" ?
                                <Icons.weightBox className={"h-[1em] text-purple-600 mr-1 text-purple-600"}/> : null : null
                    }
                    <span
                        className={"min-w-max h-[25px] leading-[25px] whitespace-no-wrap group-active:text-purple-800 hover:text-purple-600 flex text-[24px] text-purple-700 font-[700]"}>{weight.billable || '-'}</span>
                </div>


                <div className={"flex flex-row gap-[4px] text-[12px] justify-center"}>

                    {((weight?.selected !== 'actual' && weight?.is_billable_weight_manually_updated !== 1) || weight?.is_billable_weight_manually_updated === 1) &&
                        <util.popUp
                            toggle={(selected) => (
                                <span
                                    className={`opacity-40 ${weight?.selected === "actual" && "!opacity-100"} ${selected ? "!opacity-70" : ""} text-purple-600 font-medium flex flex-row gap-[4px] items-center  text-medium`}>
                                    <Icons.weightTarazo className={"h-[1em]"}/>{weight?.actual}
                                </span>
                            )}
                            action={"hover"}
                            forcePosition={"top"}
                            delay={500}
                            toggleClassName={"!cursor-default"}
                        >
                            Actual Weight
                        </util.popUp>}
                    {((weight?.selected !== 'dimensional' && weight?.is_billable_weight_manually_updated !== 1) || weight?.is_billable_weight_manually_updated === 1) &&
                        <util.popUp
                            toggle={(selected) => (
                                <span
                                    className={`opacity-40 ${weight?.selected === "dimensional" && "opacity-100"} ${selected ? "!opacity-70" : ""} text-purple-600 font-medium flex flex-row gap-[4px] items-center text-medium"`}>
                                    <Icons.weightBox className={"h-[1em]"}/>{weight?.dim}
                                </span>
                            )}
                            action={"hover"}
                            forcePosition={"top"}
                            delay={500}
                            toggleClassName={"!cursor-default"}
                        >
                            Dimensional Weight
                        </util.popUp>}


                </div>
            </>
            {/*}*/}

        </div>

    )
}


export default Weight;