import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import UI from "../../dynamic-form/elements";
import Components from "../../orders/components";
import StatusSteps from "../components/StatusSteps";
import StatusHistory from "../components/statusHistory";
import OrderId from "../components/orderid";
import priceHandler from "../../../utils/priceHandler";
import Weight from "../components/weight";
import ShipmentLabel from "../components/shipmentLabel";
import Numbers from "../../offer/components/numbers";
import InternalNote from "../components/internalNote";
import moment from "moment";
import { howLongFromNow } from "../../../utils/helper";
import descriptiveContent from "../../../utils/descriptiveContent";

const tableView = (list, callBack) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-500 hover:text-gray-300";
    }
  };

  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };

  const statusLabel = (item) => {
    if (item?.statuses) {
      return Object.values(item?.statuses).map((status) => {
        return {
          action: status?.detail,
          date: status?.timestamp,
          user: status?.created_by || "System",
          location: status?.location || "Warehouse",
        };
      });
    } else {
      return [];
    }
  };

  return {
    list: list,
    theme: {
      notes: (item) => (
        <div className="flex flex-col gap-1">
          <div className={"flex justify-center items-center"}>
            {/* <util.popUp
                    toggle={(selected) =>
                        descriptiveContent(
                            <div>
                                <UI.Button
                                    item={{
                                        buttonType: "flat",
                                        className: `p-[2px]  ${activeStyle(
                                            actions?.customer_note_enabled
                                        )}`,
                                    }}
                                >
                                    <Icons.buyerNote/>
                                </UI.Button>
                            </div>,
                            "Customer note"
                        )
                    }
                    action={"hover"}
                    leftSpace={style?.leftSpace}
                >
                    <div
                        style={{hyphens: "auto"}}
                        className={
                            "max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"
                        }
                    >
                        {actions?.customer_note ? (
                            util.upperCaseString(actions?.customer_note)
                        ) : (
                            <span className={"text-gray-400"}>Buyer note is empty!</span>
                        )}
                    </div>
                </util.popUp> */}

            {/* Internal note is here */}
            <util.popUp
              toggle={(selected) =>
                descriptiveContent(
                  <div className={"relative"}>
                    <UI.Button
                      item={{
                        buttonType: "flat",
                        className: `p-[2px] w-[23px] h-[23px]  ${activeStyle(
                          item?.notes?.length > 0
                        )}`,
                      }}
                    >
                      <Icons.internalNote className="" />
                    </UI.Button>
                    {item?.notes?.length > 0 && (
                      <div
                        className={
                          "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                        }
                      >
                        {item?.notes?.length}
                      </div>
                    )}
                  </div>,
                  "Internal note"
                )
              }
              // leftSpace={style?.leftSpace}
            >
              <div className={"w-[525px]"}>
                <Components.internalNote
                  callBack={callBack ? callBack : null}
                  notes={item?.notes}
                  id={item?.id}
                />
              </div>
            </util.popUp>
          </div>
          <div className={"flex justify-center items-center"}>
            {/* Shipping Content is here */}

            <util.popUp
              toggle={(selected) =>
                descriptiveContent(
                  <div>
                    <UI.Button
                      item={{
                        buttonType: "flat",
                        className: `p-[2px] w-[23px] h-[23px] ${activeStyle(
                          item?.shipment?.order?.shipping_documents_enabled
                        )}`,
                      }}
                    >
                      <Icons.shippingContent className="" />
                    </UI.Button>
                  </div>,
                  "Shipping content"
                )
              }
              // leftSpace={style?.leftSpace}
              className=""
            >
              <div className={"px-[8px] py-[4px]"}>
                {item?.shipment?.order?.shipping_documents?.length > 0 ? (
                  <Components.shippingContent
                    list={item?.shipment?.order?.shipping_documents}
                  />
                ) : (
                  <span className={"text-gray-400 text-[14px]"}>
                    There is no shipping documents!
                  </span>
                )}
              </div>
            </util.popUp>
          </div>
        </div>
      ),
      status: (item) =>
        descriptiveContent(
          <StatusSteps
            statusId={item?.current_status_id || 1}
            list={<StatusHistory item={item} list={statusLabel(item)} />}
          />,
          item?.current_status || "Status"
        ),
      "latest event": (item) => {
        return <util.dateDisplay item={item} date={item?.last_status_update} />;
      },
      "SHIPPED FROM & AT": (item) => (
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 items-center">
            <util.popUp
              toggle={(selected) => (
                <img
                  src={item?.fromState?.country?.flag}
                  className={"h-[16px] w-[22.4px] rounded-[2px]"}
                  style={{ objectFit: "cover" }}
                />
              )}
              action={"hover"}
            >
              <div className={"px-[8px] py-[4px]"}>
                {" "}
                {item?.fromState?.country?.name}{" "}
              </div>
            </util.popUp>
            <span className="text-gray-900 text-[14px] font-medium leading-[14px]">
              {item?.fromState?.name}
            </span>
          </div>
          <div className="flex justify-between items-center  ">
            <span className="text-[12px] leading-[12px] font-normal text-[#111928] ">
              {moment(item?.timestamps?.created_at).format("MMM D, YYYY")}
            </span>

            <div className=" text-gray-600 text-[12px] font-normal  leading-[12px]">
              {moment(nullHandle(item, item?.timestamps?.created_at)).format(
                "h:mm a"
              )}
            </div>
          </div>
        </div>
      ),
      "order date": (item) => (
        <util.dateDisplay item={item} date={item?.order?.date} />
      ),
      "order id": (item) => (
        <div
          className={
            "flex flex-col gap-[0px] relative justify-start  w-full pr-8"
          }
        >
          <OrderId
            callBack={callBack}
            orderId={item?.order?.id}
            order={{
              shipment_labels_count: item?.shipment_labels_count,
              order_number: item?.order?.order_number?.[0] || "",
              marketplace: item?.sales_channel?.channel?.marketplace,
              ...item?.sales_channel?.account,
            }}
          />
        </div>
      ),
      "tracking id": (item) => (
        <div className={"flex  justify-start !w-full flex-1"}>
          <ShipmentLabel shipment={item} callBack={callBack} />
        </div>
      ),
      COST: (item) => (
        <Numbers
          data={item?.shipping_cost}
          processFunction={priceHandler}
          currency={{ symbol: "$", position: 1 }}
          color={"text-red-700"}
        />
      ),

      weight: (item) => (
        <div className={"flex flex-col  gap-[0px]"}>
          <Weight
            weight={{
              billable: item?.weight?.billable_weight
                ? (+item?.weight?.billable_weight).toFixed(2)
                : "-",
              actual: item?.weight?.actual_weight
                ? (+item?.weight?.actual_weight).toFixed(2)
                : "-",
              dim: item?.weight?.dim_weight
                ? (+item?.weight?.dim_weight).toFixed(2)
                : "-",
              selected: item?.weight?.selected_weight || null,
              is_billable_weight_manually_updated:
                item?.weight?.is_billable_weight_manually_updated,
              orderId: item?.weight?.id,
            }}
          />
        </div>
      ),
      "ship to": (item) => (
        <div className={"flex flex-col gap-[8px] relative w-full"}>
          <Components.shipTo
            state={item?.ship_to?.state}
            name={item?.ship_to?.name}
            city={item?.ship_to?.city}
            country={item?.ship_to?.country}
            // customerOrderCount = {item?.custome?._order_count}
            callBack={callBack}
          />
        </div>
      ),
    },
  };
};
export default tableView;
