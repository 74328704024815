import * as Yup from "yup";
import moment from "moment/moment";


const formView = (data) => ([
    {
        name: "order_id",
        label: "Order ID",
        placeholder: "Enter order id",
        type: "Input",
        defaultValue: data?.order_id || null, //|| data?.order_number || null,
        validation: Yup.string().required('Order id is required'),
        innerClassName: " h-[36px] bg-[rgba(0,0,0,0.03)]",
        clear: false,
        reset: false,
        disabled: data?._data?.isView,
    }, {
        name: "sales_channel_id",
        label: "Sales Channel",
        placeholder: "Select channel",
        type: "Select",
        innerClassName: "flex flex-col ",
        api: {
            url: '/api/v1/channel?filter[designation]=TARGET',
            view: '/api/v1/channel',
            query: '&filter[name]={query}',
            optionValue: (item) => ({
                value: item.id,
                label: item.name
            })
        },
        style: {
            control: {
                minHeight: "auto !important",
                background: "rgba(0,0,0,0.03)",
                height: "36px",
                paddingTop: "0px",
                paddingBottom: "0px"
            },
            menu: {
                whiteSpace: 'wrap !important',
            },
        },
        clear: false,
        reset: false,
        defaultValue: data?.sales_channel_id || null, //|| data?.sales_channel?.id || null,
        validation: Yup.string().required('Sales channel is required'),
    }, {
        name: "sales_channel_account_id",
        label: "Account",
        placeholder: "Select account",
        type: "Select",
        innerClassName: "flex flex-col ",
        api: {
            url: `/api/v1/accounts?filter[channel]=${data?.sales_channel_id || '{sales_channel_id}'}&filter[status]=active`,
            query: '&filter[name]={query}',
            view: '/api/v1/accounts',
            optionValue: (item) => ({
                value: item.id,
                label: item.name

            })
        },
        style: {
            control: {
                minHeight: "auto !important",
                background: "rgba(0,0,0,0.03)",
                height: "36px",
                paddingTop: "0px",
                paddingBottom: "0px"
            },
            menu: {
                whiteSpace: 'wrap !important',
            },
        },
        clear: false,
        reset: false,
        defaultValue: data?.sales_channel_account_id || null, //|| data?.sales_channel_account?.id || null,
        validation: Yup.string().required('Sales Account is required'),
    },
    {
        name: "order_date",
        label: "Order Date",
        placeholder: "Select order date",
        type: "Date",
        defaultValue: data?.order_date ? moment(data?.order_date).format('YYYY-MM-DD') : null,
        validation: Yup.string().required('Order Date is required'),
        innerClassName: "h-[36px] bg-[rgba(0,0,0,0.03)]",
        clear: false,
        reset: false
    },
    // {
    //     name: "ship_by_date",
    //     label: "Ship By Date",
    //     placeholder: "Enter order id",
    //     type: "Date",
    //     defaultValue: data?.ship_by_date? moment(data?.ship_by_date).format('YYYY-MM-DD') : null,
    //     validation: Yup.string().required('Ship by date is required'),
    //     innerClassName: "h-[36px] bg-gray-50",
    //     clear: false,
    //     reset: false
    // },
    // {
    //     name: "shipping_paid",
    //     label: "Shipping Paid" ,
    //     placeholder: "00.00",
    //     defaultValue: String(data?.shipping_paid) === "0" ? 0 : data?.shipping_paid || null,
    //     validation: Yup.string().required('Shipping paid is required'),
    //     type: "Module",
    //     price: 10,
    //     module: ShippingPaid,
    //     reset: false,
    //     clear: false,
    // },
    // {
    //     name: "shipping_paid_currency",
    //     label: "Currency",
    //     placeholder: "Currency",
    //     type: "Input",
    //     defaultValue: data?.shipping_paid_currency || null , //|| data?.shipping_paid_currency?.id || null,
    //     validation: Yup.string().required('Currency is required'),
    //     innerClassName: "h-[36px] bg-gray-50",
    //     className: "hidden",
    //     clear: false,
    //     reset: false
    // }
])

export default formView

// const ShippingPaid = ({data, item, change, bind, binded, validation}) => {
//     const [price, setPrice] = useState(null)
//     return (
//         <div className={"flex flex-row justify-end w-full h-full !min-w-full block"}>
//             <UI.Number
//                 item={{
//                     name: "shipping",
//                     label: "Shipping",
//                     placeholder: "00.00",
//                     type: "Input",

//                     validation: Yup.string().required('Order id is required'),
//                     innerClassName: "w-[83px] h-[36px] bg-gray-50 relative left-[8px] hover:z-10 focus:z-10",
//                     clear: false
//                 }}
//                 defaultValue={data?.shipping_paid || null}
//                 validationMessage={validation?.shipping_paid}
//                 handleChange={(data) => {
//                     change(data,"shipping_paid")
//                 }}
//             />

//             <UI.Select
//                 validationMessage={validation?.shipping_paid_currency}
//                 item={{
//                     name: "shipping_paid_currency",
//                     api: {
//                         url: '/api/v1/currencies',
//                         query: '?filter[name]={query}',

//                         optionValue: (item) => ({
//                             value: item?.id,
//                             label: upperCaseString(item?.code, "word"),
//                         })
//                     },

//                     style: {
//                         control: {
//                             minHeight: "auto !important",
//                             background: "rgb(249 250 251)",
//                             height: "36px",
//                             paddingTop: "0px",
//                             paddingBottom: "0px"
//                         },
//                         menu: {
//                             whiteSpace: 'wrap !important',
//                         },
//                     },
//                     placeholder: "Currency",
//                     className: "!min-h-[32px] !min-w-[70px] relative left-[8px]"
//                 }}
//                 value={data?.shipping_paid_currency}
//                 handleChange={(data) => {
//                     change(data,"shipping_paid_currency")
//                 }}
//                 bind={(data) => {
//                     bind(data,"shipping_paid_currency")
//                 }}
//             />
//         </div>
//     )
// }