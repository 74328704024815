import React from "react";
import upperCaseString from "../../../dynamic-page/util/upperCaseString";
import util from "../../../dynamic-page/util";

const orderId = ({order, callBack, orderId}) => {
    return (
        <div className={"justify-start w-full"}>
            <div className={"flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit"}>
                {order?.channel?.country?.flag &&
                    <util.popUp
                        toggle={(selected) => (<img src={order?.channel?.country?.flag} alt={order?.name}
                                                    className={"h-[12px] w-[17px] rounded-[2px]"}
                                                    style={{objectFit: 'cover'}}/>)}
                        action={"hover"}
                    >
                        <div className={"px-[8px] py-[4px]"}> {order?.channel?.country?.name} </div>
                    </util.popUp>
                }
                {order?.channel?.marketplace?.logo &&
                    <img src={order?.channel?.marketplace?.logo} alt={order?.name} className={"h-[12px] "}/>}
                {order?.name &&
                    <span className={"text-[14px h-18px items-center flex"}>{upperCaseString(order?.name)}</span>}

            </div>
            <div
                className={"flex flex-row justify-between items-center min-w-max whitespace-no-wrap text-blue-600 text-[12px] hover:text-blue-700 cursor-pointer gap-[4px]"}
            >
                <span onClick={() => {
                    if (orderId) {
                        window.open(`/logistics/orders/#action=details&orderId=${orderId}
                    `, "_blank")
                    }
                }}>
                    {order?.order_number}
                </span>
                <div>
                    <util.copyText className={"text-[12px]"} text={order?.order_number} hint={false}/>
                </div>
            </div>
        </div>
    )
}
export default orderId;