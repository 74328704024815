import { useEffect, useState } from "react";
import constant from "../../constant";
import { HiDuplicate, HiTrash } from "react-icons/hi";
import AsyncSelect from "react-select/async";
import moment from "moment";
import axios from "axios";
import priceHandler from "../../utils/priceHandler";

const GoodsAcceptanceListItem = ({
  item,
  handleInputChange,
  handleDuplicate,
  handleRemove,
  errors,
  first,
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const loadOptionsForNewItem = async () => {
      const response = await axios.get(
        constant.APIURL.GET_SUPPLY_PURCHASES +
          "?filter[acceptable_quantity]=1&" +
          "&include=supplier,employee&" +
          "term=" +
          item.apid
      );

      const data = response?.data?.data;

      const formattedOptions = data.map((item) => ({
        value: item,
        label: `Purchased ${priceHandler(+item?.quantity)} from ${
          item?.supplier?.name
        }`,
      }));
      if (formattedOptions.length === 1) {
        handleInputChange(item.id, "purchase", formattedOptions[0]?.value);
      }
      setOptions(formattedOptions);
    };

    if (first) loadOptionsForNewItem();
  }, [item, first]);

  const onNavigateToOrders = () => {
    window.open(
      `/logistics/orders?term=${item?.apid}&limit=20&page=1`,
      "_blank"
    );
  };

  return (
    <div className="">
      <div className="relative w-full px-2 py-4 rounded-lg border border-gray-200 justify-start items-center gap-[12px] inline-flex">
        <div className="w-[800px] self-stretch p-[0px] justify-center items-center gap-2 flex">
          <div className="grow shrink basis-0 self-stretch p-[0px] bg-white flex-col justify-center items-center gap-[2px] inline-flex">
            <img
              className="max-h-[128px]"
              src={item?.image || "/assets/images/noImage.png"}
            />
          </div>
          <div className="self-stretch px-2 flex-col justify-between items-center gap-[4px] inline-flex">
            <div className="w-[637px] text-gray-900 text-[16px] font-medium leading-normal">
              {item?.title}
            </div>
            <div className="self-stretch py-[7px] justify-between items-center gap-[10px] inline-flex">
              <div className="text-blue-700 text-[14px] font-normal leading-tight">
                {item?.apid}
              </div>
              <div className="p-[0px] justify-center items-center gap-[4px] flex">
                <div className="w-[21px] h-[21px] p-[0px] rounded-2xl justify-start items-start gap-[10px] flex">
                  <img
                    className="grow shrink basis-0 self-stretch rounded-full"
                    src={item?.brand?.assigned?.profile_image}
                  />
                </div>
                <div className="text-gray-900 text-[14px] font-normal leading-tight">
                  {item?.assign?.name}
                </div>
              </div>
            </div>
            <div className=" self-stretch h-[41px] p-[0px] justify-between items-end inline-flex">
              <div className="p-[0px] flex-col justify-start items-start gap-[4px] inline-flex">
                <div className="h-[41px] py-[10px] justify-center items-center gap-[10px] inline-flex">
                  <div className="w-[300px] p-[0px] flex-col justify-start items-start gap-[12px] inline-flex">
                    <div>
                      <AsyncSelect
                        defaultOptions={options}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            item.id,
                            "purchase",
                            selectedOption.value
                          )
                        }
                        value={options.find(
                          (option) => option.value?.id === item.purchase
                        )}
                      />
                    </div>
                  </div>
                </div>

                {errors[item.id]?.purchase && (
                  <div className="pl-1 text-red-500 text-xs">
                    {errors[item.id]?.purchase}
                  </div>
                )}
              </div>
              <div className="p-[0px] justify-center items-end gap-4 flex">
                <div className="h-[41px] py-[4px] flex-col justify-between items-end gap-[10px] inline-flex">
                  <div className="text-black text-[12px] font-normal leading-3">
                    Awaiting
                  </div>
                  <div
                    className={`text-black text-[14px] font-semibold leading-tight `}
                  >
                    {item?.selectedPurchase?.acceptable_quantity}
                  </div>
                </div>
                <div className="h-[41px] py-[4px] flex-col justify-between items-end gap-[10px] inline-flex">
                  <div className="text-black text-[12px] font-normal leading-3">
                    Purchased by
                  </div>
                  <div
                    className={`text-black text-[14px] font-semibold leading-tight ${
                      !item?.selectedPurchase ? "invisible" : "visible"
                    }`}
                  >
                    {item?.selectedPurchase?.created_by?.name}
                  </div>
                </div>
                <div className="h-[41px] py-[4px] flex-col justify-between items-end gap-[10px] inline-flex">
                  <div className="text-black text-[12px] font-normal leading-3">
                    Purchased at
                  </div>
                  <div
                    className={`text-black text-[14px] font-semibold leading-tight ${
                      !item?.selectedPurchase ? "invisible" : "visible"
                    }`}
                  >
                    {moment(item?.selectedPurchase?.created_at).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch p-[0px] flex-col justify-start items-start gap-2 inline-flex">
          <div className="p-[0px] justify-start items-start gap-[10px] inline-flex">
            <div className="w-40 p-[0px] flex-col justify-start items-start gap-2 inline-flex">
              <div className="self-stretch text-gray-900 text-[14px] font-medium leading-tight">
                Accepted Quantity
              </div>
              <div className="self-stretch px-4 py-2 bg-gray-50 rounded-lg border border border border border-gray-300 justify-start items-center gap-[10px] inline-flex">
                <div class="grow shrink basis-0 h-[21px] p-[0px] justify-start items-center gap-[10px] flex">
                  <input
                    type="number"
                    placeholder="0"
                    value={item?.quantity || ""}
                    className="w-full grow border-none bg-transparent shrink basis-0 text-gray-500 text-[14px] font-normal leading-tight"
                    onChange={(e) =>
                      handleInputChange(item.id, "quantity", e.target.value)
                    }
                  />
                </div>
              </div>

              {errors[item.id]?.quantity && (
                <div className="text-red-500 text-xs">
                  {errors[item.id]?.quantity}
                </div>
              )}
            </div>
            <div className="w-40 p-[0px] flex-col justify-start items-start gap-2 inline-flex">
              <div className="self-stretch text-gray-900 text-[14px] font-medium leading-tight">
                Exp. Date (optional)
              </div>
              <div className="self-stretch px-4 py-2 bg-gray-50 rounded-lg border border border border border-gray-300 justify-start items-center gap-[10px] inline-flex">
                <div className="grow shrink basis-0 h-[21px] p-[0px] justify-start items-center gap-[10px] flex">
                  <input
                    type="date"
                    placeholder="00.00.0000"
                    value={item?.expiry_date || ""}
                    onChange={(e) =>
                      handleInputChange(item.id, "expiry_date", e.target.value)
                    }
                    className="w-full grow border-none bg-transparent shrink basis-0 text-gray-500 text-[14px] font-normal leading-tight"
                  />

                  <div className="w-[12px] h-[12px] relative" />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch grow shrink basis-0 p-[0px] justify-start items-start gap-[10px] inline-flex">
            <div className="grow shrink basis-0 self-stretch p-[0px] flex-col justify-start items-start gap-2 inline-flex">
              <div className="self-stretch grow shrink basis-0 px-4 py-2 bg-gray-50 rounded-lg border border border border border-gray-300 justify-start items-start gap-[10px] inline-flex">
                <div className="grow shrink basis-0 h-[65px] p-[0px] justify-start items-start gap-[10px] flex">
                  <textarea
                    rows="3"
                    placeholder="Note"
                    className="grow bg-transparent border-none shrink basis-0 text-gray-500 text-[14px] font-normal leading-tight"
                    value={item?.note || ""}
                    onChange={(e) =>
                      handleInputChange(item.id, "note", e.target.value)
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow shrink basis-0 self-stretch px-[10px] flex-col justify-between items-start gap-4 inline-flex">
          <div className="self-stretch p-[0px] justify-end items-start gap-[4px] inline-flex">
            <button>
              <HiDuplicate
                onClick={() => handleDuplicate(item?.id)}
                className="text-gray-900 w-6 h-6 cursor-pointer relative"
              />
            </button>
            <button>
              <HiTrash
                onClick={() => handleRemove(item?.id)}
                className="text-red-700 w-6 h-6 cursor-pointer relative"
              />
            </button>
          </div>
          <div className="self-stretch">
            {item?.selectedPurchase?.created_by?.first_name && (
              <span className="text-black text-[14px] font-bold leading-tight">
                Comment By:
              </span>
            )}
            <span className="ml-4 ext-black text-[14px] font-semibold leading-tight">
              {item?.selectedPurchase?.created_by?.first_name}{" "}
              {item?.selectedPurchase?.created_by?.last_name}
            </span>
            {item?.selectedPurchase && (
              <span className="text-black text-[14px] font-normal leading-tight">
                <br />
                {item?.selectedPurchase?.note || ""}
              </span>
            )}
          </div>
          <div className="self-stretch text-right text-red-700 text-[14px] font-normal leading-tight">
            {item?.awaiting_shipment_orders_count || 0} Related Orders Found!{" "}
            {item?.awaiting_shipment_orders_count > 0 && (
              <span className="cursor-pointer" onClick={onNavigateToOrders}>
                Go to Orders
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodsAcceptanceListItem;
