const sortView = ({ callback }) => {
  return {
    list: [
      {
        label: "Created At",
        value: "created_at",
        onClick: (item) => {
          callback("created_at");
        },
      },
      {
        label: "Product",
        value: "product",
        onClick: (item) => {
          callback("product");
        },
      },
      {
        label: "Discount",
        value: "discount",
        onClick: (item) => {
          callback("discount");
        },
      },
      {
        label: "Updated At",
        value: "updated_at",
        onClick: (item) => {
          callback("updated_at");
        },
      },
      {
        label: "Supplier",
        value: "supplier",
        onClick: (item) => {
          callback("supplier");
        },
      },
      {
        label: "Created By",
        value: "created_by",
        onClick: (item) => {
          callback("created_by");
        },
      },

      {
        label: "Purchase Price",
        value: "purchase_price",
        onClick: (item) => {
          callback("purchase_price");
        },
      },
      {
        label: "Quantity",
        value: "quantity",
        onClick: (item) => {
          callback("quantity");
        },
      },
      {
        label: "Subtotal",
        value: "subtotal",
        onClick: (item) => {
          callback("subtotal");
        },
      },
      {
        label: "Total",
        value: "total",
        onClick: (item) => {
          callback("total");
        },
      },
      {
        label: "Total Due",
        value: "total_due",
        onClick: (item) => {
          callback("total_due");
        },
      },
    ],
  };
};

export default sortView;
