import {createAsyncThunk} from "@reduxjs/toolkit";
import api from './api';

const fetchSupplyChannels = createAsyncThunk(
    'get:api/v1/channel',
    async (params, thunkAPI) => {
        const response = await api.fetchSupplyChannels(params)
        // console.log(response)
        return response.data
    }
)

const fetchSupplyChannelFilters = createAsyncThunk(
    'get:api/v1/channel/filters',
    async (params, thunkAPI) => {
        const response = await api.fetchSupplyListingFilters(params)
        // console.log(response)
        return response.data
    }
)

export {
    fetchSupplyChannels,
    fetchSupplyChannelFilters
}
