// ------------------------------
// Product Summary
// ------------------------------

// Import main libraries
import React from "react";
import usePage from "../dynamic-page/util/pageControl";

// Import model-view libraries
import tableView from "./models-view/table";
import filtersView from "./models-view/filters";
import sortView from "./models-view/sort";
import tabsView from "./models-view/tabs";
import actionsView from "./models-view/actions";
import addForm from "./models-view/addForm";
import optionsView from "./models-view/options";
import detailsOptionsView from "./models-view/detailsOptionsView";
import productComponentFormView from "./models-view/connectForm";

// Main Component
const ProductList = ({ product_id, type, components, extra }) => {
  // Initial Page
  const Page = usePage({
    pageTitle: "Products",
    api: {
      fetch: "product",
      filters: "product/filters",
      query: "include=brand.assigned",
    },
    ModelsView: {
      table: tableView,
      filter: filtersView,
      sort: sortView,
      tabs: tabsView,
      actions: actionsView,
      headerOption: optionsView,
      detailsOptions: product_id && type === 1 ? detailsOptionsView : [],
      forms: {
        add: addForm,
        connect: productComponentFormView,
      },
    },
    pageSettings: {
      ignoredFilters: ["type", "summaryType", "product_id"],
      product: {
        product_id: product_id,
        hideHeaddings: product_id ? true : false,
        filter:
          type === 2
            ? { type: type, containingProduct: product_id }
            : { type: type, parentId: product_id },
      },
      tableProps: { components },
      extra: extra,
    },
  });

  // return View
  return Page;
};

export default ProductList;
