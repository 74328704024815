import summary from "./summary"
import recipient from "./recipient"
import Icon from "../../icons";
import React from "react";
import comments from "./comments"

export default {
    summary,
    recipient,
    comments
}


const tabList = (isView) => isView ? [
    {id: "summary", name: "Summary", },
    { id: "recipient", name: "Recipient", },
    { id : "comments", name: "Comments"},
] : [
    {id: "summary", name: "Summary",},
    { id: "recipient", name: "Recipient"  },
]
export {tabList}