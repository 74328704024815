import axios from '../../middleware/axios';
import constants from '../../constant';

const fetchSalesListings = async (params) => {
    let url = constants.APIURL.GET_SALESLISTINGS
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    //console.log(response)
    return response
}
const fetchSalesListing = async (id) => {
    const response = await axios.get(constants.APIURL.GET_SALES_LISTING + id, {withCredentials: true});
    return response;
}

const fetchEmployees = async () => {
    const response = await axios.get(constants.APIURL.GET_EMPLOYEE, {withCredentials: true})

    return response
}

const fetchSalesListingsFilter = async (params) => {
    let url = constants.APIURL.GET_SALESLISTINGS_FILTERS
    if (params)
        url = url.concat(params)

    // url = url.concat('&filter[available]=');

    const response = await axios.get(url, {withCredentials: true})
    return response
}

const fetchOffersFilters = async (params) => {
    let url = 'api/v1/offers';
    if (params) url = url.concat(params).concat('&filter[identifier]=');
    const response = await axios.get(url, {withCredentials: true})
    return response
}

// const SearchSalesLintings = async (params) => {
//   let url = constants.APIURL.GET_SALESLISTINGS
// }

const updateSalesListingFulfilment = async (params) => {
    let url = constants.APIURL.GET_SALESLISTINGS + `/toggle-eligibility?fulfillment=${params?.type}`
    const response = await axios.put(url, {ids: params?.payload}, {withCredentials: true})

    return response;

}

const connectProduct = async (payload) => {
    const response = await axios.post(constants.APIURL.GET_SALESLISTINGS + '/connect/' + payload?.listId + '/product/' + payload?.productId, {withCredentials: true})
    return response
}
const disconnectProduct = async (payload) => {
    const response = await axios.post(constants.APIURL.GET_SALESLISTINGS + '/connect/' + payload?.listId + '/product', {withCredentials: true})
    return response
}

// const fetchTargetSellerListingsFilters = async (params) => {
//   let url = constants.APIURL.GET_TARGETSELLERLISTINGS_FILTERS
//   if (params)
//     url = url.concat(params)
//   const response = await axios.get(
//       url,
//       { withCredentials: true }
//     )
//   return response
// }

let urlNote = 'api/v1/account-listing';

const fetchNotes = async (params) => {
    const url = `${urlNote}/${params?.orderId}/notes`;
    return await axios.get(url, {withCredentials: true,})
}

const deleteNote = async (params) => {
    const url = `${urlNote}/${params.orderId}/notes/${params?.noteId}`;
    return await axios.delete(url, {withCredentials: true,});
}

const addNote = async (params) => {
    const url = `${urlNote}/${params?.orderId}/note`;
    return await axios.post(url, {note: params?.note}, {withCredentials: true,});
}

export default {
    fetchSalesListings,
    updateSalesListingFulfilment,
    fetchSalesListingsFilter,
    fetchEmployees,
    fetchSalesListing,
    connectProduct,
    disconnectProduct,
    fetchNotes,
    deleteNote,
    addNote,
    fetchOffersFilters
}
