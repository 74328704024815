import React from "react";
import icons from "../../icons";
import {HiExclamationCircle, HiEyeOff} from "react-icons/hi";
import Util from "../../../dynamic-page/util";
import util from "../../../dynamic-page/util";
import StatusHistory from "./statusHistory";

const orderStatusStyle = {
    content: "",
    width: "100%",
    position: "absolute",
    height: "5.1px",
    background: "#FDE8E8",
    zIndex: 0,
    boxShadow:
        "0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
};

const iconStyle = {
    boxShadow:
        "0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
    borderRadius: "100%", // You can adjust the value as needed
};

const OrderStatus = ({
                         item,
                         color = "gray",
                         text,
                         callBack,
                         orderId,
                         refund_amount,
                         type,
                         order,
                         isAwaiting,
                         statusId: status
                     }) => {
    const disableCheck = (item) => {
        return false;
        if (!item?.product_connected) {
            return true;
        } else {
            let flag = false;
            item?.order?.order_items?.forEach((orderItem) => {
                if (
                    orderItem?.arbitbox_product?.fbm_actual_weight === null ||
                    orderItem?.arbitbox_product?.fbm_dimensional_weight === null
                ) {
                    flag = true;
                }
            });
            return flag;
        }
    };

    const generateStatusIcon = (
        iconColor,
        iconComponent,
        tooltip,
        border,
        hideShadow
    ) => (
        <div className="w-8 h-8 justify-center items-center flex">
            <div
                className={`grow shrink ${
                    type !== "return" && type !== "notification" && !hideShadow
                        ? "shadow-md"
                        : ""
                } basis-0 self-stretch rounded-[50px] flex-col justify-center items-center inline-flex bg-${iconColor}-100 ${
                    border && "border-2 border-" + iconColor + "-700"
                }`}
            >
                {tooltip ? (
                    <Util.popUp toggle={() => iconComponent} action={"click"}>
                        {tooltip}
                    </Util.popUp>
                ) : (
                    iconComponent
                )}
            </div>
        </div>
    );

    const generateDivider = (dividerColor = "gray") => (
        <div
            className={`grow shrink shadow-md basis-0 h-1.5 bg-${dividerColor}-100`}
        />
    );

    const Wrapper = ({children}) => (
        <Util.popUp
            toggle={() => (
                <div className="w-[140px] h-8  justify-center items-start inline-flex">
                    <div className="grow shrink basis-0 h-8 justify-center items-center flex">
                        <>{children}</>
                    </div>
                </div>
            )}
            action={"hover"}
            forcePosition={"top"}
        >
            {text}
        </Util.popUp>
    );

    const primary_label = item?.order?.shipment?.shipment_labels?.find(
        (label) => label?.is_primary
    )?.tracking_number;

    const tooltip = (
        <StatusHistory
            product={item?.order?.first_item?.arbitbox_product?.id}
            isAwaiting={isAwaiting}
            id={orderId}
            primary_label={primary_label}
        />
    );
    const notificationsTooltip = <p>{text}</p>;

    const handlePrintLabel = (id) => {
        if (disableCheck(item)) {
            util.notifier({
                type: "info",
                message:
                    "Please fill in the FBA/FBM weight and dimensions for all products connected to the order before mark as shipped. Missing weight will block the order from being shipped.",
            });
            return;
        }
        callBack({
            action: "markAsShipped",
            data: {
                id: id,
                modal: true,
            },
        });
    };

    const firstIcon = ({item}) => {
        if (status !== 14) {
            return generateStatusIcon(
                "green",
                <icons.link2 className="text-green-600"/>,
                tooltip
            );
        } else {
            return generateStatusIcon(
                "gray",
                <icons.link2 className="text-gray-600"/>,
                tooltip
            );
        }
    };

    const secondIcon = ({item}) => {
        if (status === 2 || status === 10 | (text === "Awaiting Label")) {
            return generateStatusIcon(
                "green",
                <icons.cube className="text-green-600"/>,
                tooltip
            );
        } else {
            return generateStatusIcon(
                "gray",
                <icons.cube className="text-gray-600"/>,
                tooltip
            );
        }
    };
    const fourthIcon = ({item}) => {
        if (status === 1 | (text === "Awaiting Label")) {
            return generateStatusIcon(
                "gray",
                <icons.receipt_percent className="text-gray-600" />,
                tooltip
            );
        } else {
            return
        }
    };

    const thirdIcon = ({item}) => {
        if (status === 10) {
            return generateStatusIcon(
                "green",
                <icons.truck className="text-green-600"/>,
                tooltip
            );
        } else if (status === 2) {
            return generateStatusIcon(
                disableCheck(item) ? "gray" : "blue",
                <icons.truck
                    className={` ${
                        disableCheck(item)
                            ? "text-gray-300 "
                            : "text-blue-600 cursor-pointer"
                    } `}
                    onClick={() => handlePrintLabel(orderId)}
                />,
                null,
                !disableCheck(item)
            );
        } else if (!item?.ready_to_ship && !item?.shipped) {
            return generateStatusIcon(
                "gray",
                <icons.truck className="text-gray-600"/>,
                tooltip
            );
        }
    };

    let statusId = status === 5
        ? 5
        : status === 9
            ? 4
            : status === 6
                ? 2
                : 1;
    if (type === "return") {
        refund_amount > 0 ? (statusId = 2) : (statusId = 3);
    }
    if (type === "notification") {
        if (order?.is_cancelled && order?.cancel_requested) {
            statusId = 6;
        } else if (!order?.is_cancelled && order?.cancel_requested) {
            statusId = 7;
        } else {
            statusId = 8;
        }
    }

    const statusList = {
        //awaiting
        1: (
            <Wrapper>
                {firstIcon({item})}
                {generateDivider(item && item?.ready_to_ship ? "green" : "gray")}
                {secondIcon({ item })}
                {status === 1 && generateDivider( "gray")}
                {status === 1 && fourthIcon({ item })}
                {generateDivider(item && item?.shipped ? "green" : "gray")}
                {thirdIcon({item})}
            </Wrapper>
        ),

        6: (
            <Wrapper>
                {generateStatusIcon(
                    "red",
                    <HiExclamationCircle className="text-red-600 w-5 h-5"/>,
                    notificationsTooltip
                )}
                {generateDivider("red")}
                {generateStatusIcon(
                    "red",
                    <icons.xMark className="text-red-600  w-3 h-3"/>,
                    notificationsTooltip
                )}
            </Wrapper>
        ),
        // shipped
        // 10: (
        //   <Wrapper>
        //     {generateStatusIcon(
        //       "green",
        //       <icons.link2 className="text-green-600" />
        //     )}
        //     {generateDivider("green")}
        //     {generateStatusIcon("green", <icons.cube className="text-green-600" />)}
        //     {generateDivider("green")}
        //     {generateStatusIcon(
        //       "green",
        //       <icons.truck className="text-green-600" />
        //     )}
        //   </Wrapper>
        // ),
        4: (
            <Wrapper>
                <div className=" h-8 py-1.5 bg-purple-100 rounded-[50px] justify-center items-center w-[96px] flex">
                    {generateStatusIcon(
                        "purple",
                        <HiEyeOff className="text-purple-600  w-5 h-5"/>,
                        tooltip,
                        null,
                        true
                    )}
                </div>
            </Wrapper>
        ),
        5: (
            <Wrapper>
                <div className=" h-8 py-1.5 bg-rose-100 rounded-[50px] justify-center items-center w-full flex">
                    {generateStatusIcon(
                        "red",
                        <icons.xMark className="text-red-600  w-3 h-3"/>,
                        tooltip
                    )}
                </div>
            </Wrapper>
        ),
        7: (
            <Wrapper>
                <div className="  h-8 py-1.5 bg-rose-100 shadow rounded-[50px] justify-center items-center w-full flex">
                    {generateStatusIcon(
                        "red",
                        <HiExclamationCircle className="text-red-600  w-5 h-5"/>,
                        notificationsTooltip
                    )}
                </div>
            </Wrapper>
        ),
        8: (
            <Wrapper>
                <div className=" h-8 py-1.5 bg-gray-100 shadow rounded-[50px] justify-center items-center w-full flex">
                    {generateStatusIcon(
                        "gray",
                        <HiExclamationCircle className="text-gray-600  w-5 h-5"/>,
                        notificationsTooltip
                    )}
                </div>
            </Wrapper>
        ),
        2: (
            <Wrapper>
                <div
                    className=" h-8 py-1.5 bg-red-100 shadow rounded-[50px] justify-center items-center w-[96px]  flex">
                    {generateStatusIcon(
                        "red",
                        <icons.cash className="text-red-600  "/>,
                        text === "Refunded" ? tooltip : notificationsTooltip,
                        null,
                        true
                    )}
                </div>
            </Wrapper>
        ),
        3: (
            <Wrapper>
                <div
                    className=" h-8 py-1.5 bg-yellow-100 shadow rounded-[50px] justify-center items-center w-full flex">
                    {generateStatusIcon(
                        "yellow",
                        <icons.cash className="text-yellow-600  "/>,
                        notificationsTooltip
                    )}
                </div>
            </Wrapper>
        ),
    };
    if (statusId) return statusList[statusId];
};

export default OrderStatus;
