import upperCaseString from "../../../../dynamic-page/util/upperCaseString";

const combinedWarehouseSelect = (order, shipmentItem, label) => [
  {
    label: 'Ships from',
    name: "warehouse",
    type: "Select",
    api: {
      url: "/api/v1/warehouses?include=state",
      //url: '/api/v1/warehouses',
      skipCancel: true,
      query: "&filter[name]={query}",
      view: "/api/v1/warehouses",
      optionValue: (item) => ({
        value: upperCaseString(item?.name, "word", true) + `id:${item?.id}`,
        label: upperCaseString(item?.name, "word", true),
      }),
      ...(label && { list: [{ label: "Default", value: null }] }),
    },
    className: "h-[42px]",
    style: {
      control: {
        height: "42px !important",
        minHeight: "auto",
        // marginTop: '-9px !important',
        width: "220px !important",
        minWidth: "220px !important",
        background: "#F9FAFB",
      },
      menu: {
        whiteSpace: "wrap !important",
      },
      valueContainer: {
        width: "174px !important",
        minWidth: "174px !important",
      },
      singleValue: {
        fontSize: "14px !important",
        width: "154px !important",
        minWidth: "154px !important",
      },
    },
    // defaultValue: shipmentItem?.warehouseId ||  null,
    defaultBound: order?.defaultWarehouse === 1 ? {
      id: 1,
      name: 'Istanbul Warehouse'
    } : {
      id: 2,
      name: 'Pegasus Warehouse'
    },
    reset: false,
    clear: false,

    placeholder: "Select Warehouse",
  },
  {
    label: 'on',
    name: "onWarehouse",
    type: "Select",
    api: {
      url: "/api/v1/warehouses?include=state",
      skipCancel: true,
      url2: "/api/v1/pickup-points?include=state",
      query: "&filter[name]={query}",
      view: "/api/v1/warehouses",
      optionValue: (item) => ({
        // value: item?.id,
        value: upperCaseString(item?.name, "word", true) + `id:${item?.id}`,
        label: upperCaseString(item?.name, "word", true),
      }),
      optionValue2: (item) => ({
        type: "pickup",
        value: upperCaseString(item?.name, "word", true) + `id:${item?.id}`,
        label: upperCaseString(item?.name, "word", true),
      }),
      ...(label && { list: [{ label: "Default", value: null }] }),
    },
    className: "h-[42px]",
    style: {
      control: {
        height: "42px !important",
        minHeight: "auto",
        // marginTop: '-9px !important',
        width: "200px !important",
        minWidth: "200px !important",
        background: "#F9FAFB",
      },
      menu: {
        whiteSpace: "wrap !important",
      },
      valueContainer: {
        width: "174px !important",
        minWidth: "174px !important",
      },
      singleValue: {
        width: "154px !important",
        fontSize: "14px !important",
        minWidth: "154px !important",
      }
    },
    defaultBound: order?.defaultWarehouse === 1 ? {
      id: 1,
      name: 'Istanbul Warehouse'
    } : {
      id: 2,
      name: 'Pegasus Warehouse'
    },
    reset: false,
    clear: false,

    placeholder: "Select Warehouse",
  },
];

export default combinedWarehouseSelect;
