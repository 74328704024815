import React from "react";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import {useSelector} from "react-redux";
import constants from "../../../constant";

const CustomFiltersView = (selectedFilters, boundFilters) => {
    const filtersMeta = useSelector((state) => state.salesListing?.filtersMeta);
    return [
        {
            name: "identifier",
            label: "Identifier",
            placeholder: "Filter by identifier",
            type: "Input",
            toggle: true,
            isClearable: true,
            defaultValue: selectedFilters?.identifier,
        },
        {
            name: "channel",
            label: "Channel",
            placeholder: "Filter by Channel",
            type: "Select",
            api: {
                url: "/api/v1/channel",
                query: "?term={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: item?.name,
                    logo: item?.logo,
                }),
            },
            features: {
                isMulti: true,
                formatOptionLabel: ({label, logo}) => (
                    <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
                className={
                    "w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "
                }
            >
              <img src={logo || "/assets/images/img-placehoder.png"}/>
            </span>
                        <span>{upperCaseString(label)}</span>
                    </div>
                ),
            },
            defaultValue: selectedFilters?.channel,
            toggle: true,
            clear: true,
        },
        {
            name: "account",
            label: "Account",
            placeholder: "Filter by account",
            type: "Select",
            api: {
                url: "/api/v1/accounts?filter[status]=active",
                view: "/api/v1/accounts",
                query: "&filter[name]={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: item?.name,
                    avatar: item?.channel?.logo,
                }),
            },
            features: {
                isMulti: true,
                formatOptionLabel: ({label, avatar}) => (
                    <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
                className={
                    "w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "
                }
            >
              <img src={avatar || "/assets/images/img-placehoder.png"} className="object-contain w-full h-full"/>
            </span>
                        <span>{upperCaseString(label)}</span>
                    </div>
                ),
            },
            defaultValue: selectedFilters?.account,
            // defaultBound: boundFilters?.account || null,
            toggle: true,
            clear: true,
        },
        {
            name: "brand",
            label: "Brand",
            placeholder: "Filter by Brand",
            type: "Input",
            toggle: true,
            isClearable: true,
            defaultValue: selectedFilters?.brand,
        },
        {
            name: "available",
            label: "Available Sales Listing",
            placeholder: "Filter by listing availability",
            type: "RadioButton",
            list: filtersMeta?.availability?.map((item) => ({
                value: item?.id,
                label: item?.name === "available" ? "Available" : "Not Available",
                count: item?.count,
            })),

            // list: [
            //   {
            //     label: "Available",
            //     value: 1,
            //   },
            //   {
            //     label: "Not Available",
            //     value: 0,
            //   }
            // ],

            defaultValue: selectedFilters?.available,
            toggle: true,
            radioContainerClass: "!flex-col",
        },
        {
            name: "by_wh_eligibility",
            label: "WH Eligibility",
            placeholder: "Filter by WH Eligibility",
            type: "RadioButton",
            list: [
                {value: null, label: "All"},
                {value: "1", label: "Eligible"},
                {value: "0", label: "Not Eligible"},
            ],
            defaultValue: selectedFilters?.by_wh_eligibility || null,
            toggle: true,
            radioContainerClass: "!flex-col",
        },
        {
            name: "by_fc_eligibility",
            label: "FC Eligibility",
            placeholder: "Filter by FC Eligibility",
            type: "RadioButton",
            list: [
                {value: null, label: "All"},
                {value: "1", label: "Eligible"},
                {value: "0", label: "Not Eligible"},
            ],
            defaultValue: selectedFilters?.by_fc_eligibility || null,
            toggle: true,
            radioContainerClass: "!flex-col",
        },

        {
            name: "review_count",
            label: "Review Count",
            placeholder: "Filter by Review Count",
            type: "Range",
            defaultValue: selectedFilters?.review_count,
            toggle: true,
            clear: true,
        },
        {
            name: "rating",
            label: "Rating",
            placeholder: "Filter by Rating",
            type: "Range",
            defaultValue: selectedFilters?.rating,
            toggle: true,
            clear: true,
        },
        {
            name: "variant_count",
            label: "Variant Count",
            placeholder: "Filter by Variant Count",
            type: "Range",
            defaultValue: selectedFilters?.variant_count,
            toggle: true,
            clear: true,
        },
        {
            name: "fba_offers_count",
            label: "FBA Offers Count",
            placeholder: "Filter by FBA Offers Count",
            type: "Range",
            defaultValue: selectedFilters?.fba_offers_count,
            toggle: true,
            clear: true,
        },
        {
            name: "fbm_offers_count",
            label: "FBM Offers Count",
            placeholder: "Filter by FBM Offers Count",
            type: "Range",
            defaultValue: selectedFilters?.fbm_offers_count,
            toggle: true,
            clear: true,
        },
        {
            name: "sales_rank",
            label: "Sales Rank",
            placeholder: "Filter by Sales Rank",
            type: "Range",
            defaultValue: selectedFilters?.sales_rank,
            toggle: true,
            clear: true,
        },
        {
            name: "bb_oos_90",
            label: "BB OOS 90",
            placeholder: "Filter by BB OOS 90",
            type: "Range",
            defaultValue: selectedFilters?.bb_oos_90,
            toggle: true,
            clear: true,
        },
        {
            name: "velocitied_daily_sales",
            label: "Velocitied Daily Sales",
            placeholder: "Filter by Velocitied Daily Sales",
            type: "Range",
            defaultValue: selectedFilters?.velocitied_daily_sales,
            toggle: true,
            clear: true,
        },
        {
            name: "velocitied_daily_sales_value",
            label: "Velocitied Daily Sales Value",
            placeholder: "Filter by Velocitied Daily Sales Value",
            type: "Range",
            defaultValue: selectedFilters?.velocitied_daily_sales_value,
            toggle: true,
            clear: true,
        },
        {
            name: "velocitied_monthly_sales_value",
            label: "Velocitied Monthly Sales Value",
            placeholder: "Filter by Velocitied Monthly Sales Value",
            type: "Range",
            defaultValue: selectedFilters?.velocitied_monthly_sales_value,
            toggle: true,
            clear: true,
        },
        {
            name: "buybox_price",
            label: "Buybox Price",
            placeholder: "Filter by Buybox Price",
            type: "Range",
            defaultValue: selectedFilters?.buybox_price,
            toggle: true,
            clear: true,
        },
        {
            name: "fba_price",
            label: "FBA Price",
            placeholder: "Filter by FBA Price",
            type: "Range",
            defaultValue: selectedFilters?.fba_price,
            toggle: true,
            clear: true,
        },
        {
            name: "fbm_price",
            label: "FBM Price",
            placeholder: "Filter by FBM Price",
            type: "Range",
            defaultValue: selectedFilters?.fbm_price,
            toggle: true,
            clear: true,
        },
        {
            name: "fba_inbound",
            label: "FBA Inbound",
            placeholder: "Filter by FBA Inbound",
            type: "Range",
            defaultValue: selectedFilters?.fba_inbound,
            toggle: true,
            clear: true,
        },
        {
            name: "fba_reserved_qty",
            label: "FBA Reserved Qty",
            placeholder: "Filter by FBA Reserved Qty",
            type: "Range",
            defaultValue: selectedFilters?.fba_reserved_qty,
            toggle: true,
            clear: true,
        },
        {
            name: "fba_available",
            label: "FBA Available",
            placeholder: "Filter by FBA Available",
            type: "Range",
            defaultValue: selectedFilters?.fba_available,
            toggle: true,
            clear: true,
        },
        {
            name: "fbm_available",
            label: "FBM Available",
            placeholder: "Filter by FBM Available",
            type: "Range",
            defaultValue: selectedFilters?.fbm_available,
            toggle: true,
            clear: true,
        },
        {
            name: "fba_restock",
            label: "FBA Restock",
            placeholder: "Filter by FBA Restock",
            type: "Range",
            defaultValue: selectedFilters?.fba_restock,
            toggle: true,
            clear: true,
        },
        {
            name: "fba_stock_left",
            label: "FBA Stock Left",
            placeholder: "Filter by FBA Stock Left",
            type: "Range",
            defaultValue: selectedFilters?.fba_stock_left,
            toggle: true,
            clear: true,
        },
        {
            name: "daily_average_sales",
            label: "Daily Average Sales",
            placeholder: "Filter by Daily Average Sales",
            type: "Range",
            defaultValue: selectedFilters?.daily_average_sales,
            toggle: true,
            clear: true,
        },
        {
            name: "daily_average_revenue",
            label: "Daily Average Revenue",
            placeholder: "Filter by Daily Average Revenue",
            type: "Range",
            defaultValue: selectedFilters?.daily_average_revenue,
            toggle: true,
            clear: true,
        },
        {
            name: "30_days_total_sales",
            label: "30 Days Total Sales",
            placeholder: "Filter by 30 Days Total Sales",
            type: "Range",
            defaultValue: selectedFilters?.["30_days_total_sales"],
            toggle: true,
            clear: true,
        },
        {
            name: "30_days_total_revenue",
            label: "30 Days Total Revenue",
            placeholder: "Filter by 30 Days Total Revenue",
            type: "Range",
            defaultValue: selectedFilters?.["30_days_total_revenue"],
            toggle: true,
            clear: true,
        },
        {
            name: "buybox_lowest_price",
            label: "Buybox Lowest Price",
            placeholder: "Filter by Buybox Lowest Price",
            type: "Range",
            defaultValue: selectedFilters?.buybox_lowest_price,
            toggle: true,
            clear: true,
        },
        {
            name: "fba_offers_count",
            label: "FBA Offers Count",
            placeholder: "Filter by FBA Offers Count",
            type: "Range",
            defaultValue: selectedFilters?.fba_offers_count,
            toggle: true,
            clear: true,
        },
        {
            name: "fbm_price",
            label: "FBM Price",
            placeholder: "Filter by FBM Price",
            type: "Range",
            defaultValue: selectedFilters?.fbm_price,
            toggle: true,
            clear: true,
        },
        {
            name: "assigned_user",
            label: "Assigned User",
            placeholder: "Filter by user",
            type: "Select",
            api: {
              url: "/api/v1/users",
              view: "/api/v1/employees",
              query: "?filter[name]={query}",
              optionValue: (item) => ({
                value: item?.id,
                label: item?.name,
                avatar: item?.image_url,
              }),
            },
            features: {
              isMulti: true,
              formatOptionLabel: ({ label, avatar }) => (
                <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                  <span className="block overflow-hidden flex flex-col justify-center items-center">
                    <img
                      src={avatar || "/assets/images/defaultAvatar.png"}
                      className="w-5 h-5 rounded-full object-contain"
                    />
                  </span>
                  <span>{upperCaseString(label)}</span>
                </div>
              ),
            },
            defaultValue: selectedFilters?.assigned_user,
            toggle: true,
            clear: true,
          },
        {
            name: "buybox_owner",
            label: "Buybox Owner",
            placeholder: "Filter by Buybox Owner",
            type: "Input",
            toggle: true,
            isClearable: true,
            defaultValue: selectedFilters?.buybox_owner,
        },
        {
            name: "category",
            label: "Category",
            placeholder: "Filter by Category",
            type: "Select",
            api: {
                url: "/api/v1/category",
                query: "?term={query}",
                optionValue: (item) => ({
                    value: item?.id,
                    label: item?.name,
                }),
            },

            defaultValue: selectedFilters?.category,
            toggle: true,
            clear: true,
        },
    ];
};

export default CustomFiltersView;
