import React, { useEffect, useState } from "react";
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import DynamicTable from "../dynamic-page";
import FormModal from "../dynamic-page/FormModal";
import formView from "./modals-view/form";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { useDispatch, useSelector } from "react-redux";
import util from "../dynamic-page/util";
import TableView from "./modals-view/table";
import getActionList from "../dynamic-page/util/getActionList";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import {
  fetchSupplyChannelFilters as fetchFilters,
  fetchSupplyChannels as fetchData,
  updateBoundFilter,
  updateFilter,
} from "../../store/supply-channels";
import sortView from "./modals-view/sort";
import FiltersView from "./modals-view/filters";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import SearchInput from "../dynamic-page/debt/searchInput";
import TabButton from "../dynamic-page/elements/TabButton";

const SupplyChannels = () => {
  const [modalVisibility, setModalVisibility] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let filters = {
      designation: "SOURCE",
    };

    const startPageByFilters = {
      filters: filters,
      sort: null,
      page: 1,
      search: "",
      includes: "country",
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
    // dispatch(OffersFilters(urlQuery));
  }, []);

  // Get data from store.
  const pageData = useSelector((state) => state.supplyChannels);

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleListingModal, setVisibleListingModal] = useState(false);
  const [salesModalListingData, setModalSalesListingData] = useState(null);

  const [calledItemInAction, setCalledItemInAction] = useState(null);
  const [formData, setFormData] = useState(null);

  // handle refreshing
  const [refreshing, setRefreshing] = useState(false);
  const [refreshingDate, setRefreshingDate] = useState(null);

  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    setSelectedRow([]);
    return orderId;
  };

  const handleNotifyUser = (rs) => {
    util.notifier(rs);
  };

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  const actionList = {
    edit: {
      label: "Edit",
      onClick: (item) => {
        if (item?.id) {
          setFormData(item);
          setModalVisibility(true);
        } else {
          setFormData(selectedRow[0]);
          setModalVisibility(true);
        }
      },
      bulkDisable: true,
    },
    delete: {
      label: "Delete",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText:
            "Are you sure you want to delete the selected supply channel?",
          confirmText: "Yes, Delete",
          callBack: (setSubmit) => {
            const ids = item ? [item.id] : selectedRow.map((item) => item.id);
            callEndpoint({
              // title: "Delete Supply Channel",
              url: `channel/delete/bulk-action`,
              method: "DELETE",
              data: {
                channels: ids,
              },
              pureData: true,
            }).then((res) => {
              setSubmit(false);
              util.notifier({ type: res.type, message: res.message });
              if (res.type === "success") {
                setConfirmationModalVisibility(null);
                onPaginationApply();
              }
            });
          },
        });
      },
    },
  };

  const statusList = {
    WEBSITE: {
      label: "Website",
      actions: [actionList.edit, actionList.delete],
    },
    WHOLESALER: {
      label: "Whoelsaler",
      actions: [actionList.edit, actionList.delete],
    },
    MANUFACTURER: {
      label: "Manufacturer",
      actions: [actionList.edit, actionList.delete],
    },
    null: {
      label: "All",
      actions: [actionList.edit, actionList.delete],
    },
  };

  // handle actions of statuses
  //
  const actions = (row) => {
    // todo const innerRow = row.map((item) => { add actions_id to item }
    // const innerRow = Array.isArray(row) ? row.map((item) => {
    //   return {
    //     ...item,
    //     status: 1
    //   }
    // }) : {
    //     ...row,
    //     status: 1
    // }
    return getActionList(row, statusList, "supply_channel_type");
    // return {
    //     list : [
    //       {
    //           label: "Edit",
    //             onClick: (item) => {
    //                 if (item?.id) {
    //                     setFormData(item)
    //                     setModalVisibility(true)
    //                 } else {
    //                     setFormData(selectedRow[0])
    //                     setModalVisibility(true)
    //                 }
    //             },
    //     },

    //   ]
    // }
  };

  const onHandleTab = (item) => {
    onFilterApply({ supply_channel_type: item });
  };

  // Handle Pagination
  const onHandlePrevious = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };

  const onHandleNext = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      page: page || currentStoreFilters.page || 1,
      limit: limit || currentStoreFilters.limit || 20,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const onSubmitApply = async (data, clear) => {
    let { id, ...payload } = data;

    if (!payload.is_primary) {
      payload.is_primary = false;
    } else {
      payload.is_primary = true;
    }

    // if url is empty send it as null
    if (!payload.url) {
      delete payload.url;
    }
    if (!payload.email) {
      delete payload.email;
    }
    if (!payload.phone) {
      delete payload.phone;
    }

    if (!payload.note) {
      delete payload.note;
    }

    const innerData = {
      ...payload,
      designation: "SOURCE",
    };

    // convert the image from blob to base64
    // this is the image Blob {size: 135663, type: 'image/png'}
    // if (innerData?.logo_file?.size) {
    //   const base64 = await blobToBase64(innerData?.logo_file);
    //   innerData.logo_file = base64;
    // }
    if (!innerData?.logo_file) {
      delete innerData.logo_file;
    }

    if (!id) {
      callEndpoint({
        title: "Create Supply Channel",
        url: `channel`,
        method: "POST",
        data: innerData,
        // pureData: true,
      }).then((res) => {
        if (res.type === "success") {
          onPaginationApply();
          setFormData(null);
          clear();
          //  setModalVisibility(false);
        }
      });
    } else {
      callEndpoint({
        title: "Update Supply Channel",
        url: `channel/${id}`,
        method: "POST",
        data: innerData,
        pureData: true,
      }).then((res) => {
        if (res.type === "success") {
          onPaginationApply();
          setFormData(null);
          clear();
          //  setModalVisibility(false);
        }
      });
    }

    /*if (!id) {
                  await dispatch(createRow(payload)).unwrap().then(handleNotifyUser);
                } else {
                  await dispatch(updateRow({ id: id, payload }))
                    .unwrap()
                    .then(handleNotifyUser);
                }
                onPaginationApply();*/
  };

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col w-full">
        <ArbitProductPage.Title
          title={"Supply Channels"}
          form={{
            callback: (rs) => {
              setModalVisibility(true);
            },
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          onSearch={onSearchApply}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            discard: ["designation"],
            items: FiltersView(
              pageData?.filters?.filters,
              pageData?.filtersMeta?.supply_channel_types
            ),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length - 1,
          }}
          actionList={actions(selectedRow)}
        />
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            callBack={onHandleTab}
            active={pageData?.filters?.filters?.supply_channel_type}
            list={[
              {
                id: "WEBSITE",
                name: "Website",
                count: pageData?.filtersMeta?.supply_channel_types?.find(
                  (item) => item?.name === "WEBSITE"
                )?.count,
              },
              {
                id: "WHOLESALER",
                name: "Whoelsaler",
                count: pageData?.filtersMeta?.supply_channel_types?.find(
                  (item) => item?.name === "WHOLESALER"
                )?.count,
              },
              {
                id: "MANUFACTURER",
                name: "Manufacturer",
                count: pageData?.filtersMeta?.supply_channel_types?.find(
                  (item) => item?.name === "MANUFACTURER"
                )?.count,
              },
            ]}
            allTab={true}
          />
        </div>

        <ArbitProductPage.Content>
          <div className="overflow-x-auto max-w-[100%]">
            <DynamicTable
              view={TableView}
              data={TableView(pageData?.supplyChannels)}
              actions={actions}
              onSelect={onSelectRow}
              selectedRow={selectedRow}
              loading={pageData.loading}
              style={{
                table: {
                  style: { width: "100%", minWidth: "100%" },
                },
                header: {
                  className: "justify-center !px-[16px] ",
                  "tracking id": { className: "!pr-[30px]" },
                  image: { className: "hidden" },
                  type: { className: "!py-[0px] hidden" },
                },
                row: {
                  className:
                    "!px-[8px] !py-[0px] !items-center !justify-center flex text-[12px] ",
                  // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                  name: {
                    className: "!min-w-fit min-w-max w-full ",
                    td: "w-[100%]",
                  },
                  // 'last update': {
                  //   td: 'w-fit',
                  // },
                  type: {
                    td: "w-[135px]",
                  },
                  _actions: { className: "!py-[0px]" },
                },
              }}
            />
          </div>
        </ArbitProductPage.Content>

        <FooterPagination
          meta={pageData?.meta}
          onPrevious={onHandlePrevious}
          onNext={onHandleNext}
          onPage={onHandlePage}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {modalVisibility && (
        <FormModal
          title={"Supply Channel"}
          prefix="Create"
          formView={formView}
          visibility={modalVisibility}
          data={formData}
          createNew={true}
          //    params={params}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
          }}
          onSubmit={onSubmitApply}
          // notAutoClose
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this order as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(null)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

export default SupplyChannels;
