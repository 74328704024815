import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import DynamicForm from "../../dynamic-form";
import {fetchOffer, updateRow} from "../../../store/offers";
import {useDispatch} from "react-redux";
import {connectProduct} from "../../../store/offers/thunk";
import SingleGroupProductBadge from "../../dynamic-page/components/SingleGroupProductBadge";
import descriptiveContent from "../../../utils/descriptiveContent";
import ConnectProductModal from "../../dynamic-page/components/ConnectForm";
import {SalesChannelItem} from "../../sales-listing/modals-view/form";

const mapListings = (item) => ({
    id: item?.id,
    identifier: item?.sales_channel_listing?.identifier,
    url: item?.sales_channel_listing?.url,
    label: item?.sales_channel_listing?.name,
    channel: item?.sales_channel_listing?.channel?.name,
    brand: item?.sales_channel_listing?.brand,
    image: item?.sales_channel_listing?.main_image_path
  });

const Item = ({product, orderId, offer, openListingModal}) => {
    const productList = product[0];
    const dispatch = useDispatch();
    return (
        <div className={"w-[390px] gap-[0px] flex flex-col"}>
            <div className={"flex flex-row gap-[8px] justify-between"}>
                <util.expandableText maxLines={1} className={"text-[12px]"} tooltip={true}>
                    {offer?.title}
                </util.expandableText>
            </div>
            <div className={"flex justify-between"}>

                {descriptiveContent(<div className={"flex flex-row gap-[4px] text-gray-600"}>
                    <util.copyText className={"text-[12px]"} text={offer?.sku}
                                   hint={false}>{util.shortenString(offer?.sku, 30)}</util.copyText>
                </div>, ' SKU')}
                {productList ?
                    <div className="flex items-center gap-[2px] !text-[12px]">
                        <span
                            className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium ${productList?.type === 1 ? "text-teal-700 bg-teal-100" : "text-indigo-700 bg-indigo-100"} p-[0px]`}>{productList?.type === 1 ? "S" : "G"}</span>
                        <div
                            className={"text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] "}
                            onClick={() => {
                                window.open(`/product/${productList?.id}`, "_blank")
                            }}
                        >
                            {productList?.brand?.assigned ?  <>
                            <img src={productList?.brand?.assigned?.profile_image || '/assets/images/defaultAvatar.png'}
                                 className={"w-[20px] h-[20px] rounded-full object-cover"}/>
                                {util.upperCaseString(productList?.brand?.assigned?.name, "word")}
                            </> : <div className="text-gray-400">Unassigned</div>}
                        </div>
                    </div>
                    :
                    <div className="flex items-center gap-[2px] !text-[12px] ">
                        <ConnectProductModal
                              key={offer?.id}
                              toggle={() => (
                                  <div className={"flex flex-row gap-[2px]"}>
                                      <span className="w-[20px] h-[20px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
                                          <Icons.link className={"w-[11px] h-[11px]"} />
                                      </span>
                                      <div
                                          className={
                                              "text-red-700 hover:text-red-800 cursor-pointer "
                                          }
                                      >
                                          Not Connected
                                      </div>
                                  </div>
                              )}
                              mapListings={mapListings}
                              salesChannelItem={SalesChannelItem}
                              defaultListing={offer}
                              callBack={(data) => {
                                 return dispatch(connectProduct({
                                    offerId: offer?.id,
                                    productId: data?.id,
                                })).then((result) => {
                                    //handleFetchAndUpdateItem(data._data.order_id, data.id, result?.payload?.data)
                                    if (result?.payload?.type === "success") {
                                        dispatch(fetchOffer(offer?.id)).then((rs) => {
                                            dispatch(updateRow({
                                                path: `offers.[id:${offer?.id}]`,
                                                value: rs.payload.data
                                            }))
                                            dispatch(updateRow({
                                                path: `offers.[id:${offer?.id}]._updatedRow`,
                                                value: true
                                            }))
                                            util.notifier({
                                                type: "success",
                                                message: result?.payload?.data,
                                                errors: result?.payload?.errors
                                            })
                                        })
                                        return true
                                    }
                                })
                              }}
            />

                    </div>}

                {/* } */}

            </div>

        </div>

    )

}

export default Item;