import React from "react";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import Components from "../components";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import priceHandler from "../../../utils/priceHandler";
import LastUpdated from "../../dynamic-page/components/lastUpdate";
import util from "../../dynamic-page/util";

const TableView = (list, openListingModal) => {

    const quantityTooltipList = (item) => {
        if (!item) {
            return []
        }
        return [
            {
                title: "Available",
                value: item?.available_quantity != null ? item?.available_quantity : '-',
            },
            {
                title: "Reserved",
                value: item?.reserved_quantity != null ? item?.reserved_quantity : '-',
            },
            {
                title: "Inbound",
                value: item?.inbound_quantity != null ? item?.inbound_quantity : '-',
            },
            {
                title: "Unfulfillable",
                value: item?.unfulfillable_quantity != null ? item?.unfulfillable_quantity : '-',
            },
        ]
    }

    const agedCostTooltip = (item) => {
        if (!item) {
            return []
        }

        let result = [];

        for (let key in item) {
            let title = key.replace('estimated_ais_', '')
                .replace('_', ' - ')
                .replace('_days', '')
                .replace('- plus', '+');
            result.push({
                title: title,
                value: '$' + item[key],
            });
        }

        return result;
    }


    return {
        list: list,
        theme: {
            "status": (item) => (
                <div className="flex flex-col justify-center items-center gap-1 inline-flex">
                    <ArbitNormalBadge
                        title={
                            item.status === 'Active' ? 'Active' : item.status === 'Inactive' ? 'Inactive' : 'Deleted'
                        }
                        bgColor={
                            item.status === 'Active' ? 'green-100' : item.status === 'Inactive' ? 'gray-100' : 'red-100'
                        }
                        textColor={
                            item.status === 'Active' ? 'green-700' : item.status === 'Inactive' ? 'gray-700' : 'red-700'
                        }
                    />

                </div>
            ),
            "type": (item) => (
                <div className="flex flex-col justify-center items-center gap-1 inline-flex">
                    <ArbitNormalBadge
                        title={
                            item.fulfilment
                        }
                        bgColor={
                            item.fulfilment === "FBM"
                                ? "blue-100"
                                : item.fulfilment === "FBA" ? "red-50" : "gray-100"
                        }
                        textColor={
                            item.fulfilment === "FBM"
                                ? "blue-800"
                                : "red-500"
                        }
                    />

                </div>
            ),


            'updated at': (item) => (
                <util.dateDisplay item={item} date={item?.updated_at} />
            ),

            listing: (item) => (
                <div className={"flex flex-col gap-[0px]  justify-start  w-full"}>
                    <Components.sku callBack={openListingModal} orderId={item.sku} order={{
                        order_number: item?.identifier,
                        listingUrl: item?.url,
                        country: item?.channel?.country,
                        account: item?.account?.name, ...item?.sales_channel_listing?.channel
                    }} offer={item}/>
                </div>
            ),

            image: (item) => (
                <div
                    className={
                        "w-[65px] h-[64px] max-h-[64px] justify-center items-center flex"
                    }
                >
                    {item?.image ? (
                        <ImageMagnifier
                            src={item?.image}
                            className={"!max-h-[64px]"}
                        />
                    ) : (
                        <img
                            src={"/assets/images/noImage.png"}
                            className={"h-[52px] w-[52px]"}
                        />
                    )}
                </div>
            ),

            offer: (item) => {
                return (
                    <div className={"w-max  flex flex-row gap-[16px] justify-start"} style={{width: "100%"}}>
                        <Components.item product={[item?.product]} orderId={item?.id} offer={item}
                                         openListingModal={openListingModal}/>
                    </div>
                )
            },

            "price": (item) => (
                <Components.numbers data={item?.price} processFunction={priceHandler}
                                    currency={{symbol: '$', position: 1}} color={"text-green-700"}/>
            ),
            "T. Quantity": (item) => (
                <Components.numbers data={item?.quantity} processFunction={priceHandler}
                                    list={item?.fulfilment === "FBA" ? quantityTooltipList(item?.fba_quantity_tooltip) : null}
                                    color={"text-purple-700"}/>
            ),
            "est. aged inv. cost": (item) => (
                <Components.numbers data={item?.estimated_aged_inventory_cost} processFunction={priceHandler}
                                    currency={{symbol: '$', position: 1}}
                                    list={agedCostTooltip(item?.aged_inventory_cost_tooltip)} color={"text-red-700"}/>
            ),
            "est. STORAGE COST": (item) => (
                <Components.numbers data={item?.estimated_storage_cost_next_month} processFunction={priceHandler}
                                    currency={{symbol: '$', position: 1}} color={"text-red-700"}/>
            ),
        },
    };
};
export default TableView;
