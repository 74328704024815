import { HiCheck } from "react-icons/hi";
import ArbitButton from "../sub-elements/button";

// import 'react-Select.js/dist/react-Select.js.css'
// import 'react-virtualized/styles.css'
// import 'react-virtualized-Select.js/styles.css'
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorComponent from "../sub-elements/ErrorComponent";

import AsyncSelect from "react-select/async";
import axios from "../../middleware/axios";
import constants from "../../constant";
import quantityHandler from "../../utils/quantityHandler";
import MainModal from "../dynamic-page/MainModal";
import CustomAsyncSelect from "./customAsyncSelect";

const UpdateRequest = ({ onClose, updateItem, onUpdate, show }) => {
  // get current date and time in local timezone
  // const current = new Date();
  // const date = current.toISOString().substr(0, 10);
  // const time = current.toLocaleTimeString('en-US', { hour12: false })
  // console.log(time);

  // const [products, setProducts] = useState([])
  // const [selectedProduct, setSelectedProduct] = useState('')
  // const [errorMessage , setErrorMessage] = useState(false)

  // console.log(products);
  // consoleole.log(selectedProduct);

  // const fetchProducts = async (params) => {
  //     let url = constants.APIURL.GET_PRODUCT
  //     if (params)
  //         url = url.concat(params)
  //     const response = await axios.get(url, { withCredentials: true });
  //     // console.log(response);
  //     return response.data

  // }

  // const mapOptionsToValues = options => {
  //     return options.map(option => ({
  //         value: option?.id,
  //         label: option?.title,
  //         image: option?.image,
  //         title: option?.title,
  //         product: option?.id,
  //         qty: '',
  //         comment: '',
  //     }));
  // };

  // const loadOptions = async (inputValue) => {

  //     // let url;
  //     // if(inputValue[0] === 'a' && inputValue[1] === 'p'){
  //     //     url = "?filter[apid]=" + inputValue
  //     // }
  //     // else{
  //     //     url = "?filter[title]=" + inputValue
  //     // }
  //     // console.log(url);
  //     return new Promise(resolve => {
  //         fetchProducts("?filter[title]=" + inputValue)
  //             .then(response => {
  //                 // console.log(response);
  //                 const options = mapOptionsToValues(response.data);
  //                 // console.log(options);
  //                 // setProducts(options)
  //                 resolve(options)
  //             })
  //             .catch(error => {
  //                 // console.log(error);
  //             }
  //             );
  //     });

  const mapProductsOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: option?.name,
      image: option?.image,
      logo: option?.logo,
      assign: option?.assign?.name,
      profile: option?.assign?.profile_image,
    }));
  };

  const mapOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label:
        option?.title ||
        option?.name ||
        "ID:" + option?.id + " || Number:" + option?.order_number,
      image: option?.image,
      title: option?.title,
      product: option?.id,
      assign: option?.assign?.name,
      profile: option?.assign?.profile_image,
      qty: "",
      comment: "",
    }));
  };

  const mapCurrenciesOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: option?.name,
      symbol: option?.symbol,
      position: option?.position,
    }));
  };

  const fetchWarehouses = async (params) => {
    let url = "api/v1/warehouses";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const idsLoadOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchWarehouses("/search?term=" + inputValue + "&not_deleted=true")
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  };

  const validationSchema = Yup.object({
    date: Yup.string().required("Required"),
    time: Yup.string().required("Required"),
    quantity: Yup.number().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      date: updateItem?.request_date_time?.substr(0, 10),
      time: updateItem?.request_date_time?.substr(11, 8),
      comment: updateItem?.comment,
      url: updateItem?.url,
      quantity: quantityHandler(updateItem?.qty),
      warehouse_id: {
        value: updateItem?.warehouse?.id,
        label: updateItem?.warehouse?.name,
      },
      supplier_id: {
        value: updateItem?.supplier?.id,
        label: updateItem?.supplier?.name,
        logo: updateItem?.supplier?.logo,
      },
      max_acceptable_price: updateItem?.max_acceptable_price?.TRY,
      max_acceptable_price_currency_id: {
        value: updateItem?.max_acceptable_price_currency?.id,
        label: updateItem?.max_acceptable_price_currency?.name,
      },
      reason:
        updateItem?.reason === "order"
          ? !updateItem?.related_reason
            ? "bulk_order"
            : "order"
          : updateItem?.reason,
      reason_related_id: updateItem?.related_reason?.id,
    },
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        request_date_time: values.date + " " + values.time,
        comment: values.comment,
        qty: values.quantity,
        url: values.url,
        warehouse_id: values.warehouse_id.value,
        supplier_id: values.supplier_id.value,
        max_acceptable_price: values.max_acceptable_price,
        max_acceptable_price_currency_id:
          values.max_acceptable_price_currency_id.value,
        reason: values.reason,
        reason_related_id: values.reason_related_id,
      };
      onUpdate({ id: updateItem?.id, payload });
      // console.log(payload, "values")
      // setSupplyList([...supplyList, values])
      // onClose()
    },
  });

  return (
    <MainModal
      onClose={onClose}
      show={show}
      center={true}
      title="Update Supply Request"
      item={{
        title: "Edit Supply Request",
        cancel: onClose,
        submit: formik.handleSubmit,
        view: (item) => (
          <form
            onSubmit={formik.handleSubmit}
            className="border-t border-t-gray-200 pt-[24px] w-[65vw]"
          >
            {/* <h2 className="font-medium text-[24px] leading-[36px] text-gray-900">{}</h2> */}
            <div className="flex flex-col gap-4 my-4 px-4">
              <div className="grid grid-cols-2 gap-[10px]">
                <div className="flex flex-col  flex-grow">
                  <label className="text-gray-900 text-[14px] font-medium">
                    Warehouse
                  </label>
                  <AsyncSelect
                    loadOptions={(inputValue) => idsLoadOptions(inputValue)}
                    placeholder={"Select Warehouse"}
                    // key={selectedProduct?.request_reason}
                    defaultOptions
                    cacheOptions
                    value={formik.values.warehouse_id}
                    onChange={(data) => {
                      formik.setFieldValue("warehouse_id", data);
                    }}
                    optionHeight={50}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        height: 42,
                        width: "100%",
                        backgroundColor: "#F9FAFB",
                        borderRadius: 10,
                        overflow: "auto",
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : formik.errors.warehouse_id
                          ? "1px solid #FF0000"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #00A3FF"
                            : "1px solid #E5E5E5",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#00A3FF" : "white",
                        color: state.isSelected ? "white" : "black",

                        "&:hover": {
                          backgroundColor: "#00A3FF",
                          color: "white",
                        },
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        height: 150,
                        zIndex: 999,
                        // borderRadius: 10,
                        overflow: "hidden",
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #00A3FF"
                            : "1px solid #E5E5E5",
                        },
                      }),
                      container: (provided, state) => ({
                        ...provided,
                        width: "100%",
                        height: 42,
                      }),
                    }}
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-gray-900 text-[14px] font-medium">
                    Supply Channel
                  </label>
                  <CustomAsyncSelect
                    url={constants.APIURL.GET_CHANNELS}
                    mapOptions={mapProductsOptionsToValues}
                    //  defaultOptions={defaultProducts}
                    defaultParams="?not_deleted=true"
                    placeholder="Select Channel"
                    //  isDisabled={!!data?.product || editMode}
                    product={formik.values.supplier_id}
                    onChange={(data) => {
                      formik.setFieldValue("supplier_id", data);
                    }}
                    optionHeight={50}
                    features={{
                      formatOptionLabel: (item) => (
                        <div
                          className={"flex flex-row gap-[12px] items-center"}
                        >
                          <div className="w-[35px] h-auto">
                            <img
                              className={
                                "w-full h-auto rounded-full border-0 bg-gray-200"
                              }
                              src={item?.logo}
                            />
                          </div>
                          <span>{item?.label}</span>
                        </div>
                      ),
                    }}
                    customStyles={{
                      control: (provided, state) => ({
                        ...provided,
                        height: 42,
                        width: "100%",
                        backgroundColor: "#F9FAFB",
                        borderRadius: 8,
                        overflow: "auto",
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : formik.errors.supplier_id
                          ? "1px solid #FF0000"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #00A3FF"
                            : "1px solid #E5E5E5",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#00A3FF" : "white",
                        color: state.isSelected ? "white" : "black",

                        "&:hover": {
                          backgroundColor: "#00A3FF",
                          color: "white",
                        },
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        zIndex: 999,
                        // borderRadius: 10,
                        // overflow: 'hidden',
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #00A3FF"
                            : "1px solid #E5E5E5",
                        },
                      }),
                      container: (provided, state) => ({
                        ...provided,
                        width: "100%",
                        height: 42,
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 gap-[10px]">
                <div className="flex flex-col  ">
                  <label className="text-gray-500 text-[14px] font-medium">
                    Quantity
                  </label>
                  <input
                    type="number"
                    className={`bg-gray-50 border   text-gray-500 h-[42px] p-3 rounded-xl ${
                      formik.touched.quantity && formik.errors.quantity
                        ? "border-red-500"
                        : "border-gray-200"
                    } `}
                    name="quantity"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {/* {formik.touched.quantity && formik.errors.quantity ? (
                                <ErrorComponent errorMessage={formik.errors.quantity}>{formik.errors.quantity}</ErrorComponent>
                            ) : null} */}
                </div>
                <div className="flex flex-col ">
                  <label className="text-gray-500 text-[14px] font-medium">
                    Date
                  </label>
                  <input
                    type="date"
                    className="bg-gray-50 border border-gray-200  text-gray-500 h-[42px] p-3 rounded-xl "
                    name="date"
                    // value={formik.values.request_date_time}
                    value={formik.values.date}
                    // disabled
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.date && formik.errors.date ? (
                    <ErrorComponent errorMessage={formik.errors.date}>
                      {formik.errors.date}
                    </ErrorComponent>
                  ) : null}
                </div>
                <div className="flex flex-col ">
                  <label className="text-gray-500 text-[14px] font-medium">
                    Time
                  </label>
                  <input
                    type="time"
                    className="bg-gray-50 border border-gray-200 text-gray-500 h-[42px] p-3 rounded-xl "
                    name="time"
                    // value={formik.values.time}
                    value={formik.values.time}
                    // disabled
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.time && formik.errors.time ? (
                    <ErrorComponent errorMessage={formik.errors.time}>
                      {formik.errors.time}
                    </ErrorComponent>
                  ) : null}
                </div>
              </div>

              <div className="grid grid-cols-2  gap-[10px]">
                <div className="flex flex-col">
                  <label className="text-gray-900 text-[14px] font-medium">
                    Max. Acceptable Price
                  </label>
                  <input
                    className={`bg-gray-50 border text-gray-500 py-2 px-4 h-[45px] rounded-xl w-full ${
                      formik.errors.max_acceptable_price
                        ? "border-red-500"
                        : "border-gray-200"
                    }`}
                    placeholder="Max. Acceptable Price"
                    name="max_acceptable_price"
                    value={formik.values.max_acceptable_price}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "max_acceptable_price",
                        e.target.value
                      );
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-900 text-[14px] font-medium">
                    Max Acceptable Price Currency
                  </label>
                  <CustomAsyncSelect
                    url={constants.APIURL.GET_CURRENCIES}
                    mapOptions={mapCurrenciesOptionsToValues}
                    //  defaultOptions={defaultProducts}
                    defaultParams="?not_deleted=true"
                    placeholder="Select Currency"
                    //  isDisabled={!!data?.product || editMode}
                    product={
                      formik.values.max_acceptable_price_currency_id || ""
                    }
                    onChange={(data) => {
                      formik.setFieldValue(
                        "max_acceptable_price_currency_id",
                        data
                      );
                    }}
                    optionHeight={50}
                    customStyles={{
                      control: (provided, state) => ({
                        ...provided,
                        height: 42,
                        width: "100%",
                        backgroundColor: "#F9FAFB",
                        borderRadius: 8,
                        overflow: "auto",
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : formik.errors.max_acceptable_price_currency_id
                          ? "1px solid #FF0000"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #00A3FF"
                            : "1px solid #E5E5E5",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#00A3FF" : "white",
                        color: state.isSelected ? "white" : "black",

                        "&:hover": {
                          backgroundColor: "#00A3FF",
                          color: "white",
                        },
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        zIndex: 999,
                        // borderRadius: 10,
                        // overflow: 'hidden',
                        border: state.isFocused
                          ? "1px solid #00A3FF"
                          : "1px solid #E5E5E5",
                        boxShadow: state.isFocused
                          ? "0px 0px 0px 1px #00A3FF"
                          : "none",
                        "&:hover": {
                          border: state.isFocused
                            ? "1px solid #00A3FF"
                            : "1px solid #E5E5E5",
                        },
                      }),
                      container: (provided, state) => ({
                        ...provided,
                        width: "100%",
                        height: 42,
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col  gap-[10px]">
                <div className="flex flex-col">
                  <label className="text-gray-900 text-[14px] font-medium">
                    URL
                    <span className="text-xs text-gray-400">(optional)</span>
                  </label>
                  <input
                    className={`bg-gray-50 border border-gray-200 text-gray-500 py-2 px-4 h-[45px] rounded-xl w-full `}
                    placeholder="URL"
                    name="url"
                    value={formik.values.url}
                    onChange={(e) => {
                      formik.setFieldValue("url", e.target.value);
                    }}
                  />
                </div>

                <div className="flex flex-col w-full">
                  <label className="text-gray-500 text-[14px] font-medium">
                    Comments
                  </label>
                  <textarea
                    className={`bg-gray-50 overflow-hidden border border-gray-200  text-gray-500 h-[84px]  rounded-xl w-full `}
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {/* {formik.touched.comment && formik.errors.comment ? (
                            <ErrorComponent errorMessage={formik.errors.comment}>{formik.errors.comment}</ErrorComponent>
                        ) : null} */}
                </div>
              </div>
              <div className="flex flex-row gap-[10px]"></div>
            </div>

            <div className="flex justify-end mt-2">
              <div className="mx-1">
                <ArbitButton
                  type="submit"
                  //   onClick={() => formik.handleSubmit()}
                >
                  <HiCheck className="mr-2 h-5 w-5" />
                  Submit
                </ArbitButton>
              </div>
              <div className="mx-1">
                <ArbitButton
                  bg_color="white"
                  border_color="blue"
                  text_color="blue-600"
                  onClick={() => onClose()}
                >
                  Cancel
                </ArbitButton>
              </div>
            </div>
          </form>
        ),
      }}
    />
  );
};

export default UpdateRequest;
