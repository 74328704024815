import ImageMagnifier from "../../../../../dynamic-page/debt/magnifier";

import Components from "../../../../components";
import priceHandler from "../../../../../../utils/priceHandler";
import util from "../../../../../dynamic-page/util";

import Icons from "../../../../icons";
import React from "react";
import moment from "moment";

const tableView = (list, action, type, isView) => {

    let viewList = {};

    type === 2
        ? (viewList = {
            image: (item) => (
                <div
                    className={"w-[54px]  flex flex-col justify-center items-center"}
                >
                    <ImageMagnifier
                        src={
                            item?.image ||
                            item?.item_image ||
                            item?._data?.product?.data?.image
                        }
                        className={"!max-h-[54px]"}
                    />
                </div>
            ),
            item: (item) => (
                <div className={"flex flex-row flex-1 max-w-[490px]"}>
                    <div className={" gap-[4px] flex flex-col w-full"}>
                        {item?._data?.product?.data?.title && (
                            <div className={"group"}>
                                <util.expandableText
                                    maxLines={2}
                                    className={"text-[16px] w-[100%] leading-150"}
                                >
                                    {item?._data?.product?.data?.title /*|| item?.item_title*/}
                                </util.expandableText>
                                {/* <div className={"group-hover:visible w-[30px]"}>
                                <util.copyText className={"text-[12px]"}  mainClassName={"w-[15px]"} text={item?._data?.product?.data?.title} hint={false} />
                            </div> */}
                            </div>
                        )}
                    </div>
                </div>
            ),
            assign: (item) => (
                <div className="w-[135px] h-[21px] justify-center items-center gap-1 inline-flex">
                    <div className="w-[18px] h-[18px] rounded-2xl justify-start items-start gap-2.5 flex">
                        <img
                            className="grow shrink rounded-full object-contain basis-0 self-stretch"
                            src={
                                item?._data?.product?.data?.brand?.assigned?.profile_image ||
                                "/assets/images/defaultAvatar.png"
                            }
                        />
                    </div>
                    <div className="text-gray-600 text-sm font-normal font-['Inter'] leading-[21px]">
                        {item?._data?.product?.data?.brand?.assigned?.name}
                    </div>
                </div>
            ),
            quantity: (item) => (
                <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                    {!isView ? (
                        <Components.saveWeight
                            text={item?.qty || "-"}
                            onSave={(data) => {
                                action({
                                    action: "editItem",
                                    data: {
                                        ...item,
                                        qty: data,
                                    },
                                });
                            }}
                            className={
                                "min-w-max whitespace-no-wrap group-active:text-gray-800 hover:text-gray-600 flex text-[24px] text-gray-700 font-[700]"
                            }
                        />
                    ) : (
                        item?.qty || "-"
                    )}
                </div>
            ),
            "act. weight": (item) => (
                <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                    {item?._data?.product?.data?.fbm_actual_weight
                        ? (
                            (+item?.qty || 1) *
                            +item?._data?.product?.data?.fbm_actual_weight
                        ).toFixed(2)
                        : "-"}
                </div>
            ),
            "dim. weight": (item) => (
                <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                    {item?._data?.product?.data?.fbm_dimensional_weight
                        ? (
                            (+item?.qty || 1) *
                            +item?._data?.product?.data?.fbm_dimensional_weight
                        ).toFixed(2)
                        : "-"}
                </div>
            ),
            "bill. weight": (item) => (
                <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                    {item?._data?.product?.data?.weight?.billable_weight || "-"}
                </div>
            ),
            _delete: (item) => (
                <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                    <Icons.del
                        className={"w-[16px] h-[16px] text-red-600"}
                        onClick={() => {
                            action({
                                action: "removeItem",
                                data: item,
                            });
                        }}
                    />
                </div>
            ),
        })
        : type === 1 || !type
            ? (viewList = {
                "tracking id": (item) => (
                    <span
                        onClick={() =>
                            window.open(item?._data?.shipment?.data?.tracking?.url, "_blank")
                        }
                        className={"cursor-pointer flex flex-row gap-[4px] items-center"}
                    >
            <span className="text-gray-900 text-sm font-medium">
              {item?._data?.shipment?.data?.tracking?.number}
            </span>
            <Icons.goToTrackURL className={"text-blue-700 w-[9px]"}/>
            <util.copyText
                className={"text-[12px]"}
                text={item?._data?.shipment?.data?.tracking?.number}
                hint={""}
            />
          </span>
                ),
                carrier: (item) => (
                    <div className="w-[66px] h-[21px] justify-center items-center gap-2 inline-flex">
                        <div className="w-5 h-5 justify-start items-start gap-2.5 flex">
                            <img
                                className="grow shrink basis-0 self-stretch"
                                src={item?._data?.shipment?.data?.carrier?.logo}
                            />
                        </div>
                        <div className="text-gray-900 text-sm font-medium font-['Inter'] leading-[21px]">
                            {item?._data?.shipment?.data?.carrier?.name}
                        </div>
                    </div>
                ),
                "order id": (item) => (
                    <div className="flex gap-1">
            <span
                onClick={() => {
                    window.open(
                        `/logistics/orders/#action=details&orderId=${item?._data?.shipment?.data?.order?.id}
                    `,
                        "_blank"
                    );
                }}
                className="flex gap-1 cursor-pointer text-gray-500 text-sm"
            >
              {item?._data?.shipment?.data?.order?.number}
                <Icons.goToTrackURL className={"text-gray-500 w-[9px]"}/>
            </span>
                        <div>
                            <util.copyText
                                className={"text-[12px]"}
                                text={item?._data?.shipment?.data?.order?.number}
                                hint={false}
                            />
                        </div>
                    </div>
                ),
                "created at": (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        {moment(item?._data?.shipment?.data?.timestamps?.created_at).format(
                            "MMM DD, YYYY"
                        )}
                    </div>
                ),
                "act. weight": (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        {item?._data?.shipment?.data?.weight?.actual_weight
                            ? (
                                +item?.qty *
                                +item?._data?.shipment?.data?.weight?.actual_weight
                            ).toFixed(2)
                            : "-"}
                    </div>
                ),
                "dim. weight": (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        {item?._data?.shipment?.data?.weight?.dim_weight
                            ? (
                                +item?.qty * +item?._data?.shipment?.data?.weight?.dim_weight
                            ).toFixed(2)
                            : "-"}
                    </div>
                ),
                "bill. weight": (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        {item?._data?.shipment?.data?.weight?.billable_weight
                            ? (
                                +item?.qty *
                                +item?._data?.shipment?.data?.weight?.billable_weight
                            ).toFixed(2)
                            : "-"}
                    </div>
                ),
                "solid price": (item) => (
                    <div className="text-gray-900 text-sm font-semibold font-['Inter'] leading-[21px]">
                        {item?._data?.shipment?.data?.order?.items_paid && "$ "}
                        {priceHandler(item?._data?.shipment?.data?.order?.items_paid)}
                    </div>
                ),
                _delete: (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        <Icons.del
                            className={"w-[16px] h-[16px] text-red-600"}
                            onClick={() => {
                                action({
                                    action: "removeItem",
                                    data: item,
                                });
                            }}
                        />
                    </div>
                ),
            })
            : (viewList = {
                image: (item) => (
                    <div
                        className={"w-[54px]  flex flex-col justify-center items-center"}
                    >
                        <ImageMagnifier
                            src={
                                item?.image ||
                                item?.item_image ||
                                item?._data?.offer?.data?.image
                            }
                            className={"!max-h-[54px]"}
                        />
                    </div>
                ),
                item: (item) => (
                    <div className={"flex flex-row items-center flex-1 max-w-[200px]"}>
                        <div className={" gap-[4px] flex  w-full"}>
                            {item?._data?.offer?.data?.product?.title && (
                                <div className={"group"}>
                                    <util.expandableText
                                        maxLines={1}
                                        className={"text-[16px]  leading-150"}
                                        hideCopy
                                        tooltip
                                    >
                                        {
                                            item?._data?.offer?.data?.product
                                                ?.title /*|| item?.item_title*/
                                        }
                                    </util.expandableText>

                                    {/* <div className={"group-hover:visible w-[30px]"}>
                                <util.copyText className={"text-[12px]"}  mainClassName={"w-[15px]"} text={item?._data?.offer?.data?.title} hint={false} />
                            </div> */}
                                </div>
                            )}
                            <span
                                onClick={() => {
                                    window.open(
                                        `/product/${item?._data?.offer?.data?.product?.id}`,
                                        "_blank"
                                    );
                                }}
                                className="flex gap-1 cursor-pointer text-gray-300 text-sm"
                            >
                <Icons.goToTrackURL className={"text-gray-500 w-[9px]"}/>
              </span>
                        </div>
                    </div>
                ),
                assign: (item) =>
                    item?._data?.offer?.data?.product ? (
                        <div className="w-[135px] h-[21px] justify-center items-center gap-1 inline-flex">
                            <div className="w-[18px] h-[18px] rounded-2xl justify-start items-start gap-2.5 flex">
                                <img
                                    className="grow shrink rounded-full basis-0 self-stretch"
                                    src={item?._data?.offer?.data?.product?.brand?.assigned?.profile_image}
                                />
                            </div>
                            <div className="text-gray-600 text-sm font-normal font-['Inter'] leading-[21px]">
                                {item?._data?.offer?.data?.product?.brand?.assigned?.name}
                            </div>
                        </div>
                    ) : (
                        "-"
                    ),
                Sku: (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        {item?._data?.offer?.data?.sku}
                    </div>
                ),
                listingid: (item) => (
                    <div className="flex gap-1">
            <span
                onClick={() => {
                    window.open(item?._data?.offer?.data?.url, "_blank");
                }}
                className="flex gap-1 cursor-pointer text-gray-500 text-sm"
            >
              {item?._data?.offer?.data?.identifier}
                <Icons.goToTrackURL className={"text-gray-500 w-[9px]"}/>
            </span>
                        {/* <div>
                    <util.copyText className={"text-[12px]"} text={item?._data?.shipment?.data?.order?.number} hint={false}/>
                    </div> */}
                    </div>
                ),
                quantity: (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        {!isView ? (
                            <Components.saveWeight
                                text={item?.qty || "-"}
                                onSave={(data) => {
                                    action({
                                        action: "editItem",
                                        data: {
                                            ...item,
                                            qty: data,
                                        },
                                    });
                                }}
                                className={
                                    "min-w-max whitespace-no-wrap group-active:text-gray-800 hover:text-gray-600 flex text-[24px] text-gray-700 font-[700]"
                                }
                            />
                        ) : (
                            item?.qty || "-"
                        )}
                    </div>
                ),
                "act. weight": (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        {item?._data?.offer?.data?.product?.fbm_actual_weight
                            ? (
                                (+item?.qty || 1) *
                                +item?._data?.offer?.data?.product?.fbm_actual_weight
                            ).toFixed(2)
                            : "-"}
                    </div>
                ),
                "dim. weight": (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        {item?._data?.offer?.data?.product?.fbm_dimensional_weight
                            ? (
                                (+item?.qty || 1) *
                                +item?._data?.offer?.data?.product?.fbm_dimensional_weight
                            ).toFixed(2)
                            : "-"}
                    </div>
                ),
                "bill. weight": (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        {item?._data?.offer?.data?.product?.fbm_billable_weight
                            ? (
                                (+item?.qty || 1) *
                                +item?._data?.offer?.data?.product?.fbm_billable_weight
                            ).toFixed(2)
                            : "-"}
                    </div>
                ),
                _delete: (item) => (
                    <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
                        <Icons.del
                            className={"w-[16px] h-[16px] text-red-600"}
                            onClick={() => {
                                action({
                                    action: "removeItem",
                                    data: item,
                                });
                            }}
                        />
                    </div>
                ),
            });

    const initialTheme = (type) => {
        return type === 2
            ? {
                image: viewList["image"],

                product: viewList["item"],
                assign: viewList["assign"],
                quantity: viewList["quantity"],
                "act. weight": viewList["act. weight"],
                "dim. weight": viewList["dim. weight"],
                // 'bill. weight': viewList["bill. weight"],
                _delete: viewList["_delete"],
            }
            : type === 1 || !type
                ? {
                    trackingId: viewList["tracking id"],
                    carrier: viewList["carrier"],
                    "order Id": viewList["order id"],
                    "created At": viewList["created at"],
                    "act. weight": viewList["act. weight"],
                    "dim. weight": viewList["dim. weight"],
                    "bill. weight": viewList["bill. weight"],
                    "sold Price": viewList["solid price"],
                    _delete: viewList["_delete"],
                }
                : {
                    sku: viewList["Sku"],
                    "Listing id": viewList["listingid"],
                    quantity: viewList["quantity"],
                    image: viewList["image"],

                    product: viewList["item"],
                    assign: viewList["assign"],
                    "act. weight": viewList["act. weight"],
                    "dim. weight": viewList["dim. weight"],
                    // 'bill. weight': viewList["bill. weight"],
                    _delete: viewList["_delete"],
                };
    };

    return {
        list: list,
        theme: initialTheme(type),
    };
};
export default tableView;
