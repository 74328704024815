import React from "react";
import {HiEye, HiEyeOff} from "react-icons/hi";

const Input = ({
                   item,
                   value,
                   validationMessage,
                   handleChange,
                   handleFocus,
                   handleBlur,
                   handleKeyPress,
                   defaultValue,
                   formData,
               }) => {

    const [type, setType] = React.useState(item.type);
    const disabled =
        typeof item.disabled === "function"
            ? item.disabled(formData)
            : item.disabled || false;
    const inputValue = value || defaultValue || "";
    // Return Input in view
    return (
        <div className="relative">
            {item?.icon && (
                <div className="absolute left-4 w-4 h-4 top-1/2 -translate-y-1/2">
                    {item.icon}
                </div>
            )}
            <input
                name={item.name}
                type={type}
                placeholder={item.placeholder}
                disabled={disabled}
                className={`transition text-[14px] leading-[17.5px] ${
                    item.disabled && "disabled"
                }  disabled:text-gray-400 rounded-md ${
                    item?.icon ? "pl-10 pr-2" : "px-[16px]"
                } h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${
                    item.innerClassName || ""
                } ${validationMessage ? "!border-red-500 border-2" : ""}`}
                value={inputValue}
                onChange={(e) => {
                    if (handleChange) handleChange(e.target.value, item.name);
                }}
                onFocus={(e) => {
                    if (handleFocus) handleFocus(e.target.value, item.name);
                }}
                onBlur={(e) => {
                    if (handleBlur) handleBlur(e.target.value, item.name);
                }}
                onKeyPress={(e) => {
                    if (handleKeyPress) handleKeyPress(e);
                }}
                autoFocus={item?.autoFocus}
            />
            {item?.type === "Password" && (
                <div
                    className="absolute left-[90%] top-[50%] -translate-y-[50%] text-[20px] cursor-pointer"
                    onClick={() => setType(type === "Password" ? "Text" : "Password")}
                >
                    {type === "Password" ? <HiEye/> : <HiEyeOff/>}
                </div>
            )}
        </div>
    );
};

export default Input;
