import util from "../../../dynamic-page/util";
import React from "react";
import { useDispatch } from "react-redux";
import DynamicForm from "../../../dynamic-form";
import { apiActions, updateRow } from "../../../../store/orders";

const ShippingContent = ({ shipping_content, gtip, id, callBack }) => {
  const [innerData, setInnerData] = React.useState({});
  const [isUpdating, setIsUpdating] = React.useState(false);

  let itemId = null;
  let orderId = id;
  if (typeof orderId === "string" && orderId.includes("/")) {
    itemId = orderId.split("/")[1];
    orderId = orderId.split("/")[0];
  }

  let formItems = [
    {
      name: "shippingContent",
      label: "Shipping Content",
      placeholder: "Enter Shipping Content",
      type: "Text",
      autoFocus: true,
      defaultValue: isUpdating
        ? "..."
        : shipping_content
        ? util.upperCaseString(shipping_content)
        : null,
      clear: false,
      reset: false,
      className: "w-full gap-[0px]",
      disabled: isUpdating,
    },
  ];

  if (itemId) {
    formItems.push({
      name: "gtip",
      label: "GTIP",
      placeholder: "Enter GTIP",
      type: "Text",
      defaultValue: isUpdating
        ? "..."
        : gtip
        ? util.upperCaseString(gtip)
        : null,
      clear: false,
      reset: false,
      className: "w-full gap-[0px]",
      disabled: isUpdating,
    });
  }
  const ref = React.useRef(null);

  const dispatch = useDispatch();
  const handleSaveContent = async (text) => {
    const { shippingContent, gtip } = text;
    setIsUpdating(true);

    if (shippingContent !== innerData.shippingContent) {
      dispatch(
        apiActions({
          method: "POST",
          data: { shipping_content: shippingContent ? shippingContent : "" },
          orderId: id,
        })
      ).then((rs) => {
        setIsUpdating(false);
        util.notifier(rs?.payload?.data);
        if (rs?.payload?.data?.type === "success") {
          setInnerData({ ...innerData, shippingContent: shippingContent });
          if (itemId) {
            callBack({
              action: "updateShippingContent",
              data: { id: itemId, text: shippingContent },
            });
          } else {
            dispatch(
              updateRow({
                path: `orders.[id:${orderId}].shipping_content`,
                value: shippingContent,
              })
            );
            dispatch(
              updateRow({
                path: `orders.[id:${orderId}].shipping_content_enabled`,
                value: shippingContent,
              })
            );
          }
        }
      });
    }
    if (gtip !== innerData.gtip) {
      setIsUpdating(false);
      dispatch(
        apiActions({
          action: "update-gtip",
          data: { gtip: gtip },
          orderId: id,
        })
      ).then((rs) => {
        if (rs?.meta?.requestStatus === "fulfilled") {
          setInnerData({ ...innerData, gtip: gtip });
          if (itemId) {
            callBack({
              action: "updateGtip",
              data: { id: itemId, text: gtip },
            });
          } else {
            dispatch(
              updateRow({ path: `orders.[id:${orderId}].gtip`, value: gtip })
            );
            dispatch(
              updateRow({
                path: `orders.[id:${orderId}].gtip_enabled`,
                value: gtip,
              })
            );
          }
        }
      });
    }

    return true;
  };

  const lineAnimationStyles = {
    width: "50%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    position: "absolute",
    left: "0",
    animation: "line-animation 0.5s linear infinite",
  };
  return (
    <>
      {isUpdating && (
        <div
          style={{
            top: "15px",
            left: "10px",
            width: "calc(100% - 20px)",
            height: "5px",
            backgroundColor: "white",
            position: "absolute",
            overflow: "hidden",
            borderRadius: "5px",
            background: "#jjj",
          }}
        >
          <div style={lineAnimationStyles}></div>
        </div>
      )}
      <style>
        {" "}
        {`  @keyframes line-animation { 0% { left: -50%; } 100% { left: 100%; } } @keyframes new-animation { 0% {  background: rgba(164,202, 254, 0.3); } 90% { background: rgba(164,202, 254, 0.3); } 100% { background: white; } } .new-item { animation: new-animation 1.5s linear; }`}{" "}
      </style>
      <div className={"flex-row gap-[8px] flex items-start justify-start"}>
        <div
          className={
            "min-h-[35px] flex flex-col items-center justify-start w-[300px] text-[14px]"
          }
        >
          <DynamicForm
            items={formItems}
            ref={ref}
            style={{
              label: {
                className:
                  "w-full border-t border-gray-200 pt-[12px] !text-gray-400 text-14px",
              },
              container: { className: "gap-[0px]" },
            }}
            onChange={handleSaveContent}
          />
        </div>
      </div>
    </>
  );
};

export default ShippingContent;
