import {createSlice} from "@reduxjs/toolkit"
import {fetchCalculatorData} from "./thunk";

const initialState = {
    data: [],
    status: "idle",
    calculatorValues: {},
    xClicked: false,
}

export const calculatorSlice = createSlice({
    name: "calculator",
    initialState,
    reducers: {
        removeValues: (state) => {
            state.data = []
            state.status = "idle"
            state.calculatorValues = {}
            state.xClicked = false;
        },
        updateCalculatorValues: (state, action) => {
            state.calculatorValues = action.payload
        },
        updateXButton: (state, action) => {
            state.status = "idle"
        },
    }, extraReducers: (builder) => {
        builder.addCase(fetchCalculatorData.fulfilled, (state, action) => {
            state.data = action.payload.data
            if (action.payload.message) {
                state.status = "rejected"
            } else {
                state.status = "succeeded"
            }
        }).addCase(fetchCalculatorData.pending, (state, action) => {
            state.status = "pending"
        }).addCase(fetchCalculatorData.rejected, (state, action) => {
            state.status = "rejected"
        })
    }
})

export const {removeValues, updateCalculatorValues, updateXButton} = calculatorSlice.actions

export default calculatorSlice.reducer;