import axios from "./../../middleware/axios";

const fetchShipments = async (params) => {
    let url = 'api/v1/orders/shipment-labels';
    if (params) url = url.concat(params);
    const response = await axios.get(url, {withCredentials: true});
    return response;
};

const fetchShipmentsFilters = async (params) => {
    let url = 'api/v1/orders/shipment-labels/filters';
    if (params) url = url.concat(params).concat("&filter[current_status_id]=")
    const response = await axios.get(url, {withCredentials: true});
    return response;
};

const refreshShipments = async (ids) => {
    let url = 'api/v1/orders/shipment-labels/refresh';
    // if (ids) url = url.concat(ids);
    const response = await axios.post(url, ids, {withCredentials: true});
    return response;
}

const markAsDelivered = async (params) => {
    let url = 'api/v1/orders/shipment-labels/mark-as-delivered';

    const response = await axios.post(url, params, {withCredentials: true});
    return response;
}

const markAsVoided = async (params) => {
    let url = 'api/v1/orders/shipment-labels/mark-as-voided';

    const response = await axios.post(url, params, {withCredentials: true});
    return response;
}


let urlNote = 'api/v1/orders/shipment-labels';

const fetchNotes = async (params) => {
    const url = `${urlNote}/${params?.shipmentId}/notes`;
    return await axios.get(url, {withCredentials: true,})
}

const deleteNote = async (params) => {
    const url = `${urlNote}/notes/${params?.noteId}`;
    return await axios.delete(url, {withCredentials: true,});
}

const addNote = async (params) => {
    const url = `${urlNote}/${params?.shipmentId}/notes`;
    return await axios.post(url, {note: params?.note}, {withCredentials: true,});
}


export default {
    fetchShipments,
    fetchShipmentsFilters,
    fetchNotes,
    deleteNote,
    addNote,
    refreshShipments,
    markAsDelivered,
    markAsVoided
};