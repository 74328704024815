import moment from "moment";

export const howLongFromNow = (date, includeHours, secondDate, includeMins) => {
  const past = moment(date);
  const now = moment();
  const secondDateTemp = moment(secondDate);

  let duration = secondDate
    ? moment.duration(secondDateTemp.diff(past))
    : moment.duration(now.diff(past));

  // Negatif süreleri ele almak için mutlak değeri alın
  if (duration.asMilliseconds() < 0) {
    duration = moment.duration(-duration.asMilliseconds());
  }

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (duration.asMinutes() < 1) {
    return "just now";
  }

  let formattedDuration = "";

  // Display years and months if applicable
  if (years) {
    formattedDuration += `${years}y `;
    if (months) formattedDuration += `${months}m `;
  } else if (months) {
    formattedDuration += `${months}m `;
    if (days) formattedDuration += `${days}d `;
  } else if (days) {
    // Display days and hours
    formattedDuration += `${days}d `;
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
  } else {
    // If no days, show hours and minutes
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    } else if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }
  }

  // Append "ago" to the final result
  if (formattedDuration) {
    formattedDuration += "ago";
  }

  return formattedDuration.trim();
};

export const getDate = (date) => {
  const past = new Date(date);
  return past.getMonth() + 1 + "." + past.getDate() + "." + past.getFullYear();
};

export const getTime = (date) => {
  const past = new Date(date);
  return past.getHours() + ":" + past.getMinutes();
};

export const dateHelper = (date) => {
  if (!date) {
    return "-";
  }
  return moment(date).format("DD/MM/YYYY");
};

export function timeLeft(targetDate) {
  const targetDateTime = new Date(targetDate);
  const currentDateTime = new Date();
  let timeDifference = targetDateTime - currentDateTime;
  let timeDifferenceInSeconds = timeDifference / 1000;

  // Negatif süreleri ele almak için mutlak değeri alın
  if (timeDifferenceInSeconds < 0) {
    timeDifferenceInSeconds = -timeDifferenceInSeconds;
  }

  const days = Math.floor(timeDifferenceInSeconds / (3600 * 24));
  const hours = Math.floor((timeDifferenceInSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);

  if (days < 0) {
    return howLongFromNow(targetDate, true);
  }

  let timeLeftStr = `${days}d ${hours}h left`;
  if (days > 30) {
    const months = Math.floor(days / 30);
    const remainingDays = days % 30;
    if (days > 365) {
      const years = Math.floor(days / 365);
      const remainingMonths = Math.floor((days % 365) / 30);
      timeLeftStr = `${years}y ${remainingMonths}m left`;
    } else {
      timeLeftStr = `${months}m ${remainingDays}d left`;
    }
  }

  return timeLeftStr.trim();
}

export const joinArray = (array) => {
  if (!array || array.length === 0) {
    return "";
  }
  if (array.length === 1) {
    return array[0];
  }
  return array.join(", ");
};
