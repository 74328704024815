// ------------------------------
// Good Acceptance
// ------------------------------

// Import main libraries
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// Import dynamic page libraries
import getActions from "../dynamic-page/util/getActions";
import TabButton from "../dynamic-page/elements/TabButton";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import util from "../dynamic-page/util";
import DynamicTable from "../dynamic-page";
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// Import model-view libraries
import filtersView from "./modals-view/filters";
import sortView from "./modals-view/sort";
import tableView from "./modals-view/table";
import ConfirmModal from "../dynamic-page/util/confirmModal";

// Import redux actions
import {
  fetchRequests as fetchData,
  updateFilter,
  createRequest,
  supplyFulfilment,
  updateSupplyRequest,
  updateBoundFilter,
} from "../../store/supplies";

import NewRequest from "./newRequest";
import NewSupply from "./newSupplyPurchase";
import UpdateRequest from "./update-modal";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";
import moment from "moment";
import useSupplyTabsCounts from "../../utils/useSupplyTabsCounts";

// import formView from "./models-view/form";
// import FormModal from "../dynamic-page/FormModal";

// Main Component
const Supplies = ({ product_id }) => {
  // Define hooks.
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const termParam = searchParams.get("term");
  const [searchTerm, setSearchTerm] = useState(termParam || "");

  // First page load.
  useEffect(() => {
    const startPageByFilters = {
      search: "",
      sort: "-requested_at",
      page: 1,
      filters: {
        ...(product_id && { product_id: product_id }),
      },
      limit: 10,
      includes: "product.brand.assigned,supplier",
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );
    const urlQuery = urlQueryBuilder(
      storeQuery,
      "type=supply_requests",
      "term"
    );
    let hash = window.location.hash;
    hash = hash.replace("#", "?");
    const params = new URLSearchParams(hash);
    const action = params.get("action");
    if (action === "create") {
      setVisibleModal(true);
    }

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    // dispatch(fetchFilters(urlQuery));
  }, [location]);

  let hash = window.location.hash;
  hash = hash.replace("#", "?"); // Replace first '&' with '?'
  const params = new URLSearchParams(hash);

  // Get data from store.
  const pageData = useSelector((state) => state.supplyRequests);
  const tableData = useSelector((state) => state.supplyRequests.requests);
  const productsData = useSelector((state) => state.productReducer.products);
  const user = useSelector((state) => state.authUser.authUser);
  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  // Handle form modal.
  const [calledItemInAction, setCalledItemInAction] = useState(null);

  // Handle modal.
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleFulfillModal, setVisibleFulfillModal] = useState(false);
  const [visibleUpdateModal, setVisibleUpdateModal] = useState(false);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  const giveSelectedItemId = (item) => {
    const ID = item ? [item.id] : selectedRow.map((item) => item.id);
    // setSelectedRow([])
    return ID;
  };

  const actionList = {
    createPurchase: {
      label: "Create Purchase",
      condition: (row) => row?.is_writable,
      onClick: (row) => {
        setVisibleFulfillModal(true);
        setUpdateItem(row?.id ? [row] : selectedRow);
      },
    },
    edit: {
      label: "Update",
      condition: (row) => row?.is_writable,
      bulkDisable: true,
      onClick: (row) => {
        setVisibleUpdateModal(true);
        setUpdateItem(row);
      },
    },
    delete: {
      label: "Delete",
      condition: (row) => row?.is_writable,
      onClick: (row) => {
        setCalledItemInAction(giveSelectedItemId(row));
        setConfirmationModalVisibility(true);
      },
    },
  };

  const handleTableFilters = (filters, bound) => {
    onFilterApply(filters, bound);
  };

  const actions = (row) => {
    return getActions(row, actionList);
  };

  // Initial sort list
  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  // Handle Tabs
  const onHandleTab = (item) => {
    // onFilterApply({status: item})
    switch (item) {
      case "awaiting_supply":
        navigate(`/inventory/supply_requests?term=${searchTerm}`);
        break;
      case "awaiting_payment":
        navigate(`/inventory/supply_purchases?term=${searchTerm}`);
        break;
      case "awaiting_delivery":
        navigate(`/inventory/goods_acceptance?term=${searchTerm}`);
        break;
    }
  };

  // Handle Pagination
  const onHandlePrevious = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };

  const onHandleNext = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);
    setSearchTerm(search);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });

    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    // await dispatch(fetchFilters(urlQuery))
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const onRequest = (data) => {
    setVisibleModal(false);
    dispatch(createRequest(data))
      .unwrap()
      .then((result) => {
        if (result?.type === "success") {
          toast.success(result?.data);
          onPaginationApply();
          // update the url remove the params
          window.history.replaceState(null, null, window.location.pathname);
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error("Error creating request");
      });
  };

  const onFulfill = (data, callBack) => {
    dispatch(supplyFulfilment(data))
      .unwrap()
      .then((result) => {
        util.notifier({
          type: result?.type,
          message: result?.message,
          errors: result?.errors,
        });
        callBack();
        if (result?.type === "success") {
          setVisibleFulfillModal(false);
        }

        // onPaginationApply()
      })
      .catch((error) => {
        toast.error("Error fulfilling request");
      });
  };

  const onUpdate = ({ id, payload }) => {
    dispatch(updateSupplyRequest({ id, payload }))
      .unwrap()
      .then((result) => {
        util.notifier({
          type: result?.type,
          message: result?.message,
          errors: result?.errors,
        });
        if (result?.type === "success") {
          onPaginationApply();
          setVisibleUpdateModal(false);
        }
      })
      .catch((error) => {
        toast.error("Error updating request");
      });
  };

  const onDelete = (id, setSubmit) => {
    callEndpoint({
      title: "Deleting request",
      url: `supply-request`,
      method: "DELETE",
      data: { ids: id },
      pureData: true,
    })
      .then((result) => {
        setSubmit(false);
        if (result?.type === "success") {
          onPaginationApply();
          setConfirmationModalVisibility(false);
        }
      })
      .catch((error) => {
        toast.error("Error deleting request");
      });
  };

  const [isAddClicked, setAddClicked] = useState(false);

  const exportMapper = (data) => {
    return data?.length
      ? data?.map((item) => {
          return {
            "Supply Request ID": item?.id || "-",
            "Requested At":
              moment(item?.request_date_time)?.format("YYYY-MM-DD HH:mm:ss") ||
              "-",
            "Supply Reason": item?.reason || "-",
            "Product ID": item?.product?.apid || "-",
            "Product Name": item?.product?.title || "-",
            Quantity: item?.qty || "-",
            "Product Image URL": item?.product?.image || "-",
            "Product Type": item?.product?.type_name || "-",
            "Product Brand": item?.product?.brand?.name || "-",
            "Product Assign": item?.product?.assign?.name || "-",
            "Requested Supplier Name": item?.supplier?.name || "-",
            "Supply URL": item?.url || "-",
            Currency: item?.max_acceptable_price_currency?.code || "-",
            "Max Acceptable Price":
              item?.max_acceptable_price?.[
                item?.max_acceptable_price_currency?.code
              ] || "-",
            "Max Acceptable Price (TRY)":
              item?.max_acceptable_price?.TRY || "-",
            "Max Acceptable Price (USD)":
              item?.max_acceptable_price?.USD || "-",
          };
        })
      : [];
  };

  const headers = [
    "Supply Request ID",
    "Requested At",
    "Supply Reason",
    "Product ID",
    "Product Name",
    "Quantity",
    "Product Image URL",
    "Product Type",
    "Product Brand",
    "Product Assign",
    "Requested Supplier Name",
    "Supply URL",
    "Currency",
    "Max Acceptable Price",
    "Max Acceptable Price (TRY)",
    "Max Acceptable Price (USD)",
  ];

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {!product_id && (
          <>
            <ArbitProductPage.Title
              title={"Supply"}
              allowExport={true}
              exported={{
                exportData: selectedRow?.length > 0 ? selectedRow : tableData,
                exportMapper: exportMapper,
                headers: headers,
              }}
              loading={pageData.loading}
              filter={{
                filters: pageData?.boundFilters,
                pageFilters: pageData?.filters?.filters,
                items: filtersView(pageData?.filters?.filters),
                callback: (rs, bound) => {
                  onFilterApply(rs, bound);
                },
                count:
                  Object.values(pageData?.filters?.filters ?? {}).filter(
                    (item) => item !== null && item !== ""
                  ).length -
                  (pageData?.filters?.filters?.status === undefined ||
                  pageData?.filters?.filters?.status === null
                    ? 0
                    : 1),
              }}
              sort={{
                items: sortList,
                selected: pageData?.filters?.sort,
              }}
              form={{
                callback: (rs) => {
                  setVisibleModal(true);
                },
              }}
              actionList={actions(selectedRow)}
            />

            <div
              className={
                "flex flex-row justify-between px-[16px] py-[4px] items-center"
              }
            >
              <SearchInput
                className="w-[362px]"
                placeholder="Search..."
                onSubmit={onSearchApply}
                defaultValue={pageData?.filters?.search}
              />

              <TabButton
                className="whitespace-nowrap"
                selectedClassName="whitespace-nowrap"
                callBack={onHandleTab}
                active="awaiting_supply"
                list={[
                  {
                    id: "awaiting_supply",
                    name: "Awaiting Purchase",
                  },
                  {
                    id: "awaiting_payment",
                    name: "Awaiting Acceptance",
                  },
                  {
                    id: "awaiting_delivery",
                    name: "Goods Acceptances",
                  },
                ]}
              />
            </div>
          </>
        )}

        <ArbitProductPage.Content className="content">
          {!product_id && (
            <style>
              {!product_id
                ? `
                             @media (768px <= width <= 1000px) {
                                .content .contt {
                                    height: calc(100vh - 220px) !important;
                                }
                            }
                            @media (max-width: 767px) {
                                .content .contt {
                                    height: calc(100vh - 314px) !important;
                                }
                            }
                    `
                : `
                    height: 200px !important;
                    `}
            </style>
          )}
          <DynamicTable
            view={tableView}
            mini={!!product_id}
            hiddenFields={product_id ? ["product", "image"] : []}
            data={tableView(tableData, handleTableFilters)}
            actions={actions}
            onSelect={onSelectRow}
            selectedRow={selectedRow}
            meta={pageData?.meta}
            appending={loading}
            loadMoreData={loadMoreData}
            loading={pageData.loading}
            style={{
              table: {
                style: { width: "100%", minWidth: "100%" },
              },
              hideSelect: !!product_id,
              header: {
                className: "justify-center !px-[0px]",
                "tracking id": { className: "!pr-[30px]" },
                approve: { className: "!py-[0px] hidden" },
                id: { className: "hidden" },
                note: { className: "hidden" },
                image: { className: "hidden" },
                status: { className: "hidden" },
              },
              row: {
                className:
                  "!px-[8px] !py-[0px] !h-[64px] !items-center !w-[100%] !justify-center flex text-[12px] ",
                // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                product: {
                  className: "!justify-center !min-w-[400px] w-[100%]",
                  td: "w-[100%]",
                },
                "supply channel": {
                  className: " !justify-start",
                  td: product_id ? "w-[100%]" : "",
                },
                _actions: { className: "!py-[0px]" },
              },
            }}
          />
        </ArbitProductPage.Content>

        {!product_id && (
          <FooterPagination
            meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
            pageData={pageData}
            loading={pageData.loading}
            onLimit={onHandleLimit}
          />
        )}
      </ArbitProductPage.Page>

      {visibleModal && (
        <NewRequest
          show={visibleModal}
          setShow={setVisibleModal}
          onClose={() => {
            window.history.replaceState(null, null, window.location.pathname);
            setVisibleModal(false);
            setAddClicked(false);
          }}
          onRequest={onRequest}
          user={user}
          productsData={productsData}
          params={params}
          isAddClicked={isAddClicked}
          setAddClicked={setAddClicked}
        />
      )}

      {visibleFulfillModal && (
        <NewSupply
          show={visibleFulfillModal}
          productsData={productsData}
          setShow={setVisibleFulfillModal}
          onClose={() => {
            setVisibleFulfillModal(false);
          }}
          user={user}
          selectedSupply={updateItem}
          setUpdateItem={setUpdateItem}
          updateItem={updateItem}
          onFulfill={onFulfill}
          actions={actionList}
        />
      )}
      {visibleUpdateModal && (
        <UpdateRequest
          show={visibleUpdateModal}
          setShow={setVisibleUpdateModal}
          onClose={() => {
            setVisibleUpdateModal(false);
          }}
          updateItem={updateItem}
          onUpdate={onUpdate}
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          className={"z-[99999999]"}
          show={confirmationModalVisibility}
          infoText="Are you sure you want to delete the selected supply request/s?"
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText="Yes, Delete Request"
          confirmColor="red"
          cancelText="Cancel"
          cancelColor="blue"
          styles={{
            confirmButton: "!bg-red-50 ",
            cancelButton: "!bg-white",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            onDelete(calledItemInAction, setSubmit);
          }}
        />
      )}
    </>
  );
};

export default Supplies;
