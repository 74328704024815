import { useEffect, useState } from "react";
import DirtySelect from "../../../dynamic-form/elements/DirtySelect";
import { toast } from "react-toastify";
import axios from "axios";

const Description = ({ id, productData }) => {
  const [isValuePosted, setValuePosted] = useState(null);
  const [isDeleteClicked, setDeleteClicked] = useState(false);

  const [hasValue, setHasValue] = useState(null);

  useEffect(() => {
    if (productData) {
      setHasValue(
        productData?.attributes?.Other?.find(
          (item) => item?.attribute_id === 15
        ) ?? null
      );
    }
  }, [productData]);

  const handleValueClicked = async (props) => {
    if (props?.value) {
      if ((hasValue || isValuePosted) && !isDeleteClicked) {
        await axios
          .put(
            `/api/v1/attribute-value/${isValuePosted?.id || hasValue?.id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setDeleteClicked(false);
              toast.success(res?.data?.message);
              setValuePosted(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
            } else {
              toast.error(res?.data?.message);
            }
          });
      } else {
        await axios
          .post(
            `/api/v1/attribute-value/${props.attributeId}/${id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setValuePosted(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
              setDeleteClicked(false);
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          });
      }
    } else {
      try {
        const res = await axios.delete(
          `/api/v1/attribute-value/${isValuePosted?.id || hasValue?.id}`,
          { withCredentials: true }
        );

        if (res?.status === 204) {
          toast.success("Attribute has been deleted successfuly");
          setValuePosted("");
          setDeleteClicked(true);
          props.setDeleteModal(false);
          props.reset({ value: "" });
        } else {
          toast.error(res?.data?.message);
        }
      } catch (error) {
        toast.error("An error occurred");
        console.error(error);
      }
    }
  };

  const [isValuePosted2, setValuePosted2] = useState(null);
  const [isDeleteClicked2, setDeleteClicked2] = useState(false);

  const [hasValue2, sethasValue2] = useState(null);

  useEffect(() => {
    sethasValue2(
      productData?.attributes?.Other?.find(
        (item) => item?.attribute_id === 75
      ) ?? null
    );
  }, [productData]);

  const handleValueClicked2 = async (props) => {
    if (props?.value) {
      if ((hasValue2 || isValuePosted2) && !isDeleteClicked2) {
        await axios
          .put(
            `/api/v1/attribute-value/${isValuePosted2?.id || hasValue2?.id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setDeleteClicked2(false);
              toast.success(res?.data?.message);
              setValuePosted2(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
            } else {
              toast.error(res?.data?.message);
            }
          });
      } else {
        await axios
          .post(
            `/api/v1/attribute-value/${props.attributeId}/${id}`,
            { value: props?.value, locale: "40" },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.status === 200) {
              setValuePosted2(res?.data?.data?.attributeValue);
              props.reset({ value: props?.value });
              setDeleteClicked2(false);
              toast.success(res?.data?.message);
            } else {
              toast.error(res?.data?.message);
            }
          });
      }
    } else {
      try {
        const res = await axios.delete(
          `/api/v1/attribute-value/${isValuePosted2?.id || hasValue2?.id}`,
          { withCredentials: true }
        );

        if (res?.status === 204) {
          toast.success("Attribute has been deleted successfuly");
          setValuePosted("");
          setDeleteClicked2(true);
          props.setDeleteModal(false);
          props.reset({ value: "" });
        } else {
          toast.error(res?.data?.message);
        }
      } catch (error) {
        toast.error("An error occurred");
        console.error(error);
      }
    }
  };

  return (
    <div className="flex  gap-4  flex-col ">
      <DirtySelect
        isValue={hasValue}
        texts={{
          tooltipTitle: "Product Description",
          tooltipDesc:
            " Provide a detailed description of the product. Include features, use cases, customer benefits, and any important information to help buyers understand the value and specifics of the product. This field will also be used for generating listings using AI.",
        }}
        id={id}
        attributeId={"15"}
        className={"w-full "}
        title={"Description"}
        placeholder={"type Description..."}
        textArea={true}
        handleValueClicked={handleValueClicked}
      />
      <DirtySelect
        isValue={hasValue2}
        texts={{
          tooltipTitle: "Internal Note",
          tooltipDesc:
            "This field is for internal use only. Include important internal information about the product, supply chain warnings, or any specific details that need special attention during handling or processing.",
        }}
        id={id}
        attributeId={"75"}
        placeholder={"type Internal Note..."}
        className={"w-full"}
        title={"Internal Note"}
        textArea={true}
        handleValueClicked={handleValueClicked2}
      />
    </div>
  );
};

export default Description;
