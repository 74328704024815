import { createSlice } from "@reduxjs/toolkit";

import {
  createRequest,
  deleteRequest,
  fetchFilters,
  fetchRequests,
  supplyFulfilment,
  updateSupplyRequest,
} from "./thunk";

const initialState = {
  requests: [],
  loading: "idle",
  error: null,
  isLogin: false,
  filtersMeta: {},
  boundFilters: {},
  links: {},
  filters: {
    updated_at: "",
    payment_status: [],
    supply_status: [],
    delivery_status: [],
    quantity: "",
  },
  filterLoading: "idle",
};

export const supplySlice = createSlice({
  name: "supply",
  initialState,
  reducers: {
    updateBoundFilter: (state, action) => {
      let { filters, bound } = action.payload;
      if (!filters && !bound) {
        return {
          ...state,
          // if there
          boundFilters: {
            ...action.payload,
          },
        };
      }
      let currentBoundFilters = state.boundFilters;
      // if there is a key in the filters that is also in the state.boundFilters, remove taht key from the filters
      if (filters) {
        Object?.keys(filters).forEach((key) => {
          if (filters[key] === null) {
            currentBoundFilters[key] = null;
          }
        });

        // make the null keys in filters null in boundFilters
      }
      const updatedBoundFilters = { ...filters, ...bound };
      state.boundFilters = { ...currentBoundFilters, ...updatedBoundFilters };
      // return {
      //   ...state,
      //   // if there
      //   boundFilters: {
      //     ...state.boundFilters,
      //     ...updatedBoundFilters
      //   },
      // };
    },
    updateFilter: (state, action) => {
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    },
    fetchLoading: (state, action) => {
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequests.fulfilled, (state, action) => {
        state.loading = "idle";
        state.requests = action.payload?.append
          ? [...state.requests, ...action.payload.data]
          : action.payload.data;
        state.links = action.payload["links"];
        state.meta = action.payload["meta"];
      })
      .addCase(fetchRequests.rejected, (state, action) => {
        if (state.loading === "pending") {
          if (action.error?.name !== "CanceledError") {
            state.loading = "idle";
            state.error = action.error;
          }
        }
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.filtersMeta = action.payload.data;
        state.filterLoading = "idle";
      })
      .addCase(fetchFilters.pending, (state, action) => {
        state.filterLoading = "pending";
      });
  },
});

export default supplySlice.reducer;

export const { updateFilter, updateBoundFilter, fetchLoading } =
  supplySlice.actions;

export const supplyActions = supplySlice.actions;

export {
  fetchRequests,
  createRequest,
  supplyFulfilment,
  fetchFilters,
  deleteRequest,
  updateSupplyRequest,
};
