import Icons from "../../orders/icons";
import UI from "../../dynamic-form/elements";
import uti from "../../dynamic-page/util";
import util from "../../dynamic-page/util";
import React, { useEffect, useState } from "react";
// import ShipmentLabelList from "./shipmentLabelList";
import { useDispatch, useSelector } from "react-redux";
import zplPrint from "../../print-page/zpl";
import { FaTruckFast } from "react-icons/fa6";
import descriptiveContent from "../../../utils/descriptiveContent";

const ShipmentLabel = ({ shipment, callBack }) => {
  const dispatch = useDispatch();

  const [shipmentData, setShipmentData] = useState(null);
  const data = useSelector((state) => state.orders.orders);

  useEffect(() => {
    setShipmentData(shipment);
  }, [data, shipment]);

  const handlePrintLabel = (print) => {
    const fileUrl = shipment?.label_files?.png;
    // fileUrl.forEach((item) => {
    zplPrint.getAndSendFileToPrinter(fileUrl);
  };
  const handleViewLabel = (print) => {
    const fileUrl = shipment?.label_files?.pdf;
    util.pdfViewer(fileUrl, false, `Shipment Label`);
  };

  return (
    <div className={"max-w-[300px] w-full"}>
      {shipmentData && (
        <div
          className={"flex flex-col gap-[4px]  justify-start w-full"}
          style={{ width: "100%" }}
        >
          <div
            className={
              "flex flex-row justify-between items-center gap-[16px] w-full"
            }
            style={{ width: "100%" }}
          >
            <div>
              <div
                className={
                  "flex flex-row gap-[8px] box-content w-full items-center "
                }
              >
                {descriptiveContent(
                  shipment?.international_carrier?.logo ? (
                    <img
                      className={"w-[16px] h-[16px]"}
                      src={shipment?.international_carrier?.logo}
                    />
                  ) : (
                    <FaTruckFast className="text-gray-500 w-[15px] h-{15px]" />
                  ),
                  shipment?.international_carrier?.name
                )}
                <span
                  onClick={() => window.open(shipment?.tracking?.url, "_blank")}
                  className={
                    "cursor-pointer flex flex-row gap-[4px] items-center"
                  }
                >
                  <span>
                    {/* {util.shortenString(shipment?.tracking?.number, 20)} */}
                    {shipment?.tracking?.number}
                  </span>
                  <Icons.goToTrackURL
                    className={"text-gray-300 hover:text-gray-500 w-[9px]"}
                  />
                </span>
                <util.copyText
                  className={"text-[12px]"}
                  text={shipment?.tracking?.number}
                  hint={""}
                />
              </div>
            </div>
          </div>

          <div
            className={"flex flex-row w-full justify-between gap-[16px]"}
            style={{ width: "100%" }}
          >
            <span className={"text-gray-600 min-w-[150px] flex-grow"}>
              <util.expandableText
                maxLines={1}
                className={"text-[12px] "}
                tooltip={true}
                forcePosition={"top"}
                tooltipClassName={"text-[14px] text-gray-900 !max-w-[350px]"}
                action={"hover"}
                delay={500}
                hideCopy={true}
              >
                {uti.upperCaseString(shipment?.carrier_service?.name, "word")}
              </util.expandableText>
            </span>
            <div
              className={
                "text-blue-600 h-[16px] flex flex-row items-center gap-[4px]"
              }
            >
              <UI.Button
                item={{ buttonType: "flat", title: "Show Label" }}
                onClick={handleViewLabel}
              >
                <Icons.showLabel className={"w-[20px] p-[0px]"} />
              </UI.Button>

              <UI.Button
                item={{ buttonType: "flat", title: "Print Label" }}
                onClick={handlePrintLabel}
              >
                <Icons.printLabel className={"w-[20px] p-[2px]"} />
              </UI.Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShipmentLabel;
