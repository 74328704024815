/**
 * @author Austin Ames
 * @description ProductOrdersPage
 */

import Orders from "../orders";

const ProductOrdersPage = ({product_id}) => {
    return (
        <div>
            <Orders key='product_detail_order' product_id={product_id} displayTitle={'hidden'} displayTabGroups="show"/>
        </div>
    )
}

export default ProductOrdersPage;