import upperCaseString from "../../dynamic-page/util/upperCaseString";
import React from "react";

const FiltersView = (selectedFilers) => {
  return [
    {
      name: "created_at",
      label: "Create Date",
      placeholder: "Filter by create date",
      type: "DateRange",
      defaultValue: selectedFilers?.created_at || null,
      toggle: true,
      clear: true,
    },
    {
      name: "expiry_date",
      label: "Expiry Date",
      placeholder: "Filter by Expiry date",
      type: "DateRange",
      defaultValue: selectedFilers?.expiry_date || null,
      toggle: true,
      clear: true,
    },
    {
      name: "created_by",
      label: "Created By",
      placeholder: "Filter by employee",
      type: "Select",
      api: {
        url: "/api/v1/users",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          avatar: item?.image_url,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, avatar }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "block overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                src={avatar || "/assets/images/defaultAvatar.png"}
                className="w-5 h-5 rounded-full object-contain"
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.created_by,
      toggle: true,
      clear: true,
    },
    {
      name: "quantity",
      label: "Quantity",
      placeholder: "Filter by Quantity",
      type: "Range",
      defaultValue: selectedFilers?.quantity || null,
      toggle: true,
      clear: true,
    },
    {
      name: "product",
      label: "Product",
      type: "Select",
      api: {
        url: "/api/v1/product",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCaseString(item?.title, "word", true),
        }),
      },

      reset: false,
      clear: false,
      toggle: true,
      style: {
        control: {
          minHeight: "auto",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      placeholder: "Filter by products",
      defaultValue: selectedFilers?.product,
    },
    {
      name: "warehouse",
      label: "Warehouse",
      placeholder: "Filter by warehouse",
      type: "Select",
      api: {
        url: "api/v1/warehouses",
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          avatar: item?.image_url,
        }),
      },
      features: {
        isMulti: true,
      },
      defaultValue: selectedFilers?.warehouse,
      toggle: true,
      clear: true,
    },
  ];
};

export default FiltersView;
