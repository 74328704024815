import React from "react";
import util from "../../dynamic-page/util";
import Components from "../components";
import components from "../../orders/components";
import moment from "moment";
import {howLongFromNow} from "../../../utils/helper";

const tableView = (list, callBack) => {
    return {
        list: list,
        theme: {
            "control": (item) => (
                <div className={"w-[50px] h-[50px]"}>
                    <components.orderRowAction actions={{
                        order_id: item?.order?.id,
                        customer_note: item?.order?.customer_note,
                        internal_notes: item?.order?.internal_notes,
                        shipping_content: item?.order?.shipping_content,
                        shipping_documents: item?.order?.shipping_documents,

                        customer_note_enabled: item?.order?.customer_note_enabled,
                        internal_notes_enabled: item?.order?.internal_notes_enabled,
                        shipping_content_enabled: item?.order?.shipping_content_enabled,
                        shipping_documents_enabled: item?.order?.shipping_documents_enabled,
                    }}/>
                </div>
            ),
            "status": (item) => (
                <div className={"w-full h-[64px] flex flex-col gap-1 justify-center items-center relative"}>
                    <components.orderStatus statusId={item?.order_status_id} color={item?.order_status_color_code}
                                            type='notification'
                                            text={item?.order_status}
                                            order={item?.order}
                        // orderId={item?.id}
                        // orderHistory={<StatusHistory title='Order History' list={statusLabel(item)} />}
                        // callBack={callBack}
                    />
                </div>
            ),
            'order date': (item) => {
                return (
                    <util.dateDisplay item={item} date={item?.purchase_date} />
                )
            },
            "order id": (item) => {
                return (
                    <div className={"flex flex-col gap-[0px] relative justify-center"}>
                        <Components.orderId callBack={callBack} orderId={item?.order?.id}
                                            order={{order_number: item?.order_number, ...item?.account}}/>
                    </div>
                );
            },
            "change date": (item) => {
                return (
                    <util.dateDisplay item={item} date={item?.change_date} />
                );
            },
            "f. type": (item) => {
                return (
                    <div className="flex flexr-row justify-between gap-x-2 items-center w-max">
                        <span
                            className="uppercase text-[14px] font-normal text-gray-900">{item?.fulfillment_type}</span>
                        <util.copyText className={"text-[12px]"} text={item?.fulfillment_type} hint={false}/>
                    </div>
                );
            },
            "current status": (item) => {
                return (
                    <div className="flex flexr-row justify-between gap-x-2 items-center w-max">
                        <span className="capitalize text-[14px] font-normal text-gray-900">{item?.order_status}</span>
                        <util.copyText className={"text-[12px]"} text={item?.order_status} hint={false}/>
                    </div>
                );
            },
            "notification level": (item) => {
                return (
                    <div className="flex flexr-row justify-between gap-x-2 items-center w-max">
                        <span
                            className="capitalize text-[14px] font-normal text-gray-900">{item?.notification_level}</span>
                        <util.copyText className={"text-[12px]"} text={item?.notification_level} hint={false}/>
                    </div>
                );
            },
            "change type": (item) => {
                return (
                    <div className="flex flexr-row justify-between gap-x-2 items-center w-max">
                        <span className="capitalize text-[14px] font-normal text-gray-900">{item?.change_type}</span>
                        <util.copyText className={"text-[12px]"} text={item?.change_type} hint={false}/>
                    </div>
                );
            },
            "change reason": (item) => {
                return (
                    <div className="flex flexr-row justify-between gap-x-2 items-start w-max">
                        <span className="capitalize text-[14px] font-normal text-gray-900">{item?.change_reason}</span>
                        <util.copyText className={"text-[12px]"} text={item?.change_reason} hint={false}/>
                    </div>
                );
            }
        }
    };
}
export default tableView;