import React, { memo, useEffect, useRef, useState } from "react";
import tabs, { tabList } from "./tabs";
import MainModal from "../../../dynamic-page/MainModal";
import TabButton from "../../../dynamic-page/elements/TabButton";
import {
  apiActions,
  createOrder,
  viewOrder as viewRow,
} from "../../../../store/orders";
import { useDispatch } from "react-redux";
import Pending from "../../../dynamic-page/elements/pending";
import notifier from "../../../dynamic-page/util/notifier";
import handlePrepareDataToPost from "./summary/util/prepareDataToPost";
import prepareDataToView from "./summary/util/prepareDataToView";
import util from "../../../dynamic-page/util";
import DynamicForm from "../../../dynamic-form";
import warehouseListForm from "../create-shipment/model-view/warehouseForm";
import Icons from "../../icons";
import Util from "../../../dynamic-page/util";

const OrderDetails = memo(
  ({ submit, order, cancel, update, selectedOrder }) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState("summary");
    const [detailData, setDetailData] = useState({});
    const [isView, setIsView] = useState(selectedOrder ? true : false);
    const [loadViewState, setLoadViewState] = useState(false);
    const tabContentRef = useRef(null);
    const [allNotes, setAllNotes] = useState(selectedOrder?.internal_notes);

    const Tab = memo(tabs[selectedTab]);

    useEffect(() => {
      if (order) {
        setIsView(true);
        setLoadViewState(false);
        setDetailData(null);
        dispatch(viewRow(order)).then((rs) => {
          const returnData = rs?.payload?.data;
          const viewData = prepareDataToView(returnData, selectedOrder);
          setDetailData(viewData);
          setLoadViewState(true);
        });
      }
    }, [order]);

    const handleTabClick = async (tabName, validate) => {
      let formData = await tabContentRef?.current?.getData(validate);
      let tabValidation = formData?._validation?.isValid;
      const detail = {
        ...detailData,
        [selectedTab]: { ...detailData[selectedTab], ...formData },
      };

      if (!formData?.order_items?.length && selectedTab === "summary") {
        util.notifier({
          type: "error",
          message: "Please add atleast one item",
        });
      }

      if (tabValidation || order) {
        //if (tabName !== selectedTab) {
        setDetailData(detail);
        setSelectedTab(tabName);
        //}
      }

      return { tabValidation: tabValidation, formData: detail };
    };

    const handleUpdateDetailData = async (data, state) => {
      const actionList = {
        save: async () => {
          const { tabValidation, formData } = await handleTabClick(
            selectedTab,
            false
          );

          if (tabValidation) {
            const payload = await handlePrepareDataToPost(formData);
            const postData = {
              data: payload,
              orderId: isView ? order : null,
            };
            tabContentRef.current.disableButton();
            dispatch(createOrder(postData)).then((res) => {
              tabContentRef.current.enableButton();
              if (res?.payload) notifier(res.payload);
              if (res?.payload?.type === "success") {
                submit();
              }
              // openOrder(res.payload?.data?.id);
            });
          }
        },
        switchTab: (data) => {
          handleTabClick(data);
        },
        updateContent: async (data) => {
          const { tabValidation, formData } = await handleTabClick(
            selectedTab,
            false
          );
        },
        warehouse: (data) => {
          dispatch(
            apiActions({
              method: "POST",
              orderId: order,
              data: data,
            })
          ).then((res) => {
            notifier(res.payload.data);
            if (res.payload.data.type === "success") {
              // setWarehouse(data?.allocation_warehouse_id);
              setDetailData({
                ...detailData,
                summary: {
                  ...detailData.summary,
                  arbitbox_status: res?.payload?.data?.data?.order_status,
                  allocation_warehouse_id: data?.allocation_warehouse_id,
                },
              });
              update(res?.payload?.data?.data);
            }
          });
        },
      };

      if (state) {
        actionList[state.action](data);
      }
    };
    const myFormRef = useRef();
    const label = (
      <div className="flex items-center gap-1">
        Allocation Warehouse
        <>
          <Util.popUp
            toggle={(selected) => (
              <Icons.info className="w-5 h-5 text-gray-300" />
            )}
            action="hover"
            forcePosition={"bottom"}
          >
            <div className="flex flex-col gap-1.5 p-1.5 px-2 w-[386px]">
              <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                Allocation Warehouse
              </span>
              <span className="text-gray-500 text-[12px] leading-[15px] font-normal">
                This is the warehouse from which the order{" "}
                <b>will be dispatched.</b>
                <br />
                Selecting a single warehouse can help{" "}
                <b className="">reduce shipping costs </b> by consolidating
                items into one shipment.
                <br />
                If left blank, the default behavior will be applied.
              </span>
            </div>
          </Util.popUp>
        </>
      </div>
    );
    return (
      <MainModal
        headerClassName={"!text-[24px] !font-semibold !text-gray-900"}
        option={
          loadViewState && (
            <div
              className={`flex items-center gap-1 !text-[16px] !font-normal transition-all
           ${!loadViewState ? "opacity-0" : "opacity-100"}`}
            >
              <DynamicForm
                items={warehouseListForm(
                  {
                    defaultWarehouse:
                      detailData?.summary?.allocation_warehouse_id || null,
                  },
                  null,
                  label
                )}
                ref={myFormRef}
                style={{
                  container: {
                    className:
                      "!flex-row justify-between items-center text-[16px] mb-[8px]",
                  },
                  label: {
                    className:
                      "!min-w-[195px] !text-[16px] !font-medium !text-gray-700",
                  },
                }}
                onChange={(data) => {
                  handleUpdateDetailData(
                    { allocation_warehouse_id: data?.warehouse },
                    { action: "warehouse" }
                  );
                }}
              />
            </div>
          )
        }
        item={{
          title: isView ? detailData?.summary?.order_id : "New Order",
          submit: (data) => {
            submit(data);
          },
          cancel: () => {
            cancel();
          },
          view: (item) => (
            <div
              className={
                "flex flex-col gap-[8px] w-full h-full  orderDetailModal"
              }
              style={{
                width: "calc(100vw - 80px)",
                maxWidth: "1800px",
              }}
            >
              {!order || loadViewState ? (
                <>
                  <TabButton
                    list={tabList(isView)}
                    active={selectedTab}
                    callBack={(tab) => {
                      const validate = isView ? false : true;
                      handleTabClick(tab, validate);
                    }}
                    className={"!border-b !px-[17px] !gap-[6px] relative "}
                    selectedClassName={" !px-[17px] !gap-[8px] relative "}
                    mainClassName={
                      "!justify-start !gap-[16px]  border-b-[#E5E7EB]"
                    }
                  />

                  <Tab
                    ref={tabContentRef}
                    selectedOrder={selectedOrder}
                    setAllNotes={setAllNotes}
                    allNotes={allNotes}
                    data={detailData}
                    callBack={handleUpdateDetailData}
                    selectedTab={selectedTab}
                    isView={isView}
                    update={update}
                    id={isView ? { order: order } : null}
                  />
                </>
              ) : (
                <Pending count={1} h={20} />
              )}
            </div>
          ),
        }}
        mainClassName={"!py-[30px] "}
      />
    );
  },
  () => true
);

export default OrderDetails;
