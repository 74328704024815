import React, { memo, useState, useEffect } from "react";

const MainModal = memo(
  ({
    item,
    option,
    className,
    mainClassName,
    headerClassName,
    optionClassName,
    containerClassName,
    zIndex,
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      if (item) {
        setIsOpen(true);
      }
    }, [item]);

    const handleClose = () => {
      setIsOpen(false);
      setTimeout(() => {
        item?.cancel();
      }, 300); // Adjust the timing according to your animation duration
    };

    // const handleClose = () => {
    //       item?.cancel();
    // };
    return (
      <div
        tabIndex="-1"
        className={` transition-opacity overflow-y-auto h-[100vh]  css-1j12  overflow-x-auto fixed top-0 right-0 left-0 z-[500] w-[100vw] bg-gray-700/60 item-center items-center align-middle flex box-border ${
          mainClassName ? mainClassName : ""
        } ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
        onClick={handleClose}
      >
        <div
          className={`!rounded-[8px]  bg-white  ${
            zIndex ? "" : "css-1j124qj"
          }   min-h-max my-[50px] h-fit  max-w-[calc(100vw - 50px)] m-auto ${
            className ? className : ""
          } ${isOpen ? "scale-100" : "scale-90"}`}
          style={{ margin: "auto auto" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <div
              className={`flex justify-between items-end rounded-lg  sticky top-0 bg-white p-4 pb-[16px] z-[20]  ${
                headerClassName && headerClassName
              }`}
            >
              {item?.stitle}
              <span className="text-[18px] !font-semibold  tracking-wide  !leading-[27px] text-gray-900 py-[0px]">
                {item?.title}
              </span>
              <div
                className={`flex items-center h-full gap-[32px] ${
                  optionClassName && optionClassName
                }`}
              >
                {option && option}
                <button
                  type="button"
                  className="transition text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={handleClose}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div
              className={`pt-[0px] pb-[12px] px-[16px] gap-[16px] transition ${
                containerClassName && containerClassName
              }`}
            >
              {item?.view(item?.data)}

              {item?.actionButton && (
                <div
                  className={
                    "pt-[20px] pb-[16px] flex flex-row justify-end gap-[8px]"
                  }
                >
                  {item?.actionButton}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default MainModal;
