import axios from '../../middleware/axios';
import constants from '../../constant';

const fetchTargetSellerFolder = async (params) => {
    const response = await axios.get(constants.APIURL.GET_TARGETSELLERFOLDERS + '/' + params + '?include=salesChannel,marketplace,create_user,update_user,shipmentCosts,currency');
    return response.data;
}

const fetchTargetSellers = async (params) => {
    try {
        const response = await axios.get(constants.APIURL.GET_TARGETSELLERFOLDERS + '/' + params.id + '/sellers' + (params?.query ? params.query : ''), {
            signal: params?.signal
        });
        return response;
    } catch (error) {
        if (error.name === 'AbortError') {
            // You might want to throw an error here or handle it appropriately
        } else {
            console.error('Error:', error);
            throw error; // Propagate the error if it's not an AbortError
        }
    }
}

//use fetch instead of axios
// const fetchTargetSellers = async (params) => {
//   let url = 'https://b0bdd107-194d-4091-91ad-9e744030ac62.mock.pstmn.io/target-seller-folders/1/sellers'
//   const response = await fetch(url);
//   const data = await response.json();
//   return data;
// }

const fetchTargetSellerListings = async (params) => {
    try {
        const response = await axios.get(constants.APIURL.GET_TARGETSELLERFOLDERS + '/' + params.id + '/listings' + (params?.query ? params.query : ''), {
            signal: params?.signal
        });
        return response;
    } catch (error) {
        if (error.name === 'AbortError') {
            // You might want to throw an error here or handle it appropriately
        } else {
            console.error('Error:', error);
            throw error; // Propagate the error if it's not an AbortError
        }
    }
}


//use fetch instead of axios
// const fetchTargetSellerListings = async (params) => {
//   let url = 'https://b0bdd107-194d-4091-91ad-9e744030ac62.mock.pstmn.io/target-seller-folders/1/listings'
//   const response = await fetch(url);
//   const data = await response.json();
//   return data;
// }

const fetchTargetSellersFilters = async (params) => {
    const response = await axios.get('/api/v1/target-sellers/filters?filter[target_seller_folder_id]=' + params.id + (params?.query ? replaceThefirstChar(params.query) : ''));
    return response;
}

const replaceThefirstChar = (str) => {
    // get a string and if the first char is ? replace it with &
    if (str[0] === '?') {
        return str.replace('?', '&');
    }
    return str;
}

const fetchTargetSellerListingsFilters = async (params) => {
    const response = await axios.get('/api/v1/target-seller-listings/filters?filter[target_seller_folder_id]=' + params?.id + (params?.query ? replaceThefirstChar(params.query) : ''));
    return response;
}


const handleApprovementAction = async (params) => {
    const {folderId, dataId, type, action} = params;

    if (!['listings', 'sellers'].includes(action)) {
        throw new Error('Invalid type. Type should be either "listings" or "sellers".');
    }

    if (!['approve', 'reject'].includes(type)) {
        throw new Error('Invalid action. Action should be either "approve" or "reject".');
    }

    const response = await axios.post(`${constants.APIURL.GET_TARGETSELLERFOLDERS}/${folderId}/${action}/${type}/bulk`, {ids: params?.data});
    return response;
}

const addTargetSeller = async ({id, payload}) => {
    const response = await axios.post(`${constants.APIURL.GET_TARGETSELLERFOLDERS}/${id}/seller`, payload);
    return response
}

const updateWeight = async (params) => {
    const {id, payload} = params;
    const response = await axios.post(`api/v1/target-seller-listings/${id}`, payload);
    return response
}

let urlNote = 'api/v1/target-seller-listings';

const fetchNotes = async (params) => {
    const url = `${urlNote}/${params?.listId}/notes`;
    return await axios.get(url, {withCredentials: true,})
}


const deleteNote = async (params) => {
    const url = `${urlNote}/${params?.listId}/notes/${params?.noteId}`;
    return await axios.delete(url, {withCredentials: true,});
}


const addNote = async (params) => {
    const url = `${urlNote}/${params?.listId}/notes?note=${params?.note}`;
    return await axios.post(url, {withCredentials: true,});
}


export default {
    fetchTargetSellerFolder,
    fetchTargetSellers,
    fetchTargetSellerListings,
    handleApprovementAction,
    addTargetSeller,
    fetchTargetSellersFilters,
    fetchTargetSellerListingsFilters,
    updateWeight,
    fetchNotes,
    deleteNote,
    addNote
}
