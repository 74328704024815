import React from "react";
import { FaUsers } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import moment from "moment";
import { howLongFromNow } from "../../../utils/helper";
import Util from "../../dynamic-page/util";
import util from "../../dynamic-page/util";
import { Tooltip } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const TableView = (list) => {
  const navigate = useNavigate();

  const statusList = {
    1: (
      <div className="w-[70px] bg-green-100 text-green-600 h-[22px] py-[2px] px-[10px] flex justify-center rounded-[6px] items-center gap-1 text-[12px] font-[600]">
        Active
      </div>
    ),
    0: (
      <div className="w-[70px] bg-gray-200 text-gray-400 h-[22px] py-[2px] px-[10px] flex justify-center rounded-[6px] items-center gap-1 text-[12px] font-[600]">
        Inactive
      </div>
    ),
    2: (
      <div className="w-[70px] bg-red-100 text-red-600 h-[22px] py-[2px] px-[10px] flex justify-center rounded-[6px] items-center gap-1 text-[12px] font-[600]">
        Deleted
      </div>
    ),
  };

  const typeList = {
    0: "Debit",
    1: "Credit",
  };

  return {
    list: list,
    theme: {
      approve: (item) => (
        <div className={"min-w-max flex flex-col gap-[12px]"}>
          <div className="flex items-center">{statusList[item?.status]}</div>
          <util.dateDisplay item={item} date={item?.created_at} />
        </div>
      ),
      channel: (item) => (
        <Tooltip content={item?.salesChannel?.name} placement="right">
          <img
            src={item?.salesChannel?.logo || "/assets/images/noImageWide.jpg"}
            alt="amazon"
            className="w-[88px] h-[72px] object-contain"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/images/noImageWide.jpg";
            }}
          />
        </Tooltip>
      ),
      name: (item) => (
        <div className="flex flex-col gap-[4px] w-[240px] ml-28">
          <p className="text-[14px] leading-[14px] flex items-center font-[400] text-gray-900">
            {/* <HiExternalLink
                                            className='min-w-4 min-h-4  text-gray-500 cursor-pointer'    
                                            onClick={() => {
                                                window.open(`/growth/target_seller_folders/${item?.id}`, '_blank')
                                                // navigate(`/product/${item?.product?.id}` , {target: '_blank'})
                                            }}
                                        /> */}
            {item?.name ? (
              <Util.expandableText
                maxLines={2}
                className={"cursor-pointer text-[14px]"}
                callBack={() => {
                  window.open(
                    `/growth/target_seller_folders/${item?.id}/sellers`,
                    "_blank"
                  );
                }}
              >
                {item?.name}
              </Util.expandableText>
            ) : (
              <span className="text-[14px] leading-[14px] font-[400] text-gray-900">
                -
              </span>
            )}
          </p>
        </div>
      ),
      description: (item) => (
        <div className="flex flex-col gap-[4px] ml-20">
          <p className="text-[14px] leading-[14px] font-[400] text-gray-900 ">
            {item?.description}
          </p>
        </div>
      ),
      Target: (item) => (
        <div className="flex flex-col justify-center items-start py-3 w-full gap-[12px] ml-[100px]">
          <div className="flex flex-col items-start ">
            <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
              Listings
            </p>
            <div
              className="flex items-center gap-[8px] cursor-pointer"
              onClick={() => {
                window.open(
                  `/growth/target_seller_folders/${item?.id}/listings?filter[status]=1`,
                  "_blank"
                );
              }}
            >
              <HiOutlineLightBulb className="text-[14px] text-blue-700 h-5 w-5" />

              <p className="text-[24px] leading-[24px] font-[600] text-blue-700">
                {item?.target_seller_listings_approved_count}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start ">
            <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
              Sellers
            </p>
            <div
              className="flex items-center gap-[8px] cursor-pointer"
              onClick={() => {
                window.open(
                  `/growth/target_seller_folders/${item?.id}/sellers?filter[status]=1`,
                  "_blank"
                );
              }}
            >
              <FaUsers className="text-[14px] text-blue-700 h-4 w-5" />
              <p className="text-[24px] leading-[24px] font-[600] text-blue-700">
                {item?.target_sellers_approved_count}
              </p>
            </div>
          </div>
        </div>
      ),
      Prospective: (item) => (
        <div className="flex flex-col justify-center items-start py-3 w-full gap-[12px] ml-32">
          <div className="flex flex-col items-start ">
            <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
              Listings
            </p>
            <div
              className="flex items-center gap-[8px] cursor-pointer"
              onClick={() => {
                window.open(
                  `/growth/target_seller_folders/${item?.id}/listings?filter[status]=0`,
                  "_blank"
                );
              }}
            >
              <HiOutlineLightBulb className="text-[14px] text-blue-700 h-5 w-5" />
              <p className="text-[24px] leading-[24px] font-[600] text-blue-700">
                {item?.target_seller_listings_awaiting_approval}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start ">
            <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
              Sellers
            </p>
            <div
              className="flex items-center gap-[8px] cursor-pointer"
              onClick={() => {
                window.open(
                  `/growth/target_seller_folders/${item?.id}/sellers?filter[status]=0`,
                  "_blank"
                );
              }}
            >
              <FaUsers className="text-[14px] text-blue-700 h-4 w-5" />
              <p className="text-[24px] leading-[24px] font-[600] text-blue-700">
                {item?.target_sellers_awaiting_approval}
              </p>
            </div>
          </div>
        </div>
      ),
    },
  };
};
export default TableView;

const CopyIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 20 20"
    className="w-4 h-4 "
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
    <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
  </svg>
);
