import React from 'react'
import DynamicForm from '../../dynamic-form'

function TrackingURL({item, callBack}) {

    const formItems = [
        {
            name: "tracking_url_schema",
            // label: "Tracking URL",
            // placeholder: "Enter Tracking URL Schema",
            type: "Text",
            defaultValue: item?.tracking_url_schema,
            clear: false,
            reset: false,
            className: " gap-[0px]",
            disabled: false,
            innerClassName: '!text-gray-900 !text-[14px] leading-[14px]',
        },
    ]

    const ref = React.useRef(null)
    return (
        <DynamicForm
            items={formItems}
            ref={ref}
            style={{
                label: {className: "w-full  !text-gray-400 text-14px"},
                container: {className: "gap-[0px]"}
            }}
            onChange={data => callBack({id: item?.id, tracking_url_schema: data?.tracking_url_schema})}
        />
    )
}

export default TrackingURL