import {HiPlus} from "react-icons/hi";
import React from "react";
import callEndpoint from "../../../dynamic-page/util/callEndpoint";


const addNewView = (props) => (
    <div className="cursor-pointer flex flex-row gap-[8px] items-center">
        <span className="text-blue-700 font-medium flex flex-row gap-[4px] items-center">
            <HiPlus className="inline-block"/>
            Add New:
        </span>
        <span>{props}</span>
    </div>
)

const addNewAction = (newOption, item, formData, loadOptions) => {
    const api = typeof item?.addNew === "function" ? item?.addNew(formData) : item?.addNew
    callEndpoint({
        title: "Create New" + item?.label + ": " + newOption,
        url: api.api,
        method: "POST",
        data: api.data(newOption)
    }).then((res) => {
        if (res?.type === "success") {
            //loadOptions(newOption)
        }
    })
}


export {addNewView, addNewAction}
