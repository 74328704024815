import AsyncSelect from "react-select/async";
import Util from "../../dynamic-page/util";
import {FaMinus, FaPlus} from "react-icons/fa6";
import ErrorComponent from "../../sub-elements/ErrorComponent";
import {useState} from "react";

const PurchaseSelectItems = ({
                                 formik,
                                 selectedProduct,
                                 setUpdateItem,
                                 setSelectedProduct,
                                 changeQuantity,
                                 sourcesOptions,
                                 submit,
                                 index,
                                 updateItem,
                                 currenciesOptions,
                                 originalItems,
                             }) => {


    const [isQuantityClicked, setQuantityClicked] = useState(false)


    const findItemIndex = originalItems?.findIndex((item) => item?.product?.title === selectedProduct?.product?.title)


    const defaultCurrency = {
        code: "TRY",
        image: undefined,
        label: "Turkish lira",
        logo: undefined,
        position: "0",
        product: 1,
        symbol: "₺",
        title: "Turkish lira",
        value: 1
    }


    return (
        <div className="flex flex-col gap-4 pl-9 pr-7 bg-[#EBF5FF]">
            <div className="flex gap-4">
                <div className="flex flex-col  space-y-[6px] ">
                    <label className="text-[14px] leading-[21px] font-medium text-gray-900">
                        Supply Channel*
                    </label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={sourcesOptions}
                        placeholder={"Search for a Supply Channel"}
                        className="border-none h-[42px] !bg-gray-50 !w-[580px]"
                        name="provider"
                        value={selectedProduct?.supplier_id}
                        onChange={(value) => {
                            // formik.setFieldValue("supplier_id", value);
                            setUpdateItem((prevVal) => {
                                    let copy = [...prevVal];
                                    // Modify the copy
                                    copy[findItemIndex] = {
                                        ...copy[findItemIndex],
                                        supplier_id: value,
                                        supplier: {
                                            name: value?.label,
                                            id: value?.value,

                                        }
                                    };
                                    return copy;
                                }
                            );
                        }}
                        onBlur={formik.handleBlur}
                        formatOptionLabel={(item) => (
                            <div className={"flex flex-row gap-[12px] items-center"}>
                                <div className="w-[35px] h-auto">
                                    <img
                                        className={
                                            "w-full h-auto rounded-full border-0 bg-gray-200"
                                        }
                                        src={item?.logo || "/assets/images/img-placehoder.png"}
                                    />
                                </div>
                                <span>{item?.label}</span>
                            </div>
                        )}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                height: 42,
                                minHeight: 42,
                                width: "100%",
                                backgroundColor: "#F9FAFB",
                                borderRadius: 8,
                                border: state.isFocused
                                    ? "1px solid #00A3FF"
                                    : formik.errors.supplier_id
                                        ? "1px solid #FF0000"
                                        : "1px solid #E5E5E5",

                                boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                                "&:hover": {
                                    border: state.isFocused
                                        ? "1px solid #00A3FF"
                                        : "1px solid #E5E5E5",
                                },
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected ? "#00A3FF" : "white",
                                color: state.isSelected ? "white" : "black",

                                "&:hover": {
                                    backgroundColor: "#00A3FF",
                                    color: "white",
                                },
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                position: "absolute",
                                height: 150,
                                zIndex: 999,
                                // borderRadius: 10,
                                overflow: "hidden",
                                border: state.isFocused
                                    ? "1px solid #00A3FF"
                                    : "1px solid #E5E5E5",
                                boxShadow: state.isFocused ? "0px 0px 0px 1px #00A3FF" : "none",
                                "&:hover": {
                                    border: state.isFocused
                                        ? "1px solid #00A3FF"
                                        : "1px solid #E5E5E5",
                                },
                            }),
                            container: (provided, state) => ({
                                ...provided,
                                width: "100%",
                                height: 42,
                                minHeight: 42,
                            }),
                        }}
                    />
                </div>
                <div className="flex flex-col  space-y-[8px]">
                    <label className="text-[14px] leading-[21px] font-medium text-gray-900">
                        Quantity*
                    </label>
                    <div className="flex w-[160px] h-[42px] ">
                        <button
                            type="button"
                            onClick={() => {
                                if (updateItem[findItemIndex]?.qty !== 1 && updateItem[findItemIndex]?.qty !== "1.00") {
                                    setUpdateItem((prevVal) => {

                                        // Make a shallow copy of the previous state
                                        let copy = [...prevVal];
                                        const tempQty = Number(copy[findItemIndex].qty)
                                        // Modify the copy
                                        copy[findItemIndex] = {
                                            ...copy[findItemIndex],
                                            qty: Number(tempQty - 1),
                                        };
                                        return copy;

                                    });
                                }
                            }}
                            className="border border-gray-300 border-r-0 rounded-r-none hover:bg-gray-50 bg-gray-100 py-3 px-5 rounded-lg"
                        >
                            <FaMinus className="w-[12.8px] h-[12.8px] text-gray-900"/>
                        </button>
                        {!isQuantityClicked ?
                            <span onClick={() => setQuantityClicked(true)}
                                  className="text-[14px] border bg-gray-50 px-5  py-3 border-gray-300 leading-[17.5px] text-gray-900">
                {Number(selectedProduct?.qty) || 1}
              </span>
                            :
                            <input type="number"
                                   min={1}

                                   max={Number(originalItems?.find((purchase) => purchase?.id === selectedProduct?.id)?.qty)}
                                   className="text-[14px] border text-center focus:border-gray-300 focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none bg-gray-50  w-[50px]  py-3 border-gray-300 leading-[17.5px] text-gray-900"
                                   onChange={(e) => {
                                       if (originalItems?.find((purchase) => purchase?.id === selectedProduct?.id)?.qty > e.target.value) {
                                           if (e.target.value) {
                                               setUpdateItem((prevVal) => {
                                                   // Make a shallow copy of the previous state
                                                   let copy = [...prevVal];
                                                   // Modify the copy
                                                   copy[findItemIndex] = {
                                                       ...copy[findItemIndex],
                                                       qty: Number(e.target.value),
                                                   };
                                                   return copy;

                                               });
                                           }

                                       }
                                   }
                                   }
                                   value={Number(selectedProduct?.qty) || 1}/>
                        }
                        <button
                            type="button"
                            onClick={() => {
                                if (originalItems?.find((purchase) => purchase?.id === selectedProduct?.id)?.qty > updateItem[findItemIndex]?.qty) {
                                    setUpdateItem((prevVal) => {
                                        // Make a shallow copy of the previous state
                                        let copy = [...prevVal];
                                        const tempQty = Number(copy[findItemIndex].qty)
                                        // Modify the copy
                                        copy[findItemIndex] = {
                                            ...copy[findItemIndex],
                                            qty: Number(tempQty + 1),
                                        };
                                        return copy;

                                    });
                                }
                            }}
                            className="border border-gray-300 border-l-0  bg-gray-100 hover:bg-gray-50 py-3 px-5 rounded-lg rounded-l-none"
                        >
                            <FaPlus className="w-[12.8px] h-[12.8px] text-gray-900"/>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col space-y-[8px]  ">
                    <label className="text-[14px] leading-[21px] font-medium text-gray-900">
                        Price*
                    </label>
                    <div className="flex">
                        <input
                            type="number"
                            className={`bg-gray-50 border-gray-300  border focus:border-gray-300 [&::-webkit-inner-spin-button]:appearance-none  focus:ring-0 h-[42px] !w-[91px] text-center text-[14px] leading-[17.5px] text-gray-900  p-3 !rounded-r-none border-r-0 rounded-lg`}
                            name="purchase_price"
                            placeholder="Price"
                            value={selectedProduct?.max_acceptable_price?.[selectedProduct?.max_acceptable_price_currency?.code || defaultCurrency.code]}
                            onChange={(e) => {
                                // it should be a number bigger than 0
                                if (e.target.value >= 0) {

                                    setUpdateItem((prevVal) => {
                                        // Make a shallow copy of the previous state
                                        const newState = [...prevVal];

                                        // Ensure that newState[index] exists
                                        if (newState[findItemIndex]) {
                                            // Make a shallow copy of the object at findItemIndex
                                            const itemCopy = {...newState[findItemIndex]};
                                            // Ensure that itemCopy.max_acceptable_price exists
                                            if (itemCopy.max_acceptable_price) {
                                                // Make a shallow copy of the max_acceptable_price object
                                                const priceCopy = {...itemCopy.max_acceptable_price};

                                                // Update the price with the currency code
                                                priceCopy[itemCopy.max_acceptable_price_currency?.code || defaultCurrency.code] = Number(e.target.value);

                                                // Assign the updated priceCopy back to itemCopy
                                                itemCopy.max_acceptable_price = priceCopy;
                                            } else {
                                                // Create a new max_acceptable_price object
                                                itemCopy.max_acceptable_price = {
                                                    [defaultCurrency.code]: Number(e.target.value),
                                                };
                                            }

                                            // Assign the updated itemCopy back to newState
                                            newState[findItemIndex] = itemCopy;
                                        }

                                        // Return the updated state
                                        return newState;
                                    });
                                }
                            }}
                            // onBlur={formik.handleBlur}
                        />
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={currenciesOptions}
                            className="border-gray-300 h-[42px] bg-transparent placeholder:text-red-500 !w-[110px] rounded-l-none border-l-0 focus:border-gray-300   focus:ring-0"
                            name="currency"
                            isDisabled={formik.values.bank_account_id ? true : false}
                            value={selectedProduct?.max_acceptable_price_currency || 1}
                            onChange={(value) => {
                                setUpdateItem((prevVal) => {
                                    // Make a shallow copy of the previous state
                                    let copy = [...prevVal];
                                    // Modify the copy
                                    copy[findItemIndex] = {
                                        ...copy[findItemIndex],
                                        max_acceptable_price_currency: value,
                                    };
                                    return copy;
                                });
                            }}
                            formatOptionLabel={(item) => {
                                return (
                                    <div className="flex items-center gap-2">
                                        <Util.countryCurrencies
                                            className={"w-4 h-[11.2px] object-cover"}
                                            item={item?.code}
                                        />
                                        <span className="text-[14px] leading-[17.5px] font-medium text-gray-900">
                      {item?.code}
                    </span>
                                    </div>
                                );
                            }}
                            onBlur={formik.handleBlur}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    height: 42,
                                    minHeight: 42,
                                    width: "100%",
                                    backgroundColor: "#F3F4F6",
                                    borderRadius: "0 10px 10px 0", // Simulates rounded-l-none
                                    borderLeft: "none", // Simulates border-l-0
                                    border: "1px solid #E5E5E5", // Default border
                                    boxShadow: "none", // Remove default box-shadow
                                    "&:hover": {
                                        border: "1px solid #E5E5E5",
                                    },
                                    ...(state.isFocused && {
                                        border: "none", // Remove border on focus
                                        boxShadow: "none", // Remove box-shadow on focus
                                    }),
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? "#00A3FF" : "white",
                                    color: state.isSelected ? "white" : "black",
                                    "&:hover": {
                                        backgroundColor: "#00A3FF",
                                        color: "white",
                                    },
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                    overflow: "hidden",
                                    border: state.isFocused
                                        ? "1px solid #00A3FF"
                                        : "1px solid #E5E5E5",
                                    boxShadow: state.isFocused
                                        ? "0px 0px 0px 1px #00A3FF"
                                        : "none",
                                    "&:hover": {
                                        border: state.isFocused
                                            ? "1px solid #00A3FF"
                                            : "1px solid #E5E5E5",
                                    },
                                }),
                                container: (provided, state) => ({
                                    ...provided,
                                    width: "100%",
                                    height: 42,
                                    minHeight: 42,
                                }),
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-col  space-y-[8px]">
                    <label className="text-[14px] leading-[21px] font-medium text-gray-900">
                        Est. Delivery Date
                    </label>
                    <input
                        type="date"
                        placeholder="Select Date"
                        className={`bg-gray-50 border border-gray-200 h-[42px] !w-[200px] text-gray-500 p-3 rounded-lg `}
                        name="estimate_delivery_date"
                        value={selectedProduct?.estimate_delivery_date}
                        onChange={(e) => {
                            setUpdateItem((prevVal) => {
                                // Make a shallow copy of the previous state
                                let copy = [...prevVal];
                                // Modify the copy
                                copy[findItemIndex] = {
                                    ...copy[findItemIndex],
                                    request_date_time: e.target.value,
                                };
                                return copy;
                            });
                        }}
                        onBlur={formik.handleBlur}
                    />
                </div>
            </div>

            <div className="flex flex-col w-full h-[100px] gap-[6px]">
                <label className="text-[14px] leading-[21px] font-medium text-gray-900">
                    Supply Purchase Note
                </label>
                <input
                    placeholder="This note will appear at the time of acceptance of the goods."
                    className="bg-gray-50 border border-gray-200  p-3 rounded-lg resize-none h-[41px] "
                    name="comment"
                    value={selectedProduct?.comment}
                    onChange={(e) => {
                        setUpdateItem((prevVal) => {
                            // Make a shallow copy of the previous state
                            let copy = [...prevVal];
                            // Modify the copy
                            copy[findItemIndex] = {
                                ...copy[findItemIndex],
                                comment: e.target.value,
                            };
                            return copy;
                        });
                    }}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.comment && formik.errors.comment ? (
                    <ErrorComponent errorMessage={formik.errors.comment}/>
                ) : null}
            </div>
        </div>
    );
};

export default PurchaseSelectItems;
