import upperCaseString from "../../dynamic-page/util/upperCaseString";

// todo : add an object for all filters and use them inside of the array inside the if check
const filtersView = (selectedFilers, filtersMeta, tabStatus) => {
  const target_sellers = filtersMeta?.listingsFilters?.target_sellers
    ? [...filtersMeta?.listingsFilters?.target_sellers]
    : null;
  
  if (tabStatus === "targetListings") {
    return [
      {
        name: "asin",
        label: "ASIN",
        placeholder: "Filter by ASIN",
        type: "Input",
        toggle: true,
        isClearable: true,
        defaultValue: selectedFilers?.asin,
      },

      {
        name: "is_data_loaded",
        label: "Data loaded Status",
        placeholder: "Filter by Data loaded Status",
        type: "Checkbox",
        list: filtersMeta?.listingsFilters?.data?.is_data_loaded?.map(
          (item) => ({
            label: item?.name,
            value: item?.id,
            count: item?.count,
          })
        ),

        defaultValue: Array.isArray(selectedFilers?.is_data_loaded)
          ? selectedFilers?.is_data_loaded
          : [selectedFilers?.is_data_loaded],
        toggle: true,
        features: {
          isMulti: true,
        },
        checkAll: true,
        clear: true,
      },

      {
        name: "brand",
        label: "Brand",
        placeholder: "Select Brand",
        type: "Select",
        list: filtersMeta?.listingsFilters?.data?.brands?.map((item) => ({
          label: upperCaseString(item?.name),
          value: encodeURIComponent(item?.id),
          count: item?.count,
        })),
        features: {
          formatOptionLabel: ({ label, count }) => {
            return (
              <div
                className={
                  "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
                }
              >
                <span>{upperCaseString(label)}</span>
                <span
                  className={
                    "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                  }
                >
                  {count}
                </span>
              </div>
            );
          },
        },
        defaultValue: selectedFilers?.brand || null,
        toggle: true,
        clear: true,
      },
      {
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        type: "Select",
        list: filtersMeta?.listingsFilters?.data?.categories?.map((item) => ({
          label: upperCaseString(item?.name),
          value: encodeURIComponent(item?.id),
          count: item?.count,
        })),
        features: {
          formatOptionLabel: ({ label, count }) => {
            return (
              <div
                className={
                  "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
                }
              >
                <span>{upperCaseString(label)}</span>
                <span
                  className={
                    "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                  }
                >
                  {count}
                </span>
              </div>
            );
          },
        },
        defaultValue: selectedFilers?.category || null,
        toggle: true,
        clear: true,
      },
      {
        name: "target_seller",
        label: "Target Seller",
        placeholder: "Select Target Seller",
        type: "Select",
        list: target_sellers
          ?.sort(
            // sort by count in ascending order
            (a, b) => a?.count - b?.count
          )
          ?.map((item) => ({
            label: upperCaseString(item?.name),
            value: item?.id,
            count: item?.count,
          })),
        features: {
          formatOptionLabel: ({ label, count }) => {
            return (
              <div
                className={
                  "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
                }
              >
                <span>{upperCaseString(label)}</span>
                <span
                  className={
                    "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                  }
                >
                  {count}
                </span>
              </div>
            );
          },
        },
        defaultValue: selectedFilers?.target_seller || null,
        toggle: true,
        clear: true,
      },
      {
        name: "already_selling",
        label: "Already Selling",
        placeholder: "Filter by Selling Status",
        type: "RadioButton",
        list: filtersMeta?.listingsFilters?.data?.already_selling?.map(
          (item) => ({
            label: upperCaseString(item?.name),
            value: item?.id,
            count: item?.count,
          })
        ),
        defaultValue: selectedFilers?.already_selling,
        toggle: true,
        radioContainerClass: "!flex-col",
      },
      {
        name: "available",
        label: "Available Sales Listing",
        placeholder: "Filter by listing availability",
        type: "RadioButton",
        list: filtersMeta?.listingsFilters?.data?.availability?.map((item) => ({
          value: item?.id,
          label: item?.name === "available" ? "Available" : "Not Available",
          count: item?.count,
        })),

        defaultValue: selectedFilers?.available,
        toggle: true,
        radioContainerClass: "!flex-col",
      },
      {
        name: "account",
        label: "Account",
        placeholder: "Filter by account",
        type: "Select",
        api: {
          url: "/api/v1/accounts",
          query: "?filter[name]={query}",
          optionValue: (item) => ({
            value: item?.id,
            label: item?.name,
            avatar: item?.channel?.logo,
          }),
        },
        features: {
          isMulti: true,
          formatOptionLabel: ({ label, avatar }) => (
            <div
              className={"flex flex-row gap-[12px] items-center min-h-[25px]"}
            >
              <span
                className={
                  "w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "
                }
              >
                <img src={avatar} className="object-contain w-full h-full" />
              </span>
              <span>{upperCaseString(label)}</span>
            </div>
          ),
        },
        defaultValue: selectedFilers?.account,
        toggle: true,
        clear: true,
      },

      {
        name: "is_note_exists",
        label: "Note",
        placeholder: "Filter by Note",
        type: "Checkbox",
        list: filtersMeta?.listingsFilters?.data?.is_note_exists?.map(
          (item) => ({
            label: upperCaseString(item?.name),
            value: item?.id,
            count: item?.count,
          })
        ),
        defaultValue: selectedFilers?.is_note_exists,
        toggle: true,
      },
      {
        name: "root_category_id",
        label: "Root Category",
        placeholder: "Filter by Root Category",
        type: "Select",
        api: {
          url: "/api/v1/category",
          query: "/?search={query}&showAll=true&filter[type]=all",
          optionValue: (item) => ({
            value: item.id,
            label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }),
        },
        toggle: true,
        isClearable: true,
        defaultValue: selectedFilers?.root_category_id || null,
      },

      {
        name: "status",
        label: "Status",
        placeholder: "Filter by Status",
        type: "Checkbox",
        list: filtersMeta?.listingsFilters?.data?.statuses?.map((item) => ({
          label: upperCaseString(item?.name),
          value: item?.id,
          count: item?.count,
        })),
        defaultValue: Array.isArray(selectedFilers?.status)
          ? selectedFilers?.status
          : [selectedFilers?.status],
        toggle: true,
        features: {
          isMulti: true,
        },
        checkAll: true,
        clear: false,
      },
      {
        name: "buybox_seller_type",
        label: "Buybox Seller Type",
        placeholder: "Filter by Buybox Seller Type",
        type: "Checkbox",
        list: filtersMeta?.listingsFilters?.data?.buybox_seller_types?.map(
          (item) => ({
            label: upperCaseString(item?.name),
            value: item?.id,
            count: item?.count,
          })
        ),
        defaultValue: selectedFilers?.buybox_seller_type,
        toggle: true,
        features: {
          isMulti: true,
        },
        checkAll: true,
        clear: false,
      },

      {
        name: "billable_weight",
        label: "Billable Weight",
        placeholder: "Filter by billable weight",
        type: "Range",
        defaultValue: selectedFilers?.billable_weight,
        toggle: true,
        clear: true,
      },
      {
        name: "actual_weight",
        label: "Actual Weight",
        placeholder: "Filter by actual weight",
        type: "Range",
        defaultValue: selectedFilers?.actual_weight,
        toggle: true,
        clear: true,
      },
      {
        name: "dimensional_weight",
        label: "Dimensional Weight",
        placeholder: "Filter by dimentional weight",
        type: "Range",
        defaultValue: selectedFilers?.dimensional_weight,
        toggle: true,
        clear: true,
      },

      {
        name: "buybox_price",
        label: "BuyBox Price",
        placeholder: "Filter by buybox price",
        type: "Range",
        defaultValue: selectedFilers?.buybox_price,
        toggle: true,
        clear: true,
      },
      {
        name: "total_offer_count",
        label: "Total Offer Count",
        placeholder: "Filter by total offer count",
        type: "Range",
        defaultValue: selectedFilers?.total_offer_count,
        toggle: true,
        clear: true,
      },
      {
        name: "supply_cost",
        label: "Supply Cost",
        placeholder: "Filter by supply cost",
        type: "Range",
        defaultValue: selectedFilers?.supply_cost,
        toggle: true,
        clear: true,
      },
      {
        name: "fba_max_ideal_cost",
        label: "FBA Max Ideal Cost",
        placeholder: "Filter by fba max ideal cost",
        type: "Range",
        defaultValue: selectedFilers?.fba_max_ideal_cost,
        toggle: true,
        clear: true,
      },
      {
        name: "fbm_max_ideal_cost",
        label: "FBM Max Ideal Cost",
        placeholder: "Filter by fbm max ideal cost",
        type: "Range",
        defaultValue: selectedFilers?.fbm_max_ideal_cost,
        toggle: true,
        clear: true,
      },

      // {
      //   name: "created_at",
      //   label: "Created At",
      //   placeholder: "Filter by Create Date",
      //   type: "DateRange",
      //   defaultValue: selectedFilers?.created_at,
      //   toggle: true,
      //   clear: true,
      // },

      //     {
      //         name: "brand",
      //         label: "Brand",
      //         placeholder: "Select Brand",
      //         type: "Select",
      //         api: {
      //             url: "/api/v1/brands",
      //             query: "/search?term={query}",
      //             optionValue: (item) => ({
      //                 value: item.id,
      //                 label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
      //             })
      //         },
      //         defaultValue: formDefaultValue?.brand?.id || null,
      //     },

      //     {
      //         name: "root_category",
      //         label: "Root Category",
      //         placeholder: "Filter by Root Category",
      //         type: "Select",
      //         api: {
      //             url: "/api/v1/category",
      //             query: "?filter[name]={query}",
      //             optionValue: (item) => ({
      //                 value: item?.id,
      //                 label: item?.name,
      //             })
      //         },
      //         defaultValue: selectedFilers?.root_category,
      //         toggle: true,
      //         clear: true,
      //     },
      //     {
      //         name: "item_title",
      //         label: "Item Title",
      //         placeholder: "Filter by item title",
      //         type: "Input",
      //         defaultValue: selectedFilers?.item_title,
      //         toggle: true,
      //         clear: true,
      //     },
      //     {
      //         name: "items_count",
      //         label: "Items Count",
      //         placeholder: "Filter by items count",
      //         type: "Range",
      //         defaultValue: selectedFilers?.items_count,
      //         toggle: true,
      //         clear: true,
      //     },
      //     {
      //         name: "item_sku",
      //         label: "Item SKU",
      //         placeholder: "Filter by item item SKU",
      //         type: "Input",
      //         defaultValue: selectedFilers?.item_sku,
      //         toggle: true,
      //         clear: true,
      //     },
    ];
  } else if (
    tabStatus === "prospectiveListings" ||
    tabStatus === "rejectedListings"
  ) {
    return [
      {
        name: "is_data_loaded",
        label: "Data loaded Status",
        placeholder: "Filter by Data loaded Status",
        type: "Checkbox",
        list: filtersMeta?.listingsFilters?.data?.is_data_loaded?.map(
          (item) => ({
            label: item?.name,
            value: item?.id,
            count: item?.count,
          })
        ),
        defaultValue: Array.isArray(selectedFilers?.is_data_loaded)
          ? selectedFilers?.is_data_loaded
          : [selectedFilers?.is_data_loaded],
        toggle: true,
        features: {
          isMulti: true,
        },
        checkAll: true,
        clear: true,
      },
      {
        name: "brand",
        label: "Brand",
        placeholder: "Select Brand",
        type: "Select",
        list: filtersMeta?.listingsFilters?.data?.brands?.map((item) => ({
          label: upperCaseString(item?.name),
          value: encodeURIComponent(item?.id),
          count: item?.count,
        })),
        features: {
          formatOptionLabel: ({ label, count }) => {
            return (
              <div
                className={
                  "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
                }
              >
                <span>{upperCaseString(label)}</span>
                <span
                  className={
                    "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                  }
                >
                  {count}
                </span>
              </div>
            );
          },
        },
        defaultValue: selectedFilers?.brand || null,
        toggle: true,
        clear: true,
      },
      {
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        type: "Select",
        list: filtersMeta?.listingsFilters?.data?.categories?.map((item) => ({
          label: upperCaseString(item?.name),
          value: encodeURIComponent(item?.id),
          count: item?.count,
        })),
        features: {
          formatOptionLabel: ({ label, count }) => {
            return (
              <div
                className={
                  "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
                }
              >
                <span>{upperCaseString(label)}</span>
                <span
                  className={
                    "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                  }
                >
                  {count}
                </span>
              </div>
            );
          },
        },
        defaultValue: selectedFilers?.category || null,
        toggle: true,
        clear: true,
      },
      {
        name: "target_seller",
        label: "Target Seller",
        placeholder: "Select Target Seller",
        type: "Select",
        list: target_sellers?.map((item) => ({
          label: upperCaseString(item?.name),
          value: item?.id,
          count: item?.count,
        })),
        features: {
          formatOptionLabel: ({ label, count }) => {
            return (
              <div
                className={
                  "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
                }
              >
                <span>{upperCaseString(label)}</span>
                <span
                  className={
                    "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                  }
                >
                  {count}
                </span>
              </div>
            );
          },
        },
        defaultValue: selectedFilers?.target_seller || null,
        toggle: true,
        clear: true,
      },
      {
        name: "already_selling",
        label: "Already Selling",
        placeholder: "Filter by Selling Status",
        type: "RadioButton",
        list: filtersMeta?.listingsFilters?.data?.already_selling?.map(
          (item) => ({
            label: upperCaseString(item?.name),
            value: item?.id,
            count: item?.count,
          })
        ),
        defaultValue: selectedFilers?.already_selling,
        toggle: true,
        radioContainerClass: "!flex-col",
      },
      {
        name: "available",
        label: "Available Sales Listing",
        placeholder: "Filter by listing availability",
        type: "RadioButton",
        list: filtersMeta?.listingsFilters?.data?.availability?.map((item) => ({
          value: item?.id,
          label: item?.name === "available" ? "Available" : "Not Available",
          count: item?.count,
        })),

        defaultValue: selectedFilers?.available,
        toggle: true,
        radioContainerClass: "!flex-col",
      },
      {
        name: "account",
        label: "Account",
        placeholder: "Filter by account",
        type: "Select",
        api: {
          url: "/api/v1/accounts",
          query: "?filter[name]={query}",
          optionValue: (item) => ({
            value: item?.id,
            label: item?.name,
            avatar: item?.channel?.logo,
          }),
        },
        features: {
          isMulti: true,
          formatOptionLabel: ({ label, avatar }) => (
            <div
              className={"flex flex-row gap-[12px] items-center min-h-[25px]"}
            >
              <span
                className={
                  "w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "
                }
              >
                <img src={avatar} className="object-contain w-full h-full" />
              </span>
              <span>{upperCaseString(label)}</span>
            </div>
          ),
        },
        defaultValue: selectedFilers?.account,
        toggle: true,
        clear: true,
      },
      {
        name: "is_note_exists",
        label: "Note",
        placeholder: "Filter by Note",
        type: "Checkbox",
        list: filtersMeta?.listingsFilters?.data?.is_note_exists?.map(
          (item) => ({
            label: upperCaseString(item?.name),
            value: item?.id,
            count: item?.count,
          })
        ),
        defaultValue: selectedFilers?.is_note_exists,
        toggle: true,
      },
      {
        name: "status",
        label: "Status",
        placeholder: "Filter by Status",
        type: "Checkbox",
        list: filtersMeta?.listingsFilters?.data?.statuses?.map((item) => ({
          label: upperCaseString(item?.name),
          value: item?.id,
          count: item?.count,
        })),
        defaultValue: Array.isArray(selectedFilers?.status)
          ? selectedFilers?.status
          : [selectedFilers?.status],
        toggle: true,
        features: {
          isMulti: true,
        },
        checkAll: true,
        clear: false,
      },
    ];
  } else {
    return [
      {
        name: "status",
        label: "Status",
        placeholder: "Filter by status",
        type: "Checkbox",
        list: filtersMeta?.sellersFilters?.data?.statuses?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
            count: item?.count,
          };
        }),
        defaultValue: Array.isArray(selectedFilers?.status)
          ? selectedFilers?.status
          : [selectedFilers?.status],
        toggle: true,
        isClearable: true,
        features: {
          isMulti: true,
        },
        checkAll: true,
        clear: false,
      },

      {
        name: "is_data_loaded",
        label: "Data loaded Status",
        placeholder: "Filter by Data loaded Status",
        type: "Checkbox",
        list: filtersMeta?.sellersFilters?.data?.is_data_loaded?.map(
          (item) => ({
            label: item?.name,
            value: item?.id,
            count: item?.count,
          })
        ),

        defaultValue: Array.isArray(selectedFilers?.is_data_loaded)
          ? selectedFilers?.is_data_loaded
          : [selectedFilers?.is_data_loaded],
        toggle: true,
        features: {
          isMulti: true,
        },
        checkAll: true,
        clear: true,
      },

      {
        name: "country_id",
        label: "Country",
        placeholder: "Filter by Country",
        type: "Select",
        list: filtersMeta?.sellersFilters?.data?.countries?.map((item) => ({
          label: item?.name,
          value: item?.id,
          count: item?.count,
        })),
        features: {
          formatOptionLabel: ({ label, count }) => {
            return (
              <div
                className={
                  "flex flex-row gap-[8px] items-center justify-between min-h-[25px]"
                }
              >
                <span>{upperCaseString(label)}</span>
                <span
                  className={
                    "min-w-[20px] h-[20px] overflow-hidden flex flex-col justify-center items-center "
                  }
                >
                  {count}
                </span>
              </div>
            );
          },
        },
        defaultValue: selectedFilers?.country_id,
        toggle: true,
        isClearable: true,
      },
      {
        name: "30days_rating_count",
        label: "Last 30 Days Rating Count",
        placeholder: "Filter by last 30 days rating count",
        type: "Range",
        defaultValue: selectedFilers?.["30days_rating_count"],
        toggle: true,
        clear: true,
      },
      {
        name: "90days_rating_count",
        label: "Last 90 Days Rating Count",
        placeholder: "Filter by last 90 days rating count",
        type: "Range",
        defaultValue: selectedFilers?.["90days_rating_count"],
        toggle: true,
        clear: true,
      },
      {
        name: "365days_rating_count",
        label: "Last 365 Days Rating Count",
        placeholder: "Filter by last 365 days rating count",
        type: "Range",
        defaultValue: selectedFilers?.["365days_rating_count"],
        toggle: true,
        clear: true,
      },
      //90, 365, lifetime
      {
        name: "lifetime_rating_count",
        label: "Lifetime Rating Count",
        placeholder: "Filter by lifetime rating count",
        type: "Range",
        defaultValue: selectedFilers?.lifetime_rating_count,
        toggle: true,
        clear: true,
      },
    ];
  }
};

export default filtersView;
