import {useEffect, useRef, useState} from "react";
import MainModal from "../../dynamic-page/MainModal";
import UI from "../../dynamic-form/elements";
import DynamicForm from "../../dynamic-form";
import axios from "axios";

const ModalForm = ({cancel, data, actions, view, form}) => {
    const viewTheme = view ? view : (form) => <>{form}</>;
    const myFormRef = useRef(null);

    async function getDefaultSettings() {

        const data = await axios.get('/api/v1/account-listing/fc-restocking-settings')
        setDefaultSettings(data.data.data);

    }

    const [defaultSettings, setDefaultSettings] = useState(null);

    useEffect(() => {
        getDefaultSettings();
    }, []);


    const getFormData = async () => {
        const recipientData = await myFormRef?.current?.getData(true);
        const recipientValidation = await myFormRef?.current?.validate(true);
        return {...recipientData, _validation: recipientValidation};
    };
    return (
        <MainModal
            item={{
                title: "FC Restocking Settings",
                submit: (data) => {
                    //submit(data);
                },
                cancel: () => {
                    cancel();
                },
                data: data,
                actionButton: actions ? (
                    <div
                        className={
                            " w-full flex flex-row gap-[8px] justify-end"
                        }
                    >
                        <UI.Button
                            onClick={cancel}
                            item={{
                                buttonType: "alt",
                                className: "w-max ",
                            }}
                        >
                            Cancel
                        </UI.Button>
                        {actions.map((i) => (
                            <UI.Button
                                onClick={async () => i.onClick(await getFormData())}
                                item={{
                                    buttonType: "primary",
                                    className: "w-max ",
                                }}
                            >
                                {i.label}
                            </UI.Button>
                        ))}
                    </div>
                ) : null,
                view: (item) => (
                    <div className="flex flex-col border-t border-t-gray-200">
                        <div className="w-full h-11 justify-start items-start gap-2 my-4  inline-flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                                <div className="flex-col justify-start items-start gap-0.5 flex">
                                    <div className="w-full justify-start items-center gap-2 inline-flex">
                                        <div
                                            className="text-gray-900 text-base font-medium font-['Inter'] leading-normal">
                                            Suggestion Treshold
                                        </div>
                                    </div>
                                    <div className="w-full justify-start items-center gap-2 inline-flex">
                                        <div
                                            className="text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]">
                                            Don’t show suggestion if less than...
                                        </div>
                                    </div>
                                    <div className="w-full justify-start items-center gap-2 inline-flex">
                                        <div
                                            className="text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]">
                                            Last Updated By: {defaultSettings && defaultSettings?.user?.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className={
                                "  min-w-[550px] grid grid-cols-3 gap-2 mt-2 "
                            }
                        >
                            {viewTheme(
                                <DynamicForm
                                    items={form(defaultSettings)}
                                    ref={myFormRef}
                                    style={{
                                        label: {className: "!text-gray-900 !text-sm !font-medium"},
                                    }}
                                />
                            )}
                        </div>
                    </div>
                ),
            }}
            mainClassName={"!py-[30px] px-3 "}
        />
    );
};

export default ModalForm;
