const sortView = ({callback}) => {
    return {
        list: [
            {
                label: "Requested At",
                value: "requested_at",
                onClick: (item) => {
                    callback("requested_at");
                }
            },
            {
                label: "Product ID",
                value: "product_id",
                onClick: (item) => {
                    callback("product_id");
                }
            },

            {
                label: "Quantity",
                value: "quantity",
                onClick: (item) => {
                    callback("quantity");
                }
            },


        ]
    };
};

export default sortView;
  