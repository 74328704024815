/**
 * @author Austin Ames
 * @description ProductSalesChannel - a component to be displayed for sales channel tab
 */
import SalesChannelListings from "../sales-listing";

const ProductSalesChannelPage = ({product_id, showSalesModal, setShowSalesModal, updateListingsCount}) => {
    return (
        <SalesChannelListings product_id={product_id} showSalesModal={showSalesModal}
                              setShowSalesModal={setShowSalesModal} displayTitle={'hidden'} displayTabGroups="hidden"
                              updateListingsCount={updateListingsCount}
                              showCreateForm={true}/>
    )
}

export default ProductSalesChannelPage;
