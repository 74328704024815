import React from 'react'
import Util from '../util'

function ItemID({id}) {
    return (

        <div className="flex justify-center items-center w-full h-full">
            <p className="text-[12px] font-[500] text-black text-center">
                {id}
            </p>
            <Util.copyText text={id}/>
        </div>
    )
}

export default ItemID