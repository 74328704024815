import axios from '../../middleware/axios';
import constants from '../../constant';

const fetchSalesListings = async (params) => {
    let url = constants.APIURL.GET_SALESLISTINGS + '/fc-restocking'
    if (params)
        url = url.concat(params)
    const response = await axios.get(
        url,
        {withCredentials: true}
    )
    //console.log(response)
    return response
}
const fetchSalesListing = async (id) => {
    const response = await axios.get(constants.APIURL.GET_SALES_LISTING + 'fc-restocking/' + id, {withCredentials: true});
    return response;
}

const refreshAccountListings = async (payload) => {
    try {
        const response = await axios.post(constants.APIURL.POST_REFRESH_ACCOUNT_LISTING, payload, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const fetchDefaultSettings = async () => {

    const data = await axios.get('/api/v1/account-listing/fc-restocking-settings')
    return data?.data?.data || null

}

export default {fetchSalesListings, fetchSalesListing, refreshAccountListings, fetchDefaultSettings}
