import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import { updateRow } from "../../../store/fc-restocking";
import { useDispatch } from "react-redux";
import { HiColorSwatch, HiStar } from "react-icons/hi";
import { fetchSalesListing } from "../../../store/fc-restocking";
import ConnectForm from "../../sales-listing/modals-view/form";

const mapListings = (item) => ({
  id: item?.id,
  identifier: item?.sales_channel_listing?.identifier,
  url: item?.sales_channel_listing?.url,
  label: item?.sales_channel_listing?.name,
  channel: item?.sales_channel_listing?.channel?.name,
  brand: item?.sales_channel_listing?.brand,
  image: item?.sales_channel_listing?.main_image_path,
});

const Item = ({ item, product, listId }) => {
  const [visibleModal, setVisibleModal] = React.useState(false);

  const salesListing = item?.sales_channel_listing
    ? item?.sales_channel_listing
    : item?.sales_channel_listings?.[0];

  const productList = product[0];
  const dispatch = useDispatch();

  return (
    <div className={"w-[390px] gap-[0px] flex flex-col !px-[8px] !py-[12px]"}>
      <div className="flex justify-between text-[14px] font-bold text-orange-700">
        <div className="flex items-center justify-between gap-2">
          <p>
            {salesListing?.["reviews_count"]
              ? salesListing?.["reviews_count"] + " Ratings"
              : "No Rating"}
          </p>
          <div className="flex items-center text-orange-600">
            {salesListing?.["ratings"] && <HiStar />}
            &nbsp;
            {salesListing?.["ratings"]
              ? (+salesListing?.["ratings"])?.toFixed(1)
              : "-"}
          </div>
        </div>
        <div className="flex items-center text-orange-600">
          <HiColorSwatch />
          &nbsp;
          {salesListing?.["variation_count"]
            ? salesListing?.["variation_count"] + " Variants"
            : "No variant"}
        </div>
      </div>
      <div className="w-[370px] flex flex-row ">
        {/* {item['name'] ? item['name'] : 'No name'} */}
        {/* {salesListing?.name ? ( */}
        <util.expandableText
          maxLines={2}
          className={"text-[14px]"}
          tooltip={true}
        >
          {salesListing?.name}
        </util.expandableText>
        {/* ) : (
                  "-"   
                )} */}
      </div>

      <div className={"flex justify-between"}>
        <div className={"flex flex-row gap-[4px] text-gray-500"}>
          {util.shortenString(salesListing?.brand, 30)}
          <util.copyText
            className={"text-[14px]"}
            text={salesListing?.brand}
            hint={false}
          />
        </div>
        {productList ? (
          <div className="flex items-center gap-[2px] !text-[12px]">
            <span
              className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium ${
                productList?.type === 1
                  ? "text-teal-700 bg-teal-100"
                  : "text-indigo-700 bg-indigo-100"
              } p-[0px]`}
            >
              {productList?.type === 1 ? "S" : "G"}
            </span>
            <div
              className={
                "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] "
              }
              onClick={() => {
                window.open(`/product/${productList?.id}`, "_blank");
              }}
            >
              {productList?.brand?.assigned ? (
                <>
                  <img
                    src={
                      productList?.brand?.assigned?.image_url ||
                      "/assets/images/defaultAvatar.png"
                    }
                    className={"w-[20px] h-[20px] rounded-full object-cover"}
                  />
                  {util.upperCaseString(
                    productList?.brand?.assigned?.name,
                    "word"
                  )}
                </>
              ) : (
                <div className="text-gray-400">Unassigned</div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-[2px] !text-[12px]">
            <div
              className={"flex flex-row gap-[2px]"}
              onClick={() => {
                setVisibleModal(true);
              }}
            >
              <span className="w-[20px] h-[20px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
                <Icons.link className={"w-[11px] h-[11px]"} />
              </span>
              <div
                className={"text-red-700 hover:text-red-800 cursor-pointer "}
              >
                Not Connected
              </div>
            </div>
          </div>
        )}

        {/* } */}
      </div>

      {visibleModal && (
        <ConnectForm
          data={item}
          show={visibleModal}
          onClose={() => {
            setVisibleModal(false);
          }}
          defaultListings={[item]}
          //   callBack={() => {
          //     onPaginationApply();
          //     updateListingsCount("account-listing", "sales");
          //   }}
          updateRows={(items) => {
            items.map((item) => {
              dispatch(fetchSalesListing(item)).then((rs) => {
                dispatch(
                  updateRow({
                    path: `fcRestockings.[id:${item}]`,
                    value: rs.payload.data,
                  })
                );

                dispatch(
                  updateRow({
                    path: `salesListings.[id:${item}]._updatedRow`,
                    value: true,
                  })
                );
              });
            });
          }}
        />
      )}
    </div>
  );
};

export default Item;
