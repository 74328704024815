import React from "react";
import Components from "../components";

import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import StatusHistory from "../../goods-acceptance/components/SupplyStatusHistory";
import moment from "moment";
import util from "../../dynamic-page/util";
import { howLongFromNow } from "../../../utils/helper";

const statusLabel = (item) => [
  {
    action: "Shipped",
    name: item?.accepted_by?.name,
    date: item?.accpeted_at,
    qty: item?.quantity,
  },
  {
    action: "Dilevered",
    name: item?.purchase?.created_by?.name,
    date: item?.purchase?.created_at,
    qty: item?.purchase?.quantity,
  },
  {
    action: "Created",
    name: item?.purchase?.supply_request?.requester?.name,
    date: item?.purchase?.supply_request?.request_date_time,
    qty: item?.purchase?.supply_request?.qty,
  },
];

const tableView = (list, callBack, isAwaiting, updateRow, onFilterApply) => {
  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };

  const howMuchTimeLeft = (date) => {
    const now = moment();
    const inputDate = moment(date);
    const difference = inputDate.diff(now, "days");

    if (difference >= 0) {
      return "text-emerald-700"; // Geçmediyse
    } else if (difference > -3) {
      return "text-orange-500"; // 3 güne kadar geçtiyse
    } else {
      return "text-red-700"; // 3 günden fazla geçtiyse
    }
  };

  return {
    list: list,
    theme: {
      control: (item) => (
        <div className={"w-[20px] h-[50px]"}>
          <Components.orderRowAction
            actions={{
              order_id: item?.id,
              customer_note: item?.customer_note,
              internal_notes: item?.internal_notes,
              shipping_content: item?.shipping_content,

              customer_note_enabled: item?.customer_note_enabled,
              internal_notes_enabled: item?.internal_notes_enabled,
              shipping_content_enabled: item?.shipping_content_enabled,
            }}
          />
        </div>
      ),
      status: (item) => (
        <div
          className={
            "w-full h-[64px] flex flex-col gap-1 justify-center items-center relative"
          }
        >
          {item?.cancel_requested || item?.refund_requested ? (
            <span>
              {" "}
              <Components.salesChannelStatus
                cancel_requested={item?.cancel_requested}
                refund_requested={item?.refund_requested}
              />{" "}
            </span>
          ) : (
            ""
          )}
          <Components.orderStatus
            isAwaiting={isAwaiting}
            statusId={item?.order_status_id}
            color={item?.order_status_color_code}
            text={item?.order_status}
            item={{
              product_connected: item?.order_status !== 14,
              ready_to_ship: item?.ready_to_ship,
              shipped: item?.shipped,
              is_cancelled: item?.is_cancelled,
              order: item,
            }}
            orderId={item?.id}
            orderHistory={
              <StatusHistory title="Order History" list={statusLabel(item)} />
            }
            callBack={callBack}
          />
        </div>
      ),
      "order date": (item) => (
        <div>
          {item?.order_date ? (
            <div className="  flex-col justify-start gap-1.5 h-[64px] py-2  items-start whitespace-nowrap inline-flex w-[132px] ">
              <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                <div className="text-gray-900 text-sm font-normal leading-[14px] whitespace-nowrap">
                  {moment(nullHandle(item, item?.order_date)).format(
                    "MMM D, YYYY"
                  )}
                </div>
              </div>
              <div className="self-stretch justify-between items-center gap-4 inline-flex">
                <div className="text-gray-600 text-xs font-normal leading-[18px] whitespace-nowrap">
                  {moment(nullHandle(item, item?.order_date)).format("h:mm a")}
                </div>
                <div
                  className={`${howMuchTimeLeft(
                    item?.ship_by_date
                  )} text-xs font-medium leading-[18px] whitespace-nowrap`}
                >
                  {moment(item?.order_date).fromNow() === "a few seconds ago"
                    ? "Just now"
                    : howLongFromNow(item?.order_date, true)}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center !px-[8px] !py-[12px] items-start gap-1 inline-flex">
              -
            </div>
          )}
        </div>
      ),
      "order id": (item) => (
        <div
          className={"flex flex-col gap-[0px] relative justify-start  w-full"}
        >
          <Components.orderId
            callBack={callBack}
            orderId={item.id}
            order_numbers={item.order_numbers}
            order={{
              order_number: item?.order_numbers[0],
              marketplace: item?.sales_channel_account?.channel?.marketplace,
              ...item?.sales_channel_account,
            }}
            item={item}
            onFilterApply={onFilterApply}
          />
        </div>
      ),

      // image: (item) => (

      // ),
      item: (item) => (
        <div
          className={"w-[100%]  flex flex-row   gap-1 justify-start"}
          style={{ width: "100%" }}
        >
          <div className={"w-[56px] h-[56px] justify-center items-center flex"}>
            {item?.items_count === 1 ? (
              item?.first_item.item_image ||
              item?.first_item?.arbitbox_product?.image ? (
                <ImageMagnifier
                  src={
                    item?.first_item.item_image ||
                    item?.first_item?.arbitbox_product?.image
                  }
                  className={"!max-h-[64px]"}
                />
              ) : (
                <img
                  src={"/assets/images/noImage.png"}
                  className={"h-[52px] w-[52px]"}
                />
              )
            ) : !item?.first_item ? (
              <img
                src={"/assets/images/noImage.png"}
                className={"h-[52px] w-[52px]"}
              />
            ) : (
              <img
                src={"/assets/images/multiproduct.png"}
                className={"h-[52px] w-[52px]"}
              />
            )}
          </div>
          <Components.Item
            product={item?.order_items?.filter(
              (item) => +item?.unit_price >= 0
            )}
            orderId={item?.id}
            order={item}
            onFilterApply={onFilterApply}
          />
        </div>
      ),

      qty: (item) => (
        <div className={"flex"}>
          <Components.numberPriorityColor
            number={item?.items_total_qty}
            color={
              item?.items_total_qty <= 1
                ? "gray"
                : 1 < item?.items_total_qty && item?.items_total_qty <= 5
                ? "yellow"
                : "red"
            }
            className={"text-[14px]"}
          />
        </div>
      ),
      weight: (item) => {
        return (
          <div className={"flex flex-col gap-[0px]"}>
            <Components.weight
              order_status_id={item?.order_status_id}
              weight={{
                billable: item?.billable_weight
                  ? (+item?.billable_weight).toFixed(2)
                  : "-", //item?.billable_weight != null ? item?.billable_weight : "-",
                actual: item?.actual_weight
                  ? (item?.actual_weight).toFixed(2)
                  : "-", //item?.actual_weight != null ? item?.actual_weight :  "-",
                dim: item?.dim_weight ? (item?.dim_weight).toFixed(2) : "-", //item?.dim_weight != null ? item?.dim_weight : "-",
                selected: item?.selected_weight || null,
                orderId: item?.id,
              }}
            />
          </div>
        );
      },
      profit: (item) => (
        <div className={"flex flex-1"}>
          {item?.type !== "MANUAL" ? (
            <Components.profit
              profit={{
                margin: item?.profit_margin,
                profit: item?.order_total_paid,
                currency: item?.sales_channel_account?.currency,
                statCalculator: item?.profit_calculations,
                profit_color_code: item?.profit_color_code,
              }}
            />
          ) : (
            <div
              className={
                "flex text-indigo-700 font-bold text-[24px] items-center justify-center w-full flex-1"
              }
            >
              -
            </div>
          )}
        </div>
      ),
      "ship to": (item) => (
        <div className={"flex flex-col gap-[8px] relative w-full"}>
          <Components.shipTo
            order={item}
            state={item?.ship_to_state}
            name={item?.ship_to_name}
            city={item?.ship_to_city}
            country={item?.ship_to_country}
            customerOrderCount={item?.customer_order_count}
            callBack={callBack}
            order_address_id={item?.order_address_id}
          />
        </div>
      ),

      "tracking id": (item) => (
        <div className={"flex  justify-start !w-full flex-1"}>
          <Components.shipmentLabel
            shipment={{
              shipment_labels: item?.shipment_labels,
              suggested_shipping_cost: item?.suggested_shipping_cost,
            }}
            orderItem={item}
            callBack={callBack}
            updateRow={updateRow}
          />
        </div>
      ),
    },
  };
};
export default tableView;

const CopyIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 20 20"
    className="w-4 h-4 "
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
    <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
  </svg>
);
