// ------------------------------
// Product Summary
// ------------------------------

// Import main libraries
import usePage from "../dynamic-page/util/pageControl";

// Import model-view libraries
import tableView from "./modals-view/table";
import sortView from "./modals-view/sort";
import actionsView from "./modals-view/actions";
import addForm from "./modals-view/form";
import optionsView from "./modals-view/options";
import FiltersView from "./modals-view/filters";
import tabsView from "./modals-view/tabs";

// Main Component
const Attributes = () => {
    // Initial Page
    const Page = usePage({
        pageTitle: "Attributes",
        api: {
            fetch: "attribute",
            filters: "attribute/filters",
            query: "include=group",
        },
        ModelsView: {
            table: tableView,
            filter: FiltersView,
            sort: sortView,
            tabs: tabsView,
            actions: actionsView,
            headerOption: optionsView,
            forms: {
                add: addForm,
            },
        },
        pageSettings: {},
    });

    // return View
    return Page;
};

export default Attributes;
