import React from 'react';
// import Modal from '../sub-elements/modal'
import {Button, Modal} from 'flowbite-react'

const ProductDeleteModal = ({name, center, show, onClose, handleProductDeletion}) => {

    return (
        // <MainModal show={show} title={''} center={center}

        //     item={{
        //         cancel : onClose,
        //         view : () => (
        //             <div className='flex flex-col items-center justify-center px-[10px] py-[10px] gap-y-4'>
        //         <div>
        //             <p className='text-center leading-loose'> <span className='font-medium'>{name}</span> will be deleted completely. Are you sure <br /> you want to continue processing?</p>
        //         </div>
        //         <div className='flex justify-center gap-x-[91px] mt-[15px]'>
        //             <div >
        //                 <ArbitButton
        //                     onClick={() => onClose()}
        //                 >
        //                     Cancel
        //                 </ArbitButton>
        //             </div>
        //             <div >
        //                 <ArbitButton
        //                 border_color={'red'}
        //                 bg_color={'red'}
        //                     onClick={() => handleProductDeletion()}
        //                 >
        //                     Delete
        //                 </ArbitButton>
        //             </div>
        //         </div>
        //     </div>
        //         )
        //     }}
        // />
        <Modal
            onClose={onClose}
            show={show}
            popup
            size="md"
        >
            <Modal.Header/>
            <Modal.Body>
                <div className="text-center">
                    <svg aria-hidden="true" class="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none"
                         stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        <p>
                            Are you sure you want to delete <span className='font-medium'>{name}</span>?
                        </p>
                    </h3>
                    <div className="flex justify-center gap-4">
                        <Button
                            color="failure"
                            onClick={handleProductDeletion}
                        >
                            Yes, I'm sure
                        </Button>
                        <Button
                            color="gray"
                            onClick={onClose}
                        >
                            <p>
                                No, cancel
                            </p>
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ProductDeleteModal