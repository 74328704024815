import MainModal from "../../../dynamic-page/MainModal"
import React, {memo, useEffect, useRef} from "react";
import CreateShipmentView from "./createShipmentView";

import DynamicForm from "../../../dynamic-form";
import combinedWarehouseSelect from "./model-view/combinedWarehouseSelect";
import axios from "axios";

const CreateShipment = memo(({ order, cancel, submit, awaiting_transfer }) => {
    
    const myFormRef = useRef(null)
    const [shipmentItem, setShipmentItem] = React.useState({
        orderId: order?.id,
        warehouseId: order?.defaultWarehouse,
        onWarehouse: order?.defaultWarehouse,
        fromStateId: null,
        state: order?.state,
        billable_weight: order?.billable_weight,
        fresh: 0,
        items_qty: order?.items_qty,
        onWarehouseInfo: null,
    })

    useEffect(() => {
        const fetchDefaultWarehouse =  () => {
            axios.get(`/api/v1/warehouses/${order?.defaultWarehouse}?include=state`).then((res) => {
                setShipmentItem({
                    ...shipmentItem,
                    onWarehouseInfo: res.data?.data,
                    fromStateId: res.data?.data?.state?.id
                })
            })
        }
        if (order?.defaultWarehouse) {
            fetchDefaultWarehouse()
        }
    }, [order] )

    const handleList = (value, bound) => {
        setShipmentItem({
            ...shipmentItem,
            onWarehouseType: bound?.onWarehouse?.type || 'warehouse',
            warehouseId: typeof value?.warehouse === 'string' ? value?.warehouse?.split("id:")[1] : value?.warehouse,
        onWarehouse: typeof value?.onWarehouse === 'string' ? value?.onWarehouse?.split("id:")[1] : value?.onWarehouse,
            onWarehouseInfo: bound?.onWarehouse?.data || shipmentItem?.onWarehouseInfo,
            fromStateId: bound?.onWarehouse?.data?.state?.id,
        })
    }



    return (
        <div className={"createShipmentModal"}>
            <style>
                {`
                    .createShipmentModal .css-1u9des2-indicatorSeparator {display: none; }
                    .createShipmentModal .css-1xc3v61-indicatorContainer {padding-left: 0px; padding-right:6px }
                    .createShipmentModal .css-1xc3v61-indicatorContainer {padding-right: 12px}
                    .createShipmentModal .css-1fdsijx-ValueContainer,
                    .createShipmentModal .css-qbdosj-Input {padding-top:0px; padding-bottom:0px}
                    .css-1hb7zxy-IndicatorsContainer {height:42px !important}
                    .createShipmentModal .css-1xc3v61-indicatorContainer svg {color:transparent !important; background: url('/assets/images/arrow.svg'); width:12px; height:12px; opacity:1}
                `}
            </style>

            <MainModal
                className='!h-[602px] !w-[924px]  !min-w-[924px]'
                containerClassName='!px-0'
                headerClassName='!px-5 !py-4 !h-[74px] !items-center !justify-between'
                optionClassName='!gap-[28px]'
                item={{
                    title: `Create Shipment for ${order?.billable_weight} kg`,

                    cancel: cancel,
                    view: (item) => (
                        <CreateShipmentView item={shipmentItem} submit={(data) => submit(data)} cancel={cancel} awaiting_transfer={awaiting_transfer} />),
                }}
                option={
                    <div className={`flex items-center !gap-2 !text-[16px] !font-normal transition-all`}>
                        <style>
                            {
                                `
                                .label > div {
                                    gap: 0px !important;
                                }
                                `
                            }
                        </style>
                    <DynamicForm items={combinedWarehouseSelect(order, shipmentItem)} ref={myFormRef}
                            onChange={(value, bound) => {
                            handleList(value, bound)
                        }}
                        style={{
                            container: {
                              className:
                                "!flex-row justify-between items-center  text-[16px] mb-[8px]",
                            },
                            label: {
                              className:
                                "!min-w-fit whitespace-nowrap !text-[16px] !font-medium !text-gray-500 label",
                            },
                        }}
                        />
                </div>
                }
            />
        </div>


    )
})


export default CreateShipment;


