import React from "react";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import {HiExternalLink} from "react-icons/hi";
import ProductName from "../../dynamic-page/components/productName";
import {BsFillInfoCircleFill} from "react-icons/bs";
import PopUPList from "../../product-list/componetns/popUPList";
import descriptiveContent from "../../../utils/descriptiveContent";

const tableView = (list,actionList,e,isSelectHidden) => {

    const wareHouseDetail = (item) => {
        if (!item?.inventory_detail || item?.inventory_detail?.length === 0) {
            return [['No warehouse details available']];
        }
        if (item?.inventory_detail) {
            return item?.inventory_detail.map(detail => [
                detail.name,
                `${detail.available_quantity}`
            ]);
        }
    }

    return ({
        list: list,
        theme: {
            "": (item) => (
                <div className={`${isSelectHidden && "ml-4"} w-[56px] h-[64px] max-h-[64px] justify-center items-center flex`}>
                    <ImageMagnifier src={item?.product?.image} className={"!max-h-[64px]"}/>
                </div>
            ),
            "product": (item) => (
                <ProductName item={item?.product} user={item?.product?.brand?.assigned}/>
            ),
            "weight": (item) => (
                <div className={"flex flex-col gap-[0px] justify-center items-center"}>
                    {descriptiveContent(<div
                        className="text-purple-700 text-[24px] font-bold leading-[24px] inline-flex justify-center items-center gap-[4px]">
                        {item?.selected_weight === "dimensional" ?
                            <>
                                <Icons.weightBox className={"h-[16px]"}/>
                                {item?.dim_weight || "-"}
                            </>
                            :
                            <>
                                <Icons.weightTarazo className={"h-[16px]"}/>
                                {item?.actual_weight || "-"}
                            </>
                        }
                    </div>, 'Billable Weight')}
                    {
                        descriptiveContent(<div
                            className="w-[45px] h-4 text-purple-300 text-[12px] font-medium leading-[12px] inline-flex justify-center items-center gap-[4px]">
                            {item?.selected_weight !== "dimensional" ?
                                <><Icons.weightBox className={"h-[14px]"}/>
                                    <div
                                        className="text-purple-300 text-[12px] font-medium leading-[12px]">{item?.dim_weight || "-"}</div>
                                </>
                                :
                                <><Icons.weightTarazo className={"h-[16px]"}/>
                                    <div
                                        className="text-purple-300 text-[12px] font-medium leading-[12px]">{item?.actual_weight || "-"}</div>
                                </>
                            }
                        </div>, item?.selected_weight !== "dimensional" ? "Dimensional Weight" : "Actual Weight")
                    }
                </div>
            ),
            "order count": (item) => (
                <div className="  items-center inline-flex justify-end w-[100%] ">
                    <util.popUp
                        toggle={(selected) => (
                            <div
                                className="text-green-700 text-[24px] font-bold leading-[24px] inline-flex items-center gap-[4px] px-[8px] justify-end w-[100%] hover:text-blue-500 group transition cursor-pointer"
                                onClick={() => {
                                    const baseUrl = window.location.origin + '/logistics/orders';
                                    window.open(`${baseUrl}?term=${item?.product?.apid}&filter[order_status]=2`, "_blank");
                                }}
                            >
                                {item?.order_count}
                                <HiExternalLink className="w-4 h-4 text-gray-300 group-hover:text-gray-500"/>
                            </div>
                        )}
                        action={"hover"}
                        forcePosition={"top"}
                    >
                        <span className={"text-gray-400"}>Open Orders</span>
                    </util.popUp>
                </div>
            ),
            "t. ordered": (item) => (
                <div className=" h-6 items-center gap-1 inline-flex justify-end w-[100%] px-[8px]">
                    <div
                        className="text-green-700 text-[24px] font-bold leading-[24px]">{item?.total_ordered_product_qty}</div>
                </div>
            ),
            "s. requested": (item) => (
                <div className="  items-center inline-flex justify-end w-[100%] ">
                    <util.popUp
                        toggle={(selected) => (
                            <div
                                className={`${item?.total_supply_requested_qty ? "text-purple-700" : "text-gray-500"} hover:text-blue-500 transition text-[24px] font-bold leading-[24px] inline-flex items-center gap-[4px] justify-end w-[100%] px-[8px] cursor-pointer group`}
                                onClick={() => {
                                    const baseUrl = window.location.origin + '/inventory/supply_requests';
                                    window.open(`${baseUrl}?filter[reason]=order&term=${item?.product?.apid}`, "_blank");
                                }}
                            >
                                {item?.total_supply_requested_qty || "-"}
                                <HiExternalLink className="w-4 h-4 text-gray-300 group-hover:text-gray-500 transition"/>
                            </div>
                        )}
                        action={"hover"}
                        forcePosition={"top"}
                    >
                        <span className={"text-gray-400"}>Open Supply Request</span>
                    </util.popUp>
                </div>
            ),
            "s. purchased": (item) => (
                <div className="  items-center inline-flex justify-end w-[100%] ">
                    <util.popUp
                        toggle={(selected) => (
                            <div
                                className={`${item?.total_supply_requested_qty ? "text-purple-700" : "text-gray-500"} hover:text-blue-500 transition text-[24px] font-bold leading-[24px] inline-flex items-center gap-[4px] justify-end w-[100%] px-[8px] cursor-pointer group`}
                                onClick={() => {
                                    const baseUrl = window.location.origin + '/inventory/supply_purchases';
                                    window.open(`${baseUrl}?filter[reason]=order&term=${item?.product?.apid}`, "_blank");
                                }}
                            >
                                {item?.total_supply_purchased_qty || "-"}
                                <HiExternalLink className="w-4 h-4 text-gray-300 group-hover:text-gray-500 transition"/>
                            </div>
                        )}
                        action={"hover"}
                        forcePosition={"top"}
                    >
                        <span className={"text-gray-400"}>Open Supply Purchase</span>
                    </util.popUp>
                </div>
            ),
            "in stock": (item) => (
                <div
                    className="text-orange-600 text-[24px] font-bold leading-[24px] inline-flex items-center gap-[4px] justify-end w-[100%] px-[8px]">
                    {item?.total_inventory_qty || "-"}
                    <util.popUp
                        toggle={(selected) => (
                            <BsFillInfoCircleFill className="w-[16px] hover:text-gray-500 h-[16px] text-gray-300 "/>
                        )}
                        action={"hover"}
                    >
                        <>
                            <PopUPList list={wareHouseDetail(item)}/>
                        </>

                    </util.popUp>
                </div>
            ),
            "resupply": (item) => (
                <div className={` ${isSelectHidden && "-ml-4"} h-6 items-center gap-1 inline-flex justify-end w-[100%] px-[8px]`}>
                    <div className="text-red-700 text-[24px] font-bold leading-[24px]">{item?.restock_qty || "-"}</div>
                </div>
            ),
            "supply": (item) => (
                <div
                    className="text-blue-700 text-sm font-medium leading-[21px] px-[8px] p-r-[32px] hover:text-blue-500 transition cursor-pointer"
                    onClick={() => {
                        window.open(`/inventory/supply_requests#action=create&qty=1&reason=bulk_order&product=${item?.product?.id}`, "_blank")
                    }}
                >
                    Supply Request
                </div>
            )
        }
    })
}
export default tableView;

