import axios from "../../middleware/axios";
import constants from "../../constant";


const fetchSupPurchases = async (params) => {
    let url = constants.APIURL.GET_SUPPLY_PURCHASES;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );
    return response;
};


// bulk delete , restore , update status
const bulkDeleteSupPurchases = async (payload) => {

    const response = await axios.delete(constants.APIURL.GET_SUPPLY_PURCHASES, {
        withCredentials: true,
        data: {ids: payload}
    });
    return response;
};

const bulkRestoreSupPurchases = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_RESTORE_SUPPLY_PURCHASES, payload, {withCredentials: true});
    return response;
};

const bulkUpdateSupPurchasesStatus = async (payload) => {
    const response = await axios.put(constants.APIURL.PUT_BULK_SUPPLY_PURCHASE_STATUS, payload, {withCredentials: true});
    return response;

};

const fetchSupPurchasesFilters = async (params) => {

    let url = constants.APIURL.GET_SUPPLY_PURCHASES_FILTERS;
    if (params) url = url.concat(params);
    const response = await axios.get(url,
        {withCredentials: true}
    );

    return response?.data;
};


const updateSupplyPurchase = async ({id, payload}) => {
    const response = await axios.put(constants.APIURL.PUT_UPDATE_SUPPLY_PURCHASE + id, payload, {withCredentials: true});
    return response;
};

// const updateSupplyPurchaseFromRequest = async ({id, payload}) => {
//     const response = await axios.put(constants.APIURL.PUT_UPDATE_SUPPLY_PURCHASE_FROM_REQUEST + id , payload, { withCredentials: true });
//     return response;
// };


export default {
    fetchSupPurchases,
    bulkDeleteSupPurchases,
    bulkRestoreSupPurchases,
    bulkUpdateSupPurchasesStatus,
    fetchSupPurchasesFilters,
    updateSupplyPurchase,
    // updateSupplyPurchaseFromRequest
};
