import Icons from "../../icons";
import util from "../../../dynamic-page/util";
import React from "react";
import priceHandler from "../../../../utils/priceHandler";
import descriptiveContent from "../../../../utils/descriptiveContent";

const Profit = ({profit}) => {
    return (
        <div className={"flex flex-row gap-[9px] items-center w-full flex-1"}>
            <div className={"flex flex-col flex-1 items-end w-full"}>
                <span
                    className={`min-w-max whitespace-no-wrap text-[14px] text-${profit?.profit_color_code || 'grey'}-700 h-[21px] flex items-center gap-1 font-bold justify-center`}>
                    {descriptiveContent(profit?.margin ? `${profit?.margin}%` : `-`, 'Profit margin')}
                    <util.popUp
                        toggle={(selected) => (
                            <Icons.calculator className={`text-gray-300 hover:text-gray-500 h-[12px] ${selected && "!text-gray-500"}`}/>
                        )}
                        action={"hover"}
                    >
                        <div className={"px-[12px] py-[10px] flex gap-[6px] flex-col"}>
                            {profit?.statCalculator && Object.keys(profit?.statCalculator).map((key, index) => (
                                <div className={"flex justify-between gap-[16px] min-w-[190px]"} key={index}>
                                    <span
                                        className={"text-gray-600 font-normal text-[16px]"}>{util.upperCaseString(key.replaceAll("_", " "), "word")}</span>
                                    <span className={"text-gray-600 text-[14px] font-bold"} style={{direction: "ltr"}}>
                                        {profit?.statCalculator[key] != null ?
                                            priceHandler(profit?.statCalculator[key], profit?.currency) : "-"}</span>
                                </div>
                            ))}

                        </div>
                    </util.popUp>
                </span>
                {profit?.profit &&
                    <span
                        className={"text-grey-700 flex-row  text-xs font-medium h-[18px] items-center flex justify-center"}>
                        {descriptiveContent(priceHandler(profit?.statCalculator?.items_paid, profit?.currency), 'Sold price')}
                    </span>
                }

            </div>
            

        </div>
    )
}
/*  <StatCalculator profit={profit.statCalculator}/>*/
const StatCalculator = ({profit}) => {
    return (
        <div className="absolute flex flex-col justify-center rounded-md overflow-hidden"
             style={{filter: "drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1)), drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05))"}}
        >
            <div className="flex justify-center"><Icons.boxArrow className="h-[8px] text-white"/></div>
            <div className="bg-white min-w-[150px] p-[8px] rounded-md overflow-hidden">Items Paid:</div>
        </div>


    )
}

export default Profit