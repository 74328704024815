import {useMemo} from "react";
import constant from "../../constant";
import {useSelector} from "react-redux";
import {HiExternalLink} from "react-icons/hi";
import {MdLocalShipping} from "react-icons/md";
import CustomAsyncSelect from "../Supplies/customAsyncSelect";
import moment from "moment";

const ProductSelect = ({
                           product,
                           setProduct,
                           onProductSelected,
                           setSelectedProduct,
                       }) => {
    const productsData = useSelector((state) => state.productReducer.products);

    const mapProductsOptionsToValues = (options) => {
        return options.map((option) => ({
            value: option?.id,
            label: `#${option?.id}  |  ` + (option?.title ? option?.title : ""),
            description:
                "APID:  " +
                option?.apid +
                " | " +
                "Created At:  " +
                moment(option?.request_date_time).format("DD-MM-YYYY"),
            image: option?.image,
            assign: option?.brand?.assigned,
            profile: option?.brand?.assigned?.profile_image,
            id: option?.id,
            apid: option?.apid,
            title: option?.title,
            selectedPurchase: null,
            note: "",
            quantity: 0,
            expiry_date: "",
            awaiting_shipment_orders_count: option?.awaiting_shipment_orders_count,
            awaiting_acceptance: option?.awaiting_acceptance
        }));
    };

    const defaultProducts = useMemo(() => {
        return mapProductsOptionsToValues(productsData);
    }, [productsData]);

    const onNavigateToProduct = (item) => {
        window.open(`/product/${item?.id}`, "_blank");
    };

    return (
        <CustomAsyncSelect
            url={constant.APIURL.GET_PRODUCT}
            mapOptions={mapProductsOptionsToValues}
            defaultOptions={defaultProducts}
            defaultParams="&filter[type]=1&filter[awaiting_acceptance]=true"
            placeholder="Select Product"
            isDisabled={!!product}
            product={product}
            onChange={(data) => {
                setProduct(data);
                onProductSelected(data);
            }}
            optionHeight={50}
            features={{
                formatOptionLabel: (option) => (
                    <div className="flex">
                        <div class=" min-w-[42px] flex justify-center items-center">
                            <img
                                class="max-h-[42px] object-contain"
                                src={option?.image || "/assets/images/noImage.png"}
                            />
                        </div>
                        <div class="m-0 p-0 flex flex-row justify-around items-center h-10 w-full">
                            <div class="flex flex-grow">

                                <div class="px-4 flex h-full justify-center items-center my-auto">
                <span class="text-blue-600 text-[14px] font-normal leading-tight my-auto">
                  {option?.label}
                </span>
                                    <span class="ml-4 text-gray-500 text-[14px] font-normal leading-tight  my-auto">
                  {option?.value}
                </span>
                                </div>
                            </div>
                            <div class="flex h-full justify-center items-center">
                                <div class="w-6 h-6 relative">
                                    {option?.awaiting_acceptance && (
                                        <MdLocalShipping className="text-red-700 w-6 h-6"/>
                                    )}
                                </div>
                                <div class="w-6 h-6 relative">
                                    <HiExternalLink
                                        onClick={() => onNavigateToProduct(option)}
                                        className="cursor-pointer text-blue-700 w-6 h-6"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
            }}
            customStyles={{
                container: (provided, state) => ({
                    ...provided,
                    width: "100%",
                    height: 42,
                }),
            }}
        />
    );
};

export default ProductSelect;
