/**
 * @description New Attribute Group Modal to add a new attribute group
 */
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import ArbitButton from "../sub-elements/button";
import Dropdown from "../sub-elements/dropdown";
import Modal from "../sub-elements/modal"
import ArbitNormalInput from "../sub-elements/normal-input";
import {createAttributes, getRule} from '../../store/products';
import {toast} from "react-toastify";
import Select from 'react-select';


const ArbitNewAttributeGroupModal = ({
                                         show,
                                         onClose,
                                         onCloseParent,
                                         attributeGroupsData,
                                         attributeTypes,
                                         attributeGroupsList,
                                         dispatchListener,
                                         setDispatchListener
                                     }) => {
    const dispatch = useDispatch()
    const [searchResult, setSearchResult] = useState([])
    const [attributeGroup, setAttributeGroup] = useState('')
    const [attributeName, setAttributeName] = useState('')
    const [attributeType, setAttributeType] = useState('')
    const [attributeDescription, setAttributeDescription] = useState('')
    const [rule, setRule] = useState('')
    const [maxCount, setMaxCount] = useState();
    const [minCount, setMinCount] = useState('');
    const [regex, setRegex] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const [minValue, setMinValue] = useState('');
    const [possibleOptions, setPossibleOptions] = useState([])
    const [isMulti, setIsMulti] = useState(false)
    const [isMultiLingual, setIsMultiLingual] = useState(false)
    const [nameError, setNameError] = useState(false);
    const [groupError, setGroupError] = useState(false);
    const [typeError, setTypeError] = useState(false);

    const [validation, setValidation] = useState(rule)

    const groupOptions = attributeGroupsList.map(item => ({label: item.name, value: item.id}))

    useEffect(() => {
        if (attributeType === 'TEXT') {
            setValidation(
                (prevState) => ({
                    ...prevState,
                    maxCount: maxCount,
                    minCount: minCount,
                    regEx: regex
                })
            )
        } else if ((attributeType === 'INTEGER' || attributeType === 'FLOAT')) {
            setValidation(
                (prevState) => ({
                    ...prevState,
                    maxValue: maxValue,
                    minValue: minValue
                })
            )
        }
    }, [attributeType, maxCount, minCount, regex, maxValue, minValue])


    useEffect(() => {
        if (attributeType === 'BOOLEAN') {
            setValidation(
                {
                    'boolean': 'boolean'
                }
            )
        }
        if (attributeType === 'DROPDOWN') {
            setValidation({
                'enum': ['v', 'w']
            })
        }
        if (attributeType === 'DATETIME') {
            setValidation({
                'datetime': 'datetime'
            })
        }
        if (attributeType === 'DATE') {
            setValidation({
                'date': 'date'
            })
        }
        if (attributeType === 'TIME') {
            setValidation({
                'time': 'time'
            })
        }
        if (attributeType === 'DOCUMENT') {
            setValidation({
                'document': 'document'
            })
        }
        if (attributeType === 'IMAGE') {
            setValidation({
                'image': 'image'
            })
        }
        if (attributeType === 'DIMENSION') {
            setValidation({
                'dimension': 'dimension'
            })
        }
    }, [attributeType, rule, dispatch])


    const onSearchChange = (value) => {
        setSearchResult(
            value === ""
                ? []
                : attributeGroupsData.filter(
                    item => item.toLowerCase().startsWith(value.toLowerCase())
                )
        )
    }


    useEffect(() => {
        if (attributeType !== '') {
            dispatch(getRule(attributeType)).unwrap().then(({data}) => {
                setRule(data?.rules)
                setValidation(data?.rules)
            }).catch(err => {
                // console.log(err)
            })
        }

    }, [attributeType, dispatch])

    const valuesRule = () => {
        switch (attributeType) {
            case "TEXT":
                return (
                    <div className="flex justify-between gap-2">
                        <div className="flex w-[49%] flex-col gap-2">
                            <label className="text-sm font-medium text-gray-700">Min Count</label>
                            <ArbitNormalInput placeholder={"Min Count"} value={minCount}
                                              onChange={(e) => setMinCount(e)}/>
                        </div>
                        <div className="flex w-[49%] flex-col gap-2">
                            <label className="text-sm font-medium text-gray-700">Max Count</label>
                            <ArbitNormalInput placeholder={"Max Count"} value={maxCount}
                                              onChange={(e) => setMaxCount(e)}/>
                        </div>
                        {/* <div className="mr-[15px]">
              <ArbitNormalInput placeholder={"regex"} value={regex} onChange={(e) => setRegex(e)} width="[120px]" />
            </div> */}
                    </div>

                )
            case 'INTEGER':
            case 'FLOAT':
                return (
                    <div className="flex w-full justify-between ">
                        <div className="flex w-[49%] flex-col gap-2">
                            <label className="text-sm font-medium text-gray-700">Min Value</label>
                            <ArbitNormalInput placeholder={"Min Value"} value={minValue}
                                              onChange={(e) => setMinValue(e)}/>
                        </div>
                        <div className="flex w-[49%] flex-col gap-2">
                            <label className="text-sm font-medium text-gray-700">Max Value</label>
                            <ArbitNormalInput placeholder={"Max Value"} value={maxValue}
                                              onChange={(e) => setMaxValue(e)}/>
                        </div>
                    </div>
                )

            case 'DROPDOWN':
                return (
                    // a normal input
                    <div className="flex w-full">
                        <div className="flex w-full flex-col gap-2 ">
                            <label className="text-sm font-medium text-gray-700 flex justify-between items-center">Possible
                                Options<span
                                    className="text-xs text-red-400 font-light">*Seperated By Comma*</span></label>
                            <ArbitNormalInput placeholder={"Possible Options"} value={possibleOptions}
                                              onChange={(e) => {
                                                  setPossibleOptions(e)
                                                  setValidation({
                                                      'enum': e.split(',')
                                                  })
                                              }}
                                              width="full"/>
                        </div>
                    </div>
                )

            default:
                break;
        }
    }
    const onAddNewAttributeGroup = () => {
        if (attributeName === '') {
            setNameError(true)

        }

        if (attributeGroup === '') {
            setGroupError(true)

        }

        if (attributeType === '') {
            setTypeError(true)

        }

        if (attributeName === '' || attributeGroup === '' || attributeType === '') {
            return
        }


        const payload = {
            name: attributeName,
            group_id: attributeGroupsList.find(item => item.name === attributeGroup)?.id,
            type: attributeType,
            description: attributeDescription,
            validation_rules: validation,
            is_multiple: isMulti ? 1 : 0,
            is_multilingual: isMultiLingual ? 1 : 0,
        }
        onClose()
        // onCloseParent()
        dispatch(createAttributes(payload)).unwrap().then((response) => {
            if (response.status === 200) {
                setDispatchListener(!dispatchListener)
                toast.success('Successfuly Update the Attribute', {
                    toastId: "success1",
                });

            }
        }).catch(err => {
            toast.error('Error ', {
                toastId: "success1",
            });
        })
    }


    return (
        <Modal title="Create a New Attribute" show={show} center={true} onClose={onClose}>
            <div className="w-[50vw] min-w-[400px] flex flex-col gap-3 min-h-[185px]">
                {/* the inpout grid */}
                <div className="grid grid-cols-2 gap-[8px] items-center">
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium text-gray-700">Name</label>
                        <ArbitNormalInput placeholder={"Attribute Name"} value={attributeName} error={nameError}
                                          onChange={(e) => {
                                              setAttributeName(e)
                                              setNameError(false)
                                          }}/>
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium text-gray-700">Class</label>
                        <Select
                            placeholder="Select Attribute Groups"
                            options={groupOptions}
                            onChange={(value) => {
                                setAttributeGroup(value?.label);
                                setGroupError(false);
                            }}
                            value={groupOptions.find((item) => item.value === attributeGroup)}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    background: '#F3F4F6',
                                    width: '100%',
                                    cursor: 'pointer',
                                    borderRadius: 8,
                                    border: state.isFocused ? '1px solid #00A3FF' : (groupError ? '1px solid #FF0000' : '1px solid #E5E5E5'),
                                    boxShadow: state.isFocused ? '0px 0px 0px 1px #00A3FF' : 'none',
                                    '&:hover': {
                                        border: state.isFocused ? '1px solid #00A3FF' : '1px solid #E5E5E5',
                                    }
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? '#00A3FF' : 'white',
                                    color: state.isSelected ? 'white' : 'black',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#00A3FF',
                                        color: 'white'
                                    }
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    position: 'absolute',
                                    zIndex: 999,
                                    // borderRadius: 10,
                                    // overflow: 'hidden',
                                    border: state.isFocused ? '1px solid #00A3FF' : '1px solid #E5E5E5',
                                    boxShadow: state.isFocused ? '0px 0px 0px 1px #00A3FF' : 'none',
                                    '&:hover': {
                                        border: state.isFocused ? '1px solid #00A3FF' : '1px solid #E5E5E5',


                                    }
                                }),
                                container: (base) => ({
                                    ...base,
                                    width: '100%',
                                }),
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium text-gray-700">Type</label>
                        <Dropdown
                            placeholder="Attribute Type"
                            bg_color={"gray-50"}
                            height={'42px'}
                            // justify={'evenly'}
                            border_color={typeError ? 'red' : 'gray'}
                            text_color={"gray"}
                            title={"Attribute Type"}
                            items={attributeTypes?.types}
                            value={attributeType}
                            onSelect={(value) => {
                                setAttributeType(value)
                                setTypeError(false)
                            }}
                            width={'100%'}

                        />
                        {/* { attributeType === 'DROPDOWN' && (
              <p className=" text-sm ml-3 text-red-500" > Enter the possible options seperated by comma </p>
            )} */}
                    </div>
                    {/* <div className="flex flex-col gap-2">
            <label className="text-sm font-medium text-gray-700">Max Character Count/Value</label>
            <ArbitNormalInput placeholder={"Max Character Count/Value"} value={maxValue} onChange={(e) => setMaxValue(e)} />
          </div> */}
                    {valuesRule()}
                    {/* <div className="w-full">
            <ArbitNormalInput placeholder={"Character Count"} />
          </div> */}

                </div>
                {/* description box */}
                <div className=" flex flex-row gap-2">
                    <input type="checkbox"
                        //  checked={isMandatory} onChange={(e) => setIsMandatory(e.target.checked)}
                           className="relative z-10 w-[16px] h-[16px] text-blue-600 bg-gray-100 rounded
                            border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600
                            dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <div className="flex flex-col ">
                        <label className="text-sm font-medium text-gray-700">Multiple</label>
                        <p className=" text-sm text-gray-500"> Check it if the attribute may include multiple
                            values </p>
                    </div>
                </div>
                <div className=" flex flex-row gap-2">
                    <input type="checkbox"
                        //  checked={isMandatory} onChange={(e) => setIsMandatory(e.target.checked)}
                           className="relative z-10 w-[16px] h-[16px] text-blue-600 bg-gray-100 rounded
                            border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600
                            dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <div className="flex flex-col ">
                        <label className="text-sm font-medium text-gray-700">Multilingual</label>
                        <p className=" text-sm text-gray-500"> Check it if the attribute may include multiple values
                            across multiple languages </p>
                    </div>
                </div>
                <div className=" flex flex-col gap-2">
                    <label className="text-sm font-medium text-gray-700">Description</label>
                    <textarea
                        className="w-full min-h-[125px] border-[1px] border-gray-300 rounded-[8px] p-[16px] text-gray-500 resize-none"
                        value={attributeDescription}
                        placeholder={'Attribute Description'}
                        onChange={(e) => setAttributeDescription(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex w-full space-x-[4px] justify-end">
                <ArbitButton
                    width={"64px"}
                    text_color={"blue-600"}
                    bg_color={"white"}
                    onClick={() => onClose()}
                >
                    Cancel
                </ArbitButton>
                <ArbitButton
                    width={"48px"}
                    onClick={() => onAddNewAttributeGroup()}
                >
                    Add
                </ArbitButton>
            </div>
        </Modal>
    )
}


export default ArbitNewAttributeGroupModal;
