
export default ({validation, style}) => ({
    control: (baseStyles, state) => ({
        ...baseStyles,
        flex: "1",
        display: "flex",
        width: "100%!important",
        //height: "42px",
        minHeight: "42px",
        border: `${(validation) ? "2px solid red" : "1px solid #E5E7EB"}`,
        borderRadius: "8px",
        overflow: "hidden",
        background: "none",
        ...style?.control
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        // width: "334px",
        //maxHeight: "230px",
        overflow: "hidden",
        width: "fit-content",
        minWidth: "100%",
        border: `${(validation) ? "2px solid red" : "1px solid #E5E7EB"}`,
        borderRadius: "8px",
        marginBottom: "30px ",
        whiteSpace: "nowrap",
        paddingLeft: "4px",
        ...style?.menu

    }),

    menuList: (baseStyles, state) => ({
        ...baseStyles,
        padding: "0px",
        ...style?.menuList
    }),


    option: (provided, state) => ({
        ...provided,
        borderRadius: "6px",
        background: state.isSelected ? "rgb(63 131 248" : "",
        transition: "all 0.2s ease",
        cursor: state.isSelected ? "default" : "pointer",
        position: "relative",
        width: "calc(100% - 4px)",
        ...style?.option
    }),
    multiValue: (provided, state) => ({
        ...provided,
        background: "#EBF5FF",
        paddingLeft: "4px",
        borderRadius: "4px",
        fontSize: "17px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "500",
        ...style?.multiValue
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: "#1C64F2",
        borderRadius: "48px",
        transition: "all 0.2s ease",
        padding: "0px",
        ":hover": {
            backgroundColor: "#1C64F2",
            color: "white",
        },
        height: "22px",
        width: "22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "5px",
        marginLeft: "5px",
        ...style?.multiValueRemove

    }),
    container: (provided, state) => ({
        ...provided,
        ...style?.container,
        background: state.isDisabled ? "#F3F4F6" : "white",
    }),
    // hide the clear indicator
    clearIndicator: (provided, state) => ({
        ...provided,
        display: "none",
        visibility: "hidden",
        ...style?.clearIndicator
    }),
    placeholder: (provided, state) => ({
        ...provided,
        'whiteSpace': "nowrap !important", 
        fontSize: "14px",
        lineHeight: "17.5px",
    }),
    // dropdownIndicator : (provided, state) => ({
    //     display : "none",
    // }),
    singleValue: (provided, state) => ({
        ...provided,
        // whiteSpace: "nowrap",
        ...style?.singleValue
    }),
    menuPortal: base => {
        const {zIndex, ...rest} = base;
        return {...rest, zIndex: 9999};
    },
    indicatorSeparator: (provided, state) => ({
        display: "none",
    }),
})