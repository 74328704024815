import React, { useEffect, useState } from "react";
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import DynamicTable from "../dynamic-page";
import FormModal from "./modals-view/form";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { useDispatch, useSelector } from "react-redux";
import util from "../dynamic-page/util";
import TableView from "./modals-view/table";
import getActionList from "../dynamic-page/util/getActionList";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import {
  fetchReferralFees as fetchData,
  fetchReferralFeeFilters as fetchFilters,
  removeRow,
  updateFilter,
  updateBoundFilter,
} from "../../store/referral-fees";
import sortView from "./modals-view/sort";
import TabButton from "../dynamic-page/elements/TabButton";
import SearchInput from "../dynamic-page/debt/searchInput";
import FiltersView from "./modals-view/filters";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";
import { useCallback } from "react";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";

const ReferralFees = () => {
  const [modalVisibility, setModalVisibility] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let filters = {};

    const startPageByFilters = {
      filters: filters,
      sort: null,
      page: 1,
      search: "",
      limit: 10,
      includes: "",
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
    // dispatch(OffersFilters(urlQuery));
  }, []);

  // Get data from store.
  const pageData = useSelector((state) => state.referralFees);

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState(null);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  const actionList = {
    edit: {
      label: "Edit",
      onClick: (item) => {
        if (item?.id) {
          setFormData(item);
          setModalVisibility(true);
        } else {
          setFormData(selectedRow[0]);
          setModalVisibility(true);
        }
      },
      bulkDisable: true,
    },
    delete: {
      label: "Delete",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to delete this referral fee?",
          confirmText: "Yes, Delete",
          callBack: (setSubmit) => {
            const ids = item ? [item.id] : selectedRow.map((item) => item.id);
            callEndpoint({
              // title: "Delete Supply Channel",
              url: `referral-fees`,
              method: "DELETE",
              data: {
                ids: ids,
              },
              pureData: true,
            }).then((res) => {
              util.notifier({ type: res.type, message: res.message });
              setSubmit(false);
              if (res.type === "success") {
                setConfirmationModalVisibility(null);
                onPaginationApply();
              }
            });
          },
        });
      },
    },
    acitvate: {
      label: "Activate",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to activate this referral fee?",
          confirmText: "Yes, Activate",
          callBack: (setSubmit) => {
            const ids = item ? [item.id] : selectedRow.map((item) => item.id);
            callEndpoint({
              // title: "Delete Supply Channel",
              url: `referral-fees/toggle-status`,
              method: "POST",
              data: {
                ids: ids,
              },
            }).then((res) => {
              setSubmit(false);
              util.notifier({ type: res.type, message: res.message });
              if (res.type === "success") {
                setConfirmationModalVisibility(null);
                if (pageData?.filters?.filters?.status === 0) {
                  dispatch(removeRow(ids));
                }
                dispatch(
                  fetchFilters(
                    urlQueryBuilder(pageData?.filters?.filters, null, "term")
                  )
                );
              }
            });
          },
        });
      },
    },
    deactivate: {
      label: "Deactivate",
      onClick: (item) => {
        setConfirmationModalVisibility({
          infoText: "Are you sure you want to deactivate this referral fee?",
          confirmText: "Yes, Deactivate",
          callBack: (setSubmit) => {
            const ids = item ? [item.id] : selectedRow.map((item) => item.id);
            callEndpoint({
              // title: "Delete Supply Channel",
              url: `referral-fees/toggle-status`,
              method: "POST",
              data: {
                ids: ids,
              },
            }).then((res) => {
              setSubmit(false);
              util.notifier({ type: res.type, message: res.message });
              if (res.type === "success") {
                setConfirmationModalVisibility(null);
                if (pageData?.filters?.filters?.status === 1) {
                  dispatch(removeRow(ids));
                }
                dispatch(
                  fetchFilters(
                    urlQueryBuilder(pageData?.filters?.filters, null, "term")
                  )
                );
              }
            });
          },
        });
      },
    },
  };

  const statusList = {
    1: {
      label: "Active",
      actions: [actionList.edit, actionList.delete, actionList.deactivate],
    },
    0: {
      label: "Inactive",
      actions: [actionList.edit, actionList.delete, actionList.acitvate],
    },
    null: {
      label: "All",
      actions: [
        actionList.edit,
        actionList.delete,
        actionList.acitvate,
        actionList.deactivate,
      ],
    },
  };

  // handle actions of statuses
  //
  const actions = (row) => {
    return getActionList(row, statusList);
  };

  const onHandleTab = (item) => {
    onFilterApply({ status: item });
  };

  // Handle Pagination
  const onHandlePrevious = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };

  const onHandleNext = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const onSubmitApply = useCallback((data, close, callback) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { id, ...payload } = data;

        delete payload?.set_threshold;
        payload.currency_id = 2;

        if (!payload?.threshold) {
          delete payload?.threshold;
        }
        if (!payload?.over_threshold) {
          delete payload?.over_threshold;
        }

        if (!id) {
          const createResponse = await callEndpoint({
            title: "Create Referral Fee",
            url: `referral-fees`,
            method: "POST",
            data: payload,
          });
          if (createResponse.type === "success") {
            if (close) {
              onPaginationApply();
              setFormData(null);
              setModalVisibility(false);
            } else {
              if (callback) {
                callback();
              }
            }
            resolve(createResponse);
          } else {
            reject(createResponse); // Reject the promise on an error
          }
        } else {
          const updateResponse = await callEndpoint({
            title: "Update Referral Fee",
            url: `referral-fees/${id}`,
            method: "PUT",
            data: payload,
          });
          if (updateResponse.type === "success") {
            onPaginationApply();
            setFormData(null);
            setModalVisibility(false);
            resolve(updateResponse);
          } else {
            reject(updateResponse); // Reject the promise on an error
          }
        }
      } catch (error) {
        reject(error); // Reject the promise in case of an exception
      }
    });
  }, []);

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col w-full">
        <ArbitProductPage.Title
          title={"Referral Fees"}
          form={{
            callback: (rs) => {
              setModalVisibility(true);
            },
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: FiltersView(
              pageData?.filters?.filters,
              pageData?.filtersMeta
            ),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.supply_channel_type === undefined ||
              pageData?.filters?.filters?.supply_channel_type === null
                ? 1
                : 2),
          }}
          actionList={actions(selectedRow)}
        />

        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            loading={pageData?.filterLoading}
            callBack={onHandleTab}
            active={pageData?.filters?.filters?.status}
            list={[
              {
                id: 1,
                name: "Active",
                count: pageData?.filtersMeta?.statuses?.find(
                  (item) => item?.name === "active"
                )?.count,
              },
              {
                id: 0,
                name: "Inactive",
                count: pageData?.filtersMeta?.statuses?.find(
                  (item) => item?.name === "inactive"
                )?.count,
              },
            ]}
            allTab={true}
          />
        </div>

        <ArbitProductPage.Content>
          <div className="overflow-x-auto max-w-[100%]">
            <DynamicTable
              view={TableView}
              data={TableView(pageData?.referralFees)}
              actions={actions}
              onSelect={onSelectRow}
              selectedRow={selectedRow}
              meta={pageData?.meta}
              appending={loading}
              loadMoreData={loadMoreData}
              loading={pageData.loading}
              style={{
                table: {
                  style: { width: "100%", minWidth: "100%" },
                },
                header: {
                  className: "justify-center !px-[16px] ",
                  "tracking id": { className: "!pr-[30px]" },
                  image: { className: "hidden" },
                  type: { className: "!py-[0px] hidden" },
                },
                row: {
                  className:
                    "!px-[8px] !py-[0px] !items-center !justify-center flex text-[12px] ",
                  // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                  category: {
                    td: "w-[100%]",
                  },
                  // 'last update': {
                  //   td: 'w-fit',
                  // },
                  type: {
                    td: "w-[135px]",
                  },
                  _actions: { className: "!py-[0px]" },
                },
              }}
            />
          </div>
        </ArbitProductPage.Content>

        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {modalVisibility && (
        <FormModal
          data={formData}
          show={modalVisibility}
          //    params={params}
          onClose={() => {
            onPaginationApply();
            setFormData(null);
            setModalVisibility(false);
          }}
          onAdd={onSubmitApply}
        />
      )}

      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this order as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(null)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

export default ReferralFees;
