import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
import sortView from "./modals-view/sort";
// import formView from "./modals-view/form";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import {
  fetchReturns as fetchData,
  updateBoundFilter,
  updateFilter,
} from "../../store/returns";

import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";

const Returns = () => {
  const dispatch = useDispatch();

  // Initial page at first load.
  useEffect(() => {
    const startPageByFilters = {
      filters: {},
      sort: "-return_request_date",
      page: 1,
      limit: 10,
      search: "",
      includes: "",
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    // dispatch(fetchFilters(urlQuery));
  }, []);

  // Get data from store.
  const pageData = useSelector((state) => state.returns);

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const actions = (row) => {
    // todo const innerRow = row.map((item) => { add actions_id to item }

    // return getActionList(row, statusList);
    return [];
  };

  // Handle Tabs
  const onHandleTab = (item) => {
    onFilterApply({ refunded_type: item });
  };

  // Handle Pagination
  const onHandlePrevious = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };

  const onHandleNext = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    // dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    // await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          title={"Returns"}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            items: filtersView(pageData?.filters?.filters),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              ).length -
              (pageData?.filters?.filters?.current_status_id === undefined ||
              pageData?.filters?.filters?.current_status_id === null
                ? 0
                : 1),
          }}
          actionList={actions(selectedRow)}
        />

        {/* Tabs and search input are here. */}
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            callBack={onHandleTab}
            active={pageData?.filters?.filters?.refunded_type}
            list={[
              {
                id: "requested",
                name: "Refund Requested",
                count: pageData?.filtersMeta?.shipment_statuses?.find(
                  (item) => item?.name === "Refund Requested"
                )?.count,
              },
              // in transit , delivered , shipped
              {
                id: "refunded",
                name: "Refunded",
                count: pageData?.filtersMeta?.shipment_statuses?.find(
                  (item) => item?.name === "Refunded"
                )?.count,
              },
            ]}
            allTab={true}
          />
        </div>

        {/* Table is here. */}
        <ArbitProductPage.Content>
          <div className=" ">
            <div className="overflow-x-auto max-w-[100%]">
              <DynamicTable
                view={tableView}
                data={tableView(
                  pageData?.returns
                  // data
                )}
                actions={actions}
                onSelect={onSelectRow}
                meta={pageData?.meta}
                appending={loading}
                loadMoreData={loadMoreData}
                selectedRow={selectedRow}
                loading={pageData.loading}
                style={{
                  table: {
                    style: { width: "100%", minWidth: "100%" },
                  },
                  header: {
                    className: "justify-center !px-[16px] ",
                    "tracking id": { className: "!pr-[30px]" },
                    image: { className: "hidden" },
                    type: { className: "!py-[0px] hidden" },
                  },
                  row: {
                    className:
                      "!px-[8px] !py-[0px] !items-center !justify-center flex text-[12px] ",
                    // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                    description: {
                      className: "!min-w-fit min-w-max !w-fit !max-w-fit",
                    },
                    _actions: { className: "!py-[0px]" },
                  },
                  hiddenActions: true,
                  hideSelect: true,
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {/* Modals are here. */}
      {/* {modalVisibility && (
        <FormModal
          title={"Target Seller Folder "}
          formView={formView}
          visibility={modalVisibility}
          data={formData}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
          }}
          onSubmit={onSubmitApply}
        />
      )} */}
    </>
  );
};

export default Returns;
