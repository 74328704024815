import upperCaseString from "../../dynamic-page/util/upperCaseString";
import {useSelector} from "react-redux";

const FiltersView = (selectedFilers) => {

    const filtersMeta = useSelector(state => state?.shipments?.filtersMeta)
    return (
        [

            {
                name: "order_status_id",
                label: "Order Status",
                placeholder: "Filter by status",
                type: "Checkbox",
                list: filtersMeta?.order_statuses?.map((item) => ({
                    label: upperCaseString(item?.name),
                    value: item?.id,
                    count: item?.count
                })),
                defaultValue: selectedFilers?.['order_status_id'],
                toggle: true,
                clear: true,
                features: {
                    isMulti: true,
                },
                checkAll: true,
            },

            {
                name: "current_status_id",
                label: "Shipment Status",
                placeholder: "Filter by status",
                type: "Checkbox",
                list: filtersMeta?.shipment_statuses?.map((item) => ({
                    label: upperCaseString(item?.name),
                    value: item?.id,
                    count: item?.count
                })),
                defaultValue: selectedFilers?.['current_status_id'],
                toggle: true,
                clear: true,
                features: {
                    isMulti: true,
                },
                checkAll: true,
            },

            {
                name: "order.order_number",
                label: "Order Number",
                placeholder: "Filter by order number",
                type: "Input",
                toggle: true,
                isClearable: true,
                defaultValue: selectedFilers?.["order.order_number"],
            },

            {
                name: "employee.id",
                label: "Assigned Employee",
                placeholder: "Filter by employee",
                type: "Select",
                api: {
                    url: "/api/v1/users",
                    query: "?filter[name]={query}",
                    optionValue: (item) => ({
                        value: item?.id,
                        label: item?.name,
                        avatar: item?.image_url
                    })
                },
                features: {
                    isMulti: true,
                    formatOptionLabel: ({label, avatar}) => (
                        <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                                <span
                                    className={
                                        "block overflow-hidden flex flex-col justify-center items-center "
                                    }
                                >
                                <img
                                    src={avatar || "/assets/images/defaultAvatar.png"}
                                    className="w-5 h-5 rounded-full object-contain"
                                />
                                </span>
                            <span>{upperCaseString(label)}</span>
                        </div>)
                },
                defaultValue: selectedFilers?.['employee.id'],
                toggle: true,
                clear: true,
            },


            {
                name: "order.salesChannel.id",
                label: "Sales Channel",
                placeholder: "Filter by sales channel",
                type: "Checkbox",
                features: {
                    isMulti: true,
                },
                list: filtersMeta?.sales_channels?.map((item) => ({
                    label: upperCaseString(item?.name),
                    value: item?.id,
                    count: item?.count
                })),
                defaultValue: selectedFilers?.["order.salesChannel.id"],
                toggle: true,
                clear: true,
                checkAll: true,
            },
            {
                name: "order.salesAccount.id",
                label: "Sales Account",
                placeholder: "Filter by sales Account",
                type: "Checkbox",
                list: filtersMeta?.sales_accounts?.map((item) => ({
                    label: upperCaseString(item?.name),
                    value: item?.id,
                    count: item?.count
                })),
                defaultValue: selectedFilers?.["order.salesAccount.id"],
                toggle: true,
                clear: true,
                features: {
                    isMulti: true,
                },
                checkAll: true,
            },


            {
                name: "billable_weight",
                label: "Billable Weight",
                placeholder: "Filter by billable weight",
                type: "Range",
                defaultValue: selectedFilers?.billable_weight,
                toggle: true,
                clear: true,
            },
            {
                name: "order_actual_weight_fbm",
                label: "Actual Weight",
                placeholder: "Filter by actual weight",
                type: "Range",
                defaultValue: selectedFilers?.order_actual_weight_fbm,
                toggle: true,
                clear: true,
            },
            {
                name: "order_dimensional_weight_fbm",
                label: "Dim Weight",
                placeholder: "Filter by dim weight",
                type: "Range",
                defaultValue: selectedFilers?.order_dimensional_weight_fbm,
                toggle: true,
                clear: true,
            },

            {
                name: "order.order_date",
                label: "Order Date",
                placeholder: "Filter by order date",
                type: "DateRange",
                defaultValue: selectedFilers?.["order.order_date"],
                toggle: true,
                clear: true,
            },

            {
                name: "tracking_number",
                label: "Shipment Tracking Number",
                placeholder: "Filter by shipment tracking number",
                type: "Input",
                defaultValue: selectedFilers?.tracking_number,
                toggle: true,
                clear: true,
            },
            {
                name: "carrier.id",
                label: "Shipment Carrier",
                placeholder: "Filter by shipment carrier",
                type: "Select",
                api: {
                    url: "/api/v1/carrier",
                    query: "?filter[name]={query}",
                    optionValue: (item) => ({
                        value: item?.id,
                        label: item?.name,
                        logo: item?.logo || ""
                    })
                },
                features: {
                    isMulti: true,
                    formatOptionLabel: ({label, logo}) => (
                        <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                            <span
                                className={"w-[25px] h-[15px] block overflow-hidden flex flex-col justify-center items-center "}>
                                <img src={logo.toLowerCase()}/>
                            </span>
                            <span>{upperCaseString(label)}</span>
                        </div>)
                },
                defaultValue: selectedFilers?.["carrier.id"],
                toggle: true,
                clear: true,
            },
            {
                name: "carrierService.id",
                label: "Shipment Carrier Service",
                placeholder: "Filter by shipment carrier service",
                type: "Select",
                api: {
                    url: "/api/v1/carrier_service",
                    query: "?filter[name]={query}",
                    optionValue: (item) => ({
                        value: item?.id,
                        label: item?.name,
                    })
                },
                features: {
                    isMulti: true,
                },
                defaultValue: selectedFilers?.["carrierService.id"],
                toggle: true,
                clear: true,
            },

            {
                name: "order.shipping_content",
                label: "Shipping Content",
                placeholder: "Filter by shipping content",
                type: "Input",
                toggle: true,
                isClearable: true,
                defaultValue: selectedFilers?.["order.shipping_content"],
            },

        ]
    )
}

export default FiltersView;