import Dropdown from "../sub-elements/dropdown";
import ArbitButton from "../sub-elements/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import util from "../dynamic-page/util";
import Util from "../dynamic-page/util";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { HiExternalLink } from "react-icons/hi";
import Icons from "../orders/icons";
import AsyncSelect from "react-select/async";
import axios from "../../middleware/axios";
import constants from "../../constant";
import { toast } from "react-toastify";
import MainModal from "../dynamic-page/MainModal";
import { debounce } from "lodash";
import CustomAsyncSelect from "./customAsyncSelect";
import moment from "moment";
import { useSelector } from "react-redux";
import { RxCaretSort } from "react-icons/rx";
import { FaRegTrashAlt } from "react-icons/fa";
import FrontendFooterPagination from "../dynamic-page/elements/FrontendFooterPagination";
import { FaMinus, FaPlus } from "react-icons/fa6";
import AddProduct from "./components/addProduct";
import HandmadeSkeleton from "../../utils/skeleton";
import Skeleton from "react-loading-skeleton";
import upperCase from "../dynamic-page/util/upperCaseString";
import { HiChevronLeft, HiChevronRight, HiPlus } from "react-icons/hi";

const NewRequest = ({
  onClose,
  onRequest,
  user,
  show,
  data,
  params,
  isAddClicked,
  setAddClicked,
}) => {
  const paramProduct = params.get("product");
  const reason = params.get("reason");
  const qty = params.get("qty");
  const productIds = params.get("products");
  // get current date and time in local timezone
  const current = new Date();
  const date = current.toISOString().substr(0, 10);
  const time = current.toLocaleTimeString("en-US", { hour12: false });

  const productsData = useSelector((state) => state.productReducer.products);
  const orders = useSelector((state) => state.orders.orders);

  const [requestReason, setRequestReason] = useState(null);

  const [invalidUrl, setInvalidUrl] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({
    product: "",
    title: null,
    image: null,
    qty: qty ? qty : null,
    request_reason: null,
    comment: null,
  });
  const [errorMessage, setErrorMessage] = useState(false);

  const fetchProducts = async (params) => {
    let url = constants.APIURL.GET_PRODUCT_SUMMARY;
    if (params) url = url.concat(params);

    const response = await axios.get(url, { withCredentials: true });
    // console.log(response);
    return response.data;
  };
  const fetchProductsSummary = async (params) => {
    let url = constants.APIURL.GET_PRODUCT_SUMMARY;
    if (params) url = url.concat(params);

    const response = await axios.get(url, { withCredentials: true });
    // console.log(response);
    return response.data;
  };

  const [defaultOptions, setDefaultOptions] = useState([]);

  useEffect(() => {
    if (paramProduct || productIds) {
      fetchProducts("?not_deleted=true")
        .then((response) => {
          let options = mapOptionsToValues(response.data);
          setDefaultOptions(options);
        })
        .catch((error) => {});
    }
    // fetchProductsSummary(`?filter[summaryType]=all/?filter[summaryType]=resupply&filter[id]=${productIds}`)
    //   .then((response) => {
    //     console.log(response.data);
    //   })
  }, []);

  const mapOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label:
        option?.title ||
        option?.name ||
        "ID:" + option?.id + " || Number:" + option?.order_number,
      image: option?.image,
      title: option?.title,
      product: option?.id,
      assign: option?.assign?.name,
      profile: option?.assign?.profile_image,
      qty: "",
      comment: "",
    }));
  };
  const mapOptionsToValues2 = (options) => {
    return options?.map((option) => ({
      value: option?.id,
      label: option?.name || option?.display_name,
      image: option?.image,
      code: option?.code,
      title: option?.name,
      product: option?.id,
      position: option?.position,
      symbol: option?.symbol,
      logo: option?.logo,
    }));
  };

  const mapChannelToValues = (options) => {
    return options.map((option) => ({
      label: option?.name,
      image: option?.logo,
    }));
  };

  const mapProductsOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: `#${option?.id}  |  ` + (option?.title ? option?.title : ""),
      description:
        "APID:  " +
        option?.apid +
        " | " +
        "Created At:  " +
        moment(option?.request_date_time).format("DD-MM-YYYY"),
      name: option?.name,
      image: option?.image,
      logo: option?.logo,
      assign: option?.assign?.name,
      profile: option?.assign?.profile_image,
    }));
  };

  const mapCurrenciesOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option?.id,
      label: option?.name,
      symbol: option?.symbol,
      position: option?.position,
    }));
  };

  // const mapOrdersOptionsToValues = (options) => {
  //     return options.map((option) => ({
  //         value: option?.id,
  //         label: option?.order_number + " | " + option?.order_items[0]?.item_title,
  //         image: option?.order_items[0]?.item_image,
  //         order_items: option?.order_items,
  //     }));
  // };

  // const ordersData = useMemo(() => {
  //     return mapOrdersOptionsToValues(orders);
  // }, [orders]);

  const defaultProducts = useMemo(() => {
    return mapProductsOptionsToValues(productsData);
  }, [productsData]);

  const [input, setInput] = useState("");
  // debounce set input
  const debouncedSetInput = debounce((input) => {
    setInput(input);
  }, 300);

  const debouncedLoadOptions = debounce(async (inputValue, callBack) => {
    // calback instead of promise
    debouncedSetInput(inputValue);
    const response = await fetchProducts(
      "?term=" + inputValue + "&not_deleted=true"
    );
    const options = mapOptionsToValues(response.data);
    callBack(options);
  }, 500);

  const validationSchema = Yup.object({
    // date: Yup.string().required("Required"),
    // time: Yup.string().required("Required"),
    // /// if url is passed validate it with a regex
    // url: Yup.string().matches(
    //   /((http|https):\/\/)?(www.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))?/,
    //   "Invalid URL"
    // ),
  });

  const formik = useFormik({
    initialValues: {
      date: date,
      time: time,
      comment: "",
      products: [],
    },
    validationSchema,
    onSubmit: (values) => {
      setAddClicked(false);
      const mappedProducts = updateItem.map((product) => {
        return {
          product: product?.product,
          qty: product?.qty,
          reason: product?.request_reason,
          comment: product?.note,
          supplier_id: product?.supplier_id?.value,
          max_acceptable_price: product?.max_acceptable_price,
          max_acceptable_price_currency_id:
            product?.max_acceptable_price_currency_id?.value || 1,
          url: product?.url,
          reason_related_id: product?.reason_related_id
            ? product?.reason_related_id
            : null,
        };
      });

      const payload = {
        request_date_time: values.date + " " + values.time + ":00",
        products: mappedProducts,
        employee_id: user?.id,
      };
      if (payload.products.length === 0) {
        toast.error("Please Select at least one product");
        return;
      }
      onRequest(payload);
    },
  });

  useEffect(() => {
    // setProducts([])
    if (data?.product) {
      setSelectedProduct({
        value: data?.product?.id,
        label: data?.product?.title,
        image: data?.product?.image,
        title: data?.product?.title,
        product: data?.product?.id,
      });
    }
  }, []);

  const fetchWarehouses = async (params) => {
    let url = "api/v1/warehouses";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const fetchOrders = async (params) => {
    let url = "api/v1/orders";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const idsLoadOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchWarehouses("/search?term=" + inputValue + "&not_deleted=true")
        .then((response) => {
          const options = mapOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  };

  const [editMode, setEditMode] = useState(false);

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setProduct({
      value: product?.product,
      label: product?.title,
      image: product?.image,
    });
    setEditMode(true);
  };

  const handleAddOrUpdateProduct = () => {
    if (editMode) {
      // Update the existing product in the products array
      const updatedProducts = products.map((product) => {
        if (product.product === selectedProduct.product) {
          return selectedProduct;
        }
        return product;
      });
      setProducts(updatedProducts);
      formik.setFieldValue("products", updatedProducts);
      setEditMode(false);
      setSelectedProduct("");
      setSelectedOrder("");
    } else {
      // Add the new product to the products array
      if (
        !selectedProduct ||
        !selectedProduct.product ||
        !selectedProduct.qty
      ) {
        setErrorMessage(true);
        return;
      }
      setErrorMessage(false);
      setProducts([...products, selectedProduct]);
      formik.setFieldValue("products", [...products, selectedProduct]);
      setSelectedProduct({});
    }

    setProduct("");
    setSelectedID({});
  };

  const [updateItem, setUpdateItem] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [isIn, setIsIn] = useState(false);
  useEffect(() => {
    if (paramProduct) {
      setIsIn(true);
      fetchProducts(
        `?filter[summaryType]=all/?filter[summaryType]=resupply&filter[id]=${paramProduct}`
      )
        .then((response) => {
          const products = response?.data;
          console.log(response.data);
          const prods = [];
          products?.forEach((product) => {
            if (product?.product?.type === 1) {
              prods.push({
                value: product?.product?.id,
                product: product?.product?.id,
                assign: product?.product?.assign?.name,
                profile: product?.product?.assign?.profile_image,
                request_reason: reason ? reason : "warehouse_restocking",
                title: product?.product?.title,
                image: product?.product?.image,
                apid: product?.product?.apid,
                note: "",
                url: "",
                supplier_id: null,
                qty: product?.restock_qty || null,
                max_acceptable_price: null,
                max_acceptable_price_currency_id: {
                  value: 1,
                  label: "Turkish lira",
                  symbol: "₺",
                  position: "0",
                  product: 1,
                  code: "TRY",
                },

              });
            } else {
              product?.product?.child_products?.forEach((group) => {
                prods.push({
                  value: group?.id,
                  product: group?.id,
                  note: "",
                  url: "",
                  assign: product?.assign?.name,
                  profile: product?.assign?.profile_image,
                  request_reason: reason ? reason : "warehouse_restocking",
                  title: group?.title,
                  image: group?.image,
                  supplier_id: null,
                  qty: 1,
                  max_acceptable_price: null,
                  max_acceptable_price_currency_id: {
                    value: 1,
                    label: "Turkish lira",
                    symbol: "₺",
                    position: "0",
                  },
                  apid: group?.apid,

                });
              });
            }
          });
          setProducts(prods);
          setUpdateItem(prods);
          setOriginalItems(prods);
          setLoading(false);

          formik.setFieldValue("products", prods);
        })
        .catch((error) => {
          // console.log(error);
        });
    }

    if (productIds) {
      setIsIn(true);
      fetchProducts(
        "?filter[id]=" +
          productIds +
          "&include=childProducts&filter[summaryType]=all/?filter[summaryType]=resupply"
      )
        .then((response) => {
          const products = response.data;

          const prods = [];
          products?.forEach((product) => {
            if (product?.product?.type === 1) {
              prods.push({
                value: product?.product?.id,
                product: product?.product?.id,
                assign: product?.product?.assign?.name,
                profile: product?.product?.assign?.profile_image,
                request_reason: reason ? reason : "warehouse_restocking",
                title: product?.product?.title,
                image: product?.product?.image,
                apid: product?.product?.apid,
                note: "",
                url: "",
                supplier_id: null,
                qty: product?.restock_qty || null,
                max_acceptable_price: null,
                max_acceptable_price_currency_id: {
                  value: 1,
                  label: "Turkish lira",
                  symbol: "₺",
                  position: "0",
                  product: 1,
                  code: "TRY",
                },

              });
            } else {
              product?.product?.product?.child_products?.forEach((group) => {
                prods.push({
                  value: group?.id,
                  product: group?.id,
                  note: "",
                  url: "",
                  assign: product?.assign?.name,
                  profile: product?.assign?.profile_image,
                  request_reason: reason ? reason : "warehouse_restocking",
                  title: group?.title,
                  image: group?.image,
                  supplier_id: null,
                  qty: 1,
                  max_acceptable_price: null,
                  max_acceptable_price_currency_id: {
                    value: 1,
                    label: "Turkish lira",
                    symbol: "₺",
                    position: "0",
                  },
                  apid: group?.apid,

                });
              });
            }
          });
          setProducts(prods);
          setUpdateItem(prods);
          setOriginalItems(prods);
          setLoading(false);

          formik.setFieldValue("products", prods);
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      setIsIn(false);
    }
  }, [paramProduct, reason, qty, productIds]);

  const [clickedId, setClickedId] = useState(null);
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "!text-gray-500";
    }
  };

  const [channelOptions, setChannelOptions] = useState([]);

  const [currenciesOptions2, setCurrenciesOptions2] = useState([]);

  useEffect(() => {
    fetchChannels("?term=&not_deleted=trueundefined").then((response) => {
      const options = mapProductsOptionsToValues(response.data);
      setChannelOptions(options);
    });
    fetchCurrencies("").then((response) => {
      setCountries(response.data);
      const options = mapOptionsToValues2(response.data);
      setCurrenciesOptions2(options);
    });
  }, []);
  const fetchChannels = async (params) => {
    let url = "/api/v1/channel";
    if (params) {
      url = url.concat(params);
    }
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const loadOptions = async (inputValue) => {
    let url = `?term=` + inputValue;
    return new Promise((resolve) => {
      fetchChannels(url)
        .then((response) => {
          const options = mapProductsOptionsToValues(response.data);
          resolve(options);
        })
        .catch((error) => {});
    });
  };

  const renderOption = (item) => {
    return (
      <div className={"flex flex-row gap-[12px] items-center"}>
        <div className="w-[35px] h-auto">
          <img
            className={"w-full h-auto rounded-full border-0 bg-gray-200"}
            src={item?.logo || "/assets/images/img-placehoder.png"}
          />
        </div>

        <span>{item?.name}</span>
      </div>
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const addItemToArray = (item) => {
    setUpdateItem((prevVal) => [...prevVal, item]);
  };

  // const [currentPosts,setCurrentPosts] =useState(selectedSupply?.slice(indexOfFirstPost, indexOfLastPost));
  let currentPosts = updateItem?.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [isQuantityClicked, setQuantityClicked] = useState(false);

  const defaultCurrency = {
    code: "TRY",
    image: undefined,
    label: "Turkish lira",
    logo: undefined,
    position: "0",
    product: 1,
    symbol: "₺",
    title: "Turkish lira",
    value: 1,
  };
  const [countries, setCountries] = useState([]);
  const fetchCurrencies = async (params) => {
    let url = "api/v1/currencies";
    if (params) url = url.concat(params);
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  };

  const currenciesOptions = async (inputValue) => {
    return new Promise((resolve) => {
      fetchCurrencies("?term={query}" + inputValue + "&filter[status]=1")
        .then((response) => {
          setCountries(response.data);
          const options = mapOptionsToValues2(response.data);
          resolve(options);
        })
        .catch((error) => {});
    });
  };

  const [loading, setLoading] = useState(true);
  const [isSupplyClicked, setSupplyClicked] = useState(false);
  const [isReasonClicked, setReasonClicked] = useState(false);
  const [isMaxPriceClicked, setMaxPriceClicked] = useState(false);
  const [searching, setSearching] = useState(true);
  const [results, setResults] = useState([]);

  const mapItems = (item) => ({
    requested_at: item.created_at,
    category_id: item.category_id,
    description:
      "APID:  " +
      item?.apid +
      " | " +
      "Created At:  " +
      moment(item?.request_date_time).format("DD-MM-YYYY"),
    id: item.id,
    label: item.title,
    value: item.id,
    image: item.image,
    apid: item.apid,
    type: item.type,
    brand: item?.brand?.name,
    assign: item?.brand?.assigned,
    numbers: {
      "Supply CL": item.supply_channels_count,
      "Sales CL": item.sales_channels_count,
      Inventory: item.inventory_count,
      Orders: 0,
      "Avg. Cost": item?.average_cost_usd ? `$${item?.average_cost_usd}` : "-",
    },
  });

  const menuRef = useRef();

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = useCallback(async (inputValue) => {
    setInputValue(inputValue);
    const results = await fetchItems(inputValue);
  });

  const abortControllerRef = useRef(null);

  useEffect(() => {
    setInputValue("");
    setResults([]);
  }, [searching]);

  const fetchItems = useCallback(
    async (inputValue, page) => {
      if (!inputValue) {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        return;
      }

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const newController = new AbortController();
      abortControllerRef.current = newController;

      setLoading(true);
      try {
        const response = await axios.get(
          `/api/v1/product?term=${inputValue}&${
            params ? params + "&" : ""
          }limit=6&page=${page || 1}&include=brand&filter[type]=1`,
          { withCredentials: true, signal: newController.signal }
        );

        if (newController.signal.aborted) {
          return;
        }
        setResults({
          results: response.data.data.map(mapItems),
          meta: response?.data?.meta,
        });
        console.log(response.data);
      } catch (e) {
        if (axios.isCancel(e)) {
        } else {
          console.error("Fetch request error", e);
        }
      } finally {
        setLoading(false);
      }
    },
    [params, mapItems]
  );

  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setSupplyClicked(false);
        setReasonClicked(false);
        setMaxPriceClicked(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const [productClickedId, setProductClickedId] = useState("");

  const ProductItem = ({
    item,
    index,
    results,
    searching,
    setSelectedProduct,
    setSearching,
    selectedProduct,
    setData,
    data,
  }) => {
    const [orders, setOrders] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (item && !searching) {
        setLoading(true);
        const fetchOrders = async () => {
          const response = await axios.get(
            `/api/v1/orders/filters?filter[product]=${item.id}&filter[type]=1`,
            { withCredentials: true }
          );
          setLoading(false);
          if (response.data.data) {
            setOrders(
              response.data.data.statuses?.find((item) => item?.id === 10)
                ?.count || "-"
            );
          }
        };

        fetchOrders();
      }
    }, [item, searching]);

    return (
      <div
        key={index}
        className="  w-[382.67px] hover:bg-blue-100 cursor-pointer min-h-[104px] flex items-center p-3 mb-2 border shadow-sm rounded-lg"
        onClick={() => {
          setAddClicked(true);
          setProductClickedId(item);
        }}
      >
        <img
          src={item.image}
          alt={item.label}
          className="result-image w-[88px] h-[88px] mr-4 object-contain rounded"
        />
        <div className="result-details flex-1 flex flex-col h-full gap-[5px] justify-between ">
          {/* apid and badge here */}
          <div className="flex justify-between items-center">
            <div
              onClick={(e) => e.stopPropagation()}
              className={"flex flex-row gap-[4px] items-center"}
            >
              <div
                className=" text-[12px] font-medium leading-[18px] text-blue-700 cursor-pointer"
                onClick={(e) => {
                  window.open(`/product/${item?.id}`, "_blank");
                  e.stopPropagation();
                }}
              >
                {item?.apid}
              </div>
              <Util.copyText
                className={"text-[12px]"}
                text={item?.apid}
                hint={false}
              />
            </div>
            <div className="flex gap-2">
              {!searching && results?.results?.length ? (
                <Icons.trash
                  className="w-5 h-5 text-gray-400 cursor-pointer hover:text-red-400"
                  onClick={() => {
                    setSelectedProduct([]);
                    setData({ ...data, products: [] });
                    setSearching(true);
                  }}
                />
              ) : null}
            </div>
          </div>
          {/* title here */}
          <div>
            <util.expandableText
              maxLines={2}
              className={
                "text-[14px] leading-normal whitespace-pre-wrap font-medium"
              }
              tooltip={true}
              propagation={true}
            >
              {item.label}
            </util.expandableText>
          </div>
          {/* assign */}
          {
            <div className={"flex justify-between"}>
              <div className={"flex flex-row gap-[4px] text-gray-600"}>
                {item?.brand ? (
                  <span className="text-[12px] leading-normal font-medium text-gray-900">
                    {Util.shortenString(upperCase(item?.brand), 30)}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="flex items-center gap-[2px] !text-[12px]">
                {item?.assign ? (
                  <div className={"text-gray-900 flex flex-row gap-[4px] "}>
                    <img
                      src={
                        item?.assign?.profile_image ||
                        "/assets/images/defaultAvatar.png"
                      }
                      className={"w-[20px] h-[20px] rounded-full object-cover"}
                    />
                    {Util.upperCaseString(item?.assign?.name, "word")}
                  </div>
                ) : (
                  <div className="text-gray-400">Deleted User</div>
                )}
              </div>
            </div>
          }
        </div>
      </div>
    );
  };

  const SearchResults = ({
    results,
    fetchItems,
    inputValue,
    setSelectedProduct,
    setSearching,
    searching,
    ItemCard,
    selectedProduct,
    setData,
    data,
    loading,
    connectLoading,
    selection,
  }) => {
    const meta = results?.meta;
    const handlePagination = async (page) => {
      await fetchItems(inputValue, page);
    };

    return (
      <div className=" flex gap-2.5  flex-col ">
        <div className=" flex gap-2.5 flex-wrap ">
          {!loading ? (
            results?.results?.length > 0 ? (
              results?.results?.map((item, index) => (
                <ProductItem
                  key={index}
                  item={item}
                  index={index}
                  results={results}
                  searching={searching}
                  setSelectedProduct={setSelectedProduct}
                  setSearching={setSearching}
                  selectedProduct={selectedProduct}
                  data={data}
                  setData={setData}
                  connectLoading={connectLoading}
                  selection={selection}
                />
              ))
            ) : (
              <></>
            )
          ) : (
            inputValue && (
              <div className="flex flex-wrap gap-2 flex-row">
                {[23, 3, 3, 3, 3, 3].map(() => {
                  return <HandmadeSkeleton width={380} height={121} />;
                })}
              </div>
            )
          )}
        </div>
        {/* next and prev buttons rendered dependinb on meta.currentpage */}
        {results?.results?.length > 0 && meta?.last_page > 1 && (
          <div className="flex justify-between">
            {meta?.current_page !== 1 ? (
              <button
                className="text-gray-500 flex items-center text-[16px] leading-6"
                disabled={meta?.current_page === 1}
                onClick={() => handlePagination(meta?.current_page - 1)}
              >
                <HiChevronLeft className="w-5 h-5 mr-1" /> Previous
              </button>
            ) : (
              <div></div>
            )}
            {meta?.current_page !== meta?.last_page ? (
              <button
                className="text-gray-500 flex items-center text-[16px] leading-6"
                disabled={meta?.current_page === meta?.total_pages}
                onClick={() => handlePagination(meta?.current_page + 1)}
              >
                Next <HiChevronRight className="w-5 h-5 ml-1" />
              </button>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>
    );
  };

  const [quantityFilter, setQuantityFilter] = useState("low");
  const filterByQuantity = () => {
    if (quantityFilter === "low") {
      setUpdateItem((prevVal) => prevVal.sort((a, b) => a?.qty - b?.qty));
    } else {
      setUpdateItem((prevVal) => prevVal.sort((a, b) => b?.qty - a?.qty));
    }
  };
  const [data2, setData] = useState({
    products: [],
  });

  return (
    <MainModal
      item={{
        title: "New Supply Request",
        cancel: onClose,
        submit: formik.handleSubmit,
        view: (item) => (
          <form
            onSubmit={formik.handleSubmit}
            className={`${
              currentPosts?.length === 0 &&
              !isAddClicked &&
              "w-[1200px] h-[580px]"
            }`}
          >
            {currentPosts?.length === 0 && <hr />}
            {isAddClicked && (
              <AddProduct
                setLoading={setLoading}
                addItemToArray={addItemToArray}
                productClickedId={productClickedId}
                setProductClickedId={setProductClickedId}
              />
            )}
            {currentPosts?.length !== 0 ? (
              <div
                ref={menuRef}
                className={`  ${
                  isAddClicked
                    ? currentPosts?.length > 6
                      ? "h-[355px] overflow-y-scroll sticky"
                      : "h-[355px]"
                    : "h-[600px] "
                } ${
                  clickedId !== null &&
                  (currentPosts?.length > 7
                    ? "h-[600px] overflow-y-scroll sticky"
                    : "h-[600px]")
                } `}
              >
                <table className="!w-full">
                  <thead className="bg-gray-100 sticky !w-full -top-[2px] z-[50] ">
                    <tr className="text-[12px] h-[50px]  leading-[18px] font-semibold text-gray-500 border-t border-b border-gray-200">
                      <th className="w-[397px] pl-9">
                        <div className="flex gap-1 items-center">
                          <span>PRODUCT</span>
                        </div>
                      </th>
                      <th className="w-[100px]">
                        <div className="flex gap-1 items-center">
                          <span>QTY</span>
                          <RxCaretSort
                            onClick={() => {
                              filterByQuantity();
                              setQuantityFilter(
                                quantityFilter === "low" ? "high" : "low"
                              );
                            }}
                            className="w-4 h-4 cursor-pointer text-gray-400"
                          />
                        </div>
                      </th>
                      <th className="w-[106px]">
                        <div className="relative">
                          <div className="flex gap-3 items-center ">
                            <span>MAX.PRICE</span>
                            <span
                              onClick={() => {
                                setMaxPriceClicked(!isMaxPriceClicked);
                                setReasonClicked(false);
                                setSupplyClicked(false);
                                setAddClicked(false);
                                setClickedId(null);
                              }}
                            >
                              <Icons.edit className="w-[14.51px] cursor-pointer h-[14.51px] text-gray-400" />
                            </span>
                          </div>
                          {isMaxPriceClicked && (
                            <div className="absolute -top-[105px] right-5 bg-white z-50 shadow-lg rounded-md p-2 flex flex-col gap-1 text-start ">
                              <span className="text-[14px] leading-normal pl-2 text-[#111928] font-medium">
                                Bulk Edit
                              </span>
                              <div className="flex">
                                <input
                                  type="number"
                                  className={` border-gray-200  border-b-2 border-t-0  border-l-0 bg-transparent    [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 hover:border-gray-400    h-[38px] !w-[84px] text-center text-[14px] leading-[20px] text-gray-900  p-3 !rounded-r-none border-r-0`}
                                  name="purchase_price"
                                  placeholder="Price"
                                  value={item?.max_acceptable_price || null}
                                  onChange={(e) => {
                                    // it should be a number bigger than 0
                                    if (e.target.value >= 0) {
                                      setUpdateItem((prevVal) => {
                                        let updatedItems = prevVal.map(
                                          (item) => ({
                                            ...item,
                                            max_acceptable_price: Number(
                                              e.target.value
                                            ),
                                          })
                                        );
                                        return updatedItems;
                                      });
                                    }
                                  }}
                                  // onBlur={formik.handleBlur}
                                />
                                <AsyncSelect
                                  cacheOptions
                                  loadOptions={currenciesOptions}
                                  defaultOptions={currenciesOptions2}
                                  options={currenciesOptions2}
                                  className="border-gray-300 h-[42px] bg-transparent placeholder:text-red-500 !w-[110px] rounded-l-none border-l-0 focus:border-gray-300   focus:ring-0"
                                  name="currency"
                                  isDisabled={
                                    formik.values.bank_account_id ? true : false
                                  }
                                  value={
                                    selectedProduct?.max_acceptable_price_currency_id ||
                                    defaultCurrency
                                  }
                                  onChange={(value) => {
                                    setSelectedProduct({
                                      ...selectedProduct,
                                      max_acceptable_price_currency_id: value,
                                    });
                                    setUpdateItem((prevVal) => {
                                      // Make a shallow copy of the previous state
                                      let updatedItems = prevVal.map(
                                        (item) => ({
                                          ...item,
                                          max_acceptable_price_currency_id:
                                            value,
                                        })
                                      );
                                      return updatedItems;
                                    });
                                  }}
                                  formatOptionLabel={(item) => {
                                    return (
                                      <div className="flex items-center gap-2">
                                        <span className="text-[14px] leading-[17.5px] font-normal text-gray-900">
                                          {item?.code}
                                        </span>
                                      </div>
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      height: "fit-content",
                                      width: 80,
                                      border: "none",
                                      borderBottom: "2px solid #E5E5E5",
                                      boxShadow: "none", // Removed shadow
                                      borderRadius: 0, // Removed rounded corners
                                      paddingBottom: 5,
                                      backgroundColor: "transparent",
                                    }),
                                    option: (provided, state) => ({
                                      ...provided,
                                      backgroundColor: state.isSelected
                                        ? "#00A3FF"
                                        : "white",
                                      boxShadow: "none", // Removed shadow
                                      borderRadius: 0, // Removed rounded corners
                                      color: state.isSelected
                                        ? "white"
                                        : "black",
                                      "&:hover": {
                                        backgroundColor: "#00A3FF", // Same as non-hover state
                                        color: "black", // Same as non-hover state
                                      },
                                    }),
                                    menu: (provided, state) => ({
                                      ...provided,
                                      width: 150,
                                      borderRadius: 0, // Removed rounded corners
                                      border: state.isFocused
                                        ? "1px solid #00A3FF"
                                        : "1px solid #E5E5E5",
                                      zIndex: 999999,
                                      boxShadow: "none", // Removed shadow
                                    }),
                                    container: (provided, state) => ({
                                      ...provided,
                                      width: "100%",
                                      boxShadow: "none", // Removed shadow
                                      borderRadius: 0, // Removed rounded corners
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      marginLeft: 0,
                                    }),
                                    dropdownIndicator: (provided, state) => ({
                                      ...provided,
                                      color: "#9CA3AF", // gray-400
                                      "&:hover": {
                                        color: "#9CA3AF", // gray-400
                                      },
                                      fontSize: 12, // Smaller arrow size
                                      padding: "0 4px", // Adjust padding if necessary
                                    }),
                                  }}
                                  components={
                                    // hide the | icon
                                    {
                                      IndicatorSeparator: () => null,
                                    }
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </th>
                      <th className="w-[250px]">
                        <div className="relative">
                          <div className="flex gap-3 items-center ">
                            <span>SUPPLY CHANNEL</span>
                            <span
                              onClick={() => {
                                setSupplyClicked(!isSupplyClicked);
                                setMaxPriceClicked(false);
                                setReasonClicked(false);
                                setAddClicked(false);
                                setClickedId(null);
                              }}
                            >
                              <Icons.edit className="w-[14.51px] cursor-pointer h-[14.51px] text-gray-400" />
                            </span>
                          </div>
                          {isSupplyClicked && (
                            <div className="absolute -top-[105px] right-5 bg-white z-[999] shadow-lg rounded-md p-2 flex flex-col gap-2 text-start ">
                              <span className="text-[14px] leading-normal pl-2 text-[#111928] font-medium">
                                Bulk Edit
                              </span>
                              <AsyncSelect
                                loadOptions={loadOptions}
                                placeholder="Select supply channel"
                                defaultOptions={channelOptions}
                                options={channelOptions}
                                cacheOptions
                                onChange={(data) => {
                                  // handleTableAction("assign", {
                                  //     employee_id: selected?.value,
                                  //     brand_ids: [item?.id],
                                  // });
                                  setUpdateItem((prevVal) => {
                                    // Make a shallow copy of the previous state and modify every item
                                    let updatedItems = prevVal.map((item) => ({
                                      ...item,
                                      supplier_id: data,
                                      supplier: {
                                        name: data?.label,
                                        id: data?.value,
                                      },
                                    }));
                                    return updatedItems;
                                  });
                                }}
                                value={item?.supplier_id}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    height: "fit-content",
                                    width: 218,
                                    border: "none",
                                    borderBottom: "2px solid #E5E5E5",
                                    boxShadow: "none", // Removed shadow
                                    borderRadius: 0, // Removed rounded corners
                                    paddingBottom: 5,
                                    backgroundColor: "transparent",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                      ? "#00A3FF"
                                      : "white",
                                    boxShadow: "none", // Removed shadow
                                    borderRadius: 0, // Removed rounded corners
                                    color: state.isSelected ? "white" : "black",
                                    "&:hover": {
                                      backgroundColor: "#00A3FF", // Same as non-hover state
                                      color: "black", // Same as non-hover state
                                    },
                                  }),
                                  menu: (provided, state) => ({
                                    ...provided,
                                    width: 300,
                                    borderRadius: 0, // Removed rounded corners
                                    border: state.isFocused
                                      ? "1px solid #00A3FF"
                                      : "1px solid #E5E5E5",
                                    zIndex: 999999,
                                    boxShadow: "none", // Removed shadow
                                  }),
                                  container: (provided, state) => ({
                                    ...provided,
                                    width: "100%",
                                    boxShadow: "none", // Removed shadow
                                    borderRadius: 0, // Removed rounded corners
                                  }),
                                  placeholder: (provided, state) => ({
                                    ...provided,
                                    marginLeft: 0,
                                  }),
                                  dropdownIndicator: (provided, state) => ({
                                    ...provided,
                                    color: "#9CA3AF", // gray-400
                                    "&:hover": {
                                      color: "#9CA3AF", // gray-400
                                    },
                                    fontSize: 12, // Smaller arrow size
                                    padding: "0 4px", // Adjust padding if necessary
                                  }),
                                }}
                                components={
                                  // hide the | icon
                                  {
                                    IndicatorSeparator: () => null,
                                  }
                                }
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                formatOptionLabel={renderOption}
                              />
                            </div>
                          )}
                        </div>
                      </th>
                      <th className="w-[250px]">
                        <div className="relative">
                          <div className="flex gap-3 items-center ">
                            <span>REASON</span>
                            <span
                              onClick={() => {
                                setReasonClicked(!isReasonClicked);
                                setSupplyClicked(false);
                                setMaxPriceClicked(false);
                                setAddClicked(false);
                                setClickedId(null);
                              }}
                            >
                              <Icons.edit className="w-[14.51px] cursor-pointer h-[14.51px] text-gray-400" />
                            </span>
                          </div>
                          {isReasonClicked && (
                            <div className="absolute -top-[106px] right-20 bg-white z-[999] shadow-lg rounded-md p-2 flex flex-col gap-1 text-start ">
                              <span className="text-[14px] leading-normal pl-2 text-[#111928] font-medium">
                                Bulk Edit
                              </span>
                              <Dropdown
                                bg_color="transparent "
                                border="b-2 border-l-0 border-r-0 border-t-0"
                                border_color="#E5E7EB"
                                text_color="gray"
                                // justify="between"
                                className={`  !text-gray-500 text-[15.2px] leading-[17.5px]  h-[43.6px] cursor-default hover:border-gray-400   !rounded-none  w-[218px] `}
                                name="request_reason"
                                items={[
                                  "Warehouse Stock",
                                  "Fulfilment Stock",
                                  "Bulk Order",
                                ]}
                                value={
                                  selectedProduct?.request_reason
                                    ? selectedProduct?.request_reason ===
                                      "warehouse_restocking"
                                      ? "Warehouse Stock"
                                      : selectedProduct?.request_reason ===
                                        "fulfilment_restocking"
                                      ? "Fulfilment Stock"
                                      : selectedProduct?.request_reason ===
                                        "bulk_order"
                                      ? "Bulk Order"
                                      : "Order"
                                    : "Select Reason"
                                }
                                onSelect={(e) => {
                                  let reason;
                                  reason =
                                    e === "Warehouse Stock"
                                      ? "warehouse_restocking"
                                      : e === "Fulfilment Stock"
                                      ? "fulfilment_restocking"
                                      : e === "Bulk Order"
                                      ? "bulk_order"
                                      : "order";
                                  setRequestReason(reason);
                                  setSelectedProduct({
                                    ...selectedProduct,
                                    request_reason: reason,
                                  });
                                  setUpdateItem((prevVal) => {
                                    // Make a shallow copy of the previous state
                                    let updatedItems = prevVal.map((item) => ({
                                      ...item,
                                      request_reason: reason,
                                    }));
                                    return updatedItems;
                                  });
                                }}
                                placeholder="Select supply reason"
                                width="100%"
                                height={42}
                              />
                            </div>
                          )}
                        </div>
                      </th>
                      <th className="w-[92px] pr-7"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading
                      ? currentPosts?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr
                              className={` border-t  ${
                                clickedId === index
                                  ? "bg-blue-50"
                                  : "hover:bg-gray-50 "
                              } border-b border-gray-200 h-[54px]`}
                            >
                              <td className="w-[397px] pl-9">
                                <div className="flex gap-1.5 items-center">
                                  <Zoom>
                                    <img
                                      className="w-[40px] h-[40px] object-cover rounded-[4px]"
                                      src={item?.image}
                                    />
                                  </Zoom>
                                  <div className="flex flex-col">
                                    <div className="flex gap-1 items-center w-[160px] justify-between">
                                      <span className="text-[12px] leading-[18px] text-gray-500 font-medium">
                                        {item?.apid || "-"}
                                      </span>
                                      {item?.apid && (
                                        <div className="flex gap-1 items-center">
                                          <util.copyText2
                                            className="text-[12px]"
                                            text={item?.apid}
                                            hint={true}
                                          />
                                          <HiExternalLink
                                            onClick={() =>
                                              window.open(
                                                `/product/${item?.product}`,
                                                "_blank"
                                              )
                                            }
                                            className="w-4 h-4 text-blue-600 cursor-pointer"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div className="flex gap-1.5 items-center">
                                      {item?.title?.length > 35 ? (
                                        <util.popUp
                                          toggle={(selected) => (
                                            <span
                                              className={`text-[14px] text-justify leading-[21px] font-medium text-gray-900 ${
                                                clickedId === index &&
                                                "text-blue-700"
                                              }`}
                                            >
                                              {item?.title.slice(0, 36)}...
                                            </span>
                                          )}
                                          action="hover"
                                        >
                                          <span
                                            className={`text-[14px] text-justify leading-[21px] font-medium text-gray-900 ${
                                              clickedId === index &&
                                              "text-blue-700"
                                            }`}
                                          >
                                            {item?.title}
                                          </span>
                                        </util.popUp>
                                      ) : (
                                        <span className="text-[14px] leading-[21px] font-medium text-gray-900">
                                          {item?.title}
                                        </span>
                                      )}
                                      <util.copyText2
                                        className="text-[12px]"
                                        text={item?.title}
                                        hint={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="w-[132px] ">
                                <div className="flex items-center ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (
                                        updateItem[index]?.qty !== 1 &&
                                        updateItem[index]?.qty !== "1.00"
                                      ) {
                                        setUpdateItem((prevVal) => {
                                          // Make a shallow copy of the previous state
                                          let copy = [...prevVal];
                                          const findItem = copy.findIndex(
                                            (item2) =>
                                              item2?.title === item?.title
                                          );
                                          const tempQty = Number(
                                            copy[findItem].qty
                                          );
                                          // Modify the copy
                                          copy[findItem] = {
                                            ...copy[findItem],
                                            qty: Number(tempQty - 1),
                                          };
                                          return copy;
                                        });
                                      }
                                    }}
                                    className="bg-gray-100 rounded-[4px] p-1 border-[1px] border-gray-300 hover:bg-blue-100"
                                  >
                                    <FaMinus className="w-[12.8px] h-[12.8px] text-gray-500" />
                                  </button>
                                  {!isQuantityClicked ? (
                                    <span
                                      onClick={() => setQuantityClicked(true)}
                                      className="text-gray-900 font-medium text-[14px] leading-normal w-[39px] text-center"
                                    >
                                      {Number(item?.qty) || 1}
                                    </span>
                                  ) : (
                                    <input
                                      type="number"
                                      min={1}
                                      className=" [&::-webkit-inner-spin-button]:appearance-none border-none focus:border focus:border-black bg-transparent text text-gray-900 font-medium text-[14px] leading-normal w-[39px] text-center"
                                      onChange={(e) => {
                                        if (e.target.value) {
                                          setUpdateItem((prevVal) => {
                                            // Make a shallow copy of the previous state
                                            let copy = [...prevVal];
                                            const findItem = copy.findIndex(
                                              (item2) =>
                                                item2?.title === item?.title
                                            );
                                            // Modify the copy
                                            copy[findItem] = {
                                              ...copy[findItem],
                                              qty: Number(e.target.value),
                                            };
                                            return copy;
                                          });
                                        }
                                      }}
                                      value={Number(item?.qty) || 1}
                                    />
                                  )}
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setUpdateItem((prevVal) => {
                                        // Make a shallow copy of the previous state
                                        let copy = [...prevVal];
                                        const findItem = copy.findIndex(
                                          (item2) =>
                                            item2?.title === item?.title
                                        );
                                        const tempQty = Number(
                                          copy[findItem].qty
                                        );
                                        // Modify the copy
                                        copy[findItem] = {
                                          ...copy[findItem],
                                          qty: Number(tempQty + 1),
                                        };
                                        return copy;
                                      });
                                    }}
                                    className="bg-gray-100 rounded-[4px] p-1 border-[1px] border-gray-300 hover:bg-blue-100"
                                  >
                                    <FaPlus className="w-[12.8px] h-[12.8px] text-gray-500 " />
                                  </button>
                                </div>
                              </td>
                              <td className="w-[196px]">
                                <div className="flex">
                                  <input
                                    type="number"
                                    className={` border-gray-200  border-b-2 border-t-0  border-l-0 bg-transparent    [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 hover:border-gray-400    h-[43px] !w-[84px] text-center text-[14px] leading-[20px] text-gray-900  p-3 !rounded-r-none border-r-0`}
                                    name="purchase_price"
                                    placeholder="Price"
                                    value={item?.max_acceptable_price || null}
                                    onChange={(e) => {
                                      // it should be a number bigger than 0
                                      if (e.target.value >= 0) {
                                        setUpdateItem((prevVal) => {
                                          let copy = [...prevVal];
                                          const findItem = copy.findIndex(
                                            (item2) =>
                                              item2?.title === item?.title
                                          );
                                          // Modify the copy
                                          copy[findItem] = {
                                            ...copy[findItem],
                                            max_acceptable_price: Number(
                                              e.target.value
                                            ),
                                          };
                                          return copy;
                                        });
                                      }
                                    }}
                                    // onBlur={formik.handleBlur}
                                  />
                                  <AsyncSelect
                                    cacheOptions
                                    loadOptions={currenciesOptions}
                                    defaultOptions={currenciesOptions2}
                                    options={currenciesOptions2}
                                    className="border-gray-300 h-[42px] bg-transparent placeholder:text-red-500 !w-[110px] rounded-l-none border-l-0 focus:border-gray-300   focus:ring-0"
                                    name="currency"
                                    isDisabled={
                                      formik.values.bank_account_id
                                        ? true
                                        : false
                                    }
                                    value={
                                      item?.max_acceptable_price_currency_id ||
                                      defaultCurrency
                                    }
                                    onChange={(value) => {
                                      setUpdateItem((prevVal) => {
                                        // Make a shallow copy of the previous state
                                        let copy = [...prevVal];
                                        const findItem = copy.findIndex(
                                          (item2) =>
                                            item2?.title === item?.title
                                        );
                                        // Modify the copy
                                        copy[findItem] = {
                                          ...copy[findItem],
                                          max_acceptable_price_currency_id:
                                            value,
                                        };
                                        return copy;
                                      });
                                    }}
                                    formatOptionLabel={(item) => {
                                      return (
                                        <div className="flex items-center gap-2">
                                          <span className="text-[14px] leading-[17.5px] font-normal text-gray-900">
                                            {item?.code}
                                          </span>
                                        </div>
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    styles={{
                                      control: (provided, state) => ({
                                        ...provided,
                                        height: "fit-content",
                                        width: 80,
                                        border: "none",
                                        borderBottom: "2px solid #E5E5E5",
                                        boxShadow: "none", // Removed shadow
                                        borderRadius: 0, // Removed rounded corners
                                        paddingBottom: 5,
                                        backgroundColor: "transparent",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                          ? "#00A3FF"
                                          : "white",
                                        boxShadow: "none", // Removed shadow
                                        borderRadius: 0, // Removed rounded corners
                                        color: state.isSelected
                                          ? "white"
                                          : "black",
                                        "&:hover": {
                                          backgroundColor: "#00A3FF", // Same as non-hover state
                                          color: "black", // Same as non-hover state
                                        },
                                      }),
                                      menu: (provided, state) => ({
                                        ...provided,
                                        width: 150,
                                        borderRadius: 0, // Removed rounded corners
                                        border: state.isFocused
                                          ? "1px solid #00A3FF"
                                          : "1px solid #E5E5E5",
                                        zIndex: 999999,
                                        boxShadow: "none", // Removed shadow
                                      }),
                                      container: (provided, state) => ({
                                        ...provided,
                                        width: "100%",
                                        boxShadow: "none", // Removed shadow
                                        borderRadius: 0, // Removed rounded corners
                                      }),
                                      placeholder: (provided, state) => ({
                                        ...provided,
                                        marginLeft: 0,
                                      }),
                                      dropdownIndicator: (provided, state) => ({
                                        ...provided,
                                        color: "#9CA3AF", // gray-400
                                        "&:hover": {
                                          color: "#9CA3AF", // gray-400
                                        },
                                        fontSize: 12, // Smaller arrow size
                                        padding: "0 4px", // Adjust padding if necessary
                                      }),
                                    }}
                                    components={
                                      // hide the | icon
                                      {
                                        IndicatorSeparator: () => null,
                                      }
                                    }
                                  />
                                </div>
                              </td>
                              <td className="w-[250px] ">
                                <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-start text-center ">
                                  <AsyncSelect
                                    loadOptions={loadOptions}
                                    placeholder="Select supply channel"
                                    defaultOptions={channelOptions}
                                    options={channelOptions}
                                    cacheOptions
                                    onChange={(data) => {
                                      // handleTableAction("assign", {
                                      //     employee_id: selected?.value,
                                      //     brand_ids: [item?.id],
                                      // });
                                      setUpdateItem((prevVal) => {
                                        // Make a shallow copy of the previous state
                                        let copy = [...prevVal];
                                        const findItem = copy.findIndex(
                                          (item2) =>
                                            item2?.title === item?.title
                                        );
                                        // Modify the copy
                                        copy[findItem] = {
                                          ...copy[findItem],
                                          supplier_id: data,
                                          supplier: {
                                            name: data?.label,
                                            id: data?.value,
                                          },
                                        };
                                        return copy;
                                      });
                                    }}
                                    value={item?.supplier_id}
                                    styles={{
                                      control: (provided, state) => ({
                                        ...provided,
                                        height: "fit-content",
                                        width: 218,
                                        border: "none",
                                        borderBottom: "2px solid #E5E5E5",
                                        boxShadow: "none", // Removed shadow
                                        borderRadius: 0, // Removed rounded corners
                                        paddingBottom: 5,
                                        backgroundColor: "transparent",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                          ? "#00A3FF"
                                          : "white",
                                        boxShadow: "none", // Removed shadow
                                        borderRadius: 0, // Removed rounded corners
                                        color: state.isSelected
                                          ? "white"
                                          : "black",
                                        "&:hover": {
                                          backgroundColor: "#00A3FF", // Same as non-hover state
                                          color: "black", // Same as non-hover state
                                        },
                                      }),
                                      menu: (provided, state) => ({
                                        ...provided,
                                        width: 300,
                                        borderRadius: 0, // Removed rounded corners
                                        border: state.isFocused
                                          ? "1px solid #00A3FF"
                                          : "1px solid #E5E5E5",
                                        zIndex: 999999,
                                        boxShadow: "none", // Removed shadow
                                      }),
                                      container: (provided, state) => ({
                                        ...provided,
                                        width: "100%",
                                        boxShadow: "none", // Removed shadow
                                        borderRadius: 0, // Removed rounded corners
                                      }),
                                      placeholder: (provided, state) => ({
                                        ...provided,
                                        marginLeft: 0,
                                      }),
                                      dropdownIndicator: (provided, state) => ({
                                        ...provided,
                                        color: "#9CA3AF", // gray-400
                                        "&:hover": {
                                          color: "#9CA3AF", // gray-400
                                        },
                                        fontSize: 12, // Smaller arrow size
                                        padding: "0 4px", // Adjust padding if necessary
                                      }),
                                    }}
                                    components={
                                      // hide the | icon
                                      {
                                        IndicatorSeparator: () => null,
                                      }
                                    }
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    formatOptionLabel={renderOption}
                                  />
                                </div>
                              </td>
                              <td className="w-[250px]">
                                <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-start text-center ">
                                  <Dropdown
                                    bg_color="transparent "
                                    border="b-2 border-l-0 border-r-0 border-t-0"
                                    border_color="#E5E7EB"
                                    text_color="gray"
                                    degree={
                                      item?.request_reason ? "600" : "500"
                                    }
                                    // justify="between"
                                    className={`  !text-gray-500 text-[15.2px] leading-[17.5px]  h-[43.6px] cursor-default hover:border-gray-400   !rounded-none  w-[218px] `}
                                    name="request_reason"
                                    items={[
                                      "Warehouse Stock",
                                      "Fulfilment Stock",
                                      "Bulk Order",
                                    ]}
                                    value={
                                      item.request_reason
                                        ? item.request_reason ===
                                          "warehouse_restocking"
                                          ? "Warehouse Stock"
                                          : item.request_reason ===
                                            "fulfilment_restocking"
                                          ? "Fulfilment Stock"
                                          : item.request_reason === "bulk_order"
                                          ? "Bulk Order"
                                          : "Order"
                                        : "Select Reason"
                                    }
                                    onSelect={(e) => {
                                      let reason;
                                      reason =
                                        e === "Warehouse Stock"
                                          ? "warehouse_restocking"
                                          : e === "Fulfilment Stock"
                                          ? "fulfilment_restocking"
                                          : e === "Bulk Order"
                                          ? "bulk_order"
                                          : "order";
                                      setRequestReason(reason);
                                      setSelectedProduct({
                                        ...selectedProduct,
                                        request_reason: reason,
                                      });
                                      setUpdateItem((prevVal) => {
                                        // Make a shallow copy of the previous state
                                        let copy = [...prevVal];
                                        const findItem = copy.findIndex(
                                          (item2) =>
                                            item2?.title === item?.title
                                        );
                                        // Modify the copy
                                        copy[findItem] = {
                                          ...copy[findItem],
                                          request_reason: reason,
                                        };
                                        return copy;
                                      });
                                    }}
                                    placeholder="Select supply reason"
                                    width="100%"
                                    height={42}
                                  />
                                </div>
                              </td>
                              <td className="w-[92px] pr-7">
                                <div className="flex gap-4 items-center justify-end">
                                  <Icons.edit
                                    onClick={() => {
                                      setAddClicked(false);
                                      if (clickedId === index) {
                                        setClickedId(null);
                                      } else {
                                        setClickedId(index);
                                      }
                                      const currency_id = {
                                        label:
                                          item?.max_acceptable_price_currency
                                            ?.name,
                                        title:
                                          item?.max_acceptable_price_currency
                                            ?.name,
                                        value:
                                          item?.max_acceptable_price_currency
                                            ?.id,
                                        code: item
                                          ?.max_acceptable_price_currency?.code,
                                      };
                                      const purchase_price =
                                        item?.max_acceptable_price[
                                          currency_id.code
                                        ];
                                      setSelectedProduct({
                                        product_id: item?.id,
                                        currency_id: currency_id,
                                        title: item?.product?.title,
                                        purchase_price: purchase_price,
                                        image: item?.product?.image,
                                        quantity: Number(item?.qty),
                                        assign: item?.product?.assign?.name,
                                        profile:
                                          item?.product?.assign?.profile_image,
                                      });
                                    }}
                                    className={`${
                                      clickedId === index && "text-blue-600"
                                    } text-gray-500 hover:text-blue-600 w-4 h-4 cursor-pointer`}
                                  />
                                  <FaRegTrashAlt
                                    onClick={() => {
                                      setUpdateItem((prevVal) =>
                                        prevVal.filter(
                                          (item2) => item2 !== item
                                        )
                                      );
                                    }}
                                    // onClick={() => actions.delete.onClick(item)}
                                    className="w-4 h-4 hover:text-red-600 text-gray-500 cursor-pointer"
                                  />
                                </div>
                              </td>
                            </tr>
                            {clickedId === index && (
                              <tr className="!w-full bg-blue-50">
                                <td colSpan={6}>
                                  <div className="flex flex-col gap-4 px-8 py-4 ">
                                    <div className="flex gap-3.5 items-center">
                                      <div className="flex flex-col  space-y-[8px]">
                                        <label className="text-gray-900 text-[14px] font-medium">
                                          Reason*
                                        </label>
                                        <Dropdown
                                          bg_color="white"
                                          text_color="gray"
                                          degree={
                                            item?.request_reason ? "600" : "500"
                                          }
                                          // justify="between"

                                          className={`bg-gray-50 border  focus:bg-white focus:border-2 focus:border-blue-400 h-[42px] cursor-default  text-gray-900  p-3 rounded-xl w-[100%] ${
                                            errorMessage &&
                                            !item?.request_reason
                                              ? "border-red-500"
                                              : "border-gray-200"
                                          }`}
                                          name="request_reason"
                                          items={[
                                            "Warehouse Stock",
                                            "Fulfilment Stock",
                                            "Bulk Order",
                                          ]}
                                          value={
                                            item?.request_reason
                                              ? item?.request_reason ===
                                                "warehouse_restocking"
                                                ? "Warehouse Stock"
                                                : item?.request_reason ===
                                                  "fulfilment_restocking"
                                                ? "Fulfilment Stock"
                                                : item?.request_reason ===
                                                  "bulk_order"
                                                ? "Bulk Order"
                                                : "Order"
                                              : "Reason for Supply"
                                          }
                                          onSelect={(e) => {
                                            let reason;
                                            reason =
                                              e === "Warehouse Stock"
                                                ? "warehouse_restocking"
                                                : e === "Fulfilment Stock"
                                                ? "fulfilment_restocking"
                                                : e === "Bulk Order"
                                                ? "bulk_order"
                                                : "order";
                                            setRequestReason(reason);
                                            setSelectedProduct({
                                              ...selectedProduct,
                                              request_reason: reason,
                                            });
                                            setUpdateItem((prevVal) => {
                                              // Make a shallow copy of the previous state
                                              let copy = [...prevVal];
                                              const findItem = copy.findIndex(
                                                (item2) =>
                                                  item2?.title === item?.title
                                              );
                                              // Modify the copy
                                              copy[findItem] = {
                                                ...copy[findItem],
                                                request_reason: reason,
                                              };
                                              return copy;
                                            });
                                          }}
                                          placeholder="Select supply reason"
                                          width="200px"
                                          height={42}
                                        />
                                      </div>
                                      <div className="flex flex-col space-y-[8px]">
                                        <label className="text-gray-900 text-[14px] font-medium">
                                          Supply Channel*
                                        </label>
                                        <CustomAsyncSelect
                                          url={constants.APIURL.GET_CHANNELS}
                                          mapOptions={
                                            mapProductsOptionsToValues
                                          }
                                          //  defaultOptions={defaultProducts}
                                          //  defaultParams="?not_deleted=true"
                                          placeholder="Select the supply channel you want to request supply from."
                                          //  isDisabled={!!data?.product || editMode}
                                          product={item?.supplier_id || ""}
                                          onChange={(data) => {
                                            setUpdateItem((prevVal) => {
                                              // Make a shallow copy of the previous state
                                              let copy = [...prevVal];
                                              const findItem = copy.findIndex(
                                                (item2) =>
                                                  item2?.title === item?.title
                                              );
                                              // Modify the copy
                                              copy[findItem] = {
                                                ...copy[findItem],
                                                supplier_id: data,
                                                supplier: {
                                                  name: data?.label,
                                                  id: data?.value,
                                                },
                                              };
                                              return copy;
                                            });
                                          }}
                                          optionHeight={50}
                                          features={{
                                            formatOptionLabel: (item) => (
                                              <div
                                                className={
                                                  "flex flex-row gap-[12px] items-center"
                                                }
                                              >
                                                <div className="w-[35px] h-auto">
                                                  <img
                                                    className={
                                                      "w-full h-auto rounded-full border-0 bg-gray-200"
                                                    }
                                                    src={
                                                      item?.logo ||
                                                      "/assets/images/img-placehoder.png"
                                                    }
                                                  />
                                                </div>

                                                <span>{item?.name}</span>
                                              </div>
                                            ),
                                          }}
                                          customStyles={{
                                            control: (provided, state) => ({
                                              ...provided,
                                              height: 42,
                                              width: "100%",
                                              backgroundColor: "#F9FAFB",
                                              borderRadius: 8,
                                              overflow: "auto",
                                              border: state.isFocused
                                                ? "1px solid #00A3FF"
                                                : errorMessage &&
                                                  !selectedProduct.product
                                                ? "1px solid #FF0000"
                                                : "1px solid #E5E5E5",
                                              boxShadow: state.isFocused
                                                ? "0px 0px 0px 1px #00A3FF"
                                                : "none",
                                              "&:hover": {
                                                border: state.isFocused
                                                  ? "1px solid #00A3FF"
                                                  : "1px solid #E5E5E5",
                                              },
                                            }),
                                            option: (provided, state) => ({
                                              ...provided,
                                              backgroundColor: state.isSelected
                                                ? "#00A3FF"
                                                : "white",
                                              color: state.isSelected
                                                ? "white"
                                                : "black",

                                              "&:hover": {
                                                backgroundColor: "#00A3FF",
                                                color: "white",
                                              },
                                            }),
                                            menu: (provided, state) => ({
                                              ...provided,
                                              position: "absolute",
                                              zIndex: 999,
                                              // borderRadius: 10,
                                              // overflow: 'hidden',
                                              border: state.isFocused
                                                ? "1px solid #00A3FF"
                                                : "1px solid #E5E5E5",
                                              boxShadow: state.isFocused
                                                ? "0px 0px 0px 1px #00A3FF"
                                                : "none",
                                              "&:hover": {
                                                border: state.isFocused
                                                  ? "1px solid #00A3FF"
                                                  : "1px solid #E5E5E5",
                                              },
                                            }),
                                            container: (provided, state) => ({
                                              ...provided,
                                              width: "673px",
                                              height: 42,
                                            }),
                                          }}
                                        />
                                      </div>
                                      <div className="flex flex-col  space-y-[8px]">
                                        <label className="text-[14px] leading-[21px] font-medium text-gray-900">
                                          Quantity*
                                        </label>
                                        <div className="flex w-[160px] h-[42px] ">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              if (
                                                updateItem[index]?.qty !== 1 &&
                                                updateItem[index]?.qty !==
                                                  "1.00"
                                              ) {
                                                setUpdateItem((prevVal) => {
                                                  // Make a shallow copy of the previous state
                                                  let copy = [...prevVal];
                                                  const findItem =
                                                    copy.findIndex(
                                                      (item2) =>
                                                        item2?.title ===
                                                        item?.title
                                                    );
                                                  const tempQty = Number(
                                                    copy[findItem].qty
                                                  );
                                                  // Modify the copy
                                                  copy[findItem] = {
                                                    ...copy[findItem],
                                                    qty: Number(tempQty - 1),
                                                  };
                                                  return copy;
                                                });
                                              }
                                            }}
                                            className="border border-gray-300 border-r-0 rounded-r-none hover:bg-gray-50 bg-gray-100 py-3 px-5 rounded-lg"
                                          >
                                            <FaMinus className="w-[12.8px] h-[12.8px] text-gray-900" />
                                          </button>
                                          {!isQuantityClicked ? (
                                            <span
                                              onClick={() =>
                                                setQuantityClicked(true)
                                              }
                                              className="text-[14px] border bg-gray-50 px-5  py-3 border-gray-300 leading-[17.5px] text-gray-900"
                                            >
                                              {Number(item?.qty) || 1}
                                            </span>
                                          ) : (
                                            <input
                                              type="number"
                                              min={1}
                                              className="text-[14px] border text-center focus:border-gray-300 focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none bg-gray-50  w-[50px]  py-3 border-gray-300 leading-[17.5px] text-gray-900"
                                              onChange={(e) => {
                                                if (e.target.value) {
                                                  setUpdateItem((prevVal) => {
                                                    // Make a shallow copy of the previous state
                                                    let copy = [...prevVal];
                                                    const findItem =
                                                      copy.findIndex(
                                                        (item2) =>
                                                          item2?.title ===
                                                          item?.title
                                                      );
                                                    // Modify the copy
                                                    copy[findItem] = {
                                                      ...copy[findItem],
                                                      qty: Number(
                                                        e.target.value
                                                      ),
                                                    };
                                                    return copy;
                                                  });
                                                }
                                              }}
                                              value={Number(item?.qty) || 1}
                                            />
                                          )}
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setUpdateItem((prevVal) => {
                                                // Make a shallow copy of the previous state
                                                let copy = [...prevVal];
                                                const findItem = copy.findIndex(
                                                  (item2) =>
                                                    item2?.title === item?.title
                                                );
                                                const tempQty = Number(
                                                  copy[findItem].qty
                                                );
                                                // Modify the copy
                                                copy[findItem] = {
                                                  ...copy[findItem],
                                                  qty: Number(tempQty + 1),
                                                };
                                                return copy;
                                              });
                                            }}
                                            className="border border-gray-300 border-l-0  bg-gray-100 hover:bg-gray-50 py-3 px-5 rounded-lg rounded-l-none"
                                          >
                                            <FaPlus className="w-[12.8px] h-[12.8px] text-gray-900" />
                                          </button>
                                        </div>
                                      </div>
                                      <div className="flex flex-col space-y-[8px]  ">
                                        <label className="text-[14px] leading-[21px] font-medium text-gray-900">
                                          Max. Acceptable Price*
                                        </label>
                                        <div className="flex">
                                          <input
                                            type="number"
                                            className={`bg-gray-50 border-gray-300  border focus:border-gray-300 [&::-webkit-inner-spin-button]:appearance-none  focus:ring-0 h-[42px] !w-[91px] text-center text-[14px] leading-[17.5px] text-gray-900  p-3 !rounded-r-none border-r-0 rounded-lg`}
                                            name="purchase_price"
                                            placeholder="Price"
                                            value={
                                              item?.max_acceptable_price || null
                                            }
                                            onChange={(e) => {
                                              // it should be a number bigger than 0
                                              if (e.target.value >= 0) {
                                                setUpdateItem((prevVal) => {
                                                  let copy = [...prevVal];
                                                  const findItem =
                                                    copy.findIndex(
                                                      (item2) =>
                                                        item2?.title ===
                                                        item?.title
                                                    );
                                                  // Modify the copy
                                                  copy[findItem] = {
                                                    ...copy[findItem],
                                                    max_acceptable_price:
                                                      Number(e.target.value),
                                                  };
                                                  return copy;
                                                });
                                              }
                                            }}
                                            // onBlur={formik.handleBlur}
                                          />
                                          <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={currenciesOptions}
                                            className="border-gray-300 h-[42px] bg-transparent placeholder:text-red-500 !w-[110px] rounded-l-none border-l-0 focus:border-gray-300   focus:ring-0"
                                            name="currency"
                                            isDisabled={
                                              formik.values.bank_account_id
                                                ? true
                                                : false
                                            }
                                            value={
                                              item?.max_acceptable_price_currency_id ||
                                              defaultCurrency
                                            }
                                            onChange={(value) => {
                                              setUpdateItem((prevVal) => {
                                                // Make a shallow copy of the previous state
                                                let copy = [...prevVal];
                                                const findItem = copy.findIndex(
                                                  (item2) =>
                                                    item2?.title === item?.title
                                                );
                                                // Modify the copy
                                                copy[findItem] = {
                                                  ...copy[findItem],
                                                  max_acceptable_price_currency_id:
                                                    value,
                                                };
                                                return copy;
                                              });
                                            }}
                                            formatOptionLabel={(item) => {
                                              return (
                                                <div className="flex items-center gap-2">
                                                  <Util.countryCurrencies
                                                    className={
                                                      "w-4 h-[11.2px] object-cover"
                                                    }
                                                    item={item?.code}
                                                  />
                                                  <span className="text-[14px] leading-[17.5px] font-medium text-gray-900">
                                                    {item?.code}
                                                  </span>
                                                </div>
                                              );
                                            }}
                                            onBlur={formik.handleBlur}
                                            styles={{
                                              control: (provided, state) => ({
                                                ...provided,
                                                height: 42,
                                                minHeight: 42,
                                                width: "100%",
                                                backgroundColor: "#F3F4F6",
                                                borderRadius: "0 10px 10px 0", // Simulates rounded-l-none
                                                borderLeft: "none", // Simulates border-l-0
                                                border: "1px solid #D1D5DB", // Default border color updated to gray-300
                                                boxShadow: "none", // Remove default box-shadow
                                                "&:hover": {
                                                  border: "1px solid #D1D5DB", // Border color on hover updated to gray-300
                                                },
                                                ...(state.isFocused && {
                                                  border: "1px solid #D1D5DB", // Border color on focus updated to gray-300
                                                  boxShadow: "none", // Remove box-shadow on focus
                                                }),
                                              }),
                                              option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor:
                                                  state.isSelected
                                                    ? "#00A3FF"
                                                    : "white",
                                                color: state.isSelected
                                                  ? "white"
                                                  : "black",
                                                "&:hover": {
                                                  backgroundColor: "#00A3FF",
                                                  color: "white",
                                                },
                                              }),
                                              menu: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                width: "100%",
                                                zIndex: 999,
                                                overflow: "hidden",
                                                border: "1px solid #D1D5DB", // Border color updated to gray-300
                                                boxShadow: "none",
                                                "&:hover": {
                                                  border: "1px solid #D1D5DB", // Border color on hover updated to gray-300
                                                },
                                              }),
                                              container: (provided, state) => ({
                                                ...provided,
                                                width: "100%",
                                                height: 42,
                                                minHeight: 42,
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {(requestReason !== "order" ||
                                      editMode) && (
                                      <div className="flex gap-3.5 w-full ">
                                        <div className="flex flex-col w-full space-y-[8px]">
                                          <label className="text-gray-900 text-[14px] font-medium">
                                            Purchase Link
                                            {invalidUrl && (
                                              <span className="text-sm ml-3 text-red-500">
                                                Invalid URL
                                              </span>
                                            )}
                                          </label>
                                          <input
                                            className={`bg-gray-50 border focus:bg-white focus:border-2 focus:border-blue-400 border-gray-200 text-gray-900 py-2 px-4 h-[41px] rounded-lg w-full ${
                                              errorMessage && !item?.url
                                                ? "border-red-500"
                                                : "border-gray-200"
                                            }`}
                                            placeholder="You can share the purchase link for the product with the purchasing team."
                                            name="url"
                                            value={item?.url || ""}
                                            onChange={(e) => {
                                              setUpdateItem((prevVal) => {
                                                // Make a shallow copy of the previous state
                                                let copy = [...prevVal];
                                                const findItem = copy.findIndex(
                                                  (item2) =>
                                                    item2?.title === item?.title
                                                );
                                                // Modify the copy
                                                copy[findItem] = {
                                                  ...copy[findItem],
                                                  url: e.target.value,
                                                };
                                                return copy;
                                              });
                                            }}
                                            onInput={(e) => {
                                              // validate with this simpler regex
                                              if (
                                                !e.target.value.match(
                                                  /^https?:\/\/[^/\s]+\/?.*$/
                                                )
                                              ) {
                                                // set the border to red
                                                e.target.style.border =
                                                  "1px solid red";
                                                setInvalidUrl(true);
                                              } else {
                                                e.target.style.border =
                                                  "1px solid #E5E5E5";
                                                setInvalidUrl(false);
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="flex flex-col w-full space-y-[8px]">
                                          <label className="text-gray-900 text-[14px] font-medium">
                                            Supply Request Note
                                          </label>
                                          <input
                                            className={`bg-gray-50 border focus:bg-white focus:border-2 focus:text-gray-900  focus:border-blue-400 border-gray-200 text-gray-900 py-2 px-4 h-[41px] rounded-lg w-full ${
                                              errorMessage && !item?.note
                                                ? "border-red-500"
                                                : "border-gray-200"
                                            }`}
                                            placeholder="This note will appear at the time of purchase of the products."
                                            name="comments"
                                            value={item?.note || ""}
                                            onChange={(e) => {
                                              setUpdateItem((prevVal) => {
                                                // Make a shallow copy of the previous state
                                                let copy = [...prevVal];
                                                const findItem = copy.findIndex(
                                                  (item2) =>
                                                    item2?.title === item?.title
                                                );
                                                // Modify the copy
                                                copy[findItem] = {
                                                  ...copy[findItem],
                                                  note: e.target.value,
                                                };
                                                return copy;
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      : isIn && (
                          <tr className="!w-full">
                            <td colSpan={6}>
                              <div
                                className={
                                  "  px-6 py-3 gap-2 flex flex-col absolute"
                                }
                              >
                                {[...Array(11)].map((_, index) => (
                                  <HandmadeSkeleton
                                    key={index}
                                    width={1120}
                                    className={"w-full h-9"}
                                  />
                                ))}
                              </div>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </table>
              </div>
            ) : (
              !isAddClicked && (
                <div className=" flex flex-col gap-4 mt-20">
                  <div className="w-[595px] mt-8   m-auto text-center flex flex-col gap-4">
                    <div className="text-center flex items-center justify-center">
                      <Icons.search className="w-7 h-7 text-gray-500 " />
                    </div>
                    <span className="text-gray-700 text-[16px] font-medium leading-normal">
                      To request a new supply, please select the product you
                      wish to supply. If you cannot find the product you are
                      looking for, you can{" "}
                      <span
                        onClick={() => {
                          results?.results
                            ? window.open(
                                `/product/list#action=add${
                                  results?.results[0]?.label
                                    ? "&title=" + results?.results[0]?.label
                                    : ""
                                }${
                                  results?.results[0]?.image
                                    ? "&image=" + results?.results[0]?.image
                                    : ""
                                }`,
                                "_blank"
                              )
                            : window.open(`/product/list#action=add`, "_blank");
                        }}
                        className="text-blue-700  cursor-pointer"
                      >
                        create a new product.
                      </span>
                    </span>
                    <div className="px-2 relative flex w-[630px] items-center border  rounded-lg rounded-bl-lg bg-gray-50   border-gray-300 ">
                      <Icons.search className="w-4 h-4 text-gray-500" />
                      <input
                        type="text"
                        placeholder="Search..."
                        autoFocus
                        value={inputValue || ""}
                        onChange={(e) => handleInputChange(e.target.value)}
                        className="w-full p-2 bg-gray-50 border-none !outline-none rounded-lg text-[14px] leading-5 "
                      />
                      {inputValue && (
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            setInputValue("");
                            handleInputChange("");
                          }}
                        >
                          <Icons.close className="w-4 h-4 text-gray-500 " />
                        </span>
                      )}
                    </div>
                  </div>
                  {inputValue && (
                    <div className="px-4 ">
                      <SearchResults
                        inputValue={inputValue}
                        fetchItems={fetchItems}
                        results={
                          !searching ? { results: selectedProduct } : results
                        }
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        searching={searching}
                        setSearching={setSearching}
                        ItemCard={ProductItem}
                        data={data}
                        setData={setData}
                        loading={loading}
                      />
                    </div>
                  )}
                </div>
              )
            )}

            {updateItem?.length !== 0 && (
              <div
                className={`flex justify-between items-center sticky bottom-0 py-3  bg-white   w-full mt-4 px-5`}
              >
                <div>
                  <FrontendFooterPagination
                    postsPerPage={postsPerPage}
                    totalPosts={updateItem?.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </div>
                <div className="flex justify-end items-center">
                  <div className="mx-1">
                    <ArbitButton
                      width={"120px"}
                      bg_color="white"
                      border_color="blue"
                      text_color="blue-600"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </ArbitButton>
                  </div>
                  {/* {!product && !selectedSupply[0] && (
                  <div className="mx-1">
                    <ArbitButton
                    
                      bg_color="white"
                      border_color="blue"
                      text_color="blue-600"
                      onClick={() => {
                        setAddNew(true);
                        formik.handleSubmit();
                      }}
                    >
                      Complete & Create New
                    </ArbitButton>
                  </div>
                )} */}
                  <div className="mx-1">
                    <ArbitButton width={"120px"} type="submit">
                      Create
                    </ArbitButton>
                  </div>
                </div>
              </div>
            )}
          </form>
        ),
      }}
      className="css-1j124qj2 min-w-[1200px] "
      containerClassName=" !px-0 z-[1]"
      zIndex={true}
      option={
        currentPosts?.length > 0 && (
          <div
            className="flex items-center gap-1.5 !ml-5 !pl-4 cursor-pointer hover:text-blue-500 text-blue-700"
            onClick={() => {
              setSelectedProduct({
                product_id: "",
                currency_id: "",
                title: "",
                purchase_price: "",
                image: "",
                quantity: 1,
                assign: "",
                profile: "",
              });
              setAddClicked(!isAddClicked);
              setClickedId(null);
            }}
          >
            <FaPlus className="w-2.5 h-2.5" />
            <span className="text-[14px] leading-[21px] font-medium">
              Add Product
            </span>
          </div>
        )
      }
      optionClassName="gap-8"
    />
  );
};

export default NewRequest;
