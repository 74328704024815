import React from "react";
import Icons from "../../icons";
import Components from "../index";
import {
  fetchSingleOrder,
  updateBillableWeight,
  updateRow,
} from "../../../../store/orders";
import { useDispatch, useSelector } from "react-redux";
import notifier from "../../../dynamic-page/util/notifier";
import util from "../../../dynamic-page/util";

const Weight = ({ weight, order_status_id }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.orders.orders);
  const weightInRedux =
    data?.find((item) => item?.id === weight?.orderId)?.billable_weight || "-";
  const handleSaveWeight = async (t) => {
    await dispatch(
      updateBillableWeight({
        orderId: weight?.orderId,
        billable_weight: Number(t),
      })
    ).then((rs) => {
      if (rs?.payload?.data?.type === "success") {
        const w = t;
        notifier({
          message: w
            ? `Billable Weight updated to  ${w}`
            : "Billable Weight has been removed",
          type: "success",
        });
        dispatch(fetchSingleOrder(weight?.orderId)).then((rs) => {
          dispatch(
            updateRow({
              path: `orders.[id:${weight?.orderId}].billable_weight`,
              value: w,
            })
          );
          dispatch(
            updateRow({
              path: `orders.[id:${weight?.orderId}]._updatedRow`,
              value: true,
            })
          );
          // remove the updated row after 5 seconds
          setTimeout(() => {
            dispatch(
              updateRow({
                path: `orders.[id:${weight?.orderId}]._updatedRow`,
                value: false,
              })
            );
          }, 5000);
        });
      }
    });

    return true;
  };
  return (
    <div className={"flex flex-col gap-[4px] justify-center"}>
      {/*{weight?.billable  &&*/}
      <>
        <div className={"flex flex-row gap[0px] items-center justify-center"}>
          <Components.saveWeight
            nullable={true}
            text={weightInRedux}
            onSave={handleSaveWeight}
            className={`min-w-max whitespace-no-wrap group-active:text-purple-800 hover:text-purple-600 flex  text-purple-700 ${
              order_status_id === 14
                ? "font-[600] text-[20px]"
                : "font-[700] text-[24px]"
            } `}
          />
          {/*<span className={"min-w-max whitespace-no-wrap group-active:text-purple-800 hover:text-purple-600 flex text-[24px] text-purple-700 font-[700]"} >{weight.billable}</span>*/}
        </div>

        {order_status_id !== 14 && (
          <div className={"flex flex-row gap-[4px] text-[12px] justify-center"}>
            <util.popUp
              toggle={(selected) => (
                <span
                  className={`opacity-40 ${
                    weight?.selected === "actual" && "!opacity-100"
                  } ${
                    selected ? "!opacity-70" : ""
                  } text-purple-600 font-medium flex flex-row gap-[4px] items-center  text-medium`}
                >
                  <Icons.weightTarazo className={"h-[1em]"} />
                  {weight?.actual}
                </span>
              )}
              action={"hover"}
              forcePosition={"top"}
              delay={500}
              toggleClassName={"!cursor-default"}
            >
              Actual Weight
            </util.popUp>
            <util.popUp
              toggle={(selected) => (
                <span
                  className={`opacity-40 ${
                    weight?.selected === "dimensional" && "opacity-100"
                  } ${
                    selected ? "!opacity-70" : ""
                  } text-purple-600 font-medium flex flex-row gap-[4px] items-center text-medium"`}
                >
                  <Icons.weightBox className={"h-[1em]"} />
                  {weight?.dim}
                </span>
              )}
              action={"hover"}
              forcePosition={"top"}
              delay={500}
              toggleClassName={"!cursor-default"}
            >
              Dimensional Weight
            </util.popUp>
          </div>
        )}
      </>
      {/*}*/}
    </div>
  );
};

export default Weight;
