import descriptiveContent from "../../../utils/descriptiveContent";
import util from "../../dynamic-page/util";
import React from "react";
import Icons from "../../orders/icons";

const NoteIcon = ({ notes }) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-500 hover:text-gray-300";
    }
  };

  return (
    <div
      className={
        "flex flex-col items-center justify-center h-[44px] text-gray-500"
      }
    >
      <div className={"flex flex-row items-center justify-center "}>
        <util.popUp
          toggle={(selected) =>
            descriptiveContent(
              <div
                className={`flex items-center justify-center transition rounded-full ${activeStyle(
                  notes?.purchaser
                )}`}
              >
                <Icons.purchaseNote className={"w-[20px] h-[20px]  cursor-pointer"} />
              </div>,
              "Purchaser note"
            )
          }
          action={"click"}
        >
          <div
            style={{ hyphens: "auto" }}
            className={
              "max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"
            }
          >
            {notes?.purchaser ? (
              util.upperCaseString(notes?.purchaser)
            ) : (
              <span className={"text-gray-400"}>note is empty!</span>
            )}
          </div>
        </util.popUp>
      </div>
      <div className={"flex flex-row items-center justify-center"}>
        <util.popUp
          toggle={(selected) =>
            descriptiveContent(
              <div
                className={`flex items-center justify-center transition rounded-full ${activeStyle(
                  notes?.accepter
                )}`}
              >
                <Icons.acceptanceNote className={"w-[20px] h-[20px]  cursor-pointer"} />
              </div>,
              "Accepter note"
            )
          }
          action={"click"}
        >
          <div
            style={{ hyphens: "auto" }}
            className={
              "max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"
            }
          >
            {notes?.accepter ? (
              util.upperCaseString(notes?.accepter)
            ) : (
              <span className={"text-gray-400"}>note is empty!</span>
            )}
          </div>
        </util.popUp>
      </div>
    </div>
  );
};
export default NoteIcon;
