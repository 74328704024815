import {Outlet} from "react-router-dom";
import ProductNavbar from "../products/product-navbar";


const Layout = ({children}) => {
    // It's not used in this component.
    //const navitems = useSelector((state) => state.product.navitems);
    return (
        <div
            className="w-full flex flex-col"
            style={{overflowX: "hidden", overflowY: "hidden"}}
        >
            <ProductNavbar/>{/*items={navitems}*/}
            {children || <Outlet/>}
        </div>
    );
};

export default Layout;
