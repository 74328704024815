import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import filtersView from "./modals-view/filters";
import tableView from "./modals-view/table";
import sortView from "./modals-view/sort";
import formView from "./modals-view/form";

import callEndpoint from "../dynamic-page/util/callEndpoint";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import {
  fetchOffers as fetchData,
  fetchOffersFilters as fetchFilters,
  updateBoundFilter,
  updateFilter,
} from "../../store/offers";

import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import FormModal from "../dynamic-page/FormModal";
import ListingDetailModal from "../../components/listing-detail-modal";
import UI from "../dynamic-form/elements";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";
import Icons from "../orders/icons";
import DropDownMenu from "../dynamic-page/elements/DropDownMenu";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import useLoadMoreData from "../../utils/useLoadMoreData";

const Offers = () => {
  const dispatch = useDispatch();

  // Initial page at first load.
  useEffect(() => {
    const startPageByFilters = {
      filters: {},
      sort: null,
      page: 1,
      search: "",
      limit: 10,
      includes: "account,salesChannelListing.product.brand,product.brand.assigned",
    };
    const storeQuery = storeQueryBuilder(startPageByFilters, urlQueryLoader());
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    let hash = window.location.hash;
    hash = hash.replace("#", "?"); // Replace first '&' with '?'
    const params = new URLSearchParams(hash);
    const action = params.get("action");
    if (action === "create") {
      setModalVisibility(true);
    }
    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));
  }, []);

  let hash = window.location.hash;
  hash = hash.replace("#", "?"); // Replace first '&' with '?'
  const params = new URLSearchParams(hash);

  // Get data from store.
  const pageData = useSelector((state) => state.offers);
  const tableData = useSelector(
    (state) => state.targetSellerListings.targetSellerListings
  );

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState(null);

  const [visibleListingModal, setVisibleListingModal] = useState(false);
  const [salesModalListingData, setModalSalesListingData] = useState(null); // State to store salesListing data

  const openListingModal = (modalListing) => {
    const mappedListing = {
      sales_channel_listing: {
        identifier: modalListing?.identifier,
      },
    };
    setModalSalesListingData(modalListing); // Set salesListing data in the state
    setVisibleListingModal(true);
  };

  // Handle form modal.
  const [calledItemInAction, setCalledItemInAction] = useState(null);
  const [formData, setFormData] = useState(null);

  // handle refreshing
  const [refreshing, setRefreshing] = useState(false);
  const [refreshingDate, setRefreshingDate] = useState(null);

  // Handle order details modal.
  const [modalVisibility, setModalVisibility] = useState(false);

  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    setSelectedRow([]);
    return orderId;
  };

  const handleNotifyUser = (rs) => {
    util.notifier(rs);
  };

  const onSubmitApply = async (data) => {
    let { id, ...payload } = data;

    if (payload.quantity === null) {
      delete payload.quantity;
    }

    const innerData = payload;
    callEndpoint({
      title: "Create Offer",
      url: `offers`,
      method: "POST",
      data: payload,
    }).then((res) => {
      if (res.type === "success") {
        onPaginationApply();
        setModalVisibility(false);
      }
    });
  };

  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  const actions = (row) => {
    // todo const innerRow = row.map((item) => { add actions_id to item }

    // return getActionList(row, statusList);
    return [];
  };

  // Handle Tabs
  const onHandleTab = (item) => {
    onFilterApply({ fulfillment: item });
  };

  // Handle Pagination
  const onHandlePrevious = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
  };

  const onHandleNext = () => {
    onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
  };

  const onHandlePage = (page) => {
    onPaginationApply(page);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, bound) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    dispatch(updateBoundFilter({ filters, bound }));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");
    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow(null);
    const currentStoreFilters = pageData?.filters;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term");

    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const keysArray = [
    "Updated At",
    "Status",
    "SKU",
    "Fulfillment",
    "Quantity",
    "Price",
    "Price Currency",
    "Channel",
    "Account",
    "Brand",
    "Identifier",
    "Identifier Type",
    "Title",
    "BB Low Price",
    "Buybox Price",
    "Buybox Seller Type",
    "Rating",
    "Reviews Count",
    "Variation Count",
    "Root Category",
    "Sales Rank",
    "Image",
    "URL",
    "Available Quantity",
    "Inbound Quantity",
    "Reserved Quantity",
    "Unfulfillable Quantity",
    "Estimated Aged Inventory Cost",
    "Estimated Storage Cost Next Month",
    "Estimated AIS 181-210 Days",
    "Estimated AIS 211-240 Days",
    "Estimated AIS 241-270 Days",
    "Estimated AIS 271-300 Days",
    "Estimated AIS 301-330 Days",
    "Estimated AIS 331-365 Days",
    "Estimated AIS 365+ Days",
    "Inbound Receiving",
    "Inbound Shipped",
    "Inbound Working",
    "Reserved Customer Orders",
    "Reserved FC Processing",
    "Reserved FC Transfers",
  ];

  const exportMapper = (data) => {
    return data?.map((item) => {
      return {
        "Updated At": moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss"),
        Status: item?.status || "-",
        SKU: item?.sku || "-",
        Fulfillment: item?.fulfilment || "-",
        Quantity: item?.quantity || "-",
        Price: item?.price || "-",
        "Price Currency": item?.account?.currency?.code || "-",
        Channel: item?.sales_channel_listing?.channel?.name || "-",
        Account: item?.account?.name || "-",
        Brand: item?.sales_channel_listing?.brand || "-",
        Identifier: item?.sales_channel_listing?.identifier || "-",
        "Identifier Type": item?.sales_channel_listing?.identifier_type || "-",
        Title: item?.sales_channel_listing?.name || "-",
        "BB Low Price": item?.sales_channel_listing?.bb_low_price || "-",
        "Buybox Price": item?.sales_channel_listing?.buybox_price || "-",
        "Buybox Seller Type":
          item?.sales_channel_listing?.buybox_seller_type || "-",
        Rating: item?.sales_channel_listing?.ratings || "-",
        "Reviews Count": item?.sales_channel_listing?.reviews_count || "-",
        "Variation Count": item?.sales_channel_listing?.variation_count || "-",
        "Root Category": item?.sales_channel_listing?.root_category || "-",
        "Sales Rank": item?.sales_channel_listing?.sales_rank || "-",
        Image: item?.sales_channel_listing?.main_image_path || "-",
        URL: item?.sales_channel_listing?.url || "-",
        "Available Quantity":
          item?.fba_quantity_tooltip?.available_quantity || "-",
        "Inbound Quantity": item?.fba_quantity_tooltip?.inbound_quantity || "-",
        "Reserved Quantity":
          item?.fba_quantity_tooltip?.reserved_quantity || "-",
        "Unfulfillable Quantity":
          item?.fba_quantity_tooltip?.unfulfillable_quantity || "-",
        "Estimated Aged Inventory Cost":
          item?.estimated_aged_inventory_cost || "-",
        "Estimated Storage Cost Next Month":
          item?.estimated_storage_cost_next_month || "-",
        "Estimated AIS 181-210 Days":
          item?.aged_inventory_cost_tooltip?.estimated_ais_181_210_days || "-",
        "Estimated AIS 211-240 Days":
          item?.aged_inventory_cost_tooltip?.estimated_ais_211_240_days || "-",
        "Estimated AIS 241-270 Days":
          item?.aged_inventory_cost_tooltip?.estimated_ais_241_270_days || "-",
        "Estimated AIS 271-300 Days":
          item?.aged_inventory_cost_tooltip?.estimated_ais_271_300_days || "-",
        "Estimated AIS 301-330 Days":
          item?.aged_inventory_cost_tooltip?.estimated_ais_301_330_days || "-",
        "Estimated AIS 331-365 Days":
          item?.aged_inventory_cost_tooltip?.estimated_ais_331_365_days || "-",
        "Estimated AIS 365+ Days":
          item?.aged_inventory_cost_tooltip?.estimated_ais_365_plus_days || "-",
        "Inbound Receiving":
          item?.inbound_quantity_tooltip?.inbound_receiving || "-",
        "Inbound Shipped":
          item?.inbound_quantity_tooltip?.inbound_shipped || "-",
        "Inbound Working":
          item?.inbound_quantity_tooltip?.inbound_working || "-",
        "Reserved Customer Orders":
          item?.reserved_quantity_tooltip?.reserved_customerorders || "-",
        "Reserved FC Processing":
          item?.reserved_quantity_tooltip?.reserved_fc_processing || "-",
        "Reserved FC Transfers":
          item?.reserved_quantity_tooltip?.reserved_fc_transfers || "-",
      };
    });
  };

  const dataToBeExported = useMemo(() => {
    return selectedRow?.length > 0
      ? exportMapper(selectedRow)
      : exportMapper(pageData?.offers) || [];
  }, [selectedRow, pageData?.offers]);

  const exportCSV = useCallback(() => {
    const worksheet = utils.json_to_sheet(dataToBeExported);
    const csvOutput = utils.sheet_to_csv(worksheet);

    const blob = new Blob([csvOutput], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `Offers.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [dataToBeExported]);

  const exportFile = useCallback(() => {
    const ws = utils.json_to_sheet(dataToBeExported);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "Offers.xlsx");
  }, [dataToBeExported]);

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        {/* Page Title, and buttons of Add,Filter,Sort,Action are here. */}
        <ArbitProductPage.Title
          title={"Offers"}
          form={{
            callback: (rs) => {
              setModalVisibility(true);
            },
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            discard: ["fulfillment"],
            items: filtersView(
              pageData?.filters?.filters,
              pageData?.boundFilters,
              pageData?.filtersMeta
            ),
            callback: (rs, bound) => {
              onFilterApply(rs, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item !== null && item !== ""
              )?.length -
              (pageData?.filters?.filters?.fulfillment === undefined ||
              pageData?.filters?.filters?.fulfillment === null
                ? 0
                : 1),
          }}
          actionList={actions(selectedRow)}
          exports={[
            {
              callBack: () => {
                // download(csvConfig)(csv, "export.csv", "text/csv");
                if (pageData?.loading !== "idle") return;
                exportFile();
              },
              // order: 1,
              data: refreshing,
              view: ({ callBack, data }) => (
                // <UI.Button
                //   item={{
                //     label: <Icons.uploadOutline className="h-6 w-6" />,
                //     buttonType: "icon",
                //     className: "order-[1]",
                //     disabled: pageData?.loading !== "idle",
                //   }}
                //   onClick={() => {
                //     if (!data) {
                //       callBack();
                //     }
                //   }}
                // />
                <DropDownMenu
                  toggleButton={(selected) => (
                    <UI.Button
                      item={{
                        label: <Icons.exportIcon className="h-6 w-6" />,
                        buttonType: "icon",
                        className: "order-[] !min-w-[28px] !h-[28px] !p-0" ,
                        disabled: pageData?.loading !== "idle",
                      }}
                    />
                  )}
                  // selected={sort?.selected}
                  itemMenu={{
                    list: [
                      {
                        label: "XLSX",
                        onClick: () => {
                          if (pageData?.loading !== "idle") return;
                          exportFile();
                        },
                      },
                      {
                        label: "CSV",
                        onClick: () => {
                          if (pageData?.loading !== "idle") return;
                          exportCSV();
                        },
                      },
                    ],
                  }}
                />
              ),
            },
          ]}
        />

        {/* Tabs and search input are here. */}
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          <TabButton
            callBack={onHandleTab}
            loading={pageData?.filterLoading}
            active={pageData?.filters?.filters?.fulfillment}
            list={[
              {
                id: "DEFAULT",
                name: "FBM Offers",
                count: pageData?.filtersMeta?.fulfilments?.find(
                  (item) => item?.name === "FBM"
                )?.count,
              },
              {
                id: "AMAZON_NA",
                name: "FBA Offers",
                count: pageData?.filtersMeta?.fulfilments?.find(
                  (item) => item?.name === "FBA"
                )?.count,
              },
            ]}
            allTab={true}
          />
        </div>

        {/* Table is here. */}
        <ArbitProductPage.Content>
          <div className=" ">
            <div className="overflow-x-auto max-w-[100%]">
              <DynamicTable
                view={tableView}
                data={tableView(pageData.offers, openListingModal)}
                actions={actions}
                onSelect={onSelectRow}
                selectedRow={selectedRow}
                meta={pageData?.meta}
                appending={loading}
                loadMoreData={loadMoreData}
                loading={pageData.loading}
                style={{
                  table: {
                    style: { width: "100%", minWidth: "100%" },
                  },
                  header: {
                    className: "justify-center !px-[16px] ",
                    "tracking id": { className: "!pr-[30px]" },
                    image: { className: "hidden" },
                    type: { className: "!py-[0px] hidden" },
                  },
                  row: {
                    className:
                      "!px-[8px] !py-[0px] !items-center !justify-center flex text-[12px] ",
                    // "name": { className: "!min-w-fit min-w-max !w-fit !max-w-fit" },
                    description: {
                      className: "!min-w-fit min-w-max !w-fit !max-w-fit",
                    },
                    _actions: { className: "!py-[0px]" },
                  },
                  hiddenActions: true,
                  hideSelect: true,
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        {/* Pagination is here. */}
        <FooterPagination
          meta={pageData?.meta}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
        />
      </ArbitProductPage.Page>

      {/* Modals are here. */}
      {modalVisibility && (
        <FormModal
          title={"Offer"}
          formView={formView}
          visibility={modalVisibility}
          data={formData}
          params={params}
          onClose={() => {
            setFormData(null);
            setModalVisibility(false);
          }}
          container={(child) => (
            <>
              <style>
                {`
                        .parentForm {
                            display: grid;
                            grid-template-columns: repeat(12, 1fr);
                            grid-template-rows: repeat(3, 1fr);
                           
                        }
                        
                        .parentForm > div:nth-child(1) { grid-area: 1 / 1 / 2 / 5; }
                        .parentForm > div:nth-child(2) { grid-area: 1 / 5 / 2 / 9; }
                        .parentForm > div:nth-child(3) { grid-area: 1 / 9 / 2 / 13; }
                        .parentForm > div:nth-child(4) { grid-area: 2 / 1 / 3 / 4; }
                        .parentForm > div:nth-child(5) { grid-area: 2 / 4 / 3 / 7; }
                        .parentForm > div:nth-child(6) { grid-area: 2 / 7 / 3 / 10; }
                        .parentForm > div:nth-child(7) { grid-area: 2 / 10 / 3 / 13; }
                        .parentForm > div:nth-child(8) { grid-area: 3 / 1 / 4 / 13; }
                    `}
              </style>
              <div className=" w-[900px] parentForm items-start gap-[16px] justify-center px-[16px] border-t border-t-gray-200 pt-[24px] mt-[10px]">
                {child}
              </div>
            </>
          )}
          onSubmit={onSubmitApply}
        />
      )}

      {visibleListingModal && (
        <ListingDetailModal
          show={visibleListingModal}
          onClose={() => setVisibleListingModal(false)}
          modalListing={salesModalListingData}
        />
      )}
    </>
  );
};

export default Offers;
