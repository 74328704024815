import React, { useState } from "react";
import {
  HiCheck,
  HiColorSwatch,
  HiExternalLink,
  HiFilter,
  HiStar,
  HiX,
} from "react-icons/hi";
import { FiRefreshCw } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import moment from "moment";
import { howLongFromNow } from "../../../utils/helper";
import Util from "../../dynamic-page/util";
import UI from "../../dynamic-form/elements";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import classNames from "classnames";
import SupplyCost from "../components/supplyCost";
import Weight from "../components/weight";
import { useLocation, useNavigate } from "react-router-dom";
import FbaFbmCals from "../../dynamic-page/components/fba_fbm_cals";
import Icons from "../../orders/icons";
import InternalNote from "../components/internalNote";
import Offers from "../components/offers";
import descriptiveContent from "../../../utils/descriptiveContent";
import SalesChannel from "../../dynamic-page/components/SalesChannel";
import { useDispatch, useSelector } from "react-redux";
import {
  closeNotification,
  startNotification,
} from "../../dynamic-page/util/notifierLive";
import { refreshAccountListings } from "../../../store/fc-restocking";
import { updateRow } from "../../../store/target-seller-folder";
import RefreshSales from "../../dynamic-page/components/RefreshSales";

const TableView = (
  list,
  tabStatus,
  folder,
  handleCallBackActions,
  handleApprovementCallback,
  handleUpdateWeight,
  onBrandFilter,
  openListingModal
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "!text-gray-500";
    }
  };
  const statusList = {
    1: (
      <div className="w-[70px] bg-green-100 text-green-600 h-[22px] py-[2px] px-[10px] flex justify-center rounded-[6px] items-center gap-1 text-[12px] font-[600]">
        Approved
      </div>
    ),
    2: (
      <div className="w-[70px] bg-red-100 text-red-600 h-[22px] py-[2px] px-[10px] flex justify-center rounded-[6px] items-center gap-1 text-[12px] font-[600]">
        Rejected
      </div>
    ),
  };

  const typeList = {
    0: "Debit",
    1: "Credit",
  };
  const pageData = useSelector((state) => state.targetSellerFolder);

  const [refreshing, setRefreshing] = useState("");

  const dispatch = useDispatch();
  const handleRefreshListings = async (item2) => {
    const identifiers = [item2?.sales_channel_listing?.identifier];

    const ids = item2?.id;

    try {
      setRefreshing(item2?.id);

      let a = startNotification({
        title: `Refreshing the Listing`,
      });

      dispatch(refreshAccountListings({ identifiers }))
        .unwrap()
        .then((rs) => {
          setRefreshing("");
          // util.notifier({type : rs.type , message: rs.message});
          closeNotification(a, {
            toast: {
              type: rs.type === "validation_error" ? "error" : rs.type,
              message: rs.message,
              errors: rs.errors,
            },
          });
          if (rs.type === "success") {
            identifiers.map((identifier) => {
              const row = rs.data.find(
                (item) => item.identifier === identifier
              );
              const originalRow = pageData?.targetSellerData?.find(
                (item) => item.sales_channel_listing.identifier === identifier
              );
              dispatch(
                updateRow({
                  path: `targetSellerData.[id:${originalRow.id}]`,
                  value: {
                    ...originalRow,
                    sales_channel_listing: row,
                    _updatedRow: true,
                  },
                })
              );
            });

            setTimeout(() => {
              dispatch(
                updateRow({
                  path: `targetSellerData.[id:${ids}]._updatedRow`,
                  value: false,
                })
              );
            }, 3000);
          }
        });
    } catch (error) {
      // Handle errors if the refresh fails
      console.error("Error refreshing account listings:", error);
    }
  };

  const searchToolTip = (item) => {
    return (
      <Util.popUp
        toggle={(selected) =>
          descriptiveContent(
            <FcGoogle className="h-[20px] w-5 cursor-pointer" />,
            "Search Identifiers "
          )
        }
      >
        <div className={"w-[300px] max-h-[200px] overflow-y-auto"}>
          {/* loop on identefiers and show each idendtifiers name and value and when clicking on the div it takes to a new tab to search that identifier */}
          <div className="flex flex-col divide-y-2 ">
            {/* brand and title search */}
            <div className="flex items-center  gap-3 p-2 justify-between">
              <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
                Brand
              </p>
              <p
                className="text-[14px] flex items-center leading-[14px] cursor-pointer font-[500] gap-2 text-blue-700"
                onClick={() => {
                  window.open(
                    `https://www.google.com/search?q=${item?.sales_channel_listing?.brand}`,
                    "_blank"
                  );
                }}
              >
                {item?.sales_channel_listing?.brand}
                <FcGoogle className="h-4 w-4 cursor-pointer" />
              </p>
            </div>
            <div className="flex items-center  gap-3 p-2 justify-between">
              <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
                Title
              </p>
              <p
                className="text-[14px] flex items-center leading-[14px] cursor-pointer font-[500] gap-2 text-blue-700"
                onClick={() => {
                  window.open(
                    `https://www.google.com/search?q=${item?.sales_channel_listing?.name}`,
                    "_blank"
                  );
                }}
              >
                {/* cut the name to 10character and ... */}
                {item?.sales_channel_listing?.name?.length > 10
                  ? item?.sales_channel_listing?.name?.substring(0, 10) + "..."
                  : item?.sales_channel_listing?.name}
                <FcGoogle className="h-4 w-4 cursor-pointer" />
              </p>
            </div>
            {/* <div className="flex items-center  gap-3 p-2 justify-between">
          <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
            Item
          </p>
          <p
            className="text-[14px] flex items-center leading-[14px] cursor-pointer font-[500] gap-2 text-blue-700"
            onClick={() => {
              window.open(
                `https://www.google.com/search?q=${
                  item?.brand + " " + item?.sales_channel_listing?.model_number + " " + item?.sales_channel_listing?.part_number
                }`,
                "_blank"
              );
            }}
          >
            {item?.brand + " " + item?.sales_channel_listing?.model_number + " " + item?.sales_channel_listing?.part_number}

            <FcGoogle className="h-4 w-4 cursor-pointer" />
          </p>
        </div> */}
            {item?.sales_channel_listing?.model_number ? (
              <div className="flex items-center  gap-3 p-2 justify-between">
                <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
                  Model Number
                </p>
                <p
                  className="text-[14px] flex items-center leading-[14px] cursor-pointer font-[500] gap-2 text-blue-700"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/search?q=${
                        item?.sales_channel_listing?.brand +
                        " " +
                        item?.sales_channel_listing?.model_number
                      }`,
                      "_blank"
                    );
                  }}
                >
                  {item?.sales_channel_listing?.brand +
                    " " +
                    item?.sales_channel_listing?.model_number}

                  <FcGoogle className="h-4 w-4 cursor-pointer" />
                </p>
              </div>
            ) : (
              ""
            )}
            {item?.sales_channel_listing?.model_number ? (
              <div className="flex items-center  gap-3 p-2 justify-between">
                <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
                  Part Number
                </p>
                <p
                  className="text-[14px] flex items-center leading-[14px] cursor-pointer font-[500] gap-2 text-blue-700"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/search?q=${
                        item?.sales_channel_listing?.brand +
                        " " +
                        item?.sales_channel_listing?.part_number
                      }`,
                      "_blank"
                    );
                  }}
                >
                  {item?.sales_channel_listing?.brand +
                    " " +
                    item?.sales_channel_listing?.part_number}

                  <FcGoogle className="h-4 w-4 cursor-pointer" />
                </p>
              </div>
            ) : (
              ""
            )}
            {item?.sales_channel_listing?.identifiers?.map((identifier) => (
              <div className="flex items-center  gap-3 p-2 justify-between">
                <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
                  {identifier?.identifierType}
                </p>
                <p
                  className="text-[14px] flex items-center leading-[14px] cursor-pointer font-[500] gap-2 text-blue-700"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/search?q=${identifier?.identifier}`,
                      "_blank"
                    );
                  }}
                >
                  {identifier?.identifier}
                  <FcGoogle className="h-4 w-4 cursor-pointer" />
                </p>
              </div>
            ))}
          </div>
        </div>
      </Util.popUp>
    );
  };

  const theme =
    tabStatus === "targetListings"
      ? {
          "*fit-status": (item) => {
            const salesListing = item?.sales_channel_listing
              ? item?.sales_channel_listing
              : item?.sales_channel_listings?.[0];

            const modalListing = item;
            return (
              <div className=" gap-3   py-2 w-full flex flex-col justify-between items-center">
                {item.status ? (
                  statusList[item.status]
                ) : (
                  <div className="flex w-full justify-between gap-2">
                    <UI.Button
                      item={{
                        label: (
                          <div className={"flex items-center mr-2 flex-row"}>
                            <HiCheck /> Approve
                          </div>
                        ),
                        buttonType: "primary",
                        className: "h-[28px] !w-[97px] !px-[12px] !py-[5px]",
                      }}
                      onClick={() => {
                        handleApprovementCallback("listings", "approve", [
                          item?.id,
                        ]);
                      }}
                    />
                    <UI.Button
                      item={{
                        label: (
                          <div className={"flex items-center flex-row"}>
                            <HiX className="h-5 w-5 text-red-700" />
                          </div>
                        ),
                        buttonType: "icon",
                        className:
                          "h-[28px] !min-w-[28px] rounded-lg !border-red-700 !px-0 ",
                      }}
                      onClick={() => {
                        handleApprovementCallback("listings", "reject", [
                          item?.id,
                        ]);
                      }}
                    />
                  </div>
                )}

                <RefreshSales
                  handleRefreshListings={() => handleRefreshListings(item)}
                  item={item}
                  refreshing={refreshing}
                  date={salesListing?.updated_at}
                />
              </div>
            );
          },

          identifier: (item) => (
            <div className="flex flex-col w-full gap-2 py-[12px] ">
              <div className="flex justify-start">
                {/* <img
                className="h-[20px] object-cover"
                src="assets/images/channel-logo/image 4.png"
              /> */}

                {searchToolTip(item)}
                {item?.sold_by_us && (
                  <Util.popUp
                    toggle={(selected) =>
                      descriptiveContent(<Icons.fire />, "Sold By Arbitbox")
                    }
                    // leftSpace={style?.leftSpace}
                  >
                    <Offers id={item?.sales_channel_listing?.id} />
                  </Util.popUp>
                )}
                <Util.popUp
                  toggle={(selected) => (
                    <div className={"relative"}>
                      <UI.Button
                        item={{
                          buttonType: "flat",
                          className: `p-[2px]  ${activeStyle(
                            item?.notes?.length > 0
                          )}`,
                        }}
                      >
                        <Icons.salesNote />
                      </UI.Button>
                      {item?.notes?.length > 0 && (
                        <div
                          className={
                            "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                          }
                        >
                          {item?.notes?.length}
                        </div>
                      )}
                    </div>
                  )}
                  // leftSpace={style?.leftSpace}
                >
                  <div className={"w-[400px]"}>
                    <InternalNote
                      callBack={null}
                      notes={item?.notes}
                      id={item?.id}
                    />
                  </div>
                </Util.popUp>

                {!item?.sales_channel_listing?.available ? (
                  <Util.popUp
                    toggle={(selected) => (
                      <Icons.exclamationFill className="w-[20px] h-[20px] text-red-600" />
                    )}
                    action="hover"
                  >
                    <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                      Unavailable
                    </div>
                  </Util.popUp>
                ) : (
                  ""
                )}
              </div>
              <div className="">
                <p className="text-[12px] leading-[12px] font-[400] text-gray-600">
                  {descriptiveContent(
                    item?.sales_channel_listing?.identifier_type
                      ? item?.sales_channel_listing?.identifier_type
                      : "-",
                    "Identifier Type"
                  )}
                </p>
                {descriptiveContent(
                  <Util.copyText
                    className={"text-[14px] text-gray-900"}
                    text={item?.sales_channel_listing?.identifier}
                    hint={false}
                  >
                    {item?.sales_channel_listing?.identifier
                      ? item?.sales_channel_listing?.identifier
                      : "-"}
                  </Util.copyText>,
                  "Identifier Value"
                )}
              </div>
            </div>
          ),

          image: (item) => (
            <div
              className={
                "w-[65px] h-[64px] max-h-[64px] justify-center items-center flex"
              }
            >
              <ImageMagnifier
                src={
                  item?.sales_channel_listing?.main_image_path ||
                  "/assets/images/noImageWide.jpg"
                }
                className={"!max-h-[64px]"}
              />
            </div>
          ),
          title: (item) => {
            const modalListing = item;
            const asin_url = item?.asin_url;
            item = item?.sales_channel_listing;
            return (
              <div className="flex flex-col justify-between  w-full  p-[4px]">
                <div className="flex justify-between text-[14px] font-bold text-orange-700">
                  <div className="flex items-center gap-8 justify-between ">
                    <p>
                      {item?.["reviews_count"]
                        ? item?.["reviews_count"] + " Ratings"
                        : "No Ratings"}
                    </p>
                    <div className="flex items-center text-orange-600">
                      {item?.["ratings"] && <HiStar />}
                      &nbsp;
                      {item?.["ratings"]
                        ? (+item?.["ratings"])?.toFixed(1)
                        : "-"}
                    </div>
                  </div>
                  <div className="flex items-center text-orange-600">
                    <HiColorSwatch />
                    &nbsp;
                    {item?.["variation_count"]
                      ? item?.["variation_count"] + " Variants"
                      : "No variant"}
                  </div>
                </div>
                <div className="w-[370px] flex flex-row">
                  {/* {item?.['name'] ? item?.['name'] : 'No name'} */}
                  {item?.name ? (
                    <Util.expandableText
                      maxLines={2}
                      className={"text-[14px]"}
                      tooltip={true}
                    >
                      {item?.name}
                    </Util.expandableText>
                  ) : (
                    "No Name"
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center text-[14px] font-[400] text-blue-800">
                    {/* {item?.["asin"] ? item?.["asin"] : "No ASIN"} */}
                    <Util.copyText
                      className={"text-[14px] text-blue-700"}
                      text={item?.["identifier"]}
                      hint={false}
                      callBack={() => openListingModal(modalListing)}
                    >
                      {item?.["identifier"] ? item?.["identifier"] : "No ASIN"}
                    </Util.copyText>

                    <HiExternalLink
                      className="h-4 w-4 cursor-pointer hover:text-blue-500 text-gray-300"
                      onClick={() => {
                        if (asin_url) window.open(asin_url, "_blank");
                      }}
                    />
                  </div>
                  <div className="flex items-center text-[14px] font-[400] text-gray-900">
                    &nbsp;
                    {item?.["brand"] ? item?.["brand"] : "-"}
                    {item?.["brand"] &&
                      descriptiveContent(
                        <HiFilter
                          className="h-3 w-3 ml-1 text-gray-300 cursor-pointer"
                          onClick={() => {
                            onBrandFilter(encodeURIComponent(item?.["brand"]));
                          }}
                        />,
                        "Filter by brand"
                      )}
                  </div>
                </div>
              </div>
            );
          },
          "BUYBOX & est. sales": (item) => {
            item = item?.sales_channel_listing;
            const offerCount = item?.total_offer_count;
            return (
              <div className="flex flex-col space-y-[4px] w-[200px] !px-[8px] !py-[12px] ">
                <div className="flex items-center justify-between">
                  <Util.popUp
                    toggle={(selected) => (
                      <p
                        className={classNames(
                          item?.["buybox_price"]
                            ? "text-teal-600"
                            : "text-teal-200",
                          "text-[24px] leading-[24px] font-[500] flex items-center gap-1"
                        )}
                      >
                        {item?.["buybox_price"]
                          ? "$" + item?.["buybox_price"]
                          : "No BB"}
                      </p>
                    )}
                    action="hover"
                  >
                    <div className=" text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                      Buybox Price
                    </div>
                  </Util.popUp>

                  <div className="flex flex-col justify-end gap-[2px]">
                    {/* <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                      avg {consts.currency[item?.["bb_currency"]]}
                      {item?.["bb_avg_price"]}
                    </p> */}
                    <div className="  justify-between items-center gap-2.5 inline-flex">
                      {item?.buybox_seller_type ? (
                        <Util.popUp
                          toggle={(selected) => (
                            <div
                              className={`text-center ${
                                item?.buybox_seller_type === "FBM"
                                  ? "text-blue-700"
                                  : "text-orange-700"
                              } text-xs font-normal leading-3`}
                            >
                              {item?.buybox_seller_type}
                            </div>
                          )}
                          action="hover"
                        >
                          <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                            Buybox Owner Fulfillment Type
                          </div>
                        </Util.popUp>
                      ) : (
                        ""
                      )}
                      <Util.popUp
                        toggle={(selected) => (
                          <div
                            className="justify-between items-center gap-1 flex cursor-pointer"
                            onClick={() => {
                              window.open(
                                `https://www.amazon.com/gp/offer-listing/${item?.identifier}`,
                                "_blank"
                              );
                            }}
                          >
                            <svg
                              width="10"
                              height="8"
                              viewBox="0 0 10 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.799988 1.03848C0.799988 0.879347 0.863202 0.726734 0.975724 0.614213C1.08825 0.501691 1.24086 0.438477 1.39999 0.438477H8.59999C8.75912 0.438477 8.91173 0.501691 9.02425 0.614213C9.13677 0.726734 9.19999 0.879347 9.19999 1.03848C9.19999 1.19761 9.13677 1.35022 9.02425 1.46274C8.91173 1.57526 8.75912 1.63848 8.59999 1.63848H1.39999C1.24086 1.63848 1.08825 1.57526 0.975724 1.46274C0.863202 1.35022 0.799988 1.19761 0.799988 1.03848ZM0.799988 4.03848C0.799988 3.87935 0.863202 3.72673 0.975724 3.61421C1.08825 3.50169 1.24086 3.43848 1.39999 3.43848H8.59999C8.75912 3.43848 8.91173 3.50169 9.02425 3.61421C9.13677 3.72673 9.19999 3.87935 9.19999 4.03848C9.19999 4.19761 9.13677 4.35022 9.02425 4.46274C8.91173 4.57526 8.75912 4.63848 8.59999 4.63848H1.39999C1.24086 4.63848 1.08825 4.57526 0.975724 4.46274C0.863202 4.35022 0.799988 4.19761 0.799988 4.03848ZM4.39999 7.03848C4.39999 6.87935 4.4632 6.72673 4.57572 6.61421C4.68825 6.50169 4.84086 6.43848 4.99999 6.43848H8.59999C8.75912 6.43848 8.91173 6.50169 9.02425 6.61421C9.13677 6.72673 9.19999 6.87935 9.19999 7.03848C9.19999 7.19761 9.13677 7.35022 9.02425 7.46274C8.91173 7.57526 8.75912 7.63848 8.59999 7.63848H4.99999C4.84086 7.63848 4.68825 7.57526 4.57572 7.46274C4.4632 7.35022 4.39999 7.19761 4.39999 7.03848Z"
                                fill="#C81E1E"
                              />
                            </svg>
                            <div className="text-red-700 text-xs font-semibold leading-3">
                              {offerCount != null ? offerCount : "-"}
                            </div>
                          </div>
                        )}
                        action="hover"
                      >
                        <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                          Total Offers
                        </div>
                      </Util.popUp>
                    </div>
                    <Util.popUp
                      toggle={(selected) => (
                        <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                          low{" "}
                          <span className="text-[12px] leading-[12px] text-gray-500 text-end">
                            {item?.["bb_low_price"]
                              ? "$" + item?.["bb_low_price"]
                              : "-"}
                          </span>
                        </p>
                      )}
                      action="hover"
                    >
                      <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                        Lowest Price
                      </div>
                    </Util.popUp>
                  </div>
                </div>
                <hr />
                <div>
                  <div className="flex items-center justify-between">
                    <p
                      className={classNames(
                        item?.["category_rank_degree"]
                          ? "text-purple-600"
                          : "text-purple-200",
                        "text-[24px] leading-[24px] font-[500]"
                      )}
                    >
                      {item?.["category_rank_degree"]
                        ? item?.["category_rank_degree"]
                        : "-"}
                    </p>
                    <div className="flex flex-col justify-end">
                      <Util.popUp
                        toggle={(selected) => (
                          <p className="text-[12px] leading-[12px] text-rose-700 text-end">
                            {item?.["sales_rank"]
                              ? "#" + item?.["sales_rank"]
                              : "-"}
                          </p>
                        )}
                        action="hover"
                      >
                        <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                          Sales Rank
                        </div>
                      </Util.popUp>
                      {/* <div className="flex items-center justify-end">
                      {item?.["sales_channel_top"] ? (
                        <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                          top
                        </p>
                      ) : (
                        ""
                      )}
                      &nbsp;
                      <p className="text-[12px] leading-[12px] text-yellow-400 text-end">
                        {item?.["sales_channel_top"] ? item?.["sales_channel_top"] + '%' : "-"}
                      </p>
                    </div> */}
                    </div>
                  </div>
                  <div className="flex items-center justify-start text-[12px] leading-[12px] text-rose-700 font-[400]">
                    {/* <p className="text-gray-400">in&nbsp;</p>{" "} */}
                    {/* {item?.root_category ?? "N/A"} */}
                    {item?.root_category
                      ? item?.root_category
                          ?.split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : "N/A"}
                  </div>
                </div>
              </div>
            );
          },
          weight: (item) => (
            <div className={"flex flex-col gap-[4px]"}>
              <Weight
                weight={{
                  billable: item?.billable_weight,
                  actual: item?.actual_weight || "-", //item?.actual_weight != null ? item?.actual_weight :  "-",
                  dim: item?.dimensional_weight || "-", //item?.dim_weight != null ? item?.dim_weight : "-",
                  selected: item?.selected_weight || null,
                  orderId: item?.id,
                }}
              />
              <hr />
              {descriptiveContent(
                <div className=" flex-col justify-start items-center gap-0.5 inline-flex">
                  <div className="text-gray-400 text-xs font-normal leading-3">
                    {item?.package_measurements?.height || "-"} x{" "}
                    {item?.package_measurements?.width || "-"} x{" "}
                    {item?.package_measurements?.length || "-"}{" "}
                    {item?.package_measurements?.length_unit || "-"}
                  </div>
                  <div className="text-gray-400 text-xs font-normal leading-3">
                    {item?.package_measurements?.weight || "-"}{" "}
                    {item?.package_measurements?.weight_unit || "-"}
                  </div>
                </div>,
                "Package Measurements"
              )}
            </div>
          ),

          "supply cost": (item) => (
            <div className={"flex flex-col w-[100%] gap-[0px]"}>
              <SupplyCost
                cost={{
                  orderId: item?.id,
                  supply_cost: item?.supply_cost,
                  supply_cost_usd: item?.supply_cost_usd,
                  fba_max_ideal_cost: item?.fba_max_ideal_cost,
                  fbm_max_ideal_cost: item?.fbm_max_ideal_cost,
                }}
              />
            </div>
          ),

          "est. profit": (item) => (
            <>
              {item?.supply_cost ? (
                <div className="flex gap-x-4  justify-between">
                  <FbaFbmCals
                    item={{
                      upper_title: "FBA Margin",
                      upper_value: item?.fba_profit_tooltip?.find(
                        (tooltipItem) => tooltipItem.name === "Profit"
                      )?.margin
                        ? item?.fba_profit_tooltip?.find(
                            (tooltipItem) => tooltipItem.name === "Profit"
                          )?.margin + "%"
                        : "-",
                      upper_color: "orange",
                      upper_tooltip: item?.fba_profit_tooltip ? (
                        <Util.popUp
                          toggle={(selected) => (
                            <Icons.calculator className="text-gray-300 w-3 h-3 cursor-pointer " />
                          )}
                          // leftSpace={style?.leftSpace}
                        >
                          <div className={"min-w-max px-[8px] py-[4px]"}>
                            {item?.fba_profit_tooltip?.map(
                              (tooltipItem, index) => (
                                <div key={index} className="flex flex-col mb-1">
                                  <div className="flex justify-between items-center">
                                    <p className="text-[12px] font-[400] leading-[162%] font-inter text-black">
                                      {tooltipItem?.name === "Buybox Price" &&
                                      !item?.["bb_price"]
                                        ? "Average Price"
                                        : tooltipItem?.name}
                                    </p>
                                    <div className="flex items-center">
                                      <span className="leading-[162%] text-[12px] text-gray-400">
                                        {tooltipItem.margin
                                          ? tooltipItem.margin + "%"
                                          : ""}
                                      </span>
                                      <span
                                        className={`leading-[162%] text-[12px] ${
                                          tooltipItem.name === "Buybox Price"
                                            ? "text-black"
                                            : tooltipItem.name === "Profit"
                                            ? "text-[#03543F]"
                                            : "text-red-800"
                                        }  ${
                                          tooltipItem.name === "Profit"
                                            ? "border-t-2 border-gray-300 "
                                            : ""
                                        } ml-1`}
                                      >
                                        {tooltipItem?.value
                                          ? tooltipItem.name ===
                                              "Buybox Price" ||
                                            tooltipItem.name === "Profit"
                                            ? "$" + tooltipItem.value
                                            : "-$" + tooltipItem.value
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )
                            ) ?? ""}
                          </div>
                        </Util.popUp>
                      ) : null,
                      lower_title: "FBM Margin",
                      lower_value: item?.fbm_profit_tooltip?.find(
                        (tooltipItem) => tooltipItem.name === "Profit"
                      )?.margin
                        ? item?.fbm_profit_tooltip?.find(
                            (tooltipItem) => tooltipItem.name === "Profit"
                          )?.margin + "%"
                        : "-",

                      lower_color: "blue",
                      lower_tooltip: item?.fbm_profit_tooltip ? (
                        <Util.popUp
                          toggle={(selected) => (
                            <Icons.calculator className="text-gray-300 w-3 h-3 cursor-pointer " />
                          )}
                          // leftSpace={style?.leftSpace}
                        >
                          <div className={"min-w-max px-[8px] py-[4px]"}>
                            {item?.fbm_profit_tooltip?.map(
                              (tooltipItem, index) => (
                                <div key={index} className="flex flex-col mb-1">
                                  <div className="flex justify-between items-center">
                                    <p className="text-[12px] font-[400] leading-[162%] font-inter text-black">
                                      {tooltipItem?.name === "Buybox Price" &&
                                      !item?.["bb_price"]
                                        ? "Average Price"
                                        : tooltipItem?.name}
                                    </p>
                                    <div className="flex items-center">
                                      <span className="leading-[162%] text-[12px] text-gray-400">
                                        {tooltipItem.margin
                                          ? tooltipItem.margin + "%"
                                          : ""}
                                      </span>
                                      <span
                                        className={`leading-[162%] text-[12px] ${
                                          tooltipItem.name === "Buybox Price"
                                            ? "text-black"
                                            : tooltipItem.name === "Profit"
                                            ? "text-[#03543F]"
                                            : "text-red-800"
                                        }  ${
                                          tooltipItem.name === "Profit"
                                            ? "border-t-2 border-gray-300 "
                                            : ""
                                        } ml-1`}
                                      >
                                        {tooltipItem?.value
                                          ? tooltipItem.name ===
                                              "Buybox Price" ||
                                            tooltipItem.name === "Profit"
                                            ? "$" + tooltipItem.value
                                            : "-$" + tooltipItem.value
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )
                            ) ?? ""}
                          </div>
                        </Util.popUp>
                      ) : null,
                    }}
                  />

                  <FbaFbmCals
                    item={{
                      upper_title: "FBA Profit",
                      upper_value: item?.fba_profit_tooltip?.find(
                        (tooltipItem) => tooltipItem.name === "Profit"
                      )?.value
                        ? "$" +
                          item?.fba_profit_tooltip?.find(
                            (tooltipItem) => tooltipItem.name === "Profit"
                          )?.value
                        : "-",
                      lower_title: "FBM Profit",
                      lower_value: item?.fbm_profit_tooltip?.find(
                        (tooltipItem) => tooltipItem.name === "Profit"
                      )?.value
                        ? "$" +
                          item?.fbm_profit_tooltip?.find(
                            (tooltipItem) => tooltipItem.name === "Profit"
                          )?.value
                        : "-",
                    }}
                  />
                  <FbaFbmCals
                    item={{
                      upper_title: "FBA Min. Sell Price",
                      upper_value: item?.fba_min_sell_price
                        ? "$" + item?.fba_min_sell_price
                        : "-",
                      upper_tooltip: item?.fba_min_sell_price ? (
                        <Icons.calculator className="text-gray-300 w-3 h-3 cursor-pointer " />
                      ) : null,
                      lower_title: "FBM Min. Sell Price",
                      lower_value: item?.fbm_min_sell_price
                        ? "$" + item?.fbm_min_sell_price
                        : "-",
                      lower_tooltip: item?.fbm_min_sell_price ? (
                        <Icons.calculator className="text-gray-300 w-3 h-3 cursor-pointer " />
                      ) : null,
                    }}
                  />
                </div>
              ) : (
                <div className="w-[100%]">
                  <span className="text-gray-500 text-lg font-bold leading-[27px]">
                    Fill Supply Cost
                    <br />
                  </span>
                  <span className="text-gray-500 text-lg font-semibold leading-[27px]  ">
                    to See Profit Calculator
                  </span>
                </div>
              )}
            </>
          ),
        }
      : tabStatus === "prospectiveListings"
      ? {
          "*fit-status": (item) => (
            <div className=" gap-3  py-2 w-full flex flex-col justify-between items-center">
              {item.status ? (
                statusList[item.status]
              ) : (
                <div className="flex w-full justify-between gap-2">
                  <UI.Button
                    item={{
                      label: (
                        <div className={"flex items-center mr-2 flex-row"}>
                          <HiCheck /> Approve
                        </div>
                      ),
                      buttonType: "primary",
                      className: "h-[28px] !w-[97px] !px-[12px] !py-[5px]",
                    }}
                    onClick={() => {
                      handleApprovementCallback("listings", "approve", [
                        item?.id,
                      ]);
                    }}
                  />
                  <UI.Button
                    item={{
                      label: (
                        <div className={"flex items-center flex-row"}>
                          <HiX className="h-5 w-5 text-red-700" />
                        </div>
                      ),
                      buttonType: "icon",
                      className:
                        "h-[28px] !min-w-[28px] rounded-lg !border-red-700 !px-0 ",
                    }}
                    onClick={() => {
                      handleApprovementCallback("listings", "reject", [
                        item?.id,
                      ]);
                    }}
                  />
                </div>
              )}
              <div className="w-full flex flex-col ">
                <p className="text-[14px] leading-[14px] font-[400] text-gray-900">
                  {moment(item?.sales_channel_listing?.updated_at).format(
                    "DD.MM.YYYY"
                  )}
                </p>
                <div className="flex justify-between gap-2 items-center">
                  <p className="text-[12px] leading-[12px] text-gray-600">
                    {moment(item?.sales_channel_listing?.updated_at).format(
                      "hh:mm"
                    )}
                  </p>
                  <p className="text-[12px] font-[600] leading-[12px] text-gray-600">
                    {howLongFromNow(item?.sales_channel_listing?.updated_at)}
                  </p>
                </div>
              </div>
            </div>
          ),

          identifier: (item) => (
            <div className="flex flex-col w-full gap-2 py-[12px]">
              <div className="flex justify-start">
                {/* <img
                className="h-[20px] object-cover"
                src="assets/images/channel-logo/image 4.png"
              /> */}

                {searchToolTip(item)}
                {item?.sales_channel_listing?.sold_by_us && (
                  <Util.popUp
                    toggle={(selected) =>
                      descriptiveContent(<Icons.fire />, "Sold By Arbitbox")
                    }
                    // leftSpace={style?.leftSpace}
                  >
                    <Offers id={item?.sales_channel_listing?.id} />
                  </Util.popUp>
                )}
                <Util.popUp
                  toggle={(selected) => (
                    <div className={"relative"}>
                      <UI.Button
                        item={{
                          buttonType: "flat",
                          className: `p-[2px]  ${activeStyle(
                            item?.notes?.length > 0
                          )}`,
                        }}
                      >
                        <Icons.salesNote />
                      </UI.Button>
                      {item?.notes?.length > 0 && (
                        <div
                          className={
                            "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                          }
                        >
                          {item?.notes?.length}
                        </div>
                      )}
                    </div>
                  )}
                  // leftSpace={style?.leftSpace}
                >
                  <div className={"w-[400px]"}>
                    <InternalNote
                      callBack={null}
                      notes={item?.notes}
                      id={item?.id}
                    />
                  </div>
                </Util.popUp>
                {!item?.sales_channel_listing?.available ? (
                  <Util.popUp
                    toggle={(selected) => (
                      <Icons.exclamationFill className="w-[20px] h-[20px] text-red-600" />
                    )}
                    action="hover"
                  >
                    <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                      Unavailable
                    </div>
                  </Util.popUp>
                ) : (
                  ""
                )}
              </div>
              <div className="">
                <p className="text-[12px] leading-[12px] font-[400] text-gray-600">
                  {descriptiveContent(
                    item?.sales_channel_listing?.identifier_type
                      ? item?.sales_channel_listing?.identifier_type
                      : "-",
                    "Identifier Type"
                  )}
                </p>
                {descriptiveContent(
                  <Util.copyText
                    className={"text-[14px] text-gray-900"}
                    text={item?.sales_channel_listing?.identifier}
                    hint={false}
                  >
                    {item?.sales_channel_listing?.identifier
                      ? item?.sales_channel_listing?.identifier
                      : "-"}
                  </Util.copyText>,
                  "Identifier Value"
                )}
              </div>
            </div>
          ),

          image: (item) => (
            <div
              className={
                "w-[65px] h-[64px] max-h-[64px] justify-center items-center flex"
              }
            >
              <ImageMagnifier
                src={
                  item?.sales_channel_listing?.main_image_path ||
                  "/assets/images/noImageWide.jpg"
                }
                className={"!max-h-[64px]"}
              />
            </div>
          ),
          title: (item) => {
            const modalListing = item;
            const asin_url = item?.asin_url;
            item = item?.sales_channel_listing;
            return (
              <div className="flex flex-col justify-between  w-full  p-[4px]">
                <div className="flex justify-between text-[14px] font-bold text-orange-700">
                  <div className="flex items-center gap-8 justify-between ">
                    <p>
                      {item?.["reviews_count"]
                        ? item?.["reviews_count"] + " Ratings"
                        : "No Ratings"}
                    </p>
                    <div className="flex items-center text-orange-600">
                      {item?.["ratings"] && <HiStar />}
                      &nbsp;
                      {item?.["ratings"]
                        ? (+item?.["ratings"])?.toFixed(1)
                        : "-"}
                    </div>
                  </div>
                  <div className="flex items-center text-orange-600">
                    <HiColorSwatch />
                    &nbsp;
                    {item?.["variation_count"]
                      ? item?.["variation_count"] + " Variants"
                      : "No variant"}
                  </div>
                </div>
                <div className="w-[370px] flex flex-row">
                  {/* {item?.['name'] ? item?.['name'] : 'No name'} */}
                  {item?.name ? (
                    <Util.expandableText
                      maxLines={2}
                      className={"text-[14px]"}
                      tooltip={true}
                    >
                      {item?.name}
                    </Util.expandableText>
                  ) : (
                    "No Name"
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center text-[14px] font-[400] text-blue-800">
                    {/* {item?.["asin"] ? item?.["asin"] : "No ASIN"} */}
                    <Util.copyText
                      className={"text-[14px] text-blue-700"}
                      text={item?.["identifier"]}
                      hint={false}
                      callBack={() => openListingModal(modalListing)}
                    >
                      {item?.["identifier"] ? item?.["identifier"] : "No ASIN"}
                    </Util.copyText>

                    <HiExternalLink
                      className="h-4 w-4 text-gray-300 hover:text-blue-500 cursor-pointer"
                      onClick={() => {
                        if (asin_url) window.open(asin_url, "_blank");
                      }}
                    />
                  </div>
                  <div className="flex items-center text-[14px] font-[400] text-gray-900">
                    &nbsp;
                    {item?.["brand"] ? item?.["brand"] : "-"}
                    {item?.["brand"] &&
                      descriptiveContent(
                        <HiFilter
                          className="h-3 w-3 ml-1 text-gray-300 cursor-pointer"
                          onClick={() => {
                            onBrandFilter(encodeURIComponent(item?.["brand"]));
                          }}
                        />,
                        "Filter by brand"
                      )}
                  </div>
                </div>
              </div>
            );
          },
          "BUYBOX & est. sales": (item) => {
            item = item?.sales_channel_listing;
            const offerCount = item?.total_offer_count;
            return (
              <div className="flex flex-col space-y-[4px] w-[200px] !px-[8px] !py-[12px] ">
                <div className="flex items-center justify-between">
                  <Util.popUp
                    toggle={(selected) => (
                      <p
                        className={classNames(
                          item?.["buybox_price"]
                            ? "text-teal-600"
                            : "text-teal-200",
                          "text-[24px] leading-[24px] font-[500] flex items-center gap-1"
                        )}
                      >
                        {item?.["buybox_price"]
                          ? "$" + item?.["buybox_price"]
                          : "No BB"}
                      </p>
                    )}
                    action="hover"
                  >
                    <div className=" text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                      Buybox Price
                    </div>
                  </Util.popUp>

                  <div className="flex flex-col justify-end gap-[2px]">
                    {/* <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                      avg {consts.currency[item?.["bb_currency"]]}
                      {item?.["bb_avg_price"]}
                    </p> */}
                    <div className="  justify-between items-center gap-2.5 inline-flex">
                      {item?.buybox_seller_type ? (
                        <Util.popUp
                          toggle={(selected) => (
                            <div
                              className={`text-center ${
                                item?.buybox_seller_type === "FBM"
                                  ? "text-blue-700"
                                  : "text-orange-700"
                              } text-xs font-normal leading-3`}
                            >
                              {item?.buybox_seller_type}
                            </div>
                          )}
                          action="hover"
                        >
                          <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                            Buybox Owner Fulfillment Type
                          </div>
                        </Util.popUp>
                      ) : (
                        ""
                      )}
                      <Util.popUp
                        toggle={(selected) => (
                          <div
                            className="justify-between items-center gap-1 flex cursor-pointer"
                            onClick={() => {
                              window.open(
                                `https://www.amazon.com/gp/offer-listing/${item?.identifier}`,
                                "_blank"
                              );
                            }}
                          >
                            <svg
                              width="10"
                              height="8"
                              viewBox="0 0 10 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.799988 1.03848C0.799988 0.879347 0.863202 0.726734 0.975724 0.614213C1.08825 0.501691 1.24086 0.438477 1.39999 0.438477H8.59999C8.75912 0.438477 8.91173 0.501691 9.02425 0.614213C9.13677 0.726734 9.19999 0.879347 9.19999 1.03848C9.19999 1.19761 9.13677 1.35022 9.02425 1.46274C8.91173 1.57526 8.75912 1.63848 8.59999 1.63848H1.39999C1.24086 1.63848 1.08825 1.57526 0.975724 1.46274C0.863202 1.35022 0.799988 1.19761 0.799988 1.03848ZM0.799988 4.03848C0.799988 3.87935 0.863202 3.72673 0.975724 3.61421C1.08825 3.50169 1.24086 3.43848 1.39999 3.43848H8.59999C8.75912 3.43848 8.91173 3.50169 9.02425 3.61421C9.13677 3.72673 9.19999 3.87935 9.19999 4.03848C9.19999 4.19761 9.13677 4.35022 9.02425 4.46274C8.91173 4.57526 8.75912 4.63848 8.59999 4.63848H1.39999C1.24086 4.63848 1.08825 4.57526 0.975724 4.46274C0.863202 4.35022 0.799988 4.19761 0.799988 4.03848ZM4.39999 7.03848C4.39999 6.87935 4.4632 6.72673 4.57572 6.61421C4.68825 6.50169 4.84086 6.43848 4.99999 6.43848H8.59999C8.75912 6.43848 8.91173 6.50169 9.02425 6.61421C9.13677 6.72673 9.19999 6.87935 9.19999 7.03848C9.19999 7.19761 9.13677 7.35022 9.02425 7.46274C8.91173 7.57526 8.75912 7.63848 8.59999 7.63848H4.99999C4.84086 7.63848 4.68825 7.57526 4.57572 7.46274C4.4632 7.35022 4.39999 7.19761 4.39999 7.03848Z"
                                fill="#C81E1E"
                              />
                            </svg>
                            <div className="text-red-700 text-xs font-semibold leading-3">
                              {offerCount != null ? offerCount : "-"}
                            </div>
                          </div>
                        )}
                        action="hover"
                      >
                        <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                          Total Offers
                        </div>
                      </Util.popUp>
                    </div>
                    <Util.popUp
                      toggle={(selected) => (
                        <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                          low{" "}
                          <span className="text-[12px] leading-[12px] text-gray-500 text-end">
                            {item?.["bb_low_price"]
                              ? "$" + item?.["bb_low_price"]
                              : "-"}
                          </span>
                        </p>
                      )}
                      action="hover"
                    >
                      <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                        Lowest Price
                      </div>
                    </Util.popUp>
                  </div>
                </div>
                <hr />
                <div>
                  <div className="flex items-center justify-between">
                    <p
                      className={classNames(
                        item?.["category_rank_degree"]
                          ? "text-purple-600"
                          : "text-purple-200",
                        "text-[24px] leading-[24px] font-[500]"
                      )}
                    >
                      {item?.["category_rank_degree"]
                        ? item?.["category_rank_degree"]
                        : "-"}
                    </p>
                    <div className="flex flex-col justify-end">
                      <Util.popUp
                        toggle={(selected) => (
                          <p className="text-[12px] leading-[12px] text-rose-700 text-end">
                            {item?.["sales_rank"]
                              ? "#" + item?.["sales_rank"]
                              : "-"}
                          </p>
                        )}
                        action="hover"
                      >
                        <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                          Sales Rank
                        </div>
                      </Util.popUp>
                      {/* <div className="flex items-center justify-end">
                      {item?.["sales_channel_top"] ? (
                        <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                          top
                        </p>
                      ) : (
                        ""
                      )}
                      &nbsp;
                      <p className="text-[12px] leading-[12px] text-yellow-400 text-end">
                        {item?.["sales_channel_top"] ? item?.["sales_channel_top"] + '%' : "-"}
                      </p>
                    </div> */}
                    </div>
                  </div>
                  <div className="flex items-center justify-start text-[12px] leading-[12px] text-rose-700 font-[400]">
                    {/* <p className="text-gray-400">in&nbsp;</p>{" "} */}
                    {/* {item?.root_category ?? "N/A"} */}
                    {item?.root_category
                      ? item?.root_category
                          ?.split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : "N/A"}
                  </div>
                </div>
              </div>
            );
          },

          "TARGET SELLERS": (item) => (
            <div className="w-full px-[8px] py-[12px]  flex-col justify-between items-start gap-2 inline-flex">
              <div className="self-stretch h-[37px] flex-col justify-center items-start gap-px flex">
                <div className="text-gray-600 text-xs font-normal leading-3">
                  Target Seller Rate
                </div>
                <div className="self-stretch justify-start items-center gap-0.5 inline-flex">
                  <div className="text-red-700 text-2xl font-medium leading-normal">
                    {item?.approvement_percentage ||
                    item?.approvement_percentage === 0
                      ? item?.approvement_percentage + "%"
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-between items-start inline-flex">
                <div className="flex-col justify-center items-start gap-px inline-flex">
                  <div className="text-gray-600 text-xs font-normal leading-3">
                    Offers
                  </div>
                  <div className="justify-start items-center gap-0.5 inline-flex">
                    <div className="text-orange-700 text-2xl font-medium leading-normal">
                      {item?.offers ?? "-"}
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-center items-end gap-px inline-flex">
                  <div className="text-gray-600 text-xs font-normal leading-3">
                    Targets
                  </div>
                  <div className="justify-start items-center gap-0.5 inline-flex">
                    <div className="text-blue-700 text-2xl font-medium leading-normal">
                      {item?.total_sellers ?? "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
        }
      : {
          "*fit-status": (item) => (
            <div className=" gap-3 px-4 py-2 w-full flex flex-col justify-between items-center">
              {item.status ? (
                statusList[item.status]
              ) : (
                <div className="flex w-full justify-between gap-2">
                  <UI.Button
                    item={{
                      label: (
                        <div className={"flex items-center mr-2 flex-row"}>
                          <HiCheck /> Approve
                        </div>
                      ),
                      buttonType: "primary",
                      className: "h-[28px] !w-[97px] !px-[12px] !py-[5px]",
                    }}
                    onClick={() => {
                      handleApprovementCallback("sellers", "approve", [
                        item?.id,
                      ]);
                    }}
                  />
                  <UI.Button
                    item={{
                      label: (
                        <div className={"flex items-center flex-row"}>
                          <HiX className="h-5 w-5 text-red-700" />
                        </div>
                      ),
                      buttonType: "icon",
                      className:
                        "h-[28px] !min-w-[28px] rounded-lg !border-red-700 !px-0 ",
                    }}
                    onClick={() => {
                      handleApprovementCallback("sellers", "reject", [
                        item?.id,
                      ]);
                    }}
                  />
                </div>
              )}
              <div className="w-full flex flex-col ">
                <Util.dateDisplay item={item} date={item?.updated_at} />
              </div>
            </div>
          ),
          identifier: (item) => (
            <div className="flex flex-col gap-2 w-full py-[12px]">
              {/* <div className="flex justify-center">
                <img
                  className="h-[20px] object-cover"
                  src="assets/images/channel-logo/image 4.png"
                />
              </div> */}
              <p className="text-[12px] leading-[12px] font-[400] text-gray-600">
                {descriptiveContent(item?.seller_name ?? "-", "Seller name ")}
              </p>
              <p
                className="text-[14px] leading-[14px] font-[400] cursor-pointer text-blue-800"
                onClick={() => {
                  if (item?.seller_url) window.open(item?.seller_url, "_blank");
                }}
              >
                {descriptiveContent(item?.seller_id ?? "-", "Seller ID")}
              </p>
            </div>
          ),
          "SELLER ADDRESS": (item) => {
            return (
              <div className="flex flex-col gap-2 justify-between w-[390px] h-full p-[4px]">
                <div className="flex items-center text-[14px] font-bold text-orange-700 justify-start">
                  <p>
                    {item?.["lifetime_rating_count"]
                      ? item?.["lifetime_rating_count"] + " Lifetime Ratings"
                      : "No Rating"}
                  </p>
                  <div className="flex items-center ml-4 text-orange-600">
                    {item?.["lifetime_rating_value"] && <HiStar />}
                    &nbsp;
                    {item?.["lifetime_rating_value"]
                      ? item?.["lifetime_rating_value"] + "%"
                      : "-"}
                    {/* <HiStar />
                  &nbsp; 3.4 */}
                  </div>
                </div>
                {/* <div className="flex items-center text-orange-600">
                <HiColorSwatch />
                &nbsp;
                {item?.["variants"]
                  ? item?.["variants"] + " Variants"
                  : "No variant"}
              </div> */}
                <div className="flex flex-col">
                  <p className="text-[16px] font-[400] leading-6 text-gray-900">
                    {item?.business_name ?? "-"}
                  </p>
                  {item?.seller_address && (
                    <Util.expandableText
                      maxLines={3}
                      className={"text-[12px]"}
                      tooltip={true}
                    >
                      {item?.seller_address}
                    </Util.expandableText>
                  )}

                  {/* <p className="text-[14px] font-[400] leading-5 text-gray-700">
                    {item?.seller_address ?? "No Address"}
                  </p> */}
                </div>
              </div>
            );
          },
          ratings: (item) => (
            <div className="flex p-3 flex-col justify-center items-start gap-1">
              <div className="flex w-[270px] justify-between items-center">
                <span className="text-gray-500 font-normal  text-sm font-inter leading-4">
                  Last 30 Days:
                </span>
                <div className="flex gap-3 justify-between items-center">
                  <p className="text-orange-700 font-semibold  text-sm font-inter leading-4">
                    {item?.last_30_days_rating_count
                      ? item?.last_30_days_rating_count + " Ratings"
                      : "No Rating"}
                  </p>
                  <span className="text-green-600 flex items-center font-normal  text-sm font-inter leading-4">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.23919 2.38005C7.47919 1.64325 8.52159 1.64325 8.76079 2.38005L9.61679 5.01365C9.66908 5.17404 9.77075 5.3138 9.90724 5.41294C10.0437 5.51208 10.2081 5.56553 10.3768 5.56565H13.1464C13.9216 5.56565 14.2432 6.55765 13.6168 7.01365L11.3768 8.64085C11.24 8.74008 11.1382 8.88006 11.0859 9.04074C11.0335 9.20141 11.0334 9.37452 11.0856 9.53525L11.9416 12.1689C12.1816 12.9057 11.3376 13.5193 10.7096 13.0633L8.46959 11.4361C8.33295 11.3369 8.16843 11.2834 7.99959 11.2834C7.83074 11.2834 7.66622 11.3369 7.52959 11.4361L5.28959 13.0633C4.66239 13.5193 3.81919 12.9057 4.05839 12.1689L4.91439 9.53525C4.96652 9.37452 4.96643 9.20141 4.91412 9.04074C4.86181 8.88006 4.75996 8.74008 4.62319 8.64085L2.38399 7.01445C1.75759 6.55845 2.07999 5.56645 2.85439 5.56645H5.62319C5.79202 5.5665 5.95655 5.51312 6.0932 5.41397C6.22986 5.31482 6.33165 5.17497 6.38399 5.01445L7.23999 2.38085L7.23919 2.38005Z"
                        fill="#057A55"
                      />
                    </svg>
                    {item?.last_30_days_rating_value
                      ? item?.last_30_days_rating_value + "%"
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="flex w-[270px] justify-between items-center">
                <span className="text-gray-500 font-normal  text-sm font-inter leading-4">
                  Last 90 Days:
                </span>
                <div className="flex gap-3 justify-between items-center">
                  <p className="text-orange-700 font-semibold  text-sm font-inter leading-4">
                    {item?.last_90_days_rating_count
                      ? item?.last_90_days_rating_count + " Ratings"
                      : "No Rating"}
                  </p>
                  <span className="text-green-600 flex items-center font-normal  text-sm font-inter leading-4">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.23919 2.38005C7.47919 1.64325 8.52159 1.64325 8.76079 2.38005L9.61679 5.01365C9.66908 5.17404 9.77075 5.3138 9.90724 5.41294C10.0437 5.51208 10.2081 5.56553 10.3768 5.56565H13.1464C13.9216 5.56565 14.2432 6.55765 13.6168 7.01365L11.3768 8.64085C11.24 8.74008 11.1382 8.88006 11.0859 9.04074C11.0335 9.20141 11.0334 9.37452 11.0856 9.53525L11.9416 12.1689C12.1816 12.9057 11.3376 13.5193 10.7096 13.0633L8.46959 11.4361C8.33295 11.3369 8.16843 11.2834 7.99959 11.2834C7.83074 11.2834 7.66622 11.3369 7.52959 11.4361L5.28959 13.0633C4.66239 13.5193 3.81919 12.9057 4.05839 12.1689L4.91439 9.53525C4.96652 9.37452 4.96643 9.20141 4.91412 9.04074C4.86181 8.88006 4.75996 8.74008 4.62319 8.64085L2.38399 7.01445C1.75759 6.55845 2.07999 5.56645 2.85439 5.56645H5.62319C5.79202 5.5665 5.95655 5.51312 6.0932 5.41397C6.22986 5.31482 6.33165 5.17497 6.38399 5.01445L7.23999 2.38085L7.23919 2.38005Z"
                        fill="#057A55"
                      />
                    </svg>
                    {item?.last_90_days_rating_value
                      ? item?.last_90_days_rating_value + "%"
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="flex w-[270px] justify-between items-center">
                <span className="text-gray-500 font-normal  text-sm font-inter leading-4">
                  Last 365 Days:
                </span>
                <div className="flex gap-3 justify-between items-center">
                  <p className="text-orange-700 font-semibold  text-sm font-inter leading-4">
                    {item?.last_365_days_rating_count
                      ? item?.last_365_days_rating_count + " Ratings"
                      : "No Rating"}
                  </p>
                  <span className="text-green-600 flex items-center font-normal  text-sm font-inter leading-4">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.23919 2.38005C7.47919 1.64325 8.52159 1.64325 8.76079 2.38005L9.61679 5.01365C9.66908 5.17404 9.77075 5.3138 9.90724 5.41294C10.0437 5.51208 10.2081 5.56553 10.3768 5.56565H13.1464C13.9216 5.56565 14.2432 6.55765 13.6168 7.01365L11.3768 8.64085C11.24 8.74008 11.1382 8.88006 11.0859 9.04074C11.0335 9.20141 11.0334 9.37452 11.0856 9.53525L11.9416 12.1689C12.1816 12.9057 11.3376 13.5193 10.7096 13.0633L8.46959 11.4361C8.33295 11.3369 8.16843 11.2834 7.99959 11.2834C7.83074 11.2834 7.66622 11.3369 7.52959 11.4361L5.28959 13.0633C4.66239 13.5193 3.81919 12.9057 4.05839 12.1689L4.91439 9.53525C4.96652 9.37452 4.96643 9.20141 4.91412 9.04074C4.86181 8.88006 4.75996 8.74008 4.62319 8.64085L2.38399 7.01445C1.75759 6.55845 2.07999 5.56645 2.85439 5.56645H5.62319C5.79202 5.5665 5.95655 5.51312 6.0932 5.41397C6.22986 5.31482 6.33165 5.17497 6.38399 5.01445L7.23999 2.38085L7.23919 2.38005Z"
                        fill="#057A55"
                      />
                    </svg>
                    {item?.last_365_days_rating_value
                      ? item?.last_365_days_rating_value + "%"
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          ),
          "target rate": (item) => (
            <div className="flex h-104 p-3 flex-col justify-center items-start gap-1 self-stretch">
              <div className="flex justify-between items-start self-stretch">
                <span className="text-blue-700 font-bold  text-3xl font-inter leading-10">
                  {descriptiveContent(
                    item?.listings_approvement_percentage ||
                      item?.listings_approvement_percentage === 0
                      ? item?.listings_approvement_percentage + "%"
                      : "-",
                    " Listings Approvement Percentage"
                  )}
                </span>
                <div className="flex flex-col justify-between items-start self-stretch">
                  <span className="text-red-700 font-normal  text-sm font-inter leading-4">
                    {descriptiveContent(
                      item?.listings_rejection_percentage ||
                        item?.listings_rejection_percentage === 0
                        ? item?.listings_rejection_percentage + "%"
                        : "-",
                      "Listings Rejection Percentage"
                    )}
                  </span>
                  <span className="text-gray-700 font-normal  text-sm font-inter leading-4">
                    {descriptiveContent(
                      item?.listings_pending_percentage ||
                        item?.listings_pending_percentage === 0
                        ? item?.listings_pending_percentage + "%"
                        : "-",
                      "Listings Pending Percentage"
                    )}
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-start">
                <span className="text-gray-600 font-normal  text-xs font-inter leading-3">
                  Approved
                </span>
                <span className="text-gray-700 font-normal  text-sm font-inter leading-4">
                  <span className="text-blue-600 font-bold text-sm font-inter leading-[150%]">
                    {item?.approved_listings || item?.approved_listings === 0
                      ? item?.approved_listings
                      : "-"}
                  </span>{" "}
                  of{" "}
                  <span className="text-blue-600 font-bold text-sm font-inter leading-[150%]">
                    {item?.total_listings ? item?.total_listings : "-"}
                  </span>{" "}
                  Listings
                </span>
              </div>
            </div>
          ),
          "*fit-modals": (item) => (
            <div className="flex h-104 p-3 flex-col justify-center items-start gap-2 self-stretch">
              <span
                className="text-blue-700 font-normal  text-sm font-inter cursor-pointer leading-[150%]"
                onClick={() => {
                  handleCallBackActions(
                    item?.primary_brands,
                    "Brand",
                    item?.seller_name
                  );
                }}
              >
                Primary Brands
              </span>
              <span
                className="text-blue-700 font-normal  text-sm font-inter cursor-pointer leading-[150%]"
                onClick={() => {
                  handleCallBackActions(
                    item?.primary_categories,
                    "Category",
                    item?.seller_name
                  );
                }}
              >
                Primary Categories
              </span>
            </div>
          ),
        };

  return {
    list: list,
    // theme:
    theme: theme,
  };
};
export default TableView;

const CopyIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 20 20"
    className="w-3 h-3 "
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
    <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
  </svg>
);

const statusList = {
  listing_rating: 34,
  listing_variants: 12,
  listing_rating_count: 12,
  name: "LaModaHome Star 27'' Turkish Backgammon Set, Elite Vinyl Wooden Classical Sahara Design, King Size Board Game Tavla, Poliester, Magnetic Closing Mechanism, Unscratchable Adults Kids, Café",
  brand: "LaModaHome",
  employee: {},
};
