import ImageMagnifier from "../../../../../dynamic-page/debt/magnifier";

import Components from "../../../../components";
import priceHandler from "../../../../../../utils/priceHandler";
import util from "../../../../../dynamic-page/util";

import Icons from "../../../../icons";
import React from "react";
import DynamicForm from "../../../../../dynamic-form";
import { AiOutlineInfoCircle } from "react-icons/ai";
import connectProductFrom from "../../../../../dynamic-page/components/connectProductForm";
import descriptiveContent from "../../../../../../utils/descriptiveContent";

const tableView = (list, action, isView, is_manual) => {
  let viewList = {};
  viewList = isView
    ? is_manual
      ? {
          image: (item) => (
            <div
              className={
                "w-[96px] h-[96px] flex flex-col justify-center items-center"
              }
            >
              <ImageMagnifier
                src={
                  item?.image ||
                  item?.item_image ||
                  item?._data?.arbitbox_product_id?.data?.image
                }
                className={"!max-h-[96px]"}
              />
            </div>
          ),
          item: (item) => (
            <div className={"flex flex-row flex-1 h-[100px] w-[490px]"}>
              <div
                className={" gap-[4px] flex flex-col justify-between w-full"}
              >
                {item?.title && (
                  <div className={"group"}>
                    <span className="text-blue-600 font-[900] text-[16px]">
                      {item?._data?.arbitbox_product_id?.data?.brand?.name}
                    </span>
                    <util.truncateText
                      maxLines={2}
                      className={
                        "text-[14px] text-gray-900 font-semibold w-[100%] leading-150"
                      }
                    >
                      {item?.title /*|| item?.item_title*/}
                    </util.truncateText>
                  </div>
                )}

                <div className={"flex justify-between w-full"}>
                  {item?._data?.arbitbox_product_id?.data?.brand?.assigned ? <div className="flex justify-center items-center gap-2 text-gray-600">
                    <img
                      src={
                        item?._data?.arbitbox_product_id?.data?.brand?.assigned
                          ?.profile_image || "/assets/images/defaultAvatar.png"
                      }
                      className={"w-[20px] h-[20px] rounded-full object-cover"}
                    />
                    {util.upperCaseString(
                      item?._data?.arbitbox_product_id?.data?.brand?.assigned
                        ?.name,
                      "word"
                    )}
                  </div> : <div className="text-gray-400">Deleted User</div>}
                  <div className="flex items-center gap-[2px] text-blue-700 text-[14px]">
                    <span
                      className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium ${
                        item?._data?.arbitbox_product_id?.data?.type === 1
                          ? "text-teal-700 bg-teal-100"
                          : "text-indigo-700 bg-indigo-100"
                      } p-[0px]`}
                    >
                      {item?._data?.arbitbox_product_id?.data?.type === 1
                        ? "S"
                        : "G"}
                    </span>
                    <span
                      onClick={() => {
                        window.open(
                          `/product/${item?._data?.arbitbox_product_id?.data?.id}`,
                          "_blank"
                        );
                      }}
                    >
                      {item?._data?.arbitbox_product_id?.data?.apid}
                    </span>
                    <util.copyText
                      className={"text-[12px]"}
                      text={item?._data?.arbitbox_product_id?.data?.apid}
                      hint={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
          qty: (item) => (
            // <Components.numberPriorityColor number={item?.qty} color={item?.item_qty_color_code} className={"text-[14px]"}/>
            <div className="w-[100px] h-full  flex justify-center items-center inline-flex">
              <div className="flex-col justify-start items-start gap-1 flex">
                <div className="text-gray-900 text-xs font-medium font-['Inter'] leading-3">
                  Quantity
                </div>
                <Components.numberPriorityColor
                  number={item?.qty}
                  color={item?.item_qty_color_code}
                  className={"text-[14px]"}
                />
              </div>
            </div>
          ),
          cost: (item) => (
            <div className="w-[120px] h-[100px]    flex-col justify-between items-start inline-flex">
              <div className="flex-col justify-start items-start gap-1 flex">
                <div className="text-gray-900 text-xs font-medium font-['Inter'] leading-3">
                  Cost
                </div>
                <div className="justify-start items-center gap-0.5 inline-flex">
                  {item?._data?.arbitbox_product_id?.data?.average_cost_usd &&
                  item?.qty ? (
                    <div className="text-pink-700 text-2xl font-bold font-['Inter'] leading-normal">
                      $
                      {(
                        +item?._data?.arbitbox_product_id?.data
                          ?.average_cost_usd * item?.qty
                      ).toFixed(2)}
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="self-stretch h-[26px] flex-col justify-center items-start gap-0.5 flex">
                <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                  {item?._data?.arbitbox_product_id?.data?.average_cost_try} TRY
                </div>
                <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                  ${item?._data?.arbitbox_product_id?.data?.average_cost_usd} x{" "}
                  {item?.qty}
                </div>
              </div>
            </div>
          ),

          "bill/ weight": (item) => (
            <div className="w-[120px] h-[100px]    flex-col justify-between items-start inline-flex">
              <div className="flex-col justify-start items-start gap-1 flex">
                <div className="text-gray-900 text-xs font-medium font-['Inter'] leading-3">
                  Bill. Weight
                </div>
                <div className="justify-start items-center gap-0.5 inline-flex">
                  <div className="text-violet-700 text-2xl font-bold font-['Inter'] leading-normal">
                    {item?._data?.arbitbox_product_id?.data?.fbm_billable_weight
                      ? (
                          +item?.qty *
                          +item?._data?.arbitbox_product_id?.data
                            ?.fbm_billable_weight
                        ).toFixed(2)
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="self-stretch h-[26px] flex-col justify-center items-start gap-0.5 flex">
                <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                  {item?._data?.arbitbox_product_id?.data?.actual_weight || "-"}{" "}
                  kg
                </div>
                <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                  {item?._data?.arbitbox_product_id?.data?.package_dimensions
                    ?.length > 0
                    ? `${item?._data?.arbitbox_product_id?.data?.package_dimensions?.length} x ${item?._data?.arbitbox_product_id?.data?.package_dimensions?.width} x ${item?._data?.arbitbox_product_id?.data?.package_dimensions?.height} cm`
                    : "-"}
                </div>
              </div>
            </div>
          ),

          "wh. inventory": (item) => (
            <div className="w-[120px] h-[100px]    flex-col justify-between items-start inline-flex">
              <div className="flex-col justify-start items-start gap-1 flex">
                <div className="text-gray-900 text-xs font-medium font-['Inter'] leading-3">
                  Wh. Inventory
                </div>
                <div className="justify-start items-center gap-0.5 inline-flex">
                  <div className="text-orange-700 text-2xl font-bold font-['Inter'] leading-normal">
                    {item?._data?.arbitbox_product_id?.data?.inventory_count}
                  </div>
                </div>
              </div>
              <div className="self-stretch h-[26px] flex-col justify-center items-start gap-0.5 flex">
                {item?._data?.arbitbox_product_id?.data?.inventory_detail
                  ?.length > 0 ? (
                  item?._data?.arbitbox_product_id?.data?.inventory_detail?.map(
                    (item) => (
                      <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                        {item?.available_quantity} @ {item?.name?.split(" ")[0]}
                      </div>
                    )
                  )
                ) : (
                  <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                    -
                  </div>
                )}
              </div>
            </div>
          ),
        }
      : {
          image: (item) => (
            <div
              className={
                "w-[96px] h-[96px] flex flex-col justify-center items-center"
              }
            >
              <ImageMagnifier
                src={
                  item?.image ||
                  item?.item_image ||
                  item?._data?.arbitbox_product_id?.data?.image
                }
                className={"!max-h-[96px]"}
              />
            </div>
          ),

          item: (item) => (
            <div className={"w-[290px] h-full gap-2 flex flex-col"}>
              <div className={"flex flex-row gap-[8px] justify-between"}>
                <util.truncateText
                  maxLines={2}
                  className={"text-[12px] text-gray-900 font-medium"}
                  tooltip={true}
                >
                  {item?.title}
                </util.truncateText>
              </div>

              <div className={"flex justify-between"}>
                <div className={"flex flex-row gap-[4px] text-gray-600"}>
                  {item?.sku}
                  <util.copyText
                    className={"text-[12px]"}
                    text={item?.sku}
                    hint={false}
                  />
                </div>
              </div>
            </div>
          ),
          qty: (item) => (
            // <Components.numberPriorityColor number={item?.qty} color={item?.item_qty_color_code} className={"text-[14px]"}/>
            <div className="w-[90px] h-[100px]  flex-col justify-between items-start inline-flex">
              <div className="flex-col justify-start items-start gap-1 flex">
                <div className="text-gray-900 text-xs font-medium font-['Inter'] leading-3">
                  Quantity
                </div>
                <Components.numberPriorityColor
                  number={item?.qty}
                  color={item?.item_qty_color_code}
                  className={"text-[14px]"}
                />
              </div>
              <div className="flex-col justify-start items-start gap-0.5 flex">
                <div className="flex-col justify-center items-start gap-0.5 flex">
                  <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                    {priceHandler(
                      item?.unit_price,
                      item?._data?.unit_price_currency?.data
                    )}{" "}
                    x {item?.qty}
                  </div>
                  <div className="flex-col justify-center items-start gap-0.5 flex">
                    <div className="text-gray-900 text-xs font-medium font-['Inter'] leading-3">
                      {priceHandler(
                        item?.total_price,
                        item?._data?.unit_price_currency?.data
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),

          product: (item) => {
            return (
              <div
                className={
                  "flex flex-row gap-[16px] items-center justify-start w-full"
                }
              >
                {item?.arbitbox_product_id ? (
                  <>
                    {/* product */}
                    <div className={"flex flex-row flex-1 h-[100px] w-[490px]"}>
                      <div
                        className={
                          " gap-[4px] flex flex-col justify-between w-full"
                        }
                      >
                        {item?.title && (
                          <div className={"group"}>
                            <span className="text-blue-600 font-[900] text-[16px]">
                              {
                                item?._data?.arbitbox_product_id?.data?.brand
                                  ?.name
                              }
                            </span>
                            <util.truncateText
                              maxLines={2}
                              tooltip={true}
                              className={
                                "text-[14px] text-gray-900 font-semibold w-[100%] leading-150"
                              }
                            >
                              {item?.title /*|| item?.item_title*/}
                            </util.truncateText>
                            {/* <div className={"group-hover:visible w-[30px]"}>
                                <util.copyText className={"text-[12px]"}  mainClassName={"w-[15px]"} text={item?.title} hint={false} />
                            </div> */}
                          </div>
                        )}

                        <div className={"flex justify-between w-full"}>
                          {item?._data?.arbitbox_product_id?.data?.brand
                                ?.assigned ? <div className="flex justify-center items-center gap-2 text-gray-600">
                            <img
                              src={
                                item?._data?.arbitbox_product_id?.data?.brand
                                  ?.assigned?.profile_image ||
                                "/assets/images/defaultAvatar.png"
                              }
                              className={
                                "w-[20px] h-[20px] rounded-full object-cover"
                              }
                            />
                            {util.upperCaseString(
                              item?._data?.arbitbox_product_id?.data?.brand
                                ?.assigned?.name,
                              "word"
                            )}
                          </div> : <div className="text-gray-400">Deleted User</div>}
                          <div className="flex items-center gap-[2px] text-blue-700 text-[14px]">
                            <util.popUp
                              toggle={(selected) => (
                                <div className={"flex flex-row gap-[2px]"}>
                                  <span
                                    className="w-[20px] h-[20px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]"
                                    onClick={() => {
                                      action({
                                        action: "disconnectProduct",
                                        data: item,
                                      });
                                    }}
                                  >
                                    <Icons.link
                                      className={"w-[11px] h-[11px]"}
                                    />
                                  </span>
                                </div>
                              )}
                              className={"!overflow-visible"}
                              innerClassName={"!overflow-visible"}
                              action={"hover"}
                            >
                              Disconnect Product
                            </util.popUp>

                            <span
                              className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium ${
                                item?._data?.arbitbox_product_id?.data?.type ===
                                1
                                  ? "text-teal-700 bg-teal-100"
                                  : "text-indigo-700 bg-indigo-100"
                              } p-[0px]`}
                            >
                              {item?._data?.arbitbox_product_id?.data?.type ===
                              1
                                ? "S"
                                : "G"}
                            </span>
                            <span
                              onClick={() => {
                                window.open(
                                  `/product/${item?._data?.arbitbox_product_id?.data?.id}`,
                                  "_blank"
                                );
                              }}
                            >
                              {item?._data?.arbitbox_product_id?.data?.apid}
                            </span>
                            <util.copyText
                              className={"text-[12px]"}
                              text={
                                item?._data?.arbitbox_product_id?.data?.apid
                              }
                              hint={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* cost , weight , inventory */}

                    <div className="w-[120px] h-[100px]    flex-col justify-between items-start inline-flex">
                      <div className="flex-col justify-start items-start gap-1 flex">
                        <div className="text-gray-900 text-xs font-medium font-['Inter'] leading-3">
                          Cost
                        </div>
                        <div className="justify-start items-center gap-0.5 inline-flex">
                          {item?._data?.arbitbox_product_id?.data
                            ?.average_cost_usd && item?.qty ? (
                            <div className="text-pink-700 text-2xl font-bold font-['Inter'] leading-normal">
                              $
                              {(
                                +item?._data?.arbitbox_product_id?.data
                                  ?.average_cost_usd * item?.qty
                              ).toFixed(2)}
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="self-stretch h-[26px] flex-col justify-center items-start gap-0.5 flex">
                        {descriptiveContent(
                          <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                            {
                              item?._data?.arbitbox_product_id?.data
                                ?.average_cost_try
                            }{" "}
                            TRY
                          </div>,
                          "Average Cost TRY"
                        )}
                        {descriptiveContent(
                          <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                            $
                            {
                              item?._data?.arbitbox_product_id?.data
                                ?.average_cost_usd
                            }{" "}
                            x {item?.qty}
                          </div>,
                          "Average Cost USD"
                        )}
                      </div>
                    </div>

                    <div className="w-[120px] h-[100px]    flex-col justify-between items-start inline-flex">
                      <div className="flex-col justify-start items-start gap-1 flex">
                        <div className="text-gray-900 text-xs font-medium font-['Inter'] leading-3">
                          Bill. Weight
                        </div>
                        <div className="justify-start items-center gap-0.5 inline-flex">
                          <div className="text-violet-700 text-2xl font-bold font-['Inter'] leading-normal">
                            {item?._data?.arbitbox_product_id?.data
                              ?.fbm_billable_weight
                              ? (
                                  +item?.qty *
                                  +item?._data?.arbitbox_product_id?.data
                                    ?.fbm_billable_weight
                                ).toFixed(2)
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch h-[26px] flex-col justify-center items-start gap-0.5 flex">
                        <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                          {item?._data?.arbitbox_product_id?.data
                            ?.fbm_actual_weight || "-"}{" "}
                          kg
                        </div>
                        <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                          {item?._data?.arbitbox_product_id?.data
                            ?.package_dimensions?.length > 0
                            ? `${item?._data?.arbitbox_product_id?.data?.package_dimensions?.length} x ${item?._data?.arbitbox_product_id?.data?.package_dimensions?.width} x ${item?._data?.arbitbox_product_id?.data?.package_dimensions?.height} cm`
                            : "-"}
                        </div>
                      </div>
                    </div>

                    <div className="w-[120px] h-[100px]    flex-col justify-between items-start inline-flex">
                      <div className="flex-col justify-start items-start gap-1 flex">
                        <div className="text-gray-900 text-xs font-medium font-['Inter'] leading-3">
                          Wh. Inventory
                        </div>
                        <div className="justify-start items-center gap-0.5 inline-flex">
                          <div className="text-orange-700 text-2xl font-bold font-['Inter'] leading-normal">
                            {
                              item?._data?.arbitbox_product_id?.data
                                ?.inventory_count
                            }
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch h-[26px] flex-col justify-center items-start gap-0.5 flex">
                        {item?._data?.arbitbox_product_id?.data
                          ?.inventory_detail?.length > 0 ? (
                          item?._data?.arbitbox_product_id?.data?.inventory_detail?.map(
                            (item) => (
                              <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                                {item?.available_quantity} @{" "}
                                {item?.name?.split(" ")[0]}
                              </div>
                            )
                          )
                        ) : (
                          <div className="text-gray-400 text-xs font-medium font-['Inter'] leading-3">
                            -
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className={
                      "flex flex-row gap-[16px] items-center justify-start w-full"
                    }
                  >
                    <div
                      className={
                        "flex flex-col gap-[10px] items-center justify-start w-[100%]"
                      }
                    >
                      <div className="text-red-800 text-[12px] font-medium leading-3 w-full items-center gap-[4px] flex flex-row">
                        <AiOutlineInfoCircle className="h-5 w-5" />
                        CONNECT PRODUCT TO GET COST, WEIGHT AND INVENTORY
                      </div>
                      <div className="flex-row flex justify-start w-full text-[14px] gap-[8px]">
                        <DynamicForm
                          items={connectProductFrom()}
                          onChange={(d, bind) => {
                            if (bind?.product) {
                              const data = {
                                ...item,
                                arbitbox_product_id: d?.product,
                                sku: item?.sku || item?.item_sku,
                                _data: {
                                  ...item._data,
                                  arbitbox_product_id: {
                                    data: bind?.product?.data,
                                  },
                                },
                              };
                              action({
                                action: "connectProduct",
                                data: data,
                              });
                            }
                          }}
                          style={{ label: { className: "hidden" } }}
                        />
                      </div>
                      <div
                        className="text-blue-700 text-[14px] font-normal leading-none w-full hover:text-blue-800 cursor-pointer"
                        onClick={() => {
                          //window.open(`/product/single_products#action=create&title=${item?.title}&image=${item?.image}`, '_blank')
                          window.open(
                            `/product/list#action=add&title=${item?.title}&image=${item?.image}`,
                            "_blank"
                          );
                        }}
                      >
                        Didn’t find? Create new.
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          },
        }
    : {
        image: (item) => (
          <div
            className={"w-[54px]  flex flex-col justify-center items-center"}
          >
            <ImageMagnifier
              src={
                item?.image ||
                item?.item_image ||
                item?._data?.product?.product?.image
              }
              className={"!max-h-[54px]"}
            />
          </div>
        ),
        item: (item) => (
          <div className={"flex flex-row flex-1 w-[490px]"}>
            <div className={" gap-[4px] flex flex-col w-full"}>
              {item?._data?.product?.product?.title && (
                <div className={"group"}>
                  <util.expandableText
                    maxLines={2}
                    className={"text-[16px] w-[100%] leading-150"}
                  >
                    {
                      item?._data?.product?.product
                        ?.title /*|| item?.item_title*/
                    }
                  </util.expandableText>
                  {/* <div className={"group-hover:visible w-[30px]"}>
                                <util.copyText className={"text-[12px]"}  mainClassName={"w-[15px]"} text={item?._data?.product?.product?.title} hint={false} />
                            </div> */}
                </div>
              )}
            </div>
          </div>
        ),
        assign: (item) => (
          item?._data?.product?.product?.brand?.assigned ?  <div className="w-[135px] h-[21px] justify-center items-center gap-1 inline-flex">
            <div className="w-[18px] h-[18px] rounded-2xl justify-start items-start gap-2.5 flex">
              <img
                className="grow shrink rounded-full basis-0 self-stretch"
                src={
                  item?._data?.product?.product?.brand?.assigned?.profile_image
                }
              />
            </div>
            <div className="text-gray-600 text-sm font-normal font-['Inter'] leading-[21px]">
              {item?._data?.product?.product?.brand?.assigned?.name}
            </div>
          </div> : <div className="text-gray-400">Deleted User</div>
        ),
        quantity: (item) => (
          <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
            <Components.saveWeight
              text={item?.qty || ""}
              buttonClassName={"!h-[12px] !w-[12px]"}
              onSave={(data) => {
                action({
                  action: "editItem",
                  data: {
                    ...item,
                    qty: data,
                  },
                });
              }}
              className={
                "min-w-max whitespace-no-wrap group-active:text-gray-800 hover:text-gray-600 flex text-[14px] text-gray-500 font-[500]"
              }
            />
          </div>
        ),
        "act. weight": (item) => (
          <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
            {item?._data?.product?.product?.fbm_actual_weight
              ? (
                  +item?._data?.product?.product?.fbm_actual_weight * +item?.qty
                ).toFixed(2)
              : "-"}
          </div>
        ),
        "dim. weight": (item) => (
          <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-[21px]">
            {item?._data?.product?.product?.fbm_dimensional_weight
              ? (
                  +item?._data?.product?.product?.fbm_dimensional_weight *
                  +item?.qty
                ).toFixed(2)
              : "-"}
          </div>
        ),
        delete: (item) => (
          <div className="text-gray-500 w-[54px] flex justify-center text-sm font-normal font-['Inter'] leading-[21px]">
            <Icons.trashBin
              className={
                "w-[18px] h-[18px]  text-red-600 cursor-pointer hover:text-red-700"
              }
              onClick={() => {
                action({
                  action: "removeItem",
                  data: item,
                });
              }}
            />
          </div>
        ),
      };

  const initialTheme = () => {
    return isView
      ? is_manual
        ? {
            // actions: viewList["actions"],
            image: viewList["image"],

            item: viewList["item"],
            qty: viewList["qty"],
            //price: viewList["price"],
            cost: viewList["cost"],
            "bill. weight": viewList["bill/ weight"],
            "wh. inventory": viewList["wh. inventory"],
          }
        : {
            image: viewList["image"],

            item: viewList["item"],
            qty: viewList["qty"],
            product: viewList["product"],
          }
      : {
          image: viewList["image"],

          item: viewList["item"],
          quantity: viewList["quantity"],
          assign: viewList["assign"],
          "act. Weight": viewList["act. weight"],
          "dim. Weight": viewList["dim. weight"],
          delete: viewList["delete"],
        };
  };

  const filteredList = isView
    ? list.filter((item) => +item?.unit_price >= 0)
    : list;

  return {
    list: filteredList,
    theme: initialTheme(),
  };
};
export default tableView;
