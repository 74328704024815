import * as Yup from "yup";
import React, {useState} from "react";
import constants from "../../../constant";

import UI from "../../dynamic-form/elements";


import ProductImage from "../../dynamic-form/components/productImage";
import axios from "axios";
import Select from "../../dynamic-form/elements/Select";

const formView = (item) => {

    const innerSubmit = item?.id ?
        [
            {
                label: "Save Product",
                type: "primary",
                action: "save",
            },
        ]
        :
        [
            {
                label: "Create & New Product",
                type: "alt",
                action: "saveAndNew",
            },
            {
                label: "Create & Review",
                type: "primary",
                action: "saveAndOpen",
            },

        ]

    return ({
        title: item?.id ? "Edit Product" : "Create Product",
        submit: innerSubmit,
        close: !item?.id,
        cancel: !item?.id ? false : true,
        viewModel: (formDefaultValue) => (
            [

                {
                    name: "type",
                    type: "RadioButton",
                    disabled: !item?.id ? false : true,
                    defaultValue: formDefaultValue?.type || 1,
                    className: "col-span-2 ",
                    list: [
                        {label: "Single Product", value: 1},
                        {label: "Grouped Product", value: 2},
                    ],
                },
                {
                    name: "title",
                    label: "Product",
                    placeholder: "ex. Omron M2 Basic Blood Preassure Monitor",
                    type: "Input",
                    defaultValue: formDefaultValue?.title || null,
                    validation: Yup.string().required("Product title is required"),
                    className: "col-span-2",
                },
                {
                    name: "brand_id",
                    label: "Brand",
                    placeholder: "Select Brand",
                    api: {
                        url: "/api/v1/brands?pagination=false",
                        query: "&filter[name]={query}",
                        optionValue: (item) => ({
                            value: item.id,
                            label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                        }),
                    },
                    addNew: (formData) => ({
                        api: "brands",
                        data: (value) => ({name: value}),
                    }),
                    className: "col-span-1 !w-[311px]",
                    type: "Select",
                    defaultValue: formDefaultValue?.brand?.id || formDefaultValue?.brand_id || null,
                    validation: Yup.string().required("Brand is required"),
                },
                {
                    name: "assigned_user_id",
                    label: "Assign",
                    placeholder: "Select Employee",
                    type: "Module",
                    className: "col-span-1 !w-[311px]",
                    module: Assigned,
                    defaultValue: formDefaultValue?.brand?.assigned?.employee?.id || formDefaultValue?.assigned_user_id || null,
                },
                {
                    name: "category_id",
                    label: "Category",
                    placeholder: "Select Category",
                    type: "Select",
                    features: {
                        formatOptionLabel: (child) => (
                            <div className={"flex flex-row items-center gap-[8px]"}>
                                <span className={"text-gray-800"}>{child?.data?.name}</span>
                            </div>
                        ),
                    },
                    api: {
                        url: constants.APIURL.GET_CATEGORIES + '?filter[status]=active',
                        view: constants.APIURL.GET_CATEGORIES,
                        query: "&filter[name]={query}",
                        optionValue: (item) => ({
                            value: item.id,
                            label: item.name,
                        }),
                    },
                    optional : true,
                    defaultValue: formDefaultValue?.category_id || formDefaultValue?.category_id || null,
                    className: "col-span-2",
                },
                {
                    name: "image",
                    label: "Upload Image",
                    placeholder: "Select a Single Product to add more to the Grouped Product",
                    type: "Module",
                    module: ProductImage,
                    className: "col-span-2",
                    optional : true,
                    defaultValue: formDefaultValue?.image || null,
                },
                {
                    name: "products",
                    type: "Module",
                    label : "Components",
                    module: UI.ProductList,
                    defaultValue: formDefaultValue?.groups || formDefaultValue?.products || [],
                    className: "col-span-2",
                    placeholder: "Select a Single Product to add more to the Grouped Product",
                    condition: (data) => data?.type === 2,
                },
                {
                    name: "unit_id",
                    defaultValue: 1,
                    type: "Hidden",
                }
            ]
        )
    });
};
export default formView;

const Assigned = ({item, binded, change, data}) => {
    const [assign, setAssign] = useState();
    const [disabled, setDisabled] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    React.useEffect(() => {
     
        change(assign?.id, "assigned_user_id");
    }, [assign]);

    React.useEffect(() => {
        // Update the state only when 'brand_id' changes
        if (binded?.brand_id) {
            const newAssign = binded.brand_id.data?.assigned?.id;
            if (newAssign) {
                fetchEmployeeData(newAssign);
            } else {
                setAssign(null);
                setDisabled(false);
            }
        }
    }, [binded?.brand_id]);

    const fetchEmployeeData = async (employeeId) => {
        try {
            const response = await axios.get(`${constants.APIURL.GET_EMPLOYEE}/${employeeId}`);
            const data = response?.data;
            if (data?.type === 'success') {
                setDisabled(true);
                setAssign(data?.data);
                setSelectedOption({value: data.data.id, label: data.data.name, data: data.data}); // Set the selected option
            }
        } catch (error) {
            console.error("Error fetching employee data:", error);
        }
    };

    const handleSelectChange = (selectedOption) => {
        change(selectedOption, "assigned_user_id"); // Use selectedOption's value
    };

    return (
        <>
            <div>
                <Select
                    key='product_new_brand'
                    id="product_new_brand"
                    item={{
                        name: "assigned_user_id",
                        label: "Assign",
                        placeholder: "Select Employee",
                        labelClassName: "hidden",
                        api: {
                            url: constants.APIURL.GET_EMPLOYEE + '?filter[status]=active',
                            view: constants.APIURL.GET_EMPLOYEE,
                            query: "&filter[name]={query}",
                            optionValue: (item) => ({
                                value: item.id,
                                label: item.name,
                            }),
                        },
                        disabled: disabled,
                    }}
                    value={selectedOption?.value} // Use selectedOption directly as the value
                    handleChange={handleSelectChange} // Pass the function reference without ()
                    selectedValue={selectedOption}
                    bind={(a, b) => {
                        return null;
                    }}
                />
            </div>
        </>
    );
};
  
